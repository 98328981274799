import axiosInstance from 'helpers/axiosInstance';
import { toast } from 'react-toastify';

export const loadYachtOptions = (input, callback) => {
	axiosInstance.get(`${window.location.origin}/persons/FindUsers?FirstName=${input}&take=20`).then(x => {
		callback(x.data.data);
	}).catch(e => {
		toast.error("An error occurred while fetching data.", { theme: 'colored' });
	})
}

export const loadEBOptions = async (input, loadOptions, { page }) => {
	let resp = await axiosInstance.get(`${window.location.origin}/suppliers?Name=${input}&page=${page}&take=20`);
	return {
		options: resp.data.data,
		hasMore: resp.data.data.length == 20,
		additional: {
			page: page + 1,
		},
	};
}

