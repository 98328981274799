import UserUpdate from 'components/users/UserEditForm';



const EditBrokerForm = (props) => {
    return (
        <>
            <UserUpdate isBroker={true} />
        </>
    );
};

export default EditBrokerForm;