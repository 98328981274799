import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Col, Row, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';
import TextFilter from 'components/filters/textFilter';
import NumberFilter from 'components/filters/numberFilter';
import DateRangeFilter from 'components/filters/daterangeFilter';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import axiosInstance from 'helpers/axiosInstance';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

//TODO: SYNCHRONIZE CA & RETAIL SORTED COLUMNS AT DISPLAY!

const columnsRE = [
	{
		accessor: 'contract.yachtName',
		Header: 'Yacht',
		sort: true,
		Cell: rowData => {
			const { yachtId, yachtName } = rowData.row.original.contract
			return (
				<Link target="_blank" onClick={(e) => e.stopPropagation()} to={`/components/yachts/edit?id=${yachtId}`}>{yachtName}</Link>
			)
		}
	},
	{
		accessor: 'contract.fileName',
		Header: 'File number',
		sort: true,
		Cell: rowData => {
			const { id, fileName } = rowData.row.original.contract
			return (
				<Link target="_blank" onClick={(e) => e.stopPropagation()} to={`/components/contracts/editSecondPage?id=${id}`}>{fileName}</Link>
			)
		}
	},
	{
		accessor: 'contract.contractDate',
		Header: 'Contract date',
		sort: true,
		Cell: rowData => {
			const { contractDateToString } = rowData.row.original.contract;
			return <span>{contractDateToString}</span>
		}
	},
	{
		accessor: 'contract.placeFrom',
		Header: 'Cruising',
		sort: true,
		Cell: rowData => {
			const { placeFrom, placeTo } = rowData.row.original.contract;
			return <span>{placeFrom ? `${placeFrom} - ` : ""}{placeTo}</span>
		}
	},
	{
		accessor: 'contract.clientName',
		sort: true,
		Header: 'Client'
	},
	{
		accessor: 'contract.supplierName',
		sort: true,
		Header: 'External broker'
	},
	{
		accessor: 'contract.rentalPeriod',
		sort: true,
		Header: 'Rental period'
	},
	{
		accessor: 'retailBroker',
		sort: true,
		Header: 'Broker',
	},

	{
		accessor: 'mySeaBroker',
		sort: true,
		Header: 'MySea broker',
		Cell: rowData => {
			const { mySeaBroker, mySeaBrokerID } = rowData.row.original
			return (
				<Link onClick={(e) => e.stopPropagation()} to={`/components/brokers/edit?id=${mySeaBrokerID}`}>{mySeaBroker}</Link>
			)
		}
	},
	{
		accessor: 'contract.status',
		sort: true,
		Header: 'Status',
		Cell: rowData => {
			const { status } = rowData.row.original.contract;
			return (
				<SoftBadge pill bg={status == "On Going" ? "success" :
					status == "Cancel with comm." ? "warning" :
						status == "Cancel without comm." ? "warning" :
							status == "Closed" ? "danger" :
								status == "Postponed Y+1" ? "info" : ""
				}
				>
					<span style={{ fontSize: "12px" }}>{status}</span>
				</SoftBadge>
			)
		}
	},
	{
		accessor: 'rE_TotalCharterFee',
		sort: true,
		Header: 'Charter fee',
	},
	{
		accessor: 'apa',
		sort: true,
		Header: 'APA',
		Cell: rowData => {
			const { alert_APA, apa } = rowData.row.original;
			if (alert_APA != 0 && alert_APA != null) {
				return (
					<span style={{ fontSize: "12px", color: alert_APA == 2 ? "red" : alert_APA == 1 ? "orange" : "green" }}>{apa}</span>
				)
			} else return apa;
		}
	},
	{
		accessor: 'deposit',
		sort: true,
		Header: 'Security deposit',
	},
	{
		accessor: 'deliveryFee',
		sort: true,
		Header: 'Delivery fee',
	},
	{
		accessor: 'tva',
		sort: true,
		Header: 'VAT',
		Cell: rowData => {
			const { alert_VAT, tva } = rowData.row.original;
			if (alert_VAT != 0 && alert_VAT != null) {
				return (
					<span style={{ fontSize: "12px", color: alert_VAT == 2 ? "red" : alert_VAT == 1 ? "orange" : "green" }}>{tva}</span>
				)
			} else return tva;
		}
	}
	,
	{
		accessor: 'cfOwnerToPay',
		sort: true,
		Header: 'Owner to pay',
	}
	,
	{
		accessor: 'brokerToPay',
		sort: true,
		Header: 'Broker to pay',
		Cell: rowData => {
			const { alert_B2P, brokerToPay } = rowData.row.original;
			if (alert_B2P) {
				return (
					<span style={{ fontSize: "12px", color: "red" }}>{brokerToPay}</span>
				)
			} else return brokerToPay;
		}

	},
	{
		accessor: 'marginMySea',
		sort: true,
		Header: 'MySea margin',
		Cell: rowData => {
			const { alert_MS, marginMySea } = rowData.row.original;
			if (alert_MS) {
				return (
					<span style={{ fontSize: "12px", color: "red" }}>{marginMySea}</span>
				)
			} else return marginMySea;
		}
	}
	,
	{
		accessor: 'cfOwnerToPay2',
		sort: true,
		Header: 'Owner to pay n+1',
	},
	{
		accessor: 'brokerToPayN1',
		sort: true,
		Header: 'Broker to pay n+1',
	}
	,
	{
		accessor: 'marginMySeaN1',
		sort: true,
		Header: 'MySea margin n+1',
	},
	{
		accessor: 'referralCie',
		sort: true,
		Header: 'Referral to pay',
	}
];
const columnsCA = [
	{
		accessor: 'contract.yachtName',
		Header: 'Yacht',
		sort: true,
		Cell: rowData => {
			const { yachtId, yachtName } = rowData.row.original.contract
			return (
				<Link target="_blank" onClick={(e) => e.stopPropagation()} to={`/components/yachts/edit?id=${yachtId}`}>{yachtName}</Link>
			)
		}
	},
	{
		accessor: 'contract.fileName',
		Header: 'File number',
		sort: true,
		Cell: rowData => {
			const { id, fileName } = rowData.row.original.contract
			if (id == -1) {
				return (<strong>TOTAL</strong>)
			}
			return (
				<Link target="_blank" onClick={(e) => e.stopPropagation()} to={`/components/contracts/editSecondPage?id=${id}`}>{fileName}</Link>
			)
		}
	},
	{
		accessor: 'contract.contractDate',
		sort: true,
		Header: 'Contract date',
		Cell: rowData => {
			const { contractDateToString } = rowData.row.original.contract;
			return <span>{contractDateToString}</span>
		}
	},
	{
		accessor: 'contract.placeFrom',
		sort: true,
		Header: 'Cruising',
		Cell: rowData => {
			const { placeFrom, placeTo } = rowData.row.original.contract;
			return <span>{placeFrom ? `${placeFrom} - ` : ""}{placeTo}</span>
		}
	},
	{
		accessor: 'contract.clientName',
		sort: true,
		Header: 'Client'
	},
	{
		accessor: 'contract.supplierName',
		sort: true,
		Header: 'External broker'
	},
	{
		accessor: 'contract.rentalPeriod',
		sort: true,
		Header: 'Rental period'
	},
	{
		accessor: 'retailBroker',
		sort: true,
		Header: 'Broker',
	},
	{
		accessor: 'mySeaBroker',
		sort: true,
		Header: 'MySea broker',
		Cell: rowData => {
			const { mySeaBroker, mySeaBrokerID } = rowData.row.original
			return (
				<Link target="_blank" onClick={(e) => e.stopPropagation()} to={`/components/brokers/edit?id=${mySeaBrokerID}`}>{mySeaBroker}</Link>
			)
		}
	},
	{
		accessor: 'contract.status',
		sort: true,
		Header: 'Status',
		Cell: rowData => {
			const { status } = rowData.row.original.contract;

			return (
				<SoftBadge pill bg={status == "On Going" ? "success" :
					status == "Cancel with comm." ? "warning" :
						status == "Cancel without comm." ? "warning" :
							status == "Closed" ? "danger" :
								status == "Postponed Y+1" ? "info" : ""
				}
				>
					<span style={{ fontSize: "12px" }}>{status}</span>
				</SoftBadge>
			)
		}
	},
	{
		accessor: 'cA_TotalCharterFee',
		sort: true,
		Header: 'Charter fee',
		Cell: rowData => {
			const { cA_TotalCharterFee } = rowData.row.original;
			return (
				<span style={{ display: 'block', textAlign: 'right' }}>{cA_TotalCharterFee}</span>
			)
		}

	},
	{
		accessor: 'apa',
		sort: true,
		Header: 'APA',
		Cell: rowData => {
			const { alert_APA, apa } = rowData.row.original;
			if (alert_APA != 0 && alert_APA != null) {
				return (
					<span style={{ fontSize: "12px", display: 'block', textAlign: 'right', color: alert_APA == 2 ? "red" : alert_APA == 1 ? "orange" : "green" }}>{apa}</span>
				)
			} else return (<span style={{ fontSize: "12px", display: 'block', textAlign: 'right' }} >{apa}</span>);
		}
	}
	,
	{
		accessor: 'deposit',
		sort: true,
		Header: 'Security deposit',
		Cell: rowData => {
			const { deposit } = rowData.row.original;
			return (
				<span style={{ display: 'block', textAlign: 'right' }}>{deposit}</span>
			)
		}
	}
	,
	{
		accessor: 'deliveryFee',
		sort: true,
		Header: 'Delivery fee',
		Cell: rowData => {
			const { deliveryFee } = rowData.row.original;
			return (
				<span style={{ display: 'block', textAlign: 'right' }}>{deliveryFee}</span>
			)
		}
	}
	,
	{
		accessor: 'tva',
		sort: true,
		Header: 'VAT',
		Cell: rowData => {
			const { alert_VAT, tva } = rowData.row.original;
			if (alert_VAT != 0 && alert_VAT != null) {
				return (
					<span style={{ fontSize: "12px", display: 'block', textAlign: 'right', color: alert_VAT == 2 ? "red" : alert_VAT == 1 ? "orange" : "green" }}>{tva}</span>
				)
			} else return (<span style={{ fontSize: "12px", display: 'block', textAlign: 'right' }} >{tva}</span>);
		}
	}
	,
	{
		accessor: 'retailCie',
		sort: true,
		Header: 'Retail Cie',
		Cell: rowData => {
			const { alert_RC, retailCie } = rowData.row.original;
			if (alert_RC) {
				return (
					<span style={{ fontSize: "12px", color: "red", display: 'block', textAlign: 'right' }}>{retailCie}</span>
				)
			} else return (<span style={{ fontSize: "12px", display: 'block', textAlign: 'right' }} >{retailCie}</span>);
		}
	}
	,
	{
		accessor: 'cfOwnerToPay',
		sort: true,
		Header: 'Owner to pay',
		Cell: rowData => {
			const { cfOwnerToPay } = rowData.row.original;
			return (
				<span style={{ display: 'block', textAlign: 'right' }}>{cfOwnerToPay}</span>
			)
		}
	}
	,
	{
		accessor: 'brokerToPay',
		sort: true,
		Header: 'Broker to pay',
		Cell: rowData => {
			const { alert_B2P, brokerToPay } = rowData.row.original;
			if (alert_B2P) {
				return (
					<span style={{ fontSize: "12px", color: "red", display: 'block', textAlign: 'right' }}>{brokerToPay}</span>
				)
			} else return (<span style={{ fontSize: "12px", display: 'block', textAlign: 'right' }} >{brokerToPay}</span>);
		}
	},
	{
		accessor: 'marginMySea',
		sort: true,
		Header: 'MySea margin',
		Cell: rowData => {
			const { alert_MS, marginMySea } = rowData.row.original;
			if (alert_MS) {
				return (
					<span style={{ fontSize: "12px", color: "red", display: 'block', textAlign: 'right' }}>{marginMySea}</span>
				)
			} else return (<span style={{ fontSize: "12px", display: 'block', textAlign: 'right' }} >{marginMySea}</span>);
		}

	}
	,
	{
		accessor: 'cfOwnerToPay2',
		sort: true,
		Header: 'Owner to pay n+1',
		Cell: rowData => {
			const { cfOwnerToPay2 } = rowData.row.original;
			return (
				<span style={{ display: 'block', textAlign: 'right' }}>{cfOwnerToPay2}</span>
			)
		}
	}
	,
	{
		accessor: 'brokerToPayN1',
		sort: true,
		Header: 'Broker to pay n+1',
		Cell: rowData => {
			const { brokerToPayN1 } = rowData.row.original;
			return (
				<span style={{ display: 'block', textAlign: 'right' }}>{brokerToPayN1}</span>
			)
		}
	},
	{
		accessor: 'marginMySeaN1',
		sort: true,
		Header: 'MySea margin n+1',
		Cell: rowData => {
			const { marginMySeaN1 } = rowData.row.original;
			return (
				<span style={{ display: 'block', textAlign: 'right' }}>{marginMySeaN1}</span>
			)
		}
	},
	{
		accessor: 'referralCie',
		sort: true,
		Header: 'Referral to pay',
		Cell: rowData => {
			const { referralCie } = rowData.row.original;
			return (
				<span style={{ display: 'block', textAlign: 'right' }}>{referralCie}</span>
			)
		}
	}
];

const TransactionList = (props, ref) => {
	const [stateRE, setStateRE] = useState({
		sortBy: [
			{ id: 'contract.contractDate', desc: true }
		],
		total: 0,
		totalPages: 0,
		remoteData: [],
		pageSize: 10,
		currentPage: 0
	});
	const [stateCA, setStateCA] = useState({
		sortBy: [
			{ id: 'contract.contractDate', desc: true }
		],
		total: 0,
		totalPages: 0,
		remoteData: [],
		pageSize: 10,
		currentPage: 0
	});
	const [isLoading, showLoading] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState({ id: 0, name: "" });
	const [contractStatuses, setContractStatuses] = useState({ data: [] });
	const [exportLoading, setExportLoading] = useState(false);
	const [showFilters, setShowFilters] = useState(true);
	const [allOffices, setAllOffices] = useState({ data: [] });
	const [key, setKey] = useState('CA');

	const fetchIdRef = React.useRef(0);

	useEffect(
		() => {
			let req1 = axiosInstance.get(`${window.location.origin}/parameters`);
			let req2 = axiosInstance.get(`${window.location.origin}/offices/simplified?take=200`)
			axios.all(
				[
					req1,
					req2
				]
			).then(axios.spread((...responses) => {
				setContractStatuses(o => ({
					data: responses[0].data.contractStatuses.values
				}));
				setAllOffices(o => ({
					data: responses[1].data
				}));
			}
			));
		},
		[],
	);

	useImperativeHandle(ref, () => ({
		test: (id) => {
			$("#officeFilter").val(id);
			$("#searchBtn").trigger("click");
		}
	}))

	const getTransactions = React.useCallback(({ page, size, sortBy }) => {
		// Give this fetch an ID
		const fetchId = ++fetchIdRef.current;

		// Only update the data if this is the latest fetch
		if (fetchId === fetchIdRef.current) {
			showLoading(true);
			let formQuery = $("#tableFilters").serialize();
			formQuery += `${(formQuery.length > 0 ? "&" : "")}page=${page}`;
			formQuery += `&take=${size}`;
			const statusType = $("#statusFilter").val();
			if (statusType) formQuery += "&StatusName=" + $("#statusFilter option:selected").text().trim();
			if (sortBy && sortBy.length > 0) {
				let sortQuery = encodeURIComponent(sortBy.map(x => `${x.id} ${x.desc ? "desc" : "asc"}`).join(','));
				formQuery += `&sort=${sortQuery}`;
			}
			let officeId = $("#officeFilter").val();
			if (officeId) formQuery += "&officeId=" + officeId;

			axiosInstance.get(`${window.location.origin}/offices/simplified?take=200`).then((res) => {
				console.log(res.data);
				setAllOffices({ data: res.data })
			})
			axiosInstance.get(`${window.location.origin}/widgets/CalcSummary?${formQuery}`)
				.then((res) => {
					setStateCA({
						sortBy: sortBy,
						total: res.data.countCa,
						totalPages: Math.ceil(res.data.countCa / (size * 1.0)),
						remoteData: res.data.ca,
						pageSize: size,
						currentPage: page
					});
					setStateRE({
						sortBy: sortBy,
						total: res.data.countRe,
						totalPages: Math.ceil(res.data.countRe / (size * 1.0)),
						remoteData: res.data.retail,
						pageSize: size,
						currentPage: page
					});
					showLoading(false);
				});

		}
	}, []);

	const search = () => {
		getTransactions({ page: 0, size: stateCA.pageSize, sortBy: stateCA.sortBy });
	};
	const handleSelectChange = (e, type) => {
		switch (type) {
			case "contractStatus": {
				let type = contractStatuses.data.find(x => x.id == e.target.value);
				if (type != null)
					setSelectedStatus({ id: type.id, name: type.name });
				break;
			}
		}
	}

	const loadContractStatusOptions = () => {
		if (contractStatuses.data) {
			return (
				<>
					<option value={0} key={"NonSelectorT"} disabled>All status</option> {/* By default On going */}
					{
						contractStatuses.data.map(status => (<option value={status.id} key={status.id}>
							{`${status.name}`}
						</option>))
					}
				</>
			);
		}
	}

	const exportTableToExcel = () => {
		setExportLoading(true);
		let formQuery = $("#tableFilters").serialize();
		let officeId = $("#officeFilter").val();
		if (officeId) formQuery += "&officeId=" + officeId;
		if (selectedStatus.name) formQuery += "&StatusName=" + selectedStatus.name
		axiosInstance.get(`${window.location.origin}/contracts/export-resume?${formQuery}`,
			{ responseType: 'blob' })
			.then(response => {
				let blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = `resume${moment(new Date()).format("DDMMYYYY")}.xlsx`
				link.click()
				setExportLoading(false);
			}).catch(e => {
				toast.error("There was an error exporting the data", { theme: 'colored' })
				setExportLoading(false);
			})
	}

	const transactionTable = () => {
		/*Filters*/
		const renderFilters = (visible) => {
			if (!visible) {
				return (<></>);
			} else {
				return (
					<>
						<form id="tableFilters">
							<Row className="mb-3 g-3">
								<Col lg={2}>
									<TextFilter label="Yacht" id="YachtName" placeholder="search..." />
								</Col>
								<Col lg={2}>
									<TextFilter label="File number" id="FileName" placeholder="search..." />
								</Col>
								<Col lg={2}>
									<DateRangeFilter label="Contract date" id='ContractDate' />
								</Col>
								<Col lg={1}>
									<TextFilter label="From" id="PlaceFrom" placeholder="search..." />
								</Col>
								<Col lg={1}>
									<TextFilter label="To" id="PlaceTo" placeholder="search..." />
								</Col>
								<Col lg={2}>
									<TextFilter label="Client" id="ClientName" placeholder="search..." />
								</Col>
								<Col lg={2}>
									<TextFilter label="External broker" id="SupplierName" placeholder="search..." />
								</Col>
								<Col lg={2}>
									<DateRangeFilter label="Rental period" id="RentalPeriod" placeholder="search..." />
								</Col>
								<Col lg={2}>
									<NumberFilter label="Number of days" id="Nights" />
								</Col>
								<Col lg={2}>
									<TextFilter label="Broker" id="Broker" placeholder="search..." />
								</Col>
								<Col lg={2}>
									<TextFilter label="MySea broker" id="MySeaBroker" placeholder="search..." />
								</Col>
								<Col lg={2}>
									<Form.Group>
										<Form.Label>Office</Form.Label>
										<Form.Select id="officeFilter" className="me-2">
											<option selected="selected" value={""}>
												All offices
											</option>
											{allOffices.data.map((x, index) =>
												<option key={x.id} value={x.id}>
													{x.name ? x.name : (x.city + ", " + x.country)}
												</option>
											)}
										</Form.Select>
									</Form.Group>
								</Col>
								<Col lg={2}>
									<Form.Group>
										<Form.Label>Status</Form.Label>
										<Form.Select id="statusFilter" className="me-2" required onChange={e => { handleSelectChange(e, "contractStatus") }} value={selectedStatus.id}>
											{loadContractStatusOptions()}
										</Form.Select>
									</Form.Group>
								</Col>
							</Row>
						</form>
					</>
				);
			}
		}
		console.log(stateRE);

		/*Table layout*/
		return (
			<>
				{/*Filters row*/}
				{renderFilters(showFilters)}

				<Tabs
					id="controlled-tab-example"
					activeKey={key}
					onSelect={(k) => setKey(k)}
					className="mb-1 list-inline mx-auto justify-content-center"
				>
					<Tab eventKey="CA" title="CA">
						<AdvanceTableWrapper
							columns={columnsCA}
							sortable
							initialSortBy={stateCA.sortBy}
							pagination
							perPage={stateCA.pageSize}
							data={stateCA.remoteData}
							pageCount={stateCA.totalPages}
							fetchData={getTransactions}
							initialPage={stateCA.currentPage}
							isLoading={isLoading}
						>
							<AdvanceTable
								table
								headerClassName="bg-200 text-900 text-nowrap align-middle"
								rowClassName="align-middle white-space-nowrap"
								tableProps={{
									bordered: true,
									striped: true,
									className: 'fs--1 mb-0 overflow-hidden'
								}}
							/>
							<div className="mt-3">
								<AdvanceTableFooter
									AnInsertedRowInBackend={true}
									rowCount={stateCA.total}
									pageCount={stateCA.totalPages}
									table
									rowInfo
									navButtons
									rowsPerPageSelection
								/>
							</div>
						</AdvanceTableWrapper>
					</Tab>
					<Tab eventKey="Retail" title="Retail">
						<AdvanceTableWrapper
							columns={columnsRE}
							sortable
							initialSortBy={stateRE.sortBy}
							pagination
							perPage={stateRE.pageSize}
							data={stateRE.remoteData}
							pageCount={stateRE.totalPages}
							fetchData={getTransactions}
							initialPage={stateRE.currentPage}
							isLoading={isLoading}
						>
							<AdvanceTable
								table
								headerClassName="bg-200 text-900 text-nowrap align-middle"
								rowClassName="align-middle white-space-nowrap"
								tableProps={{
									bordered: true,
									striped: true,
									className: 'fs--1 mb-0 overflow-hidden'
								}}
							/>
							<div className="mt-3">
								<AdvanceTableFooter
									AnInsertedRowInBackend={true}
									rowCount={stateRE.total}
									pageCount={stateRE.totalPages}
									table
									rowInfo
									navButtons
									rowsPerPageSelection
								/>
							</div>
						</AdvanceTableWrapper>
					</Tab>
				</Tabs>
			</>
		);
	}

	return (
		<>
			<FalconComponentCard>
				<FalconComponentCard.Header
					light={false}
					className="border-bottom border-200"
				>
					<div className="d-inline">
						<h4 className="float-start">Contract resume</h4>
						<div className="float-end">
							{showFilters && <Button variant='secondary' size='sm' className='me-2 px-4' id="searchBtn" onClick={search}>
								<FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className='pe-1' />Search</Button>}
							<IconButton
								variant="falcon-default"
								size="sm"
								icon="fa-filter"
								transform="shrink-3"
								className='me-2'
								onClick={() => setShowFilters(!showFilters)}
							>
								<span className="d-none d-sm-inline-block ms-1">Filters</span>
							</IconButton>
							<IconButton
								variant="falcon-default"
								size="sm"
								icon="external-link-alt"
								transform="shrink-3"
								onClick={exportTableToExcel}
							>
								{exportLoading ? (<Spinner animation="border" role="status" size="sm" className='ms-2'>
									<span className="visually-hidden">Loading...</span>
								</Spinner>) : "Export"}
							</IconButton>
						</div>
					</div>
				</FalconComponentCard.Header>
				<FalconComponentCard.Body
					children={transactionTable()}
					scope={{
						AdvanceTableWrapper,
						AdvanceTable,
						AdvanceTableFooter,
						AdvanceTableSearchBox,
						IconButton,
						Link
					}}
					language="jsx"
					noInline
					noLight
				/>
			</FalconComponentCard>
		</>
	);
};

export default forwardRef(TransactionList);
