import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Form, FloatingLabel, Button, Spinner, Table, InputGroup, ToggleButton, Modal, DropdownButton, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import CreateOfficeForm from 'components/offices/create'
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import axiosInstance from 'helpers/axiosInstance';
import { round } from 'helpers/utils';
import { toast } from 'react-toastify';

const ContractCommissionDetail = ({
	formData,
	setFormData,
	officesData,
	handleOfficeDetails,
	setIsOnClickComms,
	handleCommAmountOnClick,
	isClickComms,
	formatter,
	brokersData,
	handleBrokerDetails,
	brokerAdvancedCommission,
	calcBrokerAdvancedCommissionChange,
	setReadjustPayment,
	role
}) => {

	const [showModalOffice, setShowModalOffice] = useState(false);
	const modalBodyOfficeContent = useRef();
	const onOfficeSave = (office) => {
		setFormData({ ...formData, office: { id: office.id, name: office.name } })
		setShowModalOffice(false);
	}

	const loadBrokersOptions = (input, callback) => {
		if (role == "Administrator") {
			axiosInstance.get(`${window.location.origin}/brokers/FindBrokers?Name=${input}&page=0&take=5`).then(x => {
				callback(x.data.data);
			}).catch((e => {
				let errMsg = "Error getting brokers"
				if (e.response.status == 403)
					errMsg = "You are not authorized";
				toast.error(errMsg, { theme: 'colored' })
			}))
		} else {
			callback([]);
		}
	}

	const handleOfficeCommissionChange = e => {
		try {
			let officeCommission = 0;
			let vatComm = 0;
			let comm = 0;
			if (e.target.id === 'comm' || e.target.id === "isTaxIncludedOffice") {
				officeCommission = formData.charter.charterFeeAmount / 100 * (e.target.id === "isTaxIncludedOffice" ? formData.officeCommission?.comm : Number(e.target.value));
				officeCommission = Math.round(officeCommission * 100) / 100;
				comm = e.target.id === "comm" ? e.target.value : formData.officeCommission?.comm;
			} else {
				officeCommission = (e.target.value);
				comm = round((officeCommission / formData.charter.charterFeeAmount), 9) * 100;
			}
			vatComm = Math.round((formData.charter.vat / 100 * comm) * 100 / 100);
			let msComm = officeCommission / 100 * formData.mySeaBrokerCommission?.comm;
			setFormData({
				...formData,
				officeCommission: {
					...formData.officeCommission,
					vat: (e.target.id === "isTaxIncludedOffice") ? (e.target.checked ? vatComm : 0) : (!formData.officeCommission?.isTaxIncluded) ? 0 : vatComm,
					amount: officeCommission,
					isTaxIncluded: e.target.id === "isTaxIncludedOffice" ? e.target.checked : formData.officeCommission?.isTaxIncluded,
					comm: comm
				},
				mySeaBrokerCommission: {
					...formData.mySeaBrokerCommission,
					amount: msComm
				}
			});
			// calcBrokerAdvancedCommissionChange(formData.brokerAdvanceComm, msComm, officeCommission, formData.intermediaryCommission?.amount);
			if (formData.id > 0) {
				console.log("HERE");
				setReadjustPayment(true);
			}
		} catch {
			console.error("WHAR");
		}
	}

	const handleIntermidiaryCommissionChange = e => {
		if (e.target.id == "name")
			setFormData({ ...formData, intermediary: e.target.value });

		if (e.target.id === 'comm' || e.target.id === "isTaxIncludedApp" || e.target.id == "amount") {
			let commission = 0;
			let vatComm = 0;
			let comm = 0;

			if (e.target.id === 'comm' || e.target.id === "isTaxIncludedApp") {
				commission = formData.charter.charterFeeAmount / 100 * (e.target.id === "isTaxIncludedApp" ? formData.intermediaryCommission?.comm : e.target.value);
				commission = (Math.round(commission * 100) / 100);
				comm = e.target.id === "comm" ? (e.target.value) : formData.intermediaryCommission?.comm;
			} else {
				commission = (e.target.value);
				comm = round((commission / formData.charter.charterFeeAmount), 9) * 100;
			}
			vatComm = Math.round((formData.charter.vat / 100 * comm) * 100 / 100);
			let msComm = ((formData.officeCommission?.amount - commission) / 100) * (formData.mySeaBrokerCommission?.comm);
			msComm = (Math.round(msComm * 100) / 100);

			setFormData({
				...formData,
				mySeaBrokerCommission: { ...formData.mySeaBrokerCommission, amount: msComm },
				intermediaryCommission: {
					...formData.intermediaryCommission,
					amount: commission,
					vat: (e.target.id === "isTaxIncludedApp") ? (e.target.checked ? vatComm : 0) : (!formData.intermediaryCommission?.isTaxIncluded) ? 0 : vatComm,
					isTaxIncluded: e.target.id === "isTaxIncludedApp" ? e.target.checked : formData.intermediaryCommission?.isTaxIncluded,
					comm: comm
				}

			});


			// calcBrokerAdvancedCommissionChange(formData.brokerAdvanceComm, formData.mySeaBrokerCommission?.amount, formData.officeCommission?.amount, formData.intermediaryCommission?.amount);

		}
	}

	const handleRetailBrokerCommissionChange = e => {



		if (e.target.id == "name")
			setFormData({ ...formData, broker: e.target.value });



		if (e.target.id === 'comm' || e.target.id === "isTaxIncludedAgent" || e.target.id == "amount") {
			let commission = 0;
			let vatComm = 0;
			let comm = 0;

			if (e.target.id === 'comm' || e.target.id === "isTaxIncludedAgent") {
				commission = formData.charter.charterFeeAmount / 100 * (e.target.id === "isTaxIncludedAgent" ? formData.brokerCommission?.comm : e.target.value);
				commission = (Math.round(commission * 100) / 100);


				vatComm = formData.charter.vat / 100 * (e.target.id === "isTaxIncludedAgent" ? formData.brokerCommission?.comm : e.target.value);
				vatComm = (Math.round(vatComm * 100) / 100);
				comm = e.target.id === "comm" ? e.target.value : formData.brokerCommission?.comm;
				//if (installments.data.length >= 3) {
				//    let i1 = installments.data[0].amount;
				//    let i2 = installments.data[1].amount;
				//    commission = ((i1*(comm/2)) + (i2*(comm/2)))/100;
				//}
			} else {
				commission = (e.target.value);
				comm = round((commission / formData.charter.charterFeeAmount), 9) * 100;
				//if (installments.data.length >= 3) {
				//    let i1 = Number(installments.data[0].amount);
				//    let i2 = Number(installments.data[1].amount);
				//    comm = (100 * commission * 2)/(i1+i2)
				//}
			}
			vatComm = Math.round((formData.charter.vat / 100 * comm) * 100 / 100);

			setFormData({
				...formData,
				brokerCommission: {
					...formData.brokerCommission,
					amount: commission,
					vat: (e.target.id === "isTaxIncludedAgent") ? (e.target.checked ? vatComm : 0) : (!formData.brokerCommission?.isTaxIncluded) ? 0 : vatComm,
					isTaxIncluded: e.target.id === "isTaxIncludedAgent" ? e.target.checked : formData.brokerCommission?.isTaxIncluded,
					comm: comm
				}

			});
			if (formData.id > 0) {
				setReadjustPayment(true);
			}
		}
	}

	const handleMySeaBrokerCommissionChange = e => {

		let msComm = 0;
		let comm = 0;
		if (e.target.id == "comm") {
			msComm = ((formData.officeCommission?.amount - formData.intermediaryCommission?.amount) / 100) * Number(e.target.value);
			msComm = (Math.round(msComm * 100) / 100);
			comm = (e.target.value);
		} else {
			msComm = (e.target.value);
			comm = round((msComm / (formData.officeCommission?.amount - formData.intermediaryCommission?.amount)), 9) * 100;
		}
		setFormData({ ...formData, mySeaBrokerCommission: { ...formData.mySeaBrokerCommission, amount: msComm, comm: comm } });
		// calcBrokerAdvancedCommissionChange(formData.brokerAdvanceComm, msComm, formData.officeCommission?.amount, formData.intermediaryCommission?.amount);
	}

	const handleBrokerAdvancedCommissionChange = e => {
		calcBrokerAdvancedCommissionChange(Number(e.target.value), formData.mySeaBrokerCommission?.amount, formData.officeCommission?.amount, formData.intermediaryCommission?.amount);
	}


	return (
		<>
			<Card>
				<Card.Header className='d-flex justify-content-between align-items-center'>
					<h5>Commissions Details</h5>
					<div>
						<DropdownButton variant="primary" style={{ display: 'inline-block' }} size="sm" id="dropdown-basic-button" title="+ Create">
							{/* <Dropdown.Item onClick={() => { setShowModalBroker(true) }}>Create MySea broker</Dropdown.Item> */}
							<Dropdown.Item onClick={() => { setShowModalOffice(true) }}>Create Office</Dropdown.Item>
						</DropdownButton>
						{
							((role == "Administrator" && formData.id > 0) &&
								(<Link className='ms-2 btn btn-sm btn-primary' state={{ InvoiceString: formData.officeCommission?.invoice, OfficeAmount: formData.officeCommission?.amount, OfficePercent: formData.officeCommission?.comm }} to={`/components/contracts/${formData.id}/invoice`}>Preview office invoice</Link>))
						}
					</div>
				</Card.Header>
				<Card.Body className="bg-light">
					<Table responsive className="mb-0">
						<thead>
							<tr>
								<th scope="col"></th>
								<th scope="col">Comm</th>
								<th scope="col">Amount</th>
								<th scope="col">Tax</th>
								<th scope="col">Invoice</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td scope="col">
									<Select
										options={officesData.data}
										getOptionLabel={o => o.name ? o.name : (o.city + ', ' + o.country)}
										getOptionValue={o => o}
										placeholder='Select an office'
										value={formData.office}
										onChange={handleOfficeDetails}
									/>
								</td>
								<td scope="col" style={{ width: "150px", display: "block" }}>
									<InputGroup className='customInputGroupText w-100'>
										<ToggleButton
											id="isTaxIncludedOffice"
											type="checkbox"
											variant="outline-primary"
											onChange={handleOfficeCommissionChange}
											checked={formData.officeCommission?.isTaxIncluded}
										>
											Tax
										</ToggleButton>
										<Form.Control
											id="comm"
											type="number"
											onWheel={(e) => e.target.blur()}
											value={formData.officeCommission?.comm}
											onChange={handleOfficeCommissionChange}
											placeholder="Comm. %"
										/>
										<span className='valuePadding'>%</span>
									</InputGroup>
								</td>
								<td scope="col">
									{
										(isClickComms.office ?
											<Form.Control
												id="amount"
												name="officeCommAmount"
												type="number"
												onWheel={(e) => e.target.blur()}
												onBlur={(e) => { setIsOnClickComms({ ...isClickComms, office: !isClickComms.office }) }}
												value={formData.officeCommission?.amount}
												onChange={handleOfficeCommissionChange}
												placeholder="Comm. %"
											/>
											:
											<span onClick={(e) => { handleCommAmountOnClick("office") }}>{formatter.format(formData.officeCommission?.amount)}</span>)
									}

								</td>
								<td scope="col">
									<span>{formatter.format(formData.officeCommission?.vat)}</span>
								</td>
								<td scope="col">
									<Form.Control
										type="text"
										value={formData.officeCommission?.invoice}
										onChange={(e) => setFormData({
											...formData, officeCommission: {
												...formData.officeCommission,
												invoice: e.target.value
											}
										})}
										placeholder="Invoice"
										disabled={role != "Administrator"}
										id="invoice"
									/>
								</td>
							</tr>
							<tr>
								<td scope="col">
									<AsyncSelect
										getOptionLabel={option => option.person.fullName}
										getOptionValue={option => option}
										onChange={handleBrokerDetails}
										value={formData.mySeaBroker}
										loadOptions={loadBrokersOptions}
										defaultOptions
										placeholder="Select a broker"
										className="h-100"
										styles={{
											menuList: (baseStyles, state) => ({
												...baseStyles,
												backgroundColor: 'white',
											})
										}}
									>
									</AsyncSelect>

								</td>
								<td scope="col" style={{ width: "150px", display: "block" }}>
									<InputGroup className='customInputGroupText w-100'>
										<Form.Control
											type="number"
											id="comm"
											onWheel={(e) => e.target.blur()}
											value={formData.mySeaBrokerCommission?.comm}
											onChange={handleMySeaBrokerCommissionChange}
											placeholder="Comm. %"
										/>
										<span className='valuePadding'>%</span>
									</InputGroup>
								</td>
								<td scope="col">
									{
										(isClickComms.broker ?
											<Form.Control
												id="amount"
												name="brokerCommAmount"
												type="number"
												onWheel={(e) => e.target.blur()}
												onBlur={(e) => { setIsOnClickComms({ ...isClickComms, broker: !isClickComms.broker }) }}
												value={formData.mySeaBrokerCommission?.amount}
												onChange={handleMySeaBrokerCommissionChange}
												placeholder="Comm. %"
											/>
											:
											<span onClick={(e) => { handleCommAmountOnClick("broker") }}>{formatter.format(formData.mySeaBrokerCommission?.amount)}</span>)
									}

								</td>
								<td scope="col">

								</td>
								<td scope="col">
									<Form.Control
										type="text"
										value={formData.mySeaBrokerCommission?.invoice}
										onChange={(e) => setFormData({
											...formData, mySeaBrokerCommission: {
												...formData.mySeaBrokerCommission,
												invoice: e.target.value
											}
										})}
										placeholder="Invoice"
										disabled={role != "Administrator"}
										id="invoice"
									/>
								</td>
							</tr>
							<tr>
								<td scope="col">
									<Form.Control
										id="name"
										type="text"
										value={formData.broker}
										onChange={handleRetailBrokerCommissionChange}
										placeholder="Agent"
									/>
								</td>
								<td scope="col" style={{ width: "150px", display: "block" }}>
									<InputGroup className='customInputGroupText'>
										<ToggleButton
											id="isTaxIncludedAgent"
											type="checkbox"
											variant="outline-primary"
											onChange={handleRetailBrokerCommissionChange}
											checked={formData.brokerCommission?.isTaxIncluded}
										>
											Tax
										</ToggleButton>
										<Form.Control
											id="comm"
											type="number"
											onWheel={(e) => e.target.blur()}
											value={formData.brokerCommission?.comm}
											onChange={handleRetailBrokerCommissionChange}
											className="px-2"
											placeholder="Comm. %"
											required
										/>
										<span className='valuePadding'>%</span>
									</InputGroup>

								</td>
								<td scope="col">

									{
										(isClickComms.agent ?
											<Form.Control
												id="amount"
												name="agentCommAmount"
												type="number"
												onWheel={(e) => e.target.blur()}
												onBlur={(e) => { setIsOnClickComms({ ...isClickComms, agent: !isClickComms.agent }) }}
												value={formData.brokerCommission?.amount}
												onChange={handleRetailBrokerCommissionChange}
												placeholder="Comm. %"
											/>
											:
											<span onClick={(e) => { handleCommAmountOnClick("agent") }}>{formatter.format(formData.brokerCommission?.amount)}</span>)
									}
								</td>
								<td scope="col">
									{
										(isClickComms.agentVat ?
											<Form.Control
												id="vat"
												name="agentCommVat"
												type="number"
												onWheel={(e) => e.target.blur()}
												onBlur={(e) => { setIsOnClickComms({ ...isClickComms, agentVat: !isClickComms.agentVat }) }}
												value={formData.brokerCommission?.vat}
												onChange={(e) => {
													setFormData({
														...formData,
														brokerCommission: {
															...formData.brokerCommission,
															vat: e.target.value
														}
													})
												}}
											/>
											:
											<span className='text-decoration-underline text-primary cursor-pointer' onClick={(e) => { handleCommAmountOnClick("agentVat") }}>{formatter.format(formData.brokerCommission?.vat)}</span>)
									}
								</td>
								<td scope="col">
									<Form.Control
										type="text"
										value={formData.brokerCommission?.invoice}
										onChange={(e) => setFormData({
											...formData, brokerCommission: {
												...formData.brokerCommission,
												invoice: e.target.value
											}
										})}
										placeholder="Invoice"
										disabled={role != "Administrator"}
										id="invoice"
									/>
								</td>
							</tr>
							<tr>
								<td scope="col">
									<Form.Control
										id="name"
										type="text"
										value={formData.intermediary}
										onChange={handleIntermidiaryCommissionChange}
										placeholder="Referral"
									/>
								</td>
								<td scope="col" style={{ width: "150px", display: "block" }}>
									<InputGroup className='customInputGroupText w-100'>
										<ToggleButton
											id="isTaxIncludedApp"
											type="checkbox"
											variant="outline-primary"
											onChange={handleIntermidiaryCommissionChange}
											checked={formData.intermediaryCommission?.isTaxIncluded}
										>
											Tax
										</ToggleButton>
										<Form.Control
											id="comm"
											type="number"
											onWheel={(e) => e.target.blur()}
											value={formData.intermediaryCommission?.comm}
											onChange={handleIntermidiaryCommissionChange}
											placeholder="Comm. %"
											className='flex-fill'
											min={0}
											max={100}
											required
										/>
										<span className='valuePadding'>%</span>
									</InputGroup>

								</td>
								<td scope="col">
									{
										(isClickComms.intermediary ?
											<Form.Control
												id="amount"
												name="intermediaryCommAmount"
												type="number"
												onWheel={(e) => e.target.blur()}
												onBlur={(e) => { setIsOnClickComms({ ...isClickComms, intermediary: !isClickComms.intermediary }) }}
												value={formData.intermediaryCommission?.amount}
												onChange={handleIntermidiaryCommissionChange}
												placeholder="Comm. %"
											/>
											:
											<span onClick={(e) => { handleCommAmountOnClick("intermediary") }}>{formatter.format(formData.intermediaryCommission?.amount)}</span>)
									}
								</td>
								<td scope="col">
									<span>{formatter.format(formData.intermediaryCommission?.vat ? formData.intermediaryCommission?.vat : 0)}</span>
								</td>
								<td scope="col">
									<Form.Control
										type="text"
										value={formData.intermediaryCommission?.invoice}
										onChange={(e) => setFormData({
											...formData, intermediaryCommission: {
												...formData.intermediaryCommission,
												invoice: e.target.value
											}
										})}
										placeholder="Invoice"
										disabled={role != "Administrator"}
										id="invoice"
									/>
								</td>
							</tr>
							<tr>
								<td>
									<FloatingLabel label="Advanced">
										<Form.Control
											type="number"
											onWheel={(e) => e.target.blur()}
											value={formData.brokerAdvancedCommission?.brokerCommission}
											onChange={handleBrokerAdvancedCommissionChange}
											name="brokerCommission"
										/>
									</FloatingLabel>
								</td>
							</tr>
						</tbody>
					</Table>
				</Card.Body>
			</Card>
			<Modal
				show={showModalOffice}
				onHide={() => { setShowModalOffice(false) }}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header className='border-bottom-0'>
					<h4>Create office</h4>
				</Modal.Header>
				<Modal.Body className='bg-light'>
					<CreateOfficeForm
						ref={modalBodyOfficeContent}
						InModal={true}
						onSave={onOfficeSave}
					></CreateOfficeForm>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => { setShowModalOffice(false); }}>Cancel</Button>
					<Button variant="primary" onClick={() => modalBodyOfficeContent.current.save()}>
						<span id="ModalSaveBtn">Save</span>
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ContractCommissionDetail;
