import { Col, Row, Form, FloatingLabel } from 'react-bootstrap';

const UserDetail = ({ handleSubmit, formData, setRef, handleFieldChange, validated }) => {
     return (
          <Form validated={validated} onSubmit={handleSubmit} ref={setRef} className="first-form">
               <Row className="g-3">
                    <FloatingLabel as={Col} lg={6}
                         controlId="firstName"
                         label="First name"
                    >
                         <Form.Control
                              type="text"
                              value={formData.firstName}
                              onChange={handleFieldChange}
                              placeholder="First name"
                              required
                         />
                         <Form.Control.Feedback type="invalid">
                              Please enter a first name.
                         </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel as={Col} lg={6}
                         controlId="lastName"
                         label="Last name"
                    >
                         <Form.Control
                              type="text"
                              value={formData.lastName}
                              onChange={handleFieldChange}
                              placeholder="Last name"
                              required
                         />
                         <Form.Control.Feedback type="invalid">
                              Please enter a last name.
                         </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel as={Col} lg={6}
                         controlId="email"
                         label="Email"
                    >
                         <Form.Control
                              type="email"
                              value={formData.email}
                              onChange={handleFieldChange}
                              placeholder="Email"
                         />
                    </FloatingLabel>
                    <FloatingLabel as={Col} lg={6}
                         type="text"
                         controlId="phone"
                         label="Phone"
                    >
                         <Form.Control
                              type="number"
                              value={formData.phone}
                              onWheel={(e) => e.target.blur()}
                              onChange={handleFieldChange}
                              placeholder="Phone"
                         />
                         <Form.Control.Feedback type="invalid">
                              Please enter a valid phone number.
                         </Form.Control.Feedback>
                    </FloatingLabel>
               </Row>
          </Form>
     );
};

export default UserDetail;

