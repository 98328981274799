import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import TextFilter from 'components/filters/textFilter';
import NumberFilter from 'components/filters/numberFilter';
import axiosInstance from 'helpers/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const columns = [
	{
		accessor: 'person.firstName',
		Header: 'First name',
		sort: true
	},
	{
		accessor: 'person.lastName',
		Header: 'Last name',
		sort: true
	},
	{
		accessor: 'person.role',
		Header: 'Role',
		sort: true
	},
	{
		accessor: 'person.email',
		Header: 'Email',
		sort: true
	},
	{
		accessor: 'caCommission',
		Header: 'CA comm. %',
		sort: true
	},
	{
		accessor: 'retailCommission',
		Header: 'Retail comm. %',
		sort: true
	}
];

const BrokerList = () => {
	const [state, setState] = useState({
		sortBy: [
			{ id: 'person.firstName', desc: false },
//			{ id: 'person.lastName', desc: false },	//TODO: -> BUG IN API!!
		],
		total: 0,
		totalPages: 0,
		remoteData: [],
		pageSize: 10,
		currentPage: 0
	});
	const [isLoading, showLoading] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const fetchIdRef = React.useRef(0);
	const getBrokers = React.useCallback(({ page, size, sortBy }) => {
		// Give this fetch an ID
		const fetchId = ++fetchIdRef.current;

		// Only update the data if this is the latest fetch
		if (fetchId === fetchIdRef.current) {
			showLoading(true);
			let formQuery = $("#tableFilters").serialize();
			formQuery += `${(formQuery.length > 0 ? "&" : "")}page=${page}`;
			formQuery += `&take=${size}`;
			if (sortBy && sortBy.length > 0) {
				let sortQuery = encodeURIComponent(sortBy.map(x => `${x.id} ${x.desc ? "desc" : "asc"}`).join(','));
				formQuery += `&sort=${sortQuery}`;
			}
			axiosInstance.get(`${window.location.origin}/brokers/FindBrokers?${formQuery}`)
				.then((res) => {
					setState({
						sortBy: sortBy,
						total: res.data.count,
						totalPages: Math.ceil(res.data.count / (size * 1.0)),
						remoteData: res.data.data,
						pageSize: size,
						currentPage: page
					});
					showLoading(false);
				});

		}
	}, []);
	const search = () => {
		getBrokers({ page: 0, size: state.pageSize, sortBy: state.sortBy });
	};

	useEffect(() => {
		document.title = "Retail brokers";
	}, [])

	const brokerTable = () => {

		/*Filters*/
		const renderFilters = (visible) => {
			if (visible) {
				return (<></>);
			} else {
				return (
					<>
						<form id="tableFilters">
							<Row className="mb-3 g-3">
								<Col lg={2}>
									<TextFilter label="First Name" id="FirstName" placeholder="search..." />
								</Col>
								<Col lg={2}>
									<TextFilter label="Last Name" id="LastName" placeholder="search..." />
								</Col>
								<Col lg={2}>
									<TextFilter label="Role" id="Role" placeholder="search..." />
								</Col>
								{/*<Col lg={2}>
									<TextFilter label="Phone" id="Phone" placeholder="search..." />
								</Col>*/}
								<Col lg={2}>
									<TextFilter label="Email" id="Email" placeholder="search..." />
								</Col>
								<Col lg={2}>
									<NumberFilter label="CA comm. %" id="CACommission" />
								</Col>
								<Col lg={2}>
									<NumberFilter label="Retail comm. %" id="RetailCommission" />
								</Col>
							</Row>
						</form>
					</>);
			}
		}

		return (
			<>
				<AdvanceTableWrapper
					columns={columns}
					sortable
					initialSortBy={state.sortBy}
					pagination
					perPage={state.pageSize}
					data={state.remoteData}
					pageCount={state.totalPages}
					fetchData={getBrokers}
					initialPage={state.currentPage}
					isLoading={isLoading}
				>

					{/*Filter row*/}
					{renderFilters(showFilters)}

					<AdvanceTable
						trLinkById={`${window.location.origin}/components/brokers/edit`}
						table
						headerClassName="bg-200 text-900 text-nowrap align-middle"
						rowClassName="align-middle white-space-nowrap"
						tableProps={{
							bordered: true,
							striped: true,
							className: 'fs--1 mb-0 overflow-hidden'
						}}
					/>
					<div className="mt-3">
						<AdvanceTableFooter
							rowCount={state.total}
							pageCount={state.totalPages}
							table
							rowInfo
							navButtons
							rowsPerPageSelection
						/>
					</div>
				</AdvanceTableWrapper>
			</>
		);
	};

	return (
		<FalconComponentCard>
			<FalconComponentCard.Header
				light={false}
				className="border-bottom border-200"
			>
				<div className="d-flex flex-between-center">
					<Col md="auto" lg={6}>
						<h4>Retail brokers</h4>
					</Col>
					{/*Action Buttons*/}
					<Col md="auto" lg={6} className="text-end">
						{!showFilters && <Button variant='secondary' size='sm' className='me-2 px-4' onClick={search}>
							<FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className='pe-1' />Search</Button>}
						<IconButton
							variant="falcon-default"
							size="sm"
							icon="fa-filter"
							transform="shrink-3"
							className='me-2'
							onClick={() => setShowFilters(!showFilters)}
						>
							<span className="d-none d-sm-inline-block ms-1">Filters</span>
						</IconButton>
						<Link to="/components/brokers/create">
							<IconButton
								variant="primary"
								size="sm"
								icon="plus"
								transform="shrink-3"
							>
								<span className="d-none d-sm-inline-block ms-1">New</span>
							</IconButton>
						</Link>
						{/*<IconButton*/}
						{/*    variant="falcon-default"*/}
						{/*    size="sm"*/}
						{/*    icon="external-link-alt"*/}
						{/*    transform="shrink-3"*/}
						{/*    onClick={() => { window.location.replace(`${window.location.origin}/brokers/export-data?fileName=Brokers`); }}*/}
						{/*>*/}
						{/*    <span className="d-none d-sm-inline-block ms-1">Export</span>*/}
						{/*</IconButton>*/}
					</Col>
				</div>
			</FalconComponentCard.Header>
			<FalconComponentCard.Body
				children={brokerTable()}
				scope={{
					AdvanceTableWrapper,
					AdvanceTable,
					AdvanceTableFooter,
					IconButton,
					Link
				}}
				language="jsx"
				noInline
				noLight
			/>
		</FalconComponentCard>
	);
};

export default BrokerList;
