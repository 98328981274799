import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Form, FloatingLabel } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import UploadFormFiles from 'components/common/UploadFormFiles';
import YachtHeaderForm from './YachtHeaderForm';
import YachtCompanyForm from './YachtCompanyForm';
import YachtFinancialForm from './YachtFinancialForm';
import YachtEmailRecipientList from './YachtEmailRecipientList';
import YachtUserList from './YachtUserList';
import YachtRetailReferralCie from './YachtRetailReferralCie';
import YachtMyseBrokerDetail from './YachtMyseBrokerDetail';
import YachtMySeaOfficeDetail from './YachtMySeaOfficeDetail';
import YachtCADetail from './YachtCADetail';
import { loadEBOptions, loadEmailList, loadYachtOptions } from './yachtUtils';
import axiosInstance from 'helpers/axiosInstance';
import YachtRetailContactDetail from './YachtRetailContactDetail';

const YachtFormBase = ({
	formData,
	handleFieldChange,
	handleSubmit,
	validated,
	setFormData,
	currentId,
	loadYachtUserList,
	handleYachtDetails,
	attachmentsData,
	setAttachmentsData,
	emailList,
	setEmailList
}) => {
	const [Add, setAdd] = useState(-1);
	const [officeData, setOfficeData] = useState({});
	const [brokerData, setBrokerData] = useState({});
	const [brokersData, setBrokersData] = useState({ data: [] });
	const [attachmentKind, setAttachmentKind] = useState({ data: [] });
	const [officesData, setOfficesData] = useState({ data: [] });

	useEffect(() => {
		axiosInstance.get(`${window.location.origin}/offices?take=200`).then((result) => {
			setOfficesData(o => ({
				data: result.data.data
			}));
		});

		axiosInstance.get(`${window.location.origin}/brokers/FindBrokers?take=200`).then((result) => {
			setBrokersData(o => ({
				data: result.data.data
			}));
		});

		axiosInstance.get(`${window.location.origin}/parameters`).then((res) => {
			setAttachmentKind({ data: res.data.attachmentKinds.values });
		});

		console.log(currentId);
	}, []);

	const handleEmailDelete = (emailToDelete, InOrOut) => {
		let data = emailList.data;
		var objIndex = data.findIndex((item => item.email == emailToDelete && item.type == InOrOut));
		data.splice(objIndex, 1);
		setEmailList(
			{
				data: data
			});
		setFormData({ ...formData, emails: data });
	}
	const handleOfficeDetails = (value) => {
		let res = officesData.data.find(x => x.id == value);

		setFormData({...formData, officeId: res?.id});
	}
	const handleBrokerDetails = (value) => {
		let res = brokersData.data.find(x => x.id == value);
		console.log(res);
		setFormData({...formData, mySeaBrokerId: res?.id})
	}
	const handleExternalBrokerChange = (eb) => {
		setFormData({ ...formData, centralAgent: eb, centralAgentId: eb.id })
	}
	const loadOfficeOptions = () => {
		if (officesData.data) {
			return (
				<>
					<option>Select Office</option>
					{
						officesData.data.map(office => (<option value={office.id} key={office.id}>
							{office.name ? office.name : (office.city + ', ' + office.country)}
						</option>))
					}
				</>
			);
		}
	}
	const loadBrokersOptions = () => {
		if (brokersData.data) {
			return (
				<>
					<option>Select Broker</option>
					{
						brokersData.data.map(broker => (<option value={broker.id} key={broker.id}>
							{`${broker.person.firstName} ${broker.person.lastName}`}
						</option>))
					}
				</>
			);
		}
	}


	return (
		<Row className="mb-3 g-3">
			<Col lg={6}>
				<Card className="mb-3 g-3">
					<Card.Header className="d-flex justify-content-between align-items-center" >
						<h5 className="float-start">Yacht</h5>
						<div class="row">
							<Form.Group as={Col} className="">
								<div className='float-end d-flex' style={{ gap: '1rem' }}>
									<BootstrapSwitchButton
										type='switch'
										id='ca'
										onlabel='CA'
										offlabel='Retail'
										onstyle="primary"
										offstyle="light"
										width="100"
										checked={formData.ca}
										onChange={(e) => {
											setFormData({ ...formData, "ca": e });
										}} />
									<BootstrapSwitchButton
										type='switch'
										id='kyc'
										onlabel='KYC'
										offlabel='KYC'
										onstyle="primary"
										offstyle="light"
										width="100"
										checked={formData.kyc}
										onChange={(e) => {
											setFormData({ ...formData, "kyc": e });
										}} />
								</div>
							</Form.Group>
						</div>
					</Card.Header>
					<Card.Body className="bg-light">
						<YachtHeaderForm
							formData={formData}
							handleFieldChange={handleFieldChange}
							handleSubmit={handleSubmit}
							validated={validated}
							setFormData={setFormData}
						>
						</YachtHeaderForm>
					</Card.Body>
				</Card>
				<Row className="mb-3 g-3">
					<Col lg={12}>
						<Card className="mb-3 g-3">
							<Card.Header>
								<h5>Owner Company</h5>
							</Card.Header>
							<Card.Body className="bg-light">
								<YachtCompanyForm
									formData={formData}
									handleFieldChange={handleFieldChange}
									handleSubmit={handleSubmit}
									validated={validated}
									setFormData={setFormData}>
								</YachtCompanyForm>
							</Card.Body>
						</Card>
						{currentId != 0 ? <UploadFormFiles
							attachmentsData={attachmentsData}
							attachmentKinds={attachmentKind}
							setAttachmentsData={setAttachmentsData}
							uploadTo={"yacht-" + currentId}
						/> : null
						}
						<Card className="mt-3 mb-3 g-3">
							<Card.Body>
								<Row>
									<FloatingLabel as={Col}
										controlId="Custom Invoice Comment"
										label="Custom invoice comment"
									>
										<Form.Control
											as="textarea"
											placeholder="Leave a comment here"
											style={{ height: '100px' }}
											id="comment"
											value={formData.comment}
											onChange={handleFieldChange}
										/>
									</FloatingLabel>
								</Row>
							</Card.Body>
						</Card>
						<YachtEmailRecipientList
							emailList={emailList}
							setEmailList={setEmailList}
							setAdd={setAdd}
							Add={Add}
							handleEmailDelete={handleEmailDelete}
							setFormData={setFormData}
							formData={formData}
						>
						</YachtEmailRecipientList>
					</Col>
				</Row>
			</Col>
			<Col lg={6}>
				{formData.ca ?
					<>
						<Row className="mb-3 g-3">
							<Col lg={6}>
								<YachtMySeaOfficeDetail
									handleOfficeDetails={handleOfficeDetails}
									loadOfficeOptions={loadOfficeOptions}
									formData={formData}
								></YachtMySeaOfficeDetail>
							</Col>

							<Col lg={6}>
								<YachtMyseBrokerDetail
									formData={formData}
									handleBrokerDetails={handleBrokerDetails}
									loadBrokersOptions={loadBrokersOptions}
								></YachtMyseBrokerDetail>
							</Col>

						</Row>
						<YachtRetailReferralCie
							formData={formData}
							setFormData={setFormData}
						>
						</YachtRetailReferralCie>
					</>
					: <Row>
						<Col lg={12}>
							<YachtCADetail
								formData={formData}
								setFormData={setFormData}
								loadEBOptions={loadEBOptions}
								handleExternalBrokerChange={handleExternalBrokerChange}
							>
							</YachtCADetail>
						</Col>
						<Col lg={12}>
							<YachtRetailContactDetail
								externalBrokerId={formData.centralAgentId}
								formData={formData}
								setFormData={setFormData}
							></YachtRetailContactDetail>
						</Col>
					</Row>}
				<Col lg={12} className="mb-3 g-3">
					<Card className="mb-3 g-3">
						<Card.Header className="d-flex justify-content-between align-items-center">
							<h5 className="float-start">Financial Details</h5>
							<span className='float-end d-flex'>
								{formData.ca ?
									<BootstrapSwitchButton
										type='switch'
										id='vatRep'
										onlabel='VAT Rep'
										offlabel='VAT Rep'
										onstyle="primary"
										offstyle="light"
										width="150"
										checked={formData.vatRep}
										onChange={(e) => {
											setFormData({ ...formData, "vatRep": e });
										}} />
								: null}
							</span>
						</Card.Header>
						<Card.Body className="bg-light">
							<YachtFinancialForm
								formData={formData}
								handleFieldChange={handleFieldChange}
								handleSubmit={handleSubmit}
								validated={validated}
								setFormData={setFormData}
							></YachtFinancialForm>
						</Card.Body>
					</Card>
				</Col>


				<YachtUserList
					handleYachtDetails={handleYachtDetails}
					loadYachtOptions={loadYachtOptions}
					loadYachtUserList={loadYachtUserList}
				></YachtUserList>

			</Col>
		</Row>
	);

}


export default YachtFormBase;
