import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Button, Spinner } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import DeleteItemModal from 'components/modalComponents/deleteItemModal';
import UploadFormFiles from 'components/common/UploadFormFiles';
import { useNavigate } from "react-router-dom";
import axiosInstance from 'helpers/axiosInstance';
import SupplierDetail from './supplierDetail';
import ContactList from 'components/contacts/list';
import { SimpleUserForm } from 'viewModel/userForm';


const EditSupplierForm = () => {
	// State
	let form = React.createRef();
	let contactListRef = React.createRef();
	let userForm = React.createRef();
	const setRef = (ref) => {
		form = ref;
	}

	const [validated, setValidated] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const currentId = searchParams.get("id");

	const [attachmentsData, setAttachmentsData] = useState({ data: [] });
	const [attachmentKind, setAttachmentKind] = useState({ data: [] });
	const [showModal, setShowModal] = useState(false);
	const [formData, setFormData] = useState({
		id: 0,
		name: '',
		IBAN: '',
		SWIFT: '',
		address: '',
		bankAddress: '',
		notes: '',
		retailCommission: 0,
		companyRegistration: '',
		vatRegistration: ''
	});

	useEffect(() => {
		setFormData({ ...formData, ["id"]: currentId });

		axiosInstance.get(`${window.location.origin}/uploadFiles/GetFiles?FilesFrom=${"supplier-" + currentId}`).then((result) => {
			setAttachmentsData({ data: result.data });

		});

		axiosInstance.get(`${window.location.origin}/suppliers/${currentId}`)
			.then((res) => {
				if (res.data !== null) {
					setFormData({
						id: res.data.Id,
						name: res.data.Name,
						IBAN: res.data.IBAN,
						SWIFT: res.data.SWIFT,
						address: res.data.Address,
						bankAddress: res.data.BankAddress,
						notes: res.data.Notes,
						retailCommission: res.data.RetailCommission,
						companyRegistration: res.data.CompanyRegistration,
						vatRegistration: res.data.VatRegistration
					});
					document.title = res.data.Name;
					axiosInstance.get(`${window.location.origin}/attachments/GetBySupplierId/${currentId}`)
						.then((result) => {
							if (result.data !== null && result.data.count !== 0) {
								result.data.data.forEach((item, index) => {
									let data = attachmentsData.data;
									item.fileForm.fullName = `${item.fileForm.fileName}.${item.fileForm.fileExtension}`
									data.push(item);

									setAttachmentsData({ data: data });
								});
							}
						});

				} else {
					toast.error('No external broker found.', {
						theme: 'colored'
					});
				}
			});

		axiosInstance.get(`${window.location.origin}/parameters`).then((res) => {
			setAttachmentKind({ data: res.data.attachmentKinds.values });
		});
	}, []);

	//Handlers
	let navigate = useNavigate();

	const handleExit = (e) => {
		navigate("/components/externalbrokers");
	}

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		}
		else {
			// formData["attachments"] = attachmentsData.data;
			axiosInstance.post(`${window.location.origin}/suppliers`, formData)
				.then((res) => {
					if (res.data.id !== '0') {
						navigate("/components/externalbrokers");
						toast.success(`External broker information has been modified.`, {
							theme: 'colored'
						});
						formData.id = res.data.id;

					} else {
						toast.error(`${res.data.message}`, {
							theme: 'colored'
						});
					}

					$('.save-spinner').attr('hidden', 'hidden');
				});
		}
		setValidated(true);
	};

	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.id]: e.target.value
		});
	};

	const handleDelete = (e) => {
		axiosInstance.delete(`${window.location.origin}/suppliers/${currentId}`)
			.then((res) => {
				navigate("/components/externalbrokers");
				toast.success(`External broker has been deleted.`, {
					theme: 'colored'
				});
				$('.save-spinner').attr('hidden', 'hidden');
			});
	}

	//const handleDownload = (e) => {
	//    axiosInstance.delete(`${window.location.origin}/suppliers/${currentId}`)
	//        .then((res) => {
	//            navigate("/components/externalbrokers", { replace: true });
	//            toast.success(`External broker has been deleted.`, {
	//                theme: 'colored'
	//            });
	//            $('.save-spinner').attr('hidden', 'hidden');
	//        });
	//}

	return (
		<>
			<PageHeader className="mb-3 g-3" title="">
				<div className="d-inline">
					<h3 className="float-start">Edit external broker information</h3>
					<div className="float-end">
						<Button
							variant='falcon-primary'
							className='me-2'
							type="submit"
							onClick={e => form.dispatchEvent(
								new Event("submit", { cancelable: true, bubbles: true })
							)}
						>
							<Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" />
							Save</Button>
						<Button
							variant='falcon-warning'
							className='me-2'
							onClick={handleExit}
						>
							<Spinner animation="border" variant="warning" role="status" size="sm" hidden={true} className="cancel-spinner" />
							Cancel</Button>

						<DeleteItemModal item="External broker" itemName={formData.name} function={handleDelete} />
					</div>
				</div>
			</PageHeader>

			<SupplierDetail handleSubmit={handleSubmit}
				handleFieldChange={handleFieldChange}
				validated={validated}
				formData={formData}
				setRef={setRef}
			></SupplierDetail>
			<UploadFormFiles
				attachmentsData={attachmentsData}
				attachmentKinds={attachmentKind}
				setAttachmentsData={setAttachmentsData}
				uploadTo={"supplier-" + currentId}
			/>
			<ContactList ref={contactListRef} supplierId={currentId} >
			</ContactList>
			<Col lg={12} className='mt-3 mb-3 g-3'>
				<Card>
					<Card.Header className="bg-white">
						<h5>Comments</h5>
					</Card.Header>
					<Card.Body className="bg-light">
						<Form.Group as={Col}>
							<Form.Control
								style={{ minHeight: "150px" }}
								type="text"
								as="textarea"
								rows={5}
								value={formData.notes}
								onChange={handleFieldChange}
								id="notes"
							/>
						</Form.Group>
					</Card.Body>
				</Card>
			</Col>
		</>
	);
}

export default EditSupplierForm;
