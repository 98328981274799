/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';

export const AdvanceTablePagination = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  gotoPage
}) => {

  const renderPaginationButtons = () => {
    const maxButtons = 5;
    const buttons = [];

    if (pageCount <= maxButtons) {
      for (let i = 0; i < pageCount; i++) {
        buttons.push(
          <li key={i} className={classNames({ active: pageIndex === i })} >
            <Button
              size="sm"
              variant="falcon-default"
              className={classNames('page', {
                'me-1': i + 1 !== pageCount
              })}
              onClick={() => gotoPage(i)}
              disabled={i === pageIndex}
            >
              {i + 1}
            </Button>
          </li>
        );
      }
    } else {
      const halfMaxButtons = Math.floor(maxButtons / 2);
      const startButton = Math.max(0, pageIndex - halfMaxButtons);
      const endButton = Math.min(pageCount, pageIndex + halfMaxButtons);

      if (pageIndex - halfMaxButtons > 1) {
        buttons.push(
          <li key={0} >
            <Button
              size="sm"
              variant="falcon-default"
              className={classNames('page', {
                'me-1': 0 + 1 !== pageCount
              })}
              onClick={() => gotoPage(0)}
              disabled={0 === pageIndex}
            >
              1
            </Button>
          </li>,
          <span key="ellipsisStart" className='mx-1'>...</span>
        );
      }

      for (let i = startButton; i < endButton; i++) {
        buttons.push(
          <li key={i} >
            <Button
              size="sm"
              variant="falcon-default"
              className={classNames('page', {
                'me-1': i + 1 !== pageCount
              })}
              onClick={() => gotoPage(i)}
              disabled={i === pageIndex}
            >
              {i+1}
            </Button>
          </li>
        );
      }

      if (pageIndex + halfMaxButtons < pageCount) {
        buttons.push(
          <span key="ellipsisEnd" className='mx-1'>...</span>,
          <li key={"last"} >
            <Button
              size="sm"
              variant="falcon-default"
              onClick={() => gotoPage(pageCount-1)}
              className={classNames('page')}
            >
              {pageCount}
            </Button>
          </li>
        );
      }
    }

    return buttons;
  };

  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => previousPage()}
        className={classNames({ disabled: !canPreviousPage })}
      >
        <span>Previous</span>
      </Button>
      <ul className="pagination mb-0 mx-1">
        {
          renderPaginationButtons()
        }
      </ul>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => nextPage()}
        className={classNames({ disabled: !canNextPage })}
      >
        <span>Next</span>
      </Button>
    </Flex>
  );
};

export default AdvanceTablePagination;
