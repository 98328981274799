import React, { useState } from 'react';
import IconButton from 'components/common/IconButton';
import { Card, Col, Row, Form, Table, FloatingLabel, Dropdown, Button, Modal } from 'react-bootstrap';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';

import en from 'date-fns/locale/en-GB';
import TextareaAutosize from 'react-textarea-autosize';
import { InstallmentForm } from 'viewModel/DtoClasses';
import axiosInstance from 'helpers/axiosInstance';
import { toast } from 'react-toastify';

const AddNewInstallmentModal = ({ formData, setFormData, setReadjustPayment, ...props }) => {
    registerLocale('es', en);

    const [modalShow, setModalShow] = React.useState(false);
    const [newInstallment, setNewInstallment] = useState(new InstallmentForm());


    const handleNewInstallmentChange = e => {
        setNewInstallment({
            ...newInstallment,
            [e.target.name]: e.target.value
        });
    }

    const handleAddInstallment = async e => {
        if (formData.id > 0) {
            newInstallment.contractId = formData.id;
            newInstallment.id = 0;
            let res = await axiosInstance.put(`${window.location.origin}/installments`, newInstallment);
            if (res.status === 200) {
                toast.success(`Installment created successfully.`, {
                    theme: 'colored'
                });
                newInstallment.id = res.data.Id;
                setReadjustPayment(true);
            }
            else {
                toast.error(`There was an error creating the installment.`, {
                    theme: 'colored'
                });
                return;
            }
        }
        let data = formData.installments;
        data.push(newInstallment);
        setFormData({ ...formData, installments: data });
        setNewInstallment(new InstallmentForm());
        setModalShow(false);
    }

    const newInstallemnt = () => {
        let date = (new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
        date.setHours(12,0,0);
        setNewInstallment({
            ...newInstallment,
            dueDate: date,
            id: (formData.installments.length + 1) * -1
        }
        );
        setModalShow(true);
    }

    return (
        <>
            <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={() => newInstallemnt()}
            >
                <span className="d-none d-sm-inline-block ms-1" >New</span>
            </IconButton>

            <Modal
                show={modalShow}
                onHide={(e) => { setModalShow(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <h4>Add a new Installment</h4>

                    <hr />

                    <Form.Group className="mt-3">
                        <Form.Label className="mb-2">Amount</Form.Label>
                        <Form.Control
                            type="number"
                            min={0}
                            value={newInstallment.amount}
                            onChange={handleNewInstallmentChange}
                            name="amount"
                        />
                    </Form.Group>

                    <Form.Group className="mt-3">
                        <Form.Label className="mb-2">Due date</Form.Label>
                        <DatePicker
                            selected={newInstallment.dueDate}
                            onChange={(date) => setNewInstallment({ ...newInstallment, dueDate: date })}
                            formatWeekDay={day => day.slice(0, 3)}
                            className='form-control'
                            value={newInstallment.dueDate}
                            name="dueDate"
                            dateFormat="dd/MM/yyyy"
                            locale='es'
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setModalShow(false); setNewInstallment({ amount: '' }); }}>Cancel</Button>
                    <Button
                        onClick={() => handleAddInstallment()}
                        disabled={!newInstallment.dueDate || !newInstallment.amount}
                    >
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default AddNewInstallmentModal;
