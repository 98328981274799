import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Card, Col, Form, Button, Spinner } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { toast } from 'react-toastify';
import $ from 'jquery';
import UploadFormFiles from 'components/common/UploadFormFiles';
import { useNavigate } from "react-router-dom";
import axiosInstance from 'helpers/axiosInstance';
import SupplierDetail from './supplierDetail';
import ContactList from 'components/contacts/list';

const CreateSupplierForm = (props, ref) => {

	useImperativeHandle(ref, () => ({
		save: async () => {
			handleSubmit();
		}
	}))
	// State
	let form = React.createRef();
	const setRef = (ref) => {
		form = ref;
	}
	const [validated, setValidated] = useState(false);
	let contactListRef = React.createRef();

	const [formData, setFormData] = useState({
		name: '',
		IBAN: '',
		SWIFT: '',
		address: '',
		bankAddress: '',
		retailCommission: 0,
		companyRegistration: '',
		vatRegistration: ''
	});
	const [attachmentsData, setAttachmentsData] = useState({ data: [] });


	//Handlers
	let navigate = useNavigate();

	const handleSubmit = (event) => {
		event?.preventDefault();
		if (form.checkValidity() === false) {
			event?.preventDefault();
			event?.stopPropagation();
		}
		else {
			formData["attachments"] = attachmentsData.data;
			axiosInstance.post(`${window.location.origin}/suppliers`, formData)
				.then((res) => {
					if (res.data.id !== '0') {
						toast.success(`External broker has been created.`, {
							theme: 'colored'
						});
						formData.id = res.data.id;
						if (props.InModal) {
							props.onSave(res.data);
						} else {
							navigate("/components/externalbrokers/edit?id=" + formData.id);
						}
					} else {
						toast.error(`${res.data.message}`, {
							theme: 'colored'
						});
					}

					$('.save-spinner').attr('hidden', 'hidden');
				});
		}
		setValidated(true);
	};

	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.id]: e.target.value
		});
	};

	const addContact = (user) => {
		console.log(user);
		contactListRef.current.addUser(user);
	}

	return (
		<>
			{
				props.InModal ? null :
					<PageHeader className="mb-3 g-3" title="">
						<div className="d-inline">
							<h3 className="float-start">Create external broker</h3>
							<div className="float-end">
								<Button
									variant='falcon-primary'
									className='me-2'
									type="submit"
									onClick={e => form.dispatchEvent(
										new Event("submit", { cancelable: true, bubbles: true })
									)}
								>
									<Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" />
									Save</Button>
								<Button variant='falcon-warning' onClick={() => { window.history.back(); }}>
									<Spinner animation="border" variant="warning" role="status" size="sm" hidden={true} className="cancel-spinner" />
									Cancel</Button>
							</div>
						</div>
					</PageHeader>
			}
			<SupplierDetail handleSubmit={handleSubmit}
				handleFieldChange={handleFieldChange}
				validated={validated}
				formData={formData}
				setRef={setRef}
			></SupplierDetail>
			{/* <UploadFormFiles
				attachmentsData={attachmentsData}
				setAttachmentsData={setAttachmentsData}
			/> */}
			{/* <ContactList ref={contactListRef} customSubmit={addContact} >
			</ContactList> */}
			<Col lg={12} className='mt-3 mb-3 g-3'>
				<Card>
					<Card.Header className="bg-white">
						<h5>Comments</h5>
					</Card.Header>
					<Card.Body className="bg-light">
						<Form.Group as={Col}>
							<Form.Control
								style={{ minHeight: "150px" }}
								type="text"
								as="textarea"
								rows={5}
								value={formData.notes}
								onChange={handleFieldChange}
								id="notes"
							/>
						</Form.Group>
					</Card.Body>
				</Card>
			</Col>
		</>
	);
};

export default forwardRef(CreateSupplierForm);
