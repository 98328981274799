import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


Sentry.init({
  dsn: "https://736bcf59480a445295aa8cc1b2ad03c3@o1250588.ingest.sentry.io/6415047",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
const backup = console.error;

console.error = function filterWarnings(msg) {
  const supressedWarnings = ['should not be null'];
  try {

    if (!supressedWarnings.some(entry => msg.includes("Warning"))) {
      backup.apply(console, arguments);
    }
  } catch {

  }
};

const root = ReactDOM.createRoot(document.getElementById("main"));

root.render(
  <Main>
    <App />
  </Main>
);
