import React, { useState, useEffect, useContext } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'index.css'
import Layout from './layouts/Layout';
import { RoleContext } from 'context/RoleContext';
import history from "helpers/history";
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { getUserRole } from 'helpers/utils';
import { useNavigate } from 'react-router-dom';

const App = () => {
    const roleCtx = useContext(RoleContext);
    const [role, setRole] = useState();
    const [token, setToken] = useState();
    //const navigate = useNavigate();
    useEffect(() => {
        //window.onpopstate = () => {
        //    navigate(-1);
        //}
    }, []);
    return (
        <HistoryRouter basename={process.env.PUBLIC_URL} history={history}>
            <Layout setToken={setToken} />
        </HistoryRouter>
    );
};


export default App;
