import React from 'react';
import { Col, Row, Form, FloatingLabel } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

const YachtFinancialForm = ({ handleSubmit, handleFieldChange, formData, validated, setFormData }) => {
	const form = '';

	return (
		<Form validated={validated} onSubmit={handleSubmit} className="third-form-yacht">
			<Row className="g-3">
				<FloatingLabel as={Col} lg={6}
					controlId="vatIntraCom"
					label="VAT IntraCom"
				>
					<Form.Control
						type="text"
						value={formData.vatIntraCom}
						onChange={handleFieldChange}
						placeholder="VAT IntraCom"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a VAT IntraCom.
					</Form.Control.Feedback>
				</FloatingLabel>
				<Form.Group as={Col} lg={6}>
					<div>
						<BootstrapSwitchButton
							type='switch'
							id='mySeaVatApplicable'
							onlabel='Mysea VAT applicable'
							offlabel='Mysea VAT applicable'
							onstyle="primary"
							offstyle="light"
							width="250"
							checked={formData.mySeaVatApplicable}
							onChange={(e) => {
								setFormData({ ...formData, "mySeaVatApplicable": e });
							}} />
					</div>
				</Form.Group>
				<Form.Group as={Col} sm="auto" lg={6}>
					<FloatingLabel
						label="Beneficiary owner"
						controlId="beneficiary"
					>
						<Form.Control
							type="text"
							value={formData.beneficiary}
							onChange={handleFieldChange}
							placeholder="Beneficiary owner"
						/>
					</FloatingLabel>
				</Form.Group>
				<FloatingLabel as={Col} lg={6}
					controlId="ibanOwner"
					label="IBAN owner"
				>
					<Form.Control
						type="text"
						value={formData.ibanOwner}
						onChange={handleFieldChange}
						placeholder="IBAN owner"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter an IBAN owner.
					</Form.Control.Feedback>
				</FloatingLabel>
				<FloatingLabel as={Col} lg={4}
					controlId="swiftOwner"
					label="SWIFT owner"
				>
					<Form.Control
						type="text"
						value={formData.swiftOwner}
						onChange={handleFieldChange}
						placeholder="SWIFT owner"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a SWIFT Owner.
					</Form.Control.Feedback>
				</FloatingLabel>
				<FloatingLabel as={Col} lg={8}
					controlId="bankAddressOwner"
					label="Bank address owner"
				>
					<Form.Control
						type="text"
						value={formData.bankAddressOwner}
						onChange={handleFieldChange}
						placeholder="Bank address owner"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a bank address owner.
					</Form.Control.Feedback>
				</FloatingLabel>

				<FloatingLabel as={Col} sm="auto" lg={6}
					label="Beneficiary APA"
					controlId="beneficiaryAPA"
				>
					<Form.Control
						type="text"
						value={formData.beneficiaryAPA}
						onChange={handleFieldChange}
						placeholder="Beneficiary APA"
					/>
				</FloatingLabel>
				<FloatingLabel as={Col} lg={6}
					controlId="ibanApa"
					label="IBAN APA"
				>
					<Form.Control
						type="text"
						value={formData.ibanApa}
						onChange={handleFieldChange}
						placeholder="IBAN APA"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter an IBAN APA.
					</Form.Control.Feedback>
				</FloatingLabel>
				<FloatingLabel as={Col} lg={4}
					controlId="swiftApa"
					label="SWIFT APA"
				>
					<Form.Control
						type="text"
						value={formData.swiftApa}
						onChange={handleFieldChange}
						placeholder="SWIFT APA"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a SWIFT APA.
					</Form.Control.Feedback>
				</FloatingLabel>
				<FloatingLabel as={Col} lg={8}
					controlId="bankAddressApa"
					label="Bank address APA"
				>
					<Form.Control
						type="text"
						value={formData.bankAddressApa}
						onChange={handleFieldChange}
						placeholder="SWIFT APA"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a bank address APA.
					</Form.Control.Feedback>
				</FloatingLabel>
				<FloatingLabel as={Col} sm="auto" lg={6}
					label="Beneficiary other"
					controlId="beneficiaryOther"
				>
					<Form.Control
						type="text"
						value={formData.beneficiaryOther}
						onChange={handleFieldChange}
						placeholder="Beneficiary other"
					/>
				</FloatingLabel>
				<FloatingLabel as={Col} lg={6}
					controlId="ibanOther"
					label="IBAN other"
				>
					<Form.Control
						type="text"
						value={formData.ibanOther}
						onChange={handleFieldChange}
						placeholder="IBAN other"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter an IBAN other.
					</Form.Control.Feedback>
				</FloatingLabel>
				<FloatingLabel as={Col} lg={4}
					controlId="swiftOther"
					label="SWIFT other"
				>
					<Form.Control
						type="text"
						value={formData.swiftOther}
						onChange={handleFieldChange}
						placeholder="SWIFT other"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a SWIFT other.
					</Form.Control.Feedback>
				</FloatingLabel>
				<FloatingLabel as={Col} lg={8}
					controlId="bankAddressOther"
					label="Bank address other"
				>
					<Form.Control
						type="text"
						value={formData.bankAddressOther}
						onChange={handleFieldChange}
						placeholder="Bank address other"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a bank address other.
					</Form.Control.Feedback>
				</FloatingLabel>
				{formData.ca ? <>
					<FloatingLabel as={Col} sm="auto" lg={12}
						label="Beneficiary VAT"
						controlId="beneficiaryVAT"
					>
						<Form.Control
							type="text"
							value={formData.beneficiaryVAT}
							onChange={handleFieldChange}
							placeholder="Beneficiary VAT"
						/>
					</FloatingLabel>
					<FloatingLabel as={Col} lg={8}
						controlId="ibanVat"
						label="IBAN VAT"
					>
						<Form.Control
							type="text"
							value={formData.ibanVat}
							onChange={handleFieldChange}
							placeholder="IBAN VAT"
						/>
						<Form.Control.Feedback type="invalid">
							Please enter an IBAN VAT.
						</Form.Control.Feedback>
					</FloatingLabel>
					<FloatingLabel as={Col} lg={4}
						controlId="swiftVat"
						label="SWIFT VAT"
					>
						<Form.Control
							type="text"
							value={formData.swiftVat}
							onChange={handleFieldChange}
							placeholder="SWIFT VAT"
						/>
						<Form.Control.Feedback type="invalid">
							Please enter a SWIFT VAT.
						</Form.Control.Feedback>
					</FloatingLabel>
					<FloatingLabel as={Col} lg={12}
						controlId="bankAddressVat"
						label="Bank address VAT"
					>
						<Form.Control
							type="text"
							value={formData.bankAddressVat}
							onChange={handleFieldChange}
							placeholder="Bank address VAT"
						/>
						<Form.Control.Feedback type="invalid">
							Please enter a bank address VAT.
						</Form.Control.Feedback>
					</FloatingLabel>
				</> : null}
				<FloatingLabel as={Col} lg={4}
					controlId="defaultVATNumber"
					label="VAT number"
				>
					<Form.Control
						type="text"
						value={formData.defaultVATNumber}
						onChange={handleFieldChange}
						placeholder="VAT number"
					/>
				</FloatingLabel><FloatingLabel as={Col} lg={4}
					controlId="secondVATNumber"
					label="VAT number"
				>
					<Form.Control
						type="text"
						value={formData.secondVATNumber}
						onChange={handleFieldChange}
						placeholder="Second VAT number"
					/>
				</FloatingLabel><FloatingLabel as={Col} lg={4}
					controlId="thirdVATNumber"
					label="VAT number"
				>
					<Form.Control
						type="text"
						value={formData.thirdVATNumber}
						onChange={handleFieldChange}
						placeholder="VAT number"
					/>
				</FloatingLabel>
			</Row>

		</Form>
	);
};

export default YachtFinancialForm;
