import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Table } from 'react-bootstrap';
import { Navigate } from 'react-router';
import { Route } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const AdvanceTable = ({
    getTableProps,
    headers,
    page,
    prepareRow,
    headerClassName,
    rowClassName,
    tableProps,
    trLinkById,
    rowClick
}) => {
    const handleRowClick = (row) => {
        if (rowClick != null) {
            rowClick(row);
        } else {
            handleRedirect(`${trLinkById}?id=${row.original["id"]}`);
        }
    }
    function handleRedirect(location) {
        window.open(location.replace(window.location.origin, ""), '_blank');
        //navigate(location.replace(window.location.origin, ""), true);
    }
    return (
        <SimpleBarReact>
            <Table {...getTableProps(tableProps)}>
                <thead className={headerClassName}>
                    <tr>
                        {headers.map((column, index) => (
                            <th
                                key={index}
                                {...column.getHeaderProps([
                                    {
                                        className: column.className,
                                        style: column.style,
                                    },
                                    column.getSortByToggleProps(column.headerProps)
                                ])}
                            >
                                {column.render('Header')}
                                {column.canSort ? (
                                    column.isSorted ? (
                                        column.isSortedDesc ? (
                                            <span className="sort desc" />
                                        ) : (
                                            <span className="sort asc" />
                                        )
                                    ) : (
                                        <span className="sort" />
                                    )
                                ) : (
                                    ''
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {page.map((row, i) => {
                        prepareRow(row);

                        if (trLinkById || rowClick != null) {
                            return (
                                <tr key={i} className={rowClassName} {...row.getRowProps()} style={{ cursor: 'pointer' }} onClick={() => handleRowClick(row) }>
                                    {row.cells.map((cell, index) => {
                                        return (
                                            <td
                                                key={index}
                                                {...cell.getCellProps([
                                                    {
                                                        className: cell.column.className,
                                                        style: cell.column.style,
                                                        props: cell.column.cellProps
                                                    }
                                                ])}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        } else {
                            return (
                                <tr key={i} className={rowClassName} {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                        return (
                                            <td
                                                key={index}
                                                {...cell.getCellProps(cell.column.cellProps)}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        }

                    })}
                </tbody>
            </Table>
        </SimpleBarReact>
    );
};
AdvanceTable.propTypes = {
    getTableProps: PropTypes.func,
    headers: PropTypes.array,
    page: PropTypes.array,
    prepareRow: PropTypes.func,
    headerClassName: PropTypes.string,
    rowClassName: PropTypes.string,
    tableProps: PropTypes.object
};

export default AdvanceTable;
