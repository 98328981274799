
export class CommissionViewModel {
    name: string;
    comm: number;
    amount: number;
    vat: number;
    isTaxIncluded: boolean;
    invoice: string;

    constructor() {
        this.name = "";
        this.comm = 0;
        this.amount = 0;
        this.vat = 0;
        this.isTaxIncluded = true;
        this.invoice = ""
    }
};