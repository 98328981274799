import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoutImg from 'assets/img/icons/spot-illustrations/45.png';
import { removeUserSession } from 'helpers/utils';
import axios from 'axios'
import axiosInstance from 'helpers/axiosInstance';

const LogoutContent = ({ layout, titleTag: TitleTag }) => {
    const handleLogout = async () => {
      removeUserSession();
      await axiosInstance.post("Auth/revoke-token");
    }

    useEffect(() => {
      handleLogout();
    }, [])

  return (
    <>
      <TitleTag>See you again!</TitleTag>
      <p>
        Thanks for using YRM. You are <br className="d-none d-sm-block" />
        now signed out.
      </p>
      <Button
        color="primary"
        size="sm"
        className="mt-3"
        onClick={() => window.location = "/"}
      >
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="me-1"
        />
        Return to Login
      </Button>
    </>
  );
};

LogoutContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

LogoutContent.defaultProps = {
  layout: 'simple',
  titleTag: 'h4'
};

export default LogoutContent;
