import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { Card, Col, Row, Form, Button, Spinner, FloatingLabel } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import axiosInstance from 'helpers/axiosInstance';
import { toast } from 'react-toastify';
import { getUser } from "helpers/utils";

const UserSettingsPage = () => {

    let form = '';
    const [validated, setValidated] = useState(false);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [commSection, setCommSection] = useState(false);
    const [companySection, setCompanySection] = useState(false);
    const [officeSection, setOfficeSection] = useState(false);
    const [arePasswordsChecked, setPasswordsChecked] = useState(false);
    const [isTruePassword, setIsTruePassword] = useState(null);
    const [currentEmail, setCurrentEmail] = useState('');
    const [passwordConfirmed, setPasswordConfirmed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        role: '',
        password: '',
        newPassword: '',
        confirmPassword: ''
    });

    const [officeData, setOfficeData] = useState({
        address1: '',
        address2: '',
        city: '',
        country: '',
        VAT: '',
        RCI: '',
        DSEE: '',
        CACommission: '',
        retailCommission: ''
    });
    const [companyData, setCompanyData] = useState({
        company: '',
        CACommission: '',
        retailCommission: ''
    });

    useEffect(() => {
        let user = getUser();
        axiosInstance.get(`${window.location.origin}/persons/Settings/${user.id}`)
            .then((res) => {
                if (res.data !== null) {
                    setFormData({
                        ...formData,
                        // id: res.data.id,
                        firstName: res.data.firstName,
                        lastName: res.data.lastName,
                        email: res.data.email,
                        phone: res.data.phone,
                        address1: res.data.address1,
                        address2: res.data.address2,
                        role: res.data.role
                    });
                    setCurrentEmail(res.data.email);

                    setOfficeData({
                        ...officeData,
                        address1: res.data.officeAddress1,
                        address2: res.data.officeAddress2,
                        city: res.data.city,
                        country: res.data.country,
                        vat: res.data.vat,
                        rci: res.data.rci,
                        dsee: res.data.dsee,
                        caCommission: res.data.officeCACommission,
                        retailCommission: res.data.officeRetailCommission
                    });
                    setCompanyData({
                        ...companyData,
                        company: res.data.company,
                        caCommission: res.data.companyCACommission,
                        retailCommission: res.data.companyRetailCommission
                    });

                    roleHandler(res.data);
                }
            });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log(form.checkValidity(), arePasswordsChecked)
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            $('.save-spinner').attr('hidden', null);
            axiosInstance.put(`${window.location.origin}/persons/update-user`, formData)
                .then((res) => {
                    if (res.data.error) {
                        toast.error(`${res.data.error}`, {
                            theme: 'colored'
                        });
                    } else if (res.data.succes) {
                        toast.success(`${res.data.succes}`, {
                            theme: 'colored'
                        });
                    }

                    $('.save-spinner').attr('hidden', 'hidden');
                });
        }
        setValidated(true);
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const setNewPassword = (event) => {
        setFormData({ ...formData, newPassword: event.target.value });
        if (formData.password && event.target.value === formData.confirmPassword) {
            setPasswordsChecked(true);
        } else if (formData.password.length === 0 && formData.confirmPassword.length === 0 && event.target.value.length === 0) {
            setPasswordsChecked(true);
        } else {
            setPasswordsChecked(false);
        }

    };
    const setConfirmPassword = (event) => {

        setFormData({ ...formData, confirmPassword: event.target.value });
        if (formData.password && event.target.value === formData.newPassword) {
            setPasswordsChecked(true);
        } else if (formData.password.length === 0 && formData.newPassword.length === 0 && event.target.value.length === 0) {
            setPasswordsChecked(true);
        } else {
            setPasswordsChecked(false);
        }
    };

    const validatePasswords = async () => {
        setLoading(true);
        axiosInstance.post(`${window.location.origin}/Auth?resetPwd=true`, {
            password: formData.password,
            userName: currentEmail
        })
            .then(res => {
                setPasswordConfirmed(true);
                setLoading(false);
            }).catch(o => {
                toast.error("Incorrect password.", { theme: 'colored', position: toast.POSITION.BOTTOM_RIGHT });
                setLoading(false);
            });
    };

    const roleHandler = (data) => {
        if (data.role == "Broker") {
            setCommSection(true);
            setOfficeSection(true);
        } else if (data.role == "ExternalBroker") {
            setCommSection(true);
            setCompanySection(true);
        } else if (data.role == "Administrator") {
            setCommSection(false);
            setOfficeSection(false);
            setCompanySection(false);
        }
    }

    const resetPassword = async () => {
        setLoading(true);
        let user = getUser();
        let form = new FormData();
        form.append("username", formData.email);
        form.append("password", formData.newPassword);
        form.append("confirmPassword", formData.confirmPassword);
        form.append("oldPassword", formData.password);
        let pattern = /^(?=.*\w)(?=.*\d)[\S]{8,256}$/;
        if (!pattern.test(formData.newPassword)) {
            setErrorMsg(true);
            setLoading(false);
        } else {
            setErrorMsg(false);
            try {
                let req = await axiosInstance.post(`${window.location.origin}/Auth/reset-password/${user.id}?type=password`, form);
                toast.success("Password has been updated", { theme: "colored" });
            } catch (e) {
                toast.error("Error modifying the password", { theme: "colored" });
            }
            setLoading(false);
            setFormData({ ...formData, confirmPassword: "", newPassword: "", password: "" });
            setPasswordConfirmed(false);
        }
    }

    // Partial views

    const updatePassword = () => {
        if (!passwordConfirmed) {
            return (
                <>
                    <div className='pt-3 d-grid'>
                        <Button variant='primary' onClick={validatePasswords}>
                            {loading ? (<Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>) : "Reset Password"}
                        </Button>
                    </div>
                </>)
        } else {
            return (
                <>
                    <FloatingLabel
                        label="New password" className="py-2"
                    >
                        <Form.Control
                            type="password"
                            value={formData.newPassword}
                            onChange={setNewPassword}
                            onKeyDown={(e) => { if (e.key == "Enter" && arePasswordsChecked) resetPassword() }}
                            placeholder="New password"
                            pattern='^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$'
                            title='Minimum eight characters, at least one letter and one number'
                            isInvalid={!arePasswordsChecked}
                            isValid={arePasswordsChecked}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        label="Confirm new password"
                        className="mb-2"
                    >
                        <Form.Control
                            type="password"
                            value={formData.confirmPassword}
                            onChange={setConfirmPassword}
                            onKeyDown={(e) => { if (e.key == "Enter" && arePasswordsChecked) resetPassword() }}
                            pattern='^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$'
                            title='Minimum eight characters, at least one letter and one number'
                            placeholder="Confirm new password"
                            isInvalid={!arePasswordsChecked}
                            isValid={arePasswordsChecked}
                        />
                    </FloatingLabel>
                    <div className=' d-grid mb-2'>
                        <Button variant='primary' size="lg" disabled={!arePasswordsChecked} onClick={resetPassword}>
                            {loading ? (<Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>) : "Reset Password"}
                        </Button>
                    </div>
                    <div className={errorMsg && "text-danger"}>Minimum eight characters, at least one letter and one number*</div>
                </>
            );
        }
    }

    const truePassword = () => {
        return (
            <FloatingLabel as={Col}
                label="Current password"
            >
                <Form.Control
                    type="password"
                    value={formData.password}
                    id="password"
                    onChange={handleFieldChange}
                    onKeyDown={(e) => { if (e.key == "Enter") validatePasswords() }}
                    placeholder="Current password"
                />
            </FloatingLabel>
        );
    }

    const commissionSection = () => {
        if (commSection) {
            return (
                <>
                    <Row className="mb-3 g-3">
                        <Col lg={12}>
                            <Card>
                                <Card.Header >
                                    <h5>Commissions</h5>
                                </Card.Header>
                                <Card.Body className="bg-light">
                                    <Row className="g-3">
                                        <FloatingLabel as={Col} lg={6}
                                            label="CA Commission %"
                                        >
                                            <Form.Control
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                step=".01"
                                                value={companyData.caCommission}
                                                placeholder="CA Commission %"
                                                disabled
                                            />
                                        </FloatingLabel>
                                        <FloatingLabel as={Col} lg={6}
                                            label="Retail Commission %"
                                        >
                                            <Form.Control
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                step=".01"
                                                value={companyData.retailCommission}
                                                placeholder="Retail Commission %"
                                                disabled
                                            />
                                        </FloatingLabel>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </>
            );
        } else return null;
    };

    const companyInput = () => {
        if (companySection) {
            return (<>
                <Row className="mb-3 g-3">
                    <Col lg={12}>
                        <Card>
                            <Card.Header >
                                <h5>Company</h5>
                            </Card.Header>
                            <Card.Body className="bg-light">
                                <Row className="g-3">
                                    <FloatingLabel as={Col}
                                        label="Company"
                                    >
                                        <Form.Control
                                            type="text"
                                            value={companyData.company}
                                            placeholder="Company name"
                                            disabled
                                        />
                                    </FloatingLabel>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>);
        } else return null;
    }

    const officeDetailsSection = () => {
        if (officeSection) {
            return (<>
                <Row className="mb-3 g-3">
                    <Col lg={12}>
                        <Card>
                            <Card.Header >
                                <h5>Office Details</h5>
                            </Card.Header>
                            <Card.Body className="bg-light">
                                <Row className="mb-3 g-3">
                                    <FloatingLabel as={Col} lg={6}
                                        label="Address 1"
                                    >
                                        <Form.Control
                                            type="text"
                                            value={officeData.address1}
                                            placeholder="Address 1"
                                            disabled
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel as={Col} lg={6}
                                        label="Address 2"
                                    >
                                        <Form.Control
                                            type="text"
                                            value={officeData.address2}
                                            placeholder="Address 2"
                                            disabled
                                        />
                                    </FloatingLabel>
                                </Row>
                                <Row className="mb-3 g-3">
                                    <FloatingLabel as={Col} lg={6}
                                        label="City"
                                    >
                                        <Form.Control
                                            type="text"
                                            value={officeData.city}
                                            placeholder="City"
                                            disabled
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel as={Col} lg={6}
                                        label="Country"
                                    >
                                        <Form.Control
                                            type="text"
                                            value={officeData.country}
                                            placeholder="Country"
                                            disabled
                                        />
                                    </FloatingLabel>
                                </Row>
                                <Row className="mb-3 g-3">
                                    <FloatingLabel as={Col} lg={4}
                                        label="VAT"
                                    >
                                        <Form.Control
                                            type="text"
                                            value={officeData.vat}
                                            placeholder="VAT"
                                            disabled
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel as={Col} lg={4}
                                        label="RCI"
                                    >
                                        <Form.Control
                                            type="text"
                                            value={officeData.rci}
                                            placeholder="RCI"
                                            disabled
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel as={Col} lg={4}
                                        label="DSEE"
                                    >
                                        <Form.Control
                                            type="text"
                                            value={officeData.dsee}
                                            placeholder="DSEE"
                                            disabled
                                        />
                                    </FloatingLabel>
                                </Row>
                                <Row className="g-3">
                                    <FloatingLabel as={Col} lg={6}
                                        label="CA commission"
                                    >
                                        <Form.Control
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            step=".01"
                                            value={officeData.caCommission}
                                            placeholder="CA commission"
                                            disabled
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel as={Col} lg={6}
                                        label="Retail commission"
                                    >
                                        <Form.Control
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            step=".01"
                                            value={officeData.retailCommission}
                                            placeholder="Retail commission"
                                            disabled
                                        />
                                    </FloatingLabel>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>)
        }
    }

    return (
        <>
            {/*Header*/}
            <PageHeader className="mb-3 g-3" title="">
                <div className="d-inline">
                    <h3 className="float-start">{formData.firstName} {formData.lastName}</h3>
                </div>
                <div className="float-end">
                    <Button
                        variant='falcon-primary'
                        className='me-2 mr-1'
                        onClick={e => form.dispatchEvent(
                            new Event("submit", { cancelable: true, bubbles: true })
                        )}
                    >
                        <Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" /> Save</Button>

                    <Button variant='falcon-warning' onClick={() => window.history.back()}>
                        <Spinner animation="border" variant="warning" role="status" size="sm" hidden={true} className="cancel-spinner" />
                        Cancel</Button>
                </div>
            </PageHeader>

            {/*<User details*/}
            <Row className="mb-3 g-3">
                <Col lg={8}>
                    <Card>
                        <Card.Header >
                            <h5>User Details</h5>
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Form validated={validated} onSubmit={handleSubmit} ref={ref => form = ref}>
                                <Row className="g-3">
                                    <FloatingLabel as={Col} lg={6}
                                        controlId="firstName"
                                        label="First name"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            value={formData.firstName}
                                            onChange={handleFieldChange}
                                            placeholder="First name"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a first name.
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel as={Col} lg={6}
                                        controlId="lastName"
                                        label="Last name"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            value={formData.lastName}
                                            onChange={handleFieldChange}
                                            placeholder="Last name"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a last name.
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Row>
                                <Row className="g-3">
                                    <FloatingLabel as={Col} lg={6}
                                        controlId="email"
										label="Email"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="email"
                                            value={formData.email}
                                            onChange={handleFieldChange}
                                            placeholder="Email"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid email.
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                    <FloatingLabel as={Col} lg={6}
                                        controlId="phone"
                                        label="Phone"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            value={formData.phone}
                                            onChange={handleFieldChange}
                                            placeholder="Phone"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid phone number.
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Row>
                                <Row className="g-3">
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <FalconComponentCard>
                        <FalconComponentCard.Header title="Account Settings" light={false} />
                        <FalconComponentCard.Body>
                            <Row className="g-3">
                                <h5><b>Role</b>: {formData.role}</h5>
                            </Row>
                            <div className="border-dashed-bottom my-3 mb-4"></div>
                            <Row className="g-3">
                                <div>
                                    {truePassword()}
                                    {updatePassword()}
                                </div>


                            </Row>
                        </FalconComponentCard.Body>
                    </FalconComponentCard>
                </Col>
            </Row>

            {/*<Company section, hidden or disabled based on role*/}
            {/* {commissionSection()} */}

            {/* {companyInput()} */}

            {/*<Office section, hidden or disabled based on role*/}
            {/* {officeDetailsSection()} */}

        </>
    )
}

export default UserSettingsPage;
