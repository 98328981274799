import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import SoftBadge from 'components/common/SoftBadge';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import BrokerOfficeList from 'components/brokers/broker-office';
import { Card, Col, Row, Form, Button, Spinner, FloatingLabel, Badge } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import DeleteItemModal from 'components/modalComponents/deleteItemModal';
import { useNavigate, useLocation, Link } from "react-router-dom";
import ContractList from 'components/common/UserContracts';
import moment from 'moment';
import axiosInstance from 'helpers/axiosInstance';
import { OfficeForm } from 'viewModel/DtoClasses';


const EditOfficeForm = (props) => {
	// State

	let form = '';
	let formSecond = '';
	const location = useLocation();
	const [validated, setValidated] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [formData, setFormData] = useState(new OfficeForm());
	const columns = [
		{
			accessor: 'fileName',
			Header: 'File number',
			Cell: rowData => {
				const { fileName } = rowData.row.original;
				return (
					<h6>{fileName}</h6>
				)
			}
		},
		{
			accessor: 'contractDate',
			Header: 'Contract date',
			Cell: rowData => {
				const { contractDate } = rowData.row.original;
				let date = moment(contractDate).format('DD/MM/YYYY HH:mm');
				return (
					<span>{date}</span>
				)
			}
		},
		{
			accessor: 'yacht',
			Header: 'Yacht',
			Cell: rowData => {
				const { yacht, yachtId } = rowData.row.original;
				return (
					<Link onClick={(e) => e.stopPropagation()} to={`/components/yachts/edit?id=${yachtId}`} className="fw-bold">{yacht}</Link>
				)
			}
		},
		{
			accessor: 'kyc',
			Header: 'KYC',
			Cell: rowData => {
				const { kyc } = rowData.row.original;
				const bg = kyc ? "success" : "danger";
				const text = ""
				return (
					<Badge bg={bg} className="fs--2">
						{!kyc ? "No " : ""}KYC
					</Badge>
				)
			}
		},
		{
			accessor: 'kind',
			Header: 'Kind',
			Cell: rowData => {
				const { kind } = rowData.row.original;
				const text = kind == "CA" ? "Central Agent" : "Retail";
				const badgeColor = kind == "CA" ? "primary" : "warning";
				return (
					<span className={`fw-bold fs--2 btn-sm btn-${badgeColor}`}>{text}</span>
				)
			}
		},
		{
			accessor: 'type',
			Header: 'Type',
			Cell: rowData => {
				const { type } = rowData.row.original;
				return (
					<span>{type}</span>
				);
			}
		},
		{
			accessor: 'status',
			Header: 'Status',
			Cell: rowData => {
				const { status } = rowData.row.original

				return (
					<SoftBadge pill bg={status == "On Going" ? "success" :
						status == "Cancel with commission" ? "warning" :
							status == "Cancel without commission" ? "warning" :
								status == "Closed" ? "danger" :
									status == "Postponed Y+1" ? "info" : ""
					}
					>
						<span style={{ fontSize: "12px" }}>{status}</span>
					</SoftBadge>
				)
			}
		},
		{
			accessor: 'totalFees',
			Header: 'Total',
			Cell: rowData => {
				const { currency, totalFees } = rowData.row.original
				let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: currency });
				return (
					<span>{formatter.format(totalFees)}</span>
				)
			}
		},
		{
			accessor: 'placeFrom',
			Header: 'Place from'
		},
		{
			accessor: 'placeTo',
			Header: 'Place to'
		}
	];

	useEffect(() => {
		setFormData({ ...formData, ["id"]: searchParams.get("id") });

		axiosInstance.get(`${window.location.origin}/offices/${searchParams.get("id")}`)
			.then((res) => {
				if (res.data !== null) {
					setFormData(res.data);
				} else {
					toast.error(`No Office found`, {
						theme: 'colored'
					});
				}
			});
	}, []);

	//Handlers
	let navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		const form = $(".first-form")[0].checkValidity();
		const formSecond = $(".second-form")[0].checkValidity();

		if (form === false || formSecond === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			$('.save-spinner').removeAttr('hidden');

			axiosInstance.put(`${window.location.origin}/offices`, formData)
				.then((res) => {
					if (res.data.id !== '0') {
						navigate("/components/offices");
						toast.success(`Office information has been modified.`, {
							theme: 'colored'
						});
					} else {
						toast.error(`Some problem occurred, please try later.`, {
							theme: 'colored'
						});
					}
				});
			$('.save-spinner').attr('hidden', 'hidden');
		}
		setValidated(true);
	};

	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.id]: e.target.value
		});
	};

	const handleExit = (e) => {
		navigate("/components/offices");
	}

	const handleDelete = (e) => {
		axiosInstance.delete(`${window.location.origin}/offices/${searchParams.get("id")}`)
			.then((res) => {
				navigate("/components/offices");
				toast.success(`Office has been deleted.`, {
					theme: 'colored'
				});
				$('.save-spinner').attr('hidden', 'hidden');
			});
	}


	return (
		<>
			<PageHeader className="mb-3 g-3" title="">
				<div className="d-inline">
					<h3 className="float-start">Edit office information</h3>
					<div className="float-end">
						<Button
							variant='falcon-primary'
							className='me-2 mr-1'
							type="submit"
							onClick={e => form.dispatchEvent(
								new Event("submit", { cancelable: true, bubbles: true })
							)}>
							<Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" /> Save</Button>

						<Button
							variant='falcon-warning'
							className='me-2'
							onClick={handleExit}
						>
							<Spinner animation="border" variant="warning" role="status" size="sm" hidden={true} className="cancel-spinner" />Cancel</Button>

						<DeleteItemModal item="Office" itemName={formData.name} function={handleDelete} />
					</div>
				</div>
			</PageHeader>

			<Row className="mb-3 g-3">
				<Col lg={6}>
					<Card>
						<Card.Header >
							<h5>Office Details</h5>
						</Card.Header>
						<Card.Body className="bg-light">
							<Form validated={validated} onSubmit={handleSubmit} ref={ref => form = ref} className="first-form">
								<Row className="g-3">
									<FloatingLabel as={Col} lg={12}
										controlId="name"
										label="Name"
									>
										<Form.Control
											type="text"
											value={formData.name}
											onChange={handleFieldChange}
											placeholder="Name"
											required
										/>
										<Form.Control.Feedback type="invalid">
											Please enter an office name.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="address1"
										label="Address 1"
									>
										<Form.Control
											type="text"
											value={formData.address1}
											onChange={handleFieldChange}
											placeholder="Address 1"
										/>
										<Form.Control.Feedback type="invalid">
											Please enter an address.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="address2"
										label="Address 2"
									>
										<Form.Control
											type="text"
											value={formData.address2}
											onChange={handleFieldChange}
											placeholder="Address 2"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="city"
										label="City"
									>
										<Form.Control
											type="text"
											value={formData.city}
											onChange={handleFieldChange}
											placeholder="City"
											required
										/>
										<Form.Control.Feedback type="invalid">
											Please enter a city.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="country"
										label="Country"
									>
										<Form.Control
											type="text"
											value={formData.country}
											onChange={handleFieldChange}
											placeholder="Country"
											required
										/>
										<Form.Control.Feedback type="invalid">
											Please enter a country.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="email"
										label="Email"
									>
										<Form.Control
											type="text"
											value={formData.email}
											onChange={handleFieldChange}
											placeholder="Email"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="phone"
										label="Phone"
									>
										<Form.Control
											type="text"
											value={formData.phone}
											onChange={handleFieldChange}
											placeholder="(+01) 23 45 67 89"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="fax"
										label="Fax"
									>
										<Form.Control
											type="text"
											value={formData.fax}
											onChange={handleFieldChange}
											placeholder="(+01) 23 45 67 89"
										/>
									</FloatingLabel>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={6}>
					<Card>
						<Card.Header >
							<h5>Financial Details</h5>
						</Card.Header>
						<Card.Body className="bg-light">
							<Form validated={validated} onSubmit={handleSubmit} ref={ref => form = ref} className="second-form">
								<Row className="g-3">
									<FloatingLabel as={Col} lg={6}
										controlId="vat"
										label="VAT registration"
									>
										<Form.Control
											type="text"
											value={formData.vat}
											onChange={handleFieldChange}
											placeholder="VAT"
										/>
										<Form.Control.Feedback type="invalid">
											Please enter a VAT.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="registrationNumber"
										label="Company registration"
									>
										<Form.Control
											type="text"
											value={formData.registrationNumber}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
										<Form.Control.Feedback type="invalid">
											Please enter your company's registration number.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="bankAddress"
										label="Bank address"
									>
										<Form.Control
											type="text"
											value={formData.bankAddress}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="bankCode"
										label="Bank code"
									>
										<Form.Control
											type="text"
											value={formData.bankCode}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="codeGuichet"
										label="Agency code"
									>
										<Form.Control
											type="text"
											value={formData.codeGuichet}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={8}
										controlId="accountNumber"
										label="Account number"
									>
										<Form.Control
											type="text"
											value={formData.accountNumber}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={4}
										controlId="ribKey"
										label="RIB"
									>
										<Form.Control
											type="text"
											value={formData.ribKey}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="iban"
										label="IBAN"
									>
										<Form.Control
											type="text"
											value={formData.iban}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="bic"
										label="BIC"
									>
										<Form.Control
											type="text"
											value={formData.bic}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
									</FloatingLabel>
								</Row>

							</Form>
						</Card.Body>
					</Card>
				</Col>
				{/*<Col lg={12} className="mt-1 mb-2">*/}
				{/*    <Card>*/}
				{/*        <Card.Header>*/}
				{/*            <h5>Custom Invoice Comment</h5>*/}
				{/*        </Card.Header>*/}
				{/*        <Card.Body className="bg-100">*/}
				{/*            <Row className="g-3">*/}
				{/*                <Col lg={12}>*/}
				{/*                    <Form.Control*/}
				{/*                        as="textarea"*/}
				{/*                        placeholder="Leave a comment here"*/}
				{/*                        style={{ height: '100px' }}*/}
				{/*                        id="comment"*/}
				{/*                        value={formData.comment}*/}
				{/*                        className="px-2"*/}
				{/*                        onChange={handleFieldChange}*/}
				{/*                    />*/}
				{/*                </Col>*/}
				{/*            </Row>*/}
				{/*        </Card.Body>*/}
				{/*    </Card>*/}
				{/*</Col>*/}
				<Col lg={12} className="g-3">
					<Card>
						<Card.Header>
							<h5>Commissions</h5>
						</Card.Header>
						<Card.Body className="bg-100">

							<Row className="g-3">
								<FloatingLabel as={Col} lg={5}
									controlId="caCommission"
									label="CA commission"
								>
									<Form.Control
										type="number"
										onWheel={(e) => e.target.blur()}
										step=".01"
										min={0}
										value={formData.caCommission}
										onChange={handleFieldChange}
										placeholder="CA commission"
									/>
									<Form.Control.Feedback type="invalid">
										Please enter a CA commission.
									</Form.Control.Feedback>
								</FloatingLabel>
								<FloatingLabel as={Col} lg={5}
									controlId="retailCommission"
									label="Retail commission"
								>
									<Form.Control
										type="number"
										min={0}
										onWheel={(e) => e.target.blur()}
										value={formData.retailCommission}
										onChange={handleFieldChange}
										placeholder="Retail commission"
									/>
									<Form.Control.Feedback type="invalid">
										Please enter a retail commission.
									</Form.Control.Feedback>
								</FloatingLabel>
								<FloatingLabel as={Col} lg={2}
									controlId="invoicePrefix"
									label="Invoice prefix"
								>
									<Form.Control
										type="string"
										value={formData.invoicePrefix}
										onChange={handleFieldChange}
										placeholder="MC"
									/>
									<Form.Control.Feedback type="invalid">
										Please enter an invoice prefix.
									</Form.Control.Feedback>
								</FloatingLabel>

							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<BrokerOfficeList office={searchParams.get("id")} />

			<ContractList type="offices" columns={columns} Id={searchParams.get("id")}></ContractList>
		</>
	);
};

export default EditOfficeForm;
