import React, { useState } from 'react';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import FalconComponentCard from 'components/common/FalconComponentCard';
import TextFilter from 'components/filters/textFilter';
import DateRangeFilter from 'components/filters/daterangeFilter';
import { Col, Row, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import axiosInstance from 'helpers/axiosInstance';

const columns = [
    {
        accessor: 'officeName',
        Header: 'Office',
        style: {
            backgroundColor: 'white',
            position: 'sticky',
            zIndex: '2',
            left: 0,
            minWidth:"100px"
        }
    },
    {
        accessor: 'yachtName',
        Header: 'Yacht',
        style: {
            backgroundColor: 'white',
            position: 'sticky',
            zIndex: '2',
            left: '100px',
            minWidth: "100px"
        }
    },
    {
        accessor: 'fileName',
        Header: 'Contract',
        style: {
            backgroundColor: 'white',
            position: 'sticky',
            zIndex: '2',
            left: '200px'
        }
    },
    {
        accessor: 'startDateToString',
        Header: 'Start date'
    },
    {
        accessor: 'endDateToString',
        Header: 'End date'
    },
    {
        accessor: 'nights',
        Header: 'Nights'
    },
    {
        accessor: 'clientName',
        Header: 'Client'
    },
    {
        accessor: 'invoiceNumber',
        Header: 'Retail invoice'
    },
    {
        accessor: 'retailBrokerName',
        Header: 'Retail broker'
    },
    {
        accessor: 'mySeaBrokerName',
        Header: 'MySea broker'
    },
    {
        accessor: 'statusName',
        Header: 'Status'
    }, ,
    {
        accessor: 'currency',
        Header: 'Currency'
    },
    {
        accessor: 'charterFeeAmount',
        Header: 'Charter fee'
    },
    {
        accessor: 'apa',
        Header: 'APA'
    },
    {
        accessor: 'deposit',
        Header: 'Security deposit'
    },
    {
        accessor: 'deliveryFeeAmount',
        Header: 'Del. fee'
    },
    {
        accessor: 'vat',
        Header: 'VAT'
    },
    {
        accessor: 'installmentFirst',
        Header: 'Client to R. Inst 1'
    },
    {
        accessor: 'installmentSecond',
        Header: 'Client to R. Inst 2'
    },
    {
        accessor: 'installmentsOthers',
        Header: 'Client to R. Inst Other'
    },
    {
        accessor: 'toPayInstallFirst',
        Header: 'Owner to P. Inst 1'
    },
    {
        accessor: 'toPayInstallSecond',
        Header: 'Owner to P. Inst 2'
    },
    {
        accessor: 'ownerToPay',
        Header: 'Owner to pay'
    },
    {
        accessor: 'retailBrokerAmount',
        Header: 'Retail Agent comm'
    },
    {
        accessor: 'mySeaBrokerAmount',
        Header: 'Broker to pay N'
    },
    {
        accessor: 'mySeaMargin',
        Header: 'MySea margin'
    }
];

const SellsCurrentYearList = () => {
    const [state, setState] = useState({
        total: 0,
        totalPages: 0,
        remoteData: [],
        pageSize: 0,
        currentPage: 0
    });
    const [isLoading, showLoading] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const fetchIdRef = React.useRef(0);
    const getSells = React.useCallback(({ page, size }) => {
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;

        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
            showLoading(true);
            let formQuery = $("#tableFilters").serialize();
            formQuery += `${(formQuery.length > 0 ? "&" : "")}page=${page}`;
            formQuery += `&take=${size}`;
            axiosInstance.get(`${window.location.origin}/sales/FindSales?${formQuery}`)
                .then((res) => {
                    setState({
                        total: res.data.count,
                        totalPages: Math.ceil(res.data.count / (size * 1.0)),
                        remoteData: res.data.data,
                        pageSize: size,
                        currentPage: page
                    });
                    showLoading(false);
                });
        }
    }, []);

    const search = () => {
        getSells({ page: 0, size: state.pageSize });
    };

    const salesTable = () => {

        /*Filters*/
        const renderFilters = (visible) => {
            if (visible) {
                return (<></>);
            } else {
                return (
                    <>
                        <form id="tableFilters">
                            <Row className="mb-3 g-3">
                                <Col lg={2}>
                                    <TextFilter label="Office" id="OfficeName" placeholder="search..." />
                                </Col>
                                <Col lg={2}>
                                    <TextFilter label="Yacht" id="YachtName" placeholder="search..." />
                                </Col>
                                <Col lg={2}>
                                    <DateRangeFilter label="Start date" id="StartDate" />
                                </Col>
                                <Col lg={2}>
                                    <DateRangeFilter label="End date" id="EndDate" />
                                </Col>
                                <Col lg={2}>
                                    <TextFilter label="Currency" id="Currency" value="EUR" placeholder="search..." />
                                </Col>
                                <Col lg={2}>
                                </Col>
                                </Row>
                        </form>
                    </>);
            }
        }

        /*Table layout*/
        return (
            <>
                <AdvanceTableWrapper
                    columns={columns}
                    sortable
                    pagination
                    data={state.remoteData}
                    pageCount={state.totalPages}
                    fetchData={getSells}
                    initialPage={state.currentPage}
                    isLoading={isLoading}
                >

                    {/*Filters row*/}
                    {renderFilters(showFilters)}

                    <AdvanceTable
                        trLinkById={`${window.location.origin}/components/contracts/editSecondPage`}
                        table
                        totalRow={true}
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-3 overflow-scroll'
                        }}
                    />

                    <div className="mt-3">

                    </div>
                    <div className="mt-3">
                        <AdvanceTableFooter
                            rowCount={state.total}
                            pageCount={state.totalPages}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </div>
                </AdvanceTableWrapper>
            </>
        );
    }

    return (
        <FalconComponentCard>
            <FalconComponentCard.Header
                light={false}
                className="border-bottom border-200"
            >
                {/*Action Buttons*/}
                <div className="d-flex flex-between-center">
                    <Col md="auto" lg={6}>
                        <h4>Sales for the current year</h4>
                    </Col>
                    <Col md="auto" lg={6} className="text-end">
                        <Button variant='secondary' size='sm' className='me-2 px-4' onClick={search}>Search</Button>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="fa-filter"
                            transform="shrink-3"
                            className='me-2'
                            onClick={() => setShowFilters(!showFilters)}
                        >
                            <span className="d-none d-sm-inline-block ms-1">Filters</span>
                        </IconButton>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="external-link-alt"
                            transform="shrink-3"
                            onClick={() => { window.open(`${window.location.origin}/contracts/export-data?fileName=Sales`); }}
                        >
                            <span className="d-none d-sm-inline-block ms-1">Export</span>
                        </IconButton>
                    </Col>
                </div>
            </FalconComponentCard.Header>
            <FalconComponentCard.Body
                children={salesTable()}
                scope={{
                    AdvanceTableWrapper,
                    AdvanceTable,
                    AdvanceTableFooter,
                    IconButton,
                    Link
                }}
                language="jsx"
                noInline
                noLight
            />
        </FalconComponentCard>
    );
};

export default SellsCurrentYearList;
