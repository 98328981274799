import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { genericFieldChanger } from 'helpers/utils';
import UserDetail from 'components/users/UserDetail';

const ContactDetail = forwardRef(({ userFormData, setUserFormData, userValidated, handleContactSubmit, handleContactDelete }, ref) => {
  const [showModal, setShowModal] = useState(false);
  let userForm = React.createRef();

  useImperativeHandle(ref, () => ({
    setShowModal
  }), []);
  return (<Modal
    show={showModal}
    onHide={() => { setShowModal(false) }}
    size='lg'
  >
    <Modal.Header>
      <h4>Contact detail</h4>
    </Modal.Header>
    <Modal.Body>
      <UserDetail formData={userFormData}
        handleFieldChange={(e) => genericFieldChanger(e, setUserFormData, userFormData)}
        setRef={ref => userForm = ref}
        validated={userValidated}
        handleSubmit={handleContactSubmit}
      ></UserDetail>
    </Modal.Body>
    <Modal.Footer>
      {
        userFormData.id > 0 && <Button className='btn btn-danger' onClick={handleContactDelete}>Delete</Button>
      }
      <Button className='btn btn-primary' onClick={handleContactSubmit}>Save</Button>
    </Modal.Footer>
  </Modal>)
});

export default ContactDetail;