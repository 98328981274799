import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Form, Button, Spinner, FloatingLabel, Table, InputGroup, Modal, Alert, ToggleButton, DropdownButton, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import { registerLocale } from 'react-datepicker';
import { toast } from 'react-toastify';
import en from 'date-fns/locale/en-GB';
import { useNavigate, useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import $ from 'jquery';
import IconAlert from 'components/common/IconAlert';
import axiosInstance from 'helpers/axiosInstance';
import UploadFormFiles from 'components/common/UploadFormFiles';
import moment from 'moment';
import { defaultToZero, getUserRole, round } from 'helpers/utils';
import PageHeader from 'components/common/PageHeader';
import ContractDetailForm from './ContractDetailForm';
import CharterDetailForm from './CharterDetailForm';
import CharterFeesForm from './CharterFeesForm';
import ContractCommissionDetail from './ContractCommissionDetail';
import ContractInstallments from './ContractInstallments';
import ContractControlZone from './ContractControlZone';
import { Link } from 'react-router-dom';
import ContractPayments from './ContractPayments';
import NotificationsWidget from 'components/dashboard-widgets/notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const ContractFormBase = (
	{ handleSubmit,
		formData,
		setFormData,
		creationText,
		loading,
		attachmentsData,
		setAttachmentsData,
		emails,
		defaultEmails,
		setEmails
	}
) => {
	registerLocale('es', en);
	let navigate = useNavigate();
	// State
	let form = '';
	let init = true;
	const [validated, setValidated] = useState(false);
	const [attachmentKind, setAttachmentKind] = useState({ data: [] });
	const [show, setShow] = useState(false);

	const [contractKindsData, setContractKindsData] = useState({ data: [] });
	const [charterFeeTotal, setCharterFeeTotal] = useState(0);
	const [paymentKinds, setPaymentKinds] = useState({ data: [] });
	const [paymentTypes, setPaymentTypes] = useState({ data: [] });
	const [paymentRecipients, setPaymentRecipients] = useState({ data: [] });
	const [loaded, setLoaded] = useState(false);
	const [charterFeeData, setCharterFeeData] = useState({ total: 0.0 });
	const [brokerAdvancedCommission, setBrokerAdvancedCommission] = useState({
		brokerAdvancedCommission: 0, commNetMySea: 0, commNetMySeaOneMore: 0, commBrokerToPayOneMore: 0, commBrokerToPay: 0
	});

	const [currencies, setCurrencies] = useState({ data: [] });
	const [officesData, setOfficesData] = useState({ data: [] });
	const [contractTypesData, setContractTypesData] = useState({ data: [] });
	const [apas, setApas] = useState({ data: [] });
	const [isCentralAgent2, setIsCentralAgent2] = useState(false);
	const [readjustPayment, setReadjustPayment] = useState(false);


	const [brokersData, setBrokersData] = useState({ data: [] });

	const [contractStatusesData, setContractStatusesData] = useState({ data: [] });

	const [installments, setInstallments] = useState({
		data: [
		]
	});

	const [payments, setPayments] = useState({
		data: [
		]
	});

	const [paymentSummary, setPaymentSummary] = useState({
		totalOwner: 0,
		totalClient: 0,
		toReceive: 0,
		grossComm: 0,
		ownerToPay: 0,
		ownerToPayN: 0,
		ownerToPayN1: 0,
		toRInstall1: 0,
		toRInstall2: 0,
		toRInstall3: 0,
		ToPVat: 0,
		toPInstall1: 0,
		toPInstall2: 0,
		toPInstall3: 0,
		referralToPay: 0
	})

	const [warnings, setWarnings] = useState([]);
	const [notifications, setNotifications] = useState([]);
	const [formatter, setFormatter] = useState();

	const [isClickComms, setIsOnClickComms] = useState({
		office: false,
		broker: false,
		agent: false,
		agentVat: false,
		intermediary: false,
	})



	let ca = true;
	const role = getUserRole();
	let navigateHistory = useLocation();
	useEffect(() => {
		if (window.location.href.indexOf("CA") > -1) {
			setIsCentralAgent2(true);
		}
		else if (window.location.href.indexOf("RETAIL") > -1) {
			setIsCentralAgent2(false);
			formData.officeCommission.comm = 15;
			ca = false;
		}
	}, [navigateHistory])

	useEffect(() => {
		if (formData.id > 0)
			document.title = formData.fileName;
		else {
			document.title = "Create contract " + (isCentralAgent2 ? "CA" : "Retail")
		}
	}, [isCentralAgent2])

	useEffect(() => {
		if (init && !loaded) {
			let req10 = axiosInstance.get(`${window.location.origin}/parameters`);

			axios.all(
				[
					req10
				]
			).then(axios.spread((...responses) => {
				// setContractKindsData(o => ({
				//     data: responses[0].data.data
				// }));

				// setPaymentOutData({ id: responses[0].data.data[1].id, name: responses[0].data.data[1].name });
				// setPaymentInData({ id: responses[0].data.data[0].id, name: responses[0].data.data[0].name });

				setApas(o => ({
					data: responses[0].data.apas.values
				}))

				setCurrencies(o => ({
					data: responses[0].data.currencies.values
				}));
				// formData.charter["currency"] = responses[6].data.data[0];

				setPaymentKinds({
					data: responses[0].data.paymentKinds.values
				});
				setPaymentRecipients({
					data: responses[0].data.paymentRecipients.values
				})

				setPaymentTypes({
					data: responses[0].data.paymentTypes.values
				});

				setAttachmentKind({
					data: responses[0].data.attachmentKinds.values
				});

				setContractTypesData(o => ({
					data: responses[0].data.contractTypes.values
				}));

				setContractStatusesData(o => ({
					data: responses[0].data.contractStatuses.values
				}));

				setContractKindsData(o => ({
					data: responses[0].data.contractKind.values
				}));

				if (responses[0].data.contractStatuses.values.length != 0) {
					formData['contractStatus'] = responses[0].data.contractStatuses.values[0];
				}

				if (currencies.data.length > 0) {
					formData.charter.currency = currencies.data[0].name;
				}

				if (contractKindsData.data.length > 0) {
					formData.kind = window.location.href.indexOf("CA") > -1 ? 0 : 1;
				}

				if (contractTypesData.data.length > 0) {
					formData.type = contractTypesData.data[0].name;
				}

				if (contractTypesData.data.length > 0) {
					formData.type = contractTypesData.data[0].name;
				}
				init = false;
				setLoaded(true);
			})).catch(errors => {
				console.error(errors);
			})

			if (!role.includes("External")) {
				axiosInstance.get(`${window.location.origin}/offices/simplified?take=200`).then(resp => {
					setOfficesData(o => ({
						data: resp.data
					}));
				});
				axiosInstance.get(`${window.location.origin}/brokers/FindBrokers?take=200`).then(resp => {
					setBrokersData(o => ({
						data: resp.data.data
					}))
				})
			}

		}
		let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: formData.charter.currency });
		setFormatter(formatter);
		if (formData.yacht != null) {
			setIsCentralAgent2(formData.yacht?.ca);
		}
		calcSummary();
		let arr = [];
		let installmentTotal = formData.installments.reduce((a, b) => parseFloat(a) + parseFloat(b?.amount), 0);
		if (charterFeeTotal != installmentTotal) {
			arr.push({ message: `The installments total (${formatter.format(installmentTotal)}) isn't equal to the charter fees total ${formatter.format(charterFeeTotal)}`, variant: 'danger' });
		}
		setWarnings(arr);
	}, [formData, charterFeeTotal]);

	useEffect(() => {
		calcBrokerAdvancedCommissionChange(formData.brokerAdvancedCommission.brokerCommission, formData.mySeaBrokerCommission?.amount, formData.officeCommission?.amount, formData.intermediaryCommission?.amount);

	}, [formData.mySeaBrokerCommission, formData.officeCommission, formData.intermediaryCommission])

	useEffect(() => {
		let keys = Object.keys(isClickComms);
		keys.forEach(x => {
			try {
				let val = isClickComms[x];
				if (val)
					document.querySelector(`[name=${x}CommAmount]`).focus();
			} catch {

			}
		})
	}, [isClickComms])

	const [paymentUpdated, setPaymentUpdated] = useState(false);
	useEffect(
		() => {
			if ((role == "Administrator" || role == "Broker") && loaded && paymentUpdated && formData.id > 0) {
				axiosInstance.get(`${window.location.origin}/widgets/Notifications/${formData.id}`).then(res => {
					setNotifications(res.data);
				})
				setPaymentUpdated(false)
			}

		}, [paymentUpdated]
	)

	useEffect(() => {
		let officeCommAmount = round((formData.officeCommission?.comm / 100) * formData.charter.charterFeeAmount, 2);
		let officeCommVat = round((formData.officeCommission?.comm / 100) * formData.charter.vat * (formData.officeCommission?.isTaxIncluded ? 1 : 0), 2);
		let retailOrCaCommAmount = round((formData.brokerCommission?.comm / 100) * formData.charter.charterFeeAmount, 2);
		let retailOrCaCommVat = formData.brokerCommission.vat;

		if (formData.type == 0) {

			let msBrokerCommAmount = (formData.mySeaBrokerCommission?.comm / 100) * officeCommAmount;
			setFormData({
				...formData, officeCommission: {
					...formData.officeCommission,
					vat: officeCommVat,
					amount: officeCommAmount
				},
				brokerCommission: {
					...formData.brokerCommission,
					amount: retailOrCaCommAmount,
					vat: retailOrCaCommVat
				},
				mySeaBrokerCommission: { ...formData.mySeaBrokerCommission, amount: msBrokerCommAmount }
			})
		} else {
			let IntermidaryCommAmount = (formData.intermediaryCommission?.comm / 100) * formData.charter.charterFeeAmount;
			let IntermidaryCommVat = (formData.intermediaryCommission?.comm / 100) * formData.charter.vat * (formData.intermediaryCommission?.isTaxIncluded ? 1 : 0);
			let msBrokerCommAmount = (formData.mySeaBrokerCommission?.comm / 100) * (officeCommAmount - IntermidaryCommAmount);
			if (formData.installments.length >= 3) {
				retailOrCaCommAmount = formData.brokerCommission?.amount;
				retailOrCaCommVat = formData.brokerCommission.vat;
			}

			setFormData({
				...formData,
				officeCommission: {
					...formData.officeCommission,
					vat: officeCommVat,
					amount: officeCommAmount
				},
				brokerCommission: {
					...formData.brokerCommission,
					amount: retailOrCaCommAmount,
					vat: retailOrCaCommVat
				},
				intermediaryCommission: {
					...formData.intermediaryCommission,
					vat: IntermidaryCommVat,
					amount: IntermidaryCommAmount
				},
				mySeaBrokerCommission: {
					...formData.mySeaBrokerCommission,
					amount: msBrokerCommAmount
				}
			});
		}
		setCharterFeeTotal(getCharterFeeTotal());
	}, [formData.charter.charterFeeAmount, formData.charter.deliveryFeeAmount, formData.charter.apa, formData.charter.vat, formData.charter.deposit])

	const getCharterFeeTotal = () => {
		return Number(formData.charter.charterFeeAmount) + Number(formData.charter.deliveryFeeAmount) + Number(formData.charter.apa) + Number(formData.charter.vat) + Number(formData.charter.deposit);
	}

	const calcBrokerAdvancedCommissionChange = (brokerAdvanceComm, myseaBrComm, officeComm, IntermidiaryComm) => {
		let commBrToPayC = (paymentSummary.totalClient > 0 && !formData.mySeaBrokerCommission?.invoice) ? (myseaBrComm - (isNaN(brokerAdvanceComm) ? 0 : brokerAdvanceComm)) : 0;
		let commNetMySeaC = ((officeComm - myseaBrComm - IntermidiaryComm) * 100) / 100;

		let Statuses = ["Cancel without comm.", "Postponed Y+1"];
		setFormData({
			...formData,
			brokerAdvancedCommission: {
				...formData.brokerAdvancedCommission,
				brokerCommission: brokerAdvanceComm,
				commBrokerToPay: commBrToPayC,
				commNetMySea: commNetMySeaC
			}
		});
	}

	//Handlers

	const handleCharterFieldChange = e => {
		let fees = ["charterFeeAmount", "deliveryFeeAmount", "deposit", "apa", "vat"]
		if (fees.includes(e.target.name) && formData.id > 0)
			setReadjustPayment(true);

		let val = e.target.value;
		if (e.target.name == "kyc")
			val = e.target.checked;
		setFormData({
			...formData,
			charter: {
				...formData.charter,
				[e.target.name]: val
			}
		})
	}


	const handleFieldChange = e => {
		let fees = ["charterFee", "deliveryFee", "deposit", "apa", "vat"]
		let toSet = e.target.value;
		if (fees.includes(e.target.id)) {
			toSet = Number(e.target.value);
			formData[e.target.id] = toSet;
		}
		if (e.target.id == "kyc")
			toSet = e.target.checked;
		setFormData({
			...formData,
			[e.target.id]: toSet
		});
	}

	const calcSummary = () => {
		try {
			let clientPayments = formData.payments.filter(x => x.type == "In");
			let totalInstall = Number(formData.installments.reduce((acc, cur) => Number(acc) + Number(cur?.amount), 0));
			let totalClient = Number(formData.payments.filter(x => x.type == "In").reduce((acc, curr) => Number(acc) + Number(curr?.amountReceived), 0));
			let totalOwner = Number(formData.payments.filter(x => x.type == "Out").reduce((acc, curr) => Number(acc) + Number(curr?.amountReceived), 0));/* + ((formData.charter.ApaDueDate != null) ? Number(formData.charter.apa) : 0) + (!yacht.vatRep ? 0 : (formData.charter.VatDueDate != null ? Number(formData.charter.vat) : 0));*/
			if (isCentralAgent2) {

				// let totalOwner = Number(payments.data.filter((p) => p.contractPaymentType.id == 1).reduce((acc, curr) => acc + curr?.amount, 0)) + (formData.charter.apaDate != null ? Number(formData.charter.apa) : 0) + (formData.vatRep ? 0 : (formData.charter.vatDate != null ? Number(formData.charter.vat) : 0));
				let toReceive = totalInstall - (!formData.directToClient ? (Number(formData.brokerCommission?.amount) + Number(formData.brokerCommission.vat)) : 0);
				if (formData.id > 0) {
					toReceive = formData.payments.filter(x => x.type == "In").reduce((acc, curr) => Number(acc) + Number(curr?.amountToPay), 0);
				}
				let toReceiveClient = toReceive - totalClient;
				let grossComm = totalClient - totalOwner;
				let ownerToPay = (totalClient == 0 ? 0 : grossComm - formData.officeCommission?.amount - formData.officeCommission.vat);
				let ownerToPayN = formData.Status == "Postponed Y+1" ? 0 : ownerToPay;
				let ownerToPayN1 = formData.Status == "Postponed Y+1" ? ownerToPay : 0;
				let toRInstall1 = (formData.installments.length > 0 ? formData.installments[0]?.amount : 0) - formData.brokerCommission?.amount - formData.brokerCommission.vat;
				if (formData.directToClient) {
					toRInstall1 = (formData.installments.length > 0 ? formData.installments[0]?.amount : 0);
				}
				let toRInstall2 = (formData.installments.length > 1 ? formData.installments[1]?.amount : 0);
				let toRInstall3 = (formData.installments.length > 2 ? formData.installments[2]?.amount : 0);
				let toPVat = formData.charter.vat - (formData.officeCommission?.isTaxIncluded ? formData.officeCommission.vat : 0) - (formData.brokerCommission?.isTaxIncluded ? formData.brokerCommission.vat : 0);
				let toPInstall1 = (formData.charter.charterFeeAmount / 2) - formData.officeCommission?.amount - formData.brokerCommission?.amount + formData.charter.deliveryFeeAmount;
				let toPInstall2 = (!formData.vatRep ? (formData.charter.charterFeeAmount / 2) + toPVat : formData.charter.charterFeeAmount / 2);
				let toPInstall3 = null;
				console.log(toPInstall2);
				setPaymentSummary({
					totalClient: totalClient,
					totalOwner: totalOwner,
					toReceive: Number(toRInstall1) + Number(toRInstall2) + Number(toRInstall3),
					grossComm: grossComm,
					ownerToPay: ownerToPay,
					ownerToPayN: ownerToPayN,
					toReceiveClient: toReceiveClient,
					ownerToPayN1: ownerToPayN1,
					toRInstall1: toRInstall1,
					toRInstall2: toRInstall2,
					toRInstall3: toRInstall3,
					ToPVat: formData.vatRep ? toPVat : 0,
					toPInstall1: toPInstall1,
					toPInstall2: toPInstall2,
					toPInstall3: toPInstall3,
					totalInstall: totalInstall,
					referralToPay: (!formData.intermediaryCommission?.invoice && totalClient != 0) ? formData.intermediaryCommission?.amount : 0
				});

			} else {
				// let totalOwner = Number(payments.data.filter((p) => p.contractPaymentType.id == 1).reduce((acc, curr) => acc + curr?.amount, 0)) + ((!formData.vatRep && formData.charter.vatDate != null) ? Number(formData.charter.vat) : 0) + (formData.vatRep ? 0 : (!formData.charter.vatDate != null ? 0 : (formData.charter.apaDate != null ? formData.charter.apa : 0)));
				let grossComm = totalClient - totalOwner;
				let toReceiveClient = totalInstall - totalClient;
				if (formData.id > 0) {
					toReceiveClient = formData.payments.filter(x => x.type == "In").reduce((acc, curr) => Number(acc) + Number(curr?.amountToPay), 0) - totalClient;
				}
				let ownerToPay = (totalClient == 0 ? 0 : (formData.installments.length <= 2 || (formData.installments.length > 2 && formData.installments[2]?.amount == 0) ? grossComm - formData.officeCommission?.amount - formData.officeCommission.vat : (clientPayments.length <= 1 ? grossComm - (formData.officeCommission?.amount / 2) - (formData.officeCommission.vat / 2) : grossComm - formData.officeCommission?.amount - formData.officeCommission.vat)));
				let ownerToPayN = null;
				let ownerToPayN1 = null;
				let toRInstall1 = (formData.installments.length > 0 ? formData.installments[0]?.amount : 0);
				let toRInstall2 = (formData.installments.length > 1 ? formData.installments[1]?.amount : 0);
				let toRInstall3 = (formData.installments.length > 2 ? formData.installments[2]?.amount : 0);
				let toPVat = null;
				let toPInstall1 = (formData.installments.length <= 2 || formData.installments[2]?.amount == 0) ? (formData.installments.length > 0 ? formData.installments[0]?.amount : 0) - formData.officeCommission?.amount - formData.officeCommission.vat : (formData.installments.length > 0 ? formData.installments[0]?.amount : 0) - formData.officeCommission?.amount / 2 - formData.officeCommission.vat / 2;
				let toPInstall2 = (formData.installments.length <= 2 || formData.installments[2]?.amount == 0) ? (formData.installments.length > 1 ? formData.installments[1]?.amount : 0) : (formData.installments.length > 1 ? formData.installments[1]?.amount : 0) - formData.officeCommission?.amount / 2 - formData.officeCommission.vat / 2;
				let toPInstall3 = !formData.vatRep ? toRInstall3 : toRInstall3 - formData.charter.vat;
				setPaymentSummary({
					totalClient: totalClient,
					totalOwner: totalOwner,
					toReceive: Number(toRInstall1) + Number(toRInstall2) + Number(toRInstall3),
					grossComm: grossComm,
					ownerToPay: ownerToPay,
					ownerToPayN: ownerToPayN,
					ownerToPayN1: ownerToPayN1,
					toReceiveClient: toReceiveClient,
					toRInstall1: toRInstall1,
					toRInstall2: toRInstall2,
					toRInstall3: toRInstall3,
					ToPVat: toPVat,
					toPInstall1: toPInstall1,
					toPInstall2: toPInstall2,
					toPInstall3: toPInstall3,
					totalInstall: totalInstall,
					referralToPay: (!formData.intermediaryCommission?.invoice && totalClient != 0) ? formData.intermediaryCommission?.amount : 0
				});
			}

		} catch (e) {
			console.log(e);
		}
	}

	const handleDelete = (e) => {

		axiosInstance.delete(`${window.location.origin}/contracts/${formData.id}`)
			.then((res) => {
				if (res.status == 200) {

					$('.save-spinner').attr('hidden', 'hidden');
					toast.success(`Contract ${formData.fileName} has been deleted.`, {
						theme: 'colored'
					});
					navigate("/components/contracts");
				} else {
					toast.error(`There was an error deleting the contract: ${res.data.message}`, {
						theme: 'colored'
					});
					$('.save-spinner').attr('hidden', 'hidden');
				}
			});

	}

	const handleCommAmountOnClick = (type) => {
		setIsOnClickComms({ ...isClickComms, [type]: !isClickComms[type] })
	}
	/// Partial views

	const attachmentOptions = () => {
		if (attachmentKind != null)
			return (
				<><option>No Type</option>
					{attachmentKind.data.map((obj) => (<option key={obj.id} value={obj.name}>{obj.name}</option>))}
				</>
			);
		else
			<option>No Type</option>
	}

	const loadContractTypeOptions = () => {
		if (contractTypesData.data) {
			return (
				<>
					{
						contractTypesData.data.map(type => (<option value={type.id} key={type.id} >
							{`${type.name}`}
						</option>))
					}
				</>
			);
		}
	}

	const loadCurrencyOptions = () => {
		if (currencies.data) {
			return (
				<>
					{
						currencies.data.map(currency => (<option value={currency.name} key={currency.id} >
							{`${currency.name}`}
						</option>))
					}
				</>
			);
		}
	}

	const loadYachtOptions = (input, callback) => {
		axiosInstance.get(`${window.location.origin}/yachts?Name=${input}&page=0&take=15`).then(x => {
			x.data.data.push({ name: "Search for a yacht", id: -1 })
			callback(x.data.data);
		}).catch((e => {
			let errMsg = "Error getting yachts"
			if (e.response.status == 403)
				errMsg = "You are not authorized";
			toast.error(errMsg, { theme: 'colored' })
		}))
	}

	const loadContractStatusOptions = () => {
		if (contractStatusesData.data) {
			return (
				<>
					<option value={0} key={"NonSelectorT"} disabled>Select status</option> //By default On going
					{
						contractStatusesData.data.map(status => (<option value={status.name} key={status.id} >
							{`${status.name}`}
						</option>))
					}
				</>
			);
		}
	}

	const loadClientOptions = (input, callback) => {
		axiosInstance.get(`${window.location.origin}/clients?Name=${input}&page=0&take=15`).then(x => {
			x.data.data.push({ name: "Search for company", id: -1 })
			callback(x.data.data);
		}).catch((e => {
			let errMsg = "Error getting companies"
			if (e.response.status === 403)
				errMsg = "You are not authorized";
			toast.error(errMsg, { theme: 'colored' })
		}))
	}

	const loadSupplierOptions = (input, callback) => {
		axiosInstance.get(`${window.location.origin}/suppliers?Name=${input}&page=0&take=15`).then(x => {
			x.data.data.push({ name: "Search for external broker", id: -1 })
			callback(x.data.data);
		}).catch((e => {
			let errMsg = "Error getting external brokers"
			if (e.response.status === 403)
				errMsg = "You are not authorized";
			toast.error(errMsg, { theme: 'colored' })
		}))
	}

	const changeHandler = (event) => {
		let obj = {
			file: event.target.files[0],
			type: document.getElementById("uploadFileType").value,
			date: new Date(),
			size: event.target.files[0].size
		};
		document.getElementById("fileUpload").value = '';
		let attachments = attachmentsData.data;
		attachments.push(obj);
		setAttachmentsData({ data: attachments });
	};

	const removeFile = (index) => {
		let attachments = attachmentsData.data;
		attachments.splice(index, 1);
		setAttachmentsData({ data: attachments });
	}

	const handleOfficeDetails = (office) => {
		let comm = defaultToZero((isCentralAgent2) ? office.caCommission : office.retailCommission);
		if (office != null) {
			setFormData({
				...formData,
				office: office,
				officeId: office.id,
				officeCommission: {
					...formData.officeCommission,
					comm: comm,
					amount: (formData.charter.charterFeeAmount / 100) * comm,
					vat: (formData.charter.vat / 100) * comm
				}
			});
			if (formData.id > 0)
				setReadjustPayment(true);
			}
	}

	const handleBrokerDetails = (value) => {
		let comm = defaultToZero((formData.kind == "CA") ? value.caCommission : value.retailCommission);
		if (value != null) {
			setFormData({
				...formData,
				mySeaBroker: value,
				mySeaBrokerId: value.id,
				mySeaBrokerCommission: { ...formData.mySeaBrokerCommission, comm: comm, amount: (formData.officeCommission?.amount / 100) * comm }
			});
		}
	}

	const handleYachtDetails = (company) => {
		if (company != null && company.id != -1) {
			let office = officesData.data.find(x => x.id == company.officeId);
			let mySeaBroker = brokersData.data.find(x => x.id == company.mySeaBrokerId);
			let commOffice = defaultToZero(office != null ? (company.ca) ? office.caCommission : office.retailCommission : 0);
			let commBroker = defaultToZero(mySeaBroker != null ? (company.ca) ? mySeaBroker.caCommission : mySeaBroker.retailCommission : null);
			let commIntermediary = company.intermediaryComm ? company.intermediaryComm : 0;
			let newKyc = formData.charter.kyc;
			let newVatRep = formData.vatRep;
			setIsCentralAgent2(company.ca);
			if (company.ca) {
				newKyc = company.kyc;
				newVatRep = company.vatRep;
			} else {
			}
			setFormData({
				...formData,
				yacht: company,
				yachtId: company.id,
				vatRep: newVatRep,
				kind: company.ca ? "CA" : "Retail",
				office: office != null ? office : formData.office,
				officeId: office != null ? office.id : formData.officeId,
				mySeaBroker: mySeaBroker != null ? mySeaBroker : formData.mySeaBroker,
				mySeaBrokerId: mySeaBroker != null ? mySeaBroker.id : formData.mySeaBrokerId,
				intermediary: company.intermediary ? company.intermediary : "",
				intermediaryCommission: {
					...formData.intermediaryCommission,
					comm: commIntermediary,
					amount: commIntermediary * (formData.charter.charterFeeAmount / 100),
					vat: (formData.charter.vat / 100) * commIntermediary,
					isTaxIncluded: company.intermediaryVAT ? company.intermediaryVAT : false
				},
				mySeaBrokerCommission: mySeaBroker != null ?
					{
						...formData.mySeaBrokerCommission,
						comm: commBroker,
						amount: (((formData.charter.charterFeeAmount / 100) * commOffice) / 100) * commBroker
					}
					: formData.mySeaBrokerCommission,
				officeCommission: (office != null ? {
					...formData.officeCommission,
					comm: commOffice,
					isTaxIncluded: company.mySeaVatApplicable,
					amount: (formData.charter.charterFeeAmount / 100) * commOffice,
					vat: (formData.charter.vat / 100) * commOffice
				}
					: formData.officeCommission),
				broker: company.contact?.fullName ? formData.broker : (company.contact?.fullName),
				brokerCommission: {
					...formData.brokerCommission,
					comm: company.ca ? defaultToZero(company.centralAgent?.retailCommission) : company.rE_CAAgentComm
				},
				charter: {
					...formData.charter,
					supplier: company.ca ? null : (company.centralAgent),
					supplierId: company.ca ? null : (company.centralAgent?.id),
					kyc: newKyc,
				},
				contactId: company.contactId,
				contact: company.contact
			});
			if (formData.id > 0)
				setReadjustPayment(true);
		}
	}

	const handleSelectChange = (e, type) => {
		switch (type) {
			case "client": {
				let company = e;
				if (company != null) {
					let kyc = formData.charter.kyc;
					if (!isCentralAgent2)
						kyc = company.kyc;
					else {
						if (formData.yacht?.kyc != null)
							kyc = formData.yacht.kyc
					}
					setFormData({
						...formData,
						charter: {
							...formData.charter,
							client: e,
							clientId: e.id,
							kyc: kyc
						}
					});
				}
				break;
			}
			case "supplier": {
				setFormData({
					...formData,
					charter: {
						...formData.charter,
						supplier: e,
						supplierId: e.id
					},
					contactId: null,
					brokerCommission: {
						...formData.brokerCommission,
						comm: isCentralAgent2 ? defaultToZero(e.retailCommission) : formData.brokerCommission.comm,
					}
				});
				break;
			}
			case "contractType": {
				let type = contractTypesData.data.find(x => x.id == e.target.value);
				if (type != null)
					setFormData({ ...formData, type: type.name });
				break;
			}
			case "contractStatus": {
				setFormData({ ...formData, status: e.target.value });
				break;
			}
			case "office": {
				let office = e;
				let comm = defaultToZero((formData.kind == "CA") ? office.caCommission : office.retailCommission);
				if (office != null) {
					setFormData({
						...formData,
						office: e,
						officeId: e.id,
						officeCommission: {
							...formData.officeCommission, comm: comm, amount: (formData.charter.charterFeeAmount / 100) * comm,
							vat: (formData.charter.vat / 100) * comm
						}
					});
					if (formData.id > 0)
						setReadjustPayment(true);
				}
				break;
			}
			case "mySeaBroker": {
				let comm = defaultToZero((formData.kind == "CA") ? e.caCommission : e.retailCommission);
				setFormData({
					...formData,
					mySeaBroker: e,
					mySeaBrokerId: e.id,
					mySeaBrokerCommission: { ...formData.mySeaBrokerCommission, comm: comm, amount: (formData.officeCommission?.amount / 100) * comm }
				});
				
				break;
			}
			case "currency": {
				let company = currencies.data.find(x => x.name == e.target.value);
				if (company != null) {
					setFormData({ ...formData, charter: { ...formData.charter, currency: company.name } });
					// let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: company.name });
					// setFormatter(formatter);
				}
				break;
			}
			case "apaPaidTo": {
				let company = apas.data.find(x => x.id == e.target.value);
				if (company != null) {
					setFormData({ ...formData, apaPaidTo: company.name });
				}
				break;
			}
			case "vatPaidTo": {
				let company = apas.data.find(x => x.id == e.target.value);
				if (company != null) {
					setFormData({ ...formData, vatPaidTo: company.name });
				}
				break;
			}
		}
	}

	const loadWarnings = () => {

		return (
			warnings.map((warning, index) =>
				<IconAlert key={index} variant={warning.variant}>
					<p className="mb-0">{warning.message}</p>
				</IconAlert>
			)
		);
	}

	const readjustPaymentsWarning = () => {
		if (readjustPayment) {
			let warning = ({ message: `The charter's/commissions' numbers have been changed. Would you like to readjust the payments?</br><strong>This will delete all your current payments!</strong>`, variant: 'warning' });
			return (
				<IconAlert variant={warning.variant} className='mb-2'>
					<div className='d-flex justify-content-between'>
						<p className="mb-0" dangerouslySetInnerHTML={{ __html: warning.message }}></p>
						<Button variant='warning' className='ms-2' onClick={async (e) => {
							await handleSubmit(true);
							setReadjustPayment(false);
						}}>
							{loading ? (<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>) : "Confirm changes and regenerate"}
						</Button>
					</div>
				</IconAlert>
			);
		}
	}

	const setDisplay = (bool) => {
		document.getElementById("displayInfo").hidden = bool;
		document.getElementById("editInfo").hidden = !bool;
	}

	/// Main return
	if (loaded) {
		return (
			<>
				<PageHeader className="mb-3 g-3" title="">
					<div className="d-flex justify-content-between align-items-center">
						<h3 className="float-start">{formData.id != 0 ? formData.fileName : ("Create " + (isCentralAgent2 ? "CA contract" : "retail contract"))}</h3>
						<div className="float-end d-flex align-items-center">
							{
								((role == "Administrator" || role == "Broker" || role == "ExternalUser") && formData.id != 0) && (
									<Link to={`/components/contracts/${formData.id}/sac`} className="btn btn-falcon-default me-2">Statement Of Account</Link>
								)
							}
							<FloatingLabel
								label="Status"
								className="me-2 d-inline-block"
								style={{ maxWidth: "200px" }}
							>
								<Form.Select disabled={role != "Administrator"} className="me-2" required onChange={e => { handleSelectChange(e, "contractStatus") }} value={formData.status}>
									{loadContractStatusOptions()}
								</Form.Select>
							</FloatingLabel>

							{
								((role == "Administrator") && (<>
									{formData.id != 0 && <Button
										variant='falcon-danger'
										className='me-2'
										type="submit"
										onClick={(e) => { setShow(true) }}
									>
										<Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" />
										Delete</Button>}
									<Button
										variant='falcon-default'
										className='me-2'
										type="submit"
										onClick={(e) => { navigate(-1) }}
									>
										Cancel</Button>
									<Button
										variant='falcon-primary'
										type="submit"
										onClick={async (e) => { await handleSubmit(false); setReadjustPayment(false) }}
									>
										<Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" />
										Save
									</Button>

								</>))
							}
						</div>
					</div>
				</PageHeader>
				{
					loadWarnings()
				}
				{
					readjustPaymentsWarning()
				}
				<Row className="g-3">
					<Col md={12} lg={8}>
						{
							formData.id > 0 &&
							<div id="displayInfo" className='mb-3 g-3' hidden={formData.id == 0} >
								<Card>
									<Card.Header className="d-flex justify-content-between align-items-center">
										<h5>Contract Details</h5>
										{(role == "Administrator" && formData.id > 0) &&
											<button className='btn btn-primary btn-sm' onClick={() => { setDisplay(true) }}><FontAwesomeIcon icon={faPenToSquare} className="me-2"></FontAwesomeIcon>Edit</button>
										}
									</Card.Header>
									<Card.Body className="bg-light">
										<div className="" style={{ fontSize: '0.9rem' }}>
											<div className="row mx-0">
												<div className="col-lg-auto px-2">Contract n°: <b>{formData.contractNumber}</b></div>
												<div className="col-lg-auto px-2">Date: <b>{moment(formData.contractDate).format("DD/MM/YYYY")}</b></div>
												<div className="col-lg-auto px-2">Contract type: <b>{formData.type}</b></div>
												<span className="col-lg-auto px-2">VAT Rep: <span className={(!formData.vatRep ? "text-danger" : "text-success") + " fw-bold"}>{formData.vatRep ? "YES" : "NO"}</span></span>
												<span className="col-lg-auto px-2">KYC: <span className={(!formData.charter.kyc ? "text-danger" : "text-success") + " fw-bold"}>{formData.charter.kyc ? "YES" : "NO"}</span></span>
											</div>
											<hr />
											<div className='row'>
												<span className="col-lg-6">Name of Vessel: <Link target="_blank" className="text-primary fw-bold" to={`/components/yachts/edit?id=${formData.yachtId}`}>{formData.yacht?.name}</Link></span>
												<span className='d-none'>Owner: {formData.yacht.company}</span>
												<span className="col-lg-6">Client:
													{isCentralAgent2 ? <b>{formData.caClientName}</b> :
														<Link target="_blank" className="text-primary fw-bold" to={`/components/clients/edit?id=${formData.charter.clientId}`}>{formData.charter.client?.name}</Link>
													}
												</span>
												<span className="col-lg-6">External broker: <Link target="_blank" className="text-primary fw-bold" to={`/components/externalbrokers/edit?id=${formData.charter.supplierId}`}>{formData.charter.supplier?.name}</Link></span>
												<span className="col-lg-6">Contact: {formData.contact ? formData.contact.fullName : 'None selected'}</span>
											</div>
											<div className='d-none'>
												Address:<br />
												<span>
													{formData.yacht.address1}<br />
													{formData.yacht.city} {formData.yacht.zipCode}, {formData.yacht.country}
												</span>
											</div>
											<div className='row'>
												<span className="col-lg-6">Length: {formData.yacht.length}</span>
												<span className="col-lg-6">Flag: {formData.yacht.flag}</span>
											</div>
											<div className='row'>
												<span className="col-lg-6">From: <b>{formData.charter.placeFrom}</b> at {moment(formData.charter.startDate).format("DD MMMM YYYY")} {moment(formData.charter.startTime).format("hh:mm")}</span>
												<span className="col-lg-6">To: <b>{formData.charter.placeTo}</b> at {moment(formData.charter.endDate).format("DD MMMM YYYY")} {moment(formData.charter.endTime).format("hh:mm")}</span>
											</div>
											<hr />
											<div>
												<div className='row mx-0 text-center'>
													<span className='col px-2 ps-0'>Charter Fee: <br />{formatter.format(formData.charter.charterFeeAmount)} </span>
													<span className='col px-2'>VAT: <br />{formatter.format(formData.charter.vat)}</span>
													<span className='col px-2'>APA: <br />{formatter.format(formData.charter.apa)}</span>
													<span className='col px-2'>Delivery fees: <br />{formatter.format(formData.charter.deliveryFeeAmount)}</span>
													<span className='col px-2'><span style={{ whiteSpace: "nowrap" }}>Security deposit:</span> <br />{formatter.format(formData.charter.deposit)}</span>
													<span className='col px-2 d-xl-block d-md-none fw-bold'>Total: <br />{formatter.format(charterFeeTotal)}</span>

												</div>
												<div className='row mt-3 mx-0 justify-content-end'>
													<div className='col d-flex justify-content-end d-md-block d-xl-none'>
														<div className='text-end'>
															TOTAL: <span className='mx-3 fw-bold fs--1rem'>{formatter.format(charterFeeTotal)}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Card.Body>
								</Card>
							</div>
						}
						<div id="editInfo" hidden={formData.id > 0}>
							<Row className="mb-3 g-3">
								<Col xs sm md lg="12" >
									<ContractDetailForm
										formData={formData}
										setFormData={setFormData}
										handleFieldChange={handleFieldChange}
										handleSelectChange={handleSelectChange}
										handleYachtDetails={handleYachtDetails}
										loadContractTypeOptions={loadContractTypeOptions}
										loadYachtOptions={loadYachtOptions}
										isCentralAgent2={isCentralAgent2}
										validated={validated}
										loadClientOptions={loadClientOptions}
										loadSupplierOptions={loadSupplierOptions}
										handleCharterFieldChange={handleCharterFieldChange}
										setReadjustPayment={setReadjustPayment}
									/>
								</Col>
								{/*Charter Details*/}
								<Col sm md lg={12}>
									<CharterDetailForm
										formData={formData}
										setFormData={setFormData}
										handleFieldChange={handleFieldChange}
										handleCharterFieldChange={handleCharterFieldChange}
										setReadjustPayment={setReadjustPayment}
										handleSelectChange={handleSelectChange}
										isCentralAgent2={isCentralAgent2}
										loadClientOptions={loadClientOptions}
										validated={validated}
										loadSupplierOptions={loadSupplierOptions}
									/>
								</Col>
							</Row>
							<CharterFeesForm
								formData={formData}
								setFormData={setFormData}
								handleFieldChange={handleFieldChange}
								handleSelectChange={handleSelectChange}
								setReadjustPayment={setReadjustPayment}
								isCentralAgent2={isCentralAgent2}
								loadCurrencyOptions={loadCurrencyOptions}
								validated={validated}
								charterFeeTotal={charterFeeTotal}
								getCharterFeeTotal={getCharterFeeTotal}
								handleCharterFieldChange={handleCharterFieldChange}
								formatter={formatter}
								calcBrokerAdvancedCommissionChange={calcBrokerAdvancedCommissionChange}
							/>
						</div>

						{formData.id != 0 &&
							<>
								<div className="mb-3 g-3">
									<ContractPayments
										formData={formData}
										formatter={formatter}
										setFormData={setFormData}
										objectType={{ name: "Payment", subType: "Client", subTypeVal: "In" }}
										role={role}
										controlZone={[{ name: "Total Client", value: paymentSummary.totalClient.toFixed(2) }, { name: "To receive", value: paymentSummary.toReceiveClient ? paymentSummary.toReceiveClient.toFixed(2) : 0 }
										]}
										paymentKinds={paymentKinds}
										paymentRecipients={paymentRecipients}
										emails={emails}
										setEmails={setEmails}
										defaultEmails={defaultEmails}
										setPaymentUpdated={setPaymentUpdated}
									>
									</ContractPayments>
								</div>
								<div className="mb-3 g-3">
									<ContractPayments
										formData={formData}
										formatter={formatter}
										setFormData={setFormData}
										objectType={{ name: "Payment", subType: "Owner", subTypeVal: "Out" }}
										role={role}
										controlZone={[{ name: "Total Owner", value: paymentSummary.totalOwner.toFixed(2) }, { name: "Gross Comm", value: parseFloat(paymentSummary.grossComm).toFixed(2) }, { name: "Owner to pay", value: parseFloat(paymentSummary.ownerToPay).toFixed(2) }]}
										paymentKinds={paymentKinds}
										paymentRecipients={paymentRecipients}
										emails={emails}
										setEmails={setEmails}
										defaultEmails={defaultEmails}
										setPaymentUpdated={setPaymentUpdated}
									>
									</ContractPayments>
								</div>
							</>
						}
						{
							!role.includes("External") &&
							<Row className="mb-3 g-3">
								<Col sm md lg={12}>
									<ContractCommissionDetail
										formData={formData}
										handleCommAmountOnClick={handleCommAmountOnClick}
										calcBrokerAdvancedCommissionChange={calcBrokerAdvancedCommissionChange}
										handleOfficeDetails={handleOfficeDetails}
										officesData={officesData}
										setFormData={setFormData}
										setIsOnClickComms={setIsOnClickComms}
										isClickComms={isClickComms}
										formatter={formatter}
										brokersData={brokersData}
										handleBrokerDetails={handleBrokerDetails}
										brokerAdvancedCommission={formData.brokerAdvancedCommission}
										setReadjustPayment={setReadjustPayment}
										role={role}
									></ContractCommissionDetail>
								</Col>
							</Row>
						}
						<Row>
							<Col lg={12}>
								{
									(attachmentsData != null && (<UploadFormFiles
										attachmentsData={attachmentsData}
										setAttachmentsData={setAttachmentsData}
										attachmentKinds={attachmentKind}
										disableModifications={role.includes("External")}
										uploadTo={(formData.id > 0 ? ("contract-" + formData.id) : null)}
									/>))
								}
							</Col>
						</Row>
					</Col>
					<Col md={12} lg={4}>
						{
							(!role.includes("External") && formData.id > 0) && (<>
								<Col lg={12} className='mb-3 g-3'>
									<NotificationsWidget show={true} dash={false} notifications={notifications} />
								</Col>
							</>)
						}
						<ContractInstallments
							controlZone={[{ name: "Total Installment", value: paymentSummary.totalInstall }, { name: "To receive", value: paymentSummary.toReceive }]}
							formData={formData}
							setFormData={setFormData}
							currency={formData.charter.currency}
							formatter={formatter}
							role={getUserRole()}
							setReadjustPayment={setReadjustPayment}
						>
						</ContractInstallments>
						<Row className="mb-3 g-3">
							<Col >
								<ContractControlZone
									paymentSummary={paymentSummary}
									brokerAdvancedCommission={formData.brokerAdvancedCommission}
									formatter={formatter}
								/>
							</Col>
							<Col lg={12}>
								<Card>
									<Card.Header className="bg-white">
										<h5>Notes</h5>
									</Card.Header>
									<Card.Body className="bg-light">
										<Form.Group as={Col}>
											<Form.Control
												type="text"
												as="textarea"
												rows={5}
												value={formData.notes}
												onChange={handleFieldChange}
												id="notes"
											/>
										</Form.Group>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
				<Modal
					show={show}
					onHide={() => { setShow(false); }}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"

				>
					<Modal.Header
						closeButton
						className="bg-light px-card border-bottom-0"
					>
						<Modal.Title>
							<h5>Delete contract</h5>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h5 className='text-center'>
							Are you sure you want to delete this contract?

						</h5>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary'>
							Cancel
						</Button>
						<Button variant='danger' onClick={handleDelete}>
							Delete
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
	else
		return (
			<div className="d-flex flex-column min-vh-100 justify-content-center align-items-center"><BeatLoader color={"#9B9B9B"} size={25} /></div>
		)

};

export default ContractFormBase;
