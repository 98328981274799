import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import axiosInstance from 'helpers/axiosInstance';

const columns = [
    {
        accessor: 'person.firstName',
        Header: 'First Name'
    },
    {
        accessor: 'person.lastName',
        Header: 'Last Name'
    },
    {
        accessor: 'person.email',
        Header: 'Email'
    }
];

const BrokerOfficeList = (office) => {

    
    const [brokers, setBrokers] = useState({ data: [] });

    const getBrokers = () => {

        return new Promise((resolve, reject) => {
            axiosInstance.get(`${window.location.origin}/brokers/FindBrokers?officeId=${office.office}`)
                .then((res) => {
                    resolve(res.data.data);
                });
        });
    }

    useEffect(() => {
        getBrokers()
            .then((response) => {
                setBrokers(o => ({
                    data: response
                }));
            });
    }, []);

    const brokerTable = () => {

        return (
            <>
                <AdvanceTableWrapper
                    columns={columns}
                    data={brokers.data}
                    sortable
                    pagination
                    perPage={10}
                >
                    <AdvanceTable
                        trLinkById={`${window.location.origin}/components/brokers/edit`}
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                    <div className="mt-3">
                        <AdvanceTableFooter
                            rowCount={brokers.data.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </div>
                </AdvanceTableWrapper>
            </>
        );
    };

    if (brokers.data.length > 0) {
        return (
            <FalconComponentCard>
                <FalconComponentCard.Header
                    light={false}
                    className="border-bottom border-200"
                >
                    <div className="d-flex flex-between-center">
                        <Col md="auto" lg={6}>
                            <h4>Brokers</h4>
                        </Col>
                        <Col sm="auto" lg={6} className="text-end">
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="external-link-alt"
                                transform="shrink-3"
                            >
                                <span className="d-none d-sm-inline-block ms-1"
                                    onClick={() => { window.location.replace(`${window.location.origin}/brokers/export-data?fileName=Brokers`); }}>Export</span>
                            </IconButton>
                        </Col>
                    </div>
                </FalconComponentCard.Header>
                <FalconComponentCard.Body
                    children={brokerTable([brokers, setBrokers])}
                    scope={{
                        AdvanceTableWrapper,
                        AdvanceTable,
                        AdvanceTableFooter,
                        IconButton,
                        Link
                    }}
                    language="jsx"
                    noInline
                    noLight
                />
            </FalconComponentCard>
        );
    }

    else
        return null;
};

export default BrokerOfficeList;