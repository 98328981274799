import React, { useState, useEffect, useContext } from 'react';
import { Card } from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'helpers/axiosInstance';

const DownloadPage = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    useEffect(() => {
        const encrypted = window.location.search.replace('?download=', '');
        if (!encrypted) {
            navigate("/login");
        }
        else {
            console.log(encrypted);
            axiosInstance.post(`${window.location.origin}/contracts/downloadInvoice?download=${encodeURIComponent(encrypted)}`, null, { responseType: 'blob' }).then((response) => {
                console.log(response);
                const contentDisposition = response.headers["content-disposition"];
                let fileName = response.headers["content-disposition"]
                    .split("filename*=UTF-8''")[1];
                fileName = decodeURI(fileName.trim()).trim();

                const contentType = response.headers["content-type"];
                const blob = new Blob([response.data], { type: contentType });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            });
        }
    }, [])
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
              <div className="display-1 text-300 fs-error"><FontAwesomeIcon  color="grey" className="mb-1" icon={faDownload} /></div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Your download should start soon. 
        </p>
        <hr />
              <p>
                  This page will close automatically once done. If the download doesn't start, please
                  <a href="mailto:laurent@mysealtd.com" className="ms-1">
            contact us
          </a>
          .
        </p>
        <Link className="btn btn-primary btn-sm mt-3" to="/">
          <FontAwesomeIcon icon={faHome} className="me-2" />
          Take me home
        </Link>
      </Card.Body>
    </Card>
  );
};

export default DownloadPage;
