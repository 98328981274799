import React, { useEffect, useState } from 'react';
import PasswordResetForm from 'components/authentication/PasswordResetForm';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

const PasswordReset = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [token, setToken] = useState("");
  useEffect(() => {
    let token = searchParams.get("token");
    let id = searchParams.get("id");
    axios.get(`${window.location.origin}/auth/check-exist?id=${id}`).then(x => {
      setId(id);
      setToken(encodeURI(token));
    }).catch(o => {
      navigate("/login");
    })
  },[])

  return (
    <AuthCardLayout>
      <h3>Reset password</h3>
      <PasswordResetForm layout="card" hasLabel token={token} id={id} />
    </AuthCardLayout>
  );
};

export default PasswordReset;
