import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { getUserRole } from '../helpers/utils';


const RoleBasedRoute = ({ component: Component, roles, ...rest }) => {
    const role = getUserRole();

    if (role && roles && roles.indexOf(role) == -1) {
      if (role.includes("External"))
        return <Navigate to="/components/contracts" />
      return <Navigate to="/" />
    }

    return <Component />

}

export default RoleBasedRoute;