import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Form, Button, Spinner, FloatingLabel } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import PageHeader from 'components/common/PageHeader';
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import DeleteItemModal from 'components/modalComponents/deleteItemModal';
import UploadFormFiles from 'components/common/UploadFormFiles';
import { useNavigate } from "react-router-dom";
import axiosInstance from 'helpers/axiosInstance';

const EditClientForm = () => {
	// State
	let form = '';
	const [validated, setValidated] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const [attachmentsData, setAttachmentsData] = useState({ data: [] });
	const [attachmentKind, setAttachmentKind] = useState({ data: [] });
	const [formData, setFormData] = useState({
		id: 0,
		company: '',
		nationalite : '',
		name: '',
		IBAN: '',
		SWIFT: '',
		address: '',
		bankAddress: '',
		kyc: false,
		notes :''
	});


	useEffect(() => {
		setFormData({ ...formData, ["id"]: searchParams.get("id") });

		axiosInstance.get(`${window.location.origin}/uploadFiles/GetFiles?FilesFrom=${"client-" + searchParams.get("id")}`).then((result) => {
			setAttachmentsData({ data: result.data });

		});

		axiosInstance.get(`${window.location.origin}/clients/${searchParams.get("id")}`)
			.then((res) => {
				if (res.data !== null) {
					setFormData({
						id: res.data.Id,
						company: res.data.Company,
						name: res.data.Name,
						nationalite : res.data.Nationalite,
						IBAN: res.data.IBAN,
						SWIFT: res.data.SWIFT,
						address: res.data.Address,
						bankAddress: res.data.BankAddress,
						kyc: res.data.KYC,
						notes : res.data.Notes
					});

					axiosInstance.get(`${window.location.origin}/attachments/GetByClientId/${searchParams.get("id")}`)
						.then((result) => {
							if (result.data !== null && result.data.count !== 0) {
								result.data.data.forEach((item, index) => {
									let data = attachmentsData.data;
									item.fileForm.fullName = `${item.fileForm.fileName}.${item.fileForm.fileExtension}`
									data.push(item);

									setAttachmentsData({ data: data });
								});
							}
						});

				} else {
					toast.error(`No Client found`, {
						theme: 'colored'
					});
				}
			});

		axiosInstance.get(`${window.location.origin}/parameters`).then((res) => {
			setAttachmentKind({ data: res.data.attachmentKinds.values });
		});
	}, []);

	//Handlers
	let navigate = useNavigate();

	const handleExit = (e) => {
		navigate("/components/clients");
	}

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		}
		else {
				// formData["attachments"] = attachmentsData.data;
			axiosInstance.post(`${window.location.origin}/clients`, formData)
				.then((res) => {
					if (res.data.id !== '0') {
						navigate("/components/clients");
						toast.success(`Client information has been modified.`, {
							theme: 'colored'
						});
						formData.id = res.data.id;

					} else {
						toast.error(`${res.data.message}`, {
							theme: 'colored'
						});
					}

					$('.save-spinner').attr('hidden', 'hidden');
				});
		}
		setValidated(true);
	};

	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.id]: e.target.value
		});
		console.log(formData);
	};

	const handleDelete = (e) => {
		axiosInstance.delete(`${window.location.origin}/clients/${searchParams.get("id")}`)
			.then((res) => {
				navigate("/components/clients");
				toast.success(`Client has been deleted.`, {
					theme: 'colored'
				});
				$('.save-spinner').attr('hidden', 'hidden');
			});
	}

	//const handleDownload = (e) => {
	//    axiosInstance.delete(`${window.location.origin}/clients/${searchParams.get("id")}`)
	//        .then((res) => {
	//            navigate("/components/clients", { replace: true });
	//            toast.success(`Client has been deleted.`, {
	//                theme: 'colored'
	//            });
	//            $('.save-spinner').attr('hidden', 'hidden');
	//        });
	//}

	return (
		<>
			<PageHeader className="mb-3 g-3" title="">
				<div className="d-inline">
					<h3 className="float-start">Edit client information</h3>
					<div className="float-end">
						<Button
							variant='falcon-primary'
							className='me-2'
							type="submit"
							onClick={e => form.dispatchEvent(
								new Event("submit", { cancelable: true, bubbles: true })
							)}
						>
							<Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" />
							Save</Button>
						<Button
							variant='falcon-warning'
							className='me-2'
							onClick={handleExit}
						>
							<Spinner animation="border" variant="warning" role="status" size="sm" hidden={true} className="cancel-spinner" />
							Cancel</Button>

						<DeleteItemModal item="Client" itemName={formData.name} function={handleDelete} />
					</div>
				</div>
			</PageHeader>

			<Row className="mb-3 g-3">
				<Col lg={12}>
					<Card>
						<Card.Header className="d-flex justify-content-between align-items-center">
							<h5>Client Details</h5>
							<div className='d-flex'>
								<Form.Group as={Col} lg={4} className="mt-0">
									<div>
										<BootstrapSwitchButton
											type='switch'
											id='kyc'
											onlabel='KYC'
											offlabel='KYC'
											onstyle="primary"
											offstyle="light"
											width="100"
											checked={formData.kyc}
											onChange={(e) => {
												setFormData({ ...formData, "kyc": e });
											}} />
									</div>
								</Form.Group>
							</div>
						</Card.Header>
						<Card.Body className="bg-light">
							<Form validated={validated} onSubmit={handleSubmit} ref={ref => form = ref}>
								<Row className="g-3">
									<FloatingLabel as={Col} lg={6}
										controlId="name"
										label="Client name"
									>
										<Form.Control
											type="text"
											value={formData.name}
											onChange={handleFieldChange}
											placeholder="Name"
											required
										/>
										<Form.Control.Feedback type="invalid">
											Please enter a client name.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="nationalite"
										label="Client nationality"
									>
										<Form.Control
											as="textarea"
											value={formData.nationalite}
											onChange={handleFieldChange}
											placeholder="Nationality"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="address"
										label="Client address"
									>
										<Form.Control
											as="textarea"
											rows={2}
											value={formData.address}
											onChange={handleFieldChange}
											placeholder="Address"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={8}
										controlId="IBAN"
										label="IBAN"
									>
										<Form.Control
											type="text"
											value={formData.IBAN}
											onChange={handleFieldChange}
											placeholder="IBAN"
										/>
										<Form.Control.Feedback type="invalid">
											Please enter a client IBAN.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={4}
										controlId="SWIFT"
										label="SWIFT"
									>
										<Form.Control
											type="text"
											value={formData.SWIFT}
											onChange={handleFieldChange}
											placeholder="SWIFT"
										/>
										<Form.Control.Feedback type="invalid">
											Please enter a client SWIFT code.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="bankAddress"
										label="Bank address"
									>
										<Form.Control
											as="textarea"
											value={formData.bankAddress}
											onChange={handleFieldChange}
											placeholder="Bank address"
										/>
									</FloatingLabel>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<UploadFormFiles
				attachmentsData={attachmentsData}
				attachmentKinds={attachmentKind}
				setAttachmentsData={setAttachmentsData}
				uploadTo={"client-" + searchParams.get("id")}
			/>
			<Col lg={12} className='mt-3 mb-3 g-3'>
				<Card>
					<Card.Header className="bg-white">
						<h5>Comments</h5>
					</Card.Header>
					<Card.Body className="bg-light">
						<Form.Group as={Col}>
							<Form.Control
								style={{ minHeight: "150px" }}
								type="text"
								as="textarea"
								rows={5}
								value={formData.notes}
								onChange={handleFieldChange}
								id="notes"
							/>
						</Form.Group>
					</Card.Body>
				</Card>
			</Col>
		</>
	);
}

export default EditClientForm;
