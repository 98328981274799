import React from 'react';
import { Col, Row, Form, FloatingLabel, Card } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

const YachtMySeaOfficeDetail = ({ loadOfficeOptions, handleOfficeDetails, formData }) => {

	return (
		<Card>
			<Card.Header className="d-inline">
				<h5 className="float-start">MySea Office</h5>

			</Card.Header>
			<Card.Body className="bg-light">
				<Row>
					<Col lg={12}>
						<Row>
							<Form.Group as={Col} lg={12}>
								<FloatingLabel
									label="Office"
								>
									<Form.Select onChange={e => { handleOfficeDetails(e.target.value); }} value={formData.officeId}>

										{loadOfficeOptions()}

									</Form.Select>
								</FloatingLabel>
							</Form.Group>

						</Row>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default YachtMySeaOfficeDetail;
