import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Card, Col, Row, Form, Button, Spinner, FloatingLabel } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import $ from 'jquery';
import axios from 'axios';
import { toast } from 'react-toastify';
import { OfficeViewModel } from '../../viewModel/officeView'
import { useNavigate } from "react-router-dom";
import axiosInstance from 'helpers/axiosInstance';
import { OfficeForm } from 'viewModel/DtoClasses';

const CreateOfficeForm = (props, ref) => {
	useImperativeHandle(ref, () => ({
		save: async () => {
			handleSubmit();
		}
	}))
	// State
	let form = '';
	const [validated, setValidated] = useState(false);
	const [formData, setFormData] = useState(new OfficeForm(''));

	let navigate = useNavigate();

	const handleSubmit = (event) => {
		event?.preventDefault();
		const form = $(".first-form")[0].checkValidity();
		const formSecond = $(".second-form")[0].checkValidity();

		if (form === false || formSecond === false) {
			event?.preventDefault();
			event?.stopPropagation();
		}
		else {
			$('.save-spinner').removeAttr('hidden');
			if (formData.CACommission == null || isNaN(formData.CACommission) || !formData.CACommission || formData.CACommission.length === 0) formData.CACommission = 0;
			if (formData.retailCommission == null || isNaN(formData.retailCommission) || !formData.retailCommission || formData.retailCommission.length === 0) formData.retailCommission = 0;

			axiosInstance.post(`${window.location.origin}/offices`, formData)
				.then((res) => {
					if (res.data.id !== '0') {
						toast.success(`New office has successfully been created.`, {
							theme: 'colored'
						});
						formData.id = res.data.id;
						if (props.InModal) {
							props.onSave(res.data);
						} else {
							navigate("/components/offices/edit?id=" + formData.id);
						}
					} else {
						toast.error(`Some problem occurred, please try later.`, {
							theme: 'colored'
						});
					}
				});

			$('.save-spinner').attr('hidden', 'hidden');
		}
		setValidated(true);
	};

	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.id]: e.target.value
		});
	};

	return (
		<>
			{
				props.InModal ? null :
					<PageHeader className="mb-3 g-3" title="">
						<div className="d-inline">
							<h3 className="float-start">Create office</h3>
							<div className="float-end">
								<Button
									variant='falcon-primary'
									className='me-2 mr-1'
									type="submit"
									onClick={e => form.dispatchEvent(
										new Event("submit", { cancelable: true, bubbles: true })
									)}>
									<Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" /> Save</Button>
								<Button variant='falcon-warning' onClick={() => window.history.back()}>
									<Spinner animation="border" variant="warning" role="status" size="sm" hidden={true} className="cancel-spinner" />
									Cancel</Button>
							</div>
						</div>
					</PageHeader>
			}
			<Row className="mb-3 g-3">
				<Col lg={6}>
					<Card>
						<Card.Header >
							<h5>Office Details</h5>
						</Card.Header>
						<Card.Body className="bg-light">
							<Form validated={validated} onSubmit={handleSubmit} ref={ref => form = ref} className="first-form">
								<Row className="g-3">
									<FloatingLabel as={Col} lg={12}
										controlId="name"
										label="Name"
									>
										<Form.Control
											type="text"
											value={formData.name}
											onChange={handleFieldChange}
											placeholder="Name"
											required
										/>
										<Form.Control.Feedback type="invalid">
											Please enter an office name.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="address1"
										label="Address 1"
									>
										<Form.Control
											type="text"
											value={formData.address1}
											onChange={handleFieldChange}
											placeholder="Address 1"
										/>
										<Form.Control.Feedback type="invalid">
											Please enter an address.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="address2"
										label="Address 2"
									>
										<Form.Control
											type="text"
											value={formData.address2}
											onChange={handleFieldChange}
											placeholder="Address 2"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="city"
										label="City "
									>
										<Form.Control
											type="text"
											value={formData.city}
											onChange={handleFieldChange}
											placeholder="City"
											required
										/>
										<Form.Control.Feedback type="invalid">
											Please enter a city.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="country"
										label="Country "
									>
										<Form.Control
											type="text"
											value={formData.country}
											onChange={handleFieldChange}
											placeholder="Country"
											required
										/>
										<Form.Control.Feedback type="invalid">
											Please enter a country.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="email"
										label="Email"
									>
										<Form.Control
											type="text"
											value={formData.email}
											onChange={handleFieldChange}
											placeholder="Email"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="phone"
										label="Phone"
									>
										<Form.Control
											type="text"
											value={formData.phone}
											onChange={handleFieldChange}
											placeholder="Phone"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="fax"
										label="Fax"
									>
										<Form.Control
											type="text"
											value={formData.fax}
											onChange={handleFieldChange}
											placeholder="Fax"
										/>
									</FloatingLabel>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={6}>
					<Card>
						<Card.Header >
							<h5>Financial Details</h5>
						</Card.Header>
						<Card.Body className="bg-light">
							<Form validated={validated} onSubmit={handleSubmit} ref={ref => form = ref} className="second-form">
								<Row className="g-3">
									<FloatingLabel as={Col} lg={6}
										controlId="VAT"
										label="VAT registration"
									>
										<Form.Control
											type="text"
											value={formData.VAT}
											onChange={handleFieldChange}
											placeholder="VAT"
										/>
										<Form.Control.Feedback type="invalid">
											Please enter a VAT.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="registrationNumber"
										label="Company registration"
									>
										<Form.Control
											type="text"
											value={formData.registrationNumber}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
										<Form.Control.Feedback type="invalid">
											Please enter your company's registration number.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="bankAddress"
										label="Bank address"
									>
										<Form.Control
											type="text"
											value={formData.bank}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="bankCode"
										label="Bank code"
									>
										<Form.Control
											type="text"
											value={formData.bankCode}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="codeGuichet"
										label="Agency code"
									>
										<Form.Control
											type="text"
											value={formData.codeGuichet}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={8}
										controlId="accountNumber"
										label="Account number"
									>
										<Form.Control
											type="text"
											value={formData.accountNumber}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={4}
										controlId="ribKey"
										label="RIB"
									>
										<Form.Control
											type="text"
											value={formData.ribKey}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="iban"
										label="IBAN"
									>
										<Form.Control
											type="text"
											value={formData.iban}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="bic"
										label="BIC"
									>
										<Form.Control
											type="text"
											value={formData.bic}
											onChange={handleFieldChange}
											placeholder="12345789"
										/>
									</FloatingLabel>
								</Row>

							</Form>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={12} className="g-3 d-none">
					<Card>
						<Card.Header>
							<h5>Custom Invoice Comment</h5>
						</Card.Header>
						<Card.Body className="bg-100">
							<Row className="g-3">
								<Col lg={12}>
									<Form.Control
										as="textarea"
										placeholder="Leave a comment here"
										style={{ height: '100px' }}
										id="comment"
										value={formData.comment}
										className="px-2"
										onChange={handleFieldChange}
									/>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={12} className="mb-3 g-3">
					<Card>
						<Card.Header>
							<h5>Commissions</h5>
						</Card.Header>
						<Card.Body className="bg-100">
							<Row className="g-3">
								<FloatingLabel as={Col} lg={5}
									controlId="caCommission"
									label="CA commission"
								>
									<Form.Control
										type="number"
										onWheel={(e) => e.target.blur()}
										step=".01"
										min={0}
										value={formData.caCommission}
										onChange={handleFieldChange}
										placeholder="CA commission"
									/>
									<Form.Control.Feedback type="invalid">
										Please enter a CA commission.
									</Form.Control.Feedback>
								</FloatingLabel>
								<FloatingLabel as={Col} lg={5}
									controlId="retailCommission"
									label="Retail commission"
								>
									<Form.Control
										type="number"
										min={0}
										onWheel={(e) => e.target.blur()}
										value={formData.retailCommission}
										onChange={handleFieldChange}
										placeholder="Retail commission"
									/>
									<Form.Control.Feedback type="invalid">
										Please enter a retail commission.
									</Form.Control.Feedback>
								</FloatingLabel>
								<FloatingLabel as={Col} lg={2}
									controlId="invoicePrefix"
									label="Invoice prefix"
								>
									<Form.Control
										type="string"
										value={formData.invoicePrefix}
										onChange={handleFieldChange}
										placeholder="MC"
									/>
									<Form.Control.Feedback type="invalid">
										Please enter an invoice prefix.
									</Form.Control.Feedback>
								</FloatingLabel>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default forwardRef(CreateOfficeForm);

