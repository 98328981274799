import { ClientViewModel } from './clientView'
import { SupplierViewModel } from './supplierView'

export class CharterViewModel {
	client: ClientViewModel;
	supplier: SupplierViewModel;
	placeFrom: string;
	placeTo: string;
	startDate: object;
	endDate: object;
	kyc: boolean;

	constructor() {
		this.placeFrom = this.placeTo;
		this.kyc = false;
	}
}
