import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { LoginViewModel } from '../../viewModel/loginView';

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  // Handler
  async function handleSubmit() {
    if (email) {
      let form = new FormData();
      form.append("email", email);
      try {
        const res = await axios.post(`${window.location.origin}/Auth/reset-password`, form);
        setEmailSent(true);
      } catch (e) {
        if (e.response) {
          toast.error(e.response.data, {theme: 'colored'})
        }
      }
      
    }
  };

  if (emailSent)
    return (
      <>
        <div className='mt-2 text-left'>
          An email has been sent to <b>{email}</b>. <br />
          Please follow the instruction to reset your password.
        </div>
        <div className="d-flex justify-content-center mt-3">
        <Link to="/login" className="btn btn-sm btn-primary px-5">Login</Link>

        </div>
      </>
    )
  else
    return (
      <Form className="mt-4">
        <Form.Group className="mb-3">
          <Form.Control
            placeholder={'Email address'}
            value={email}
            name="email"
            onChange={({ target }) => setEmail(target.value)}
            type="email"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Button className="w-100" type="button" disabled={!email} onClick={handleSubmit}>
            <Spinner animation="border" variant="default" size="sm" hidden={!showLoading} /> Send new Password
          </Button>
        </Form.Group>


      </Form>
    );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
