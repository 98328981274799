import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import axiosInstance from 'helpers/axiosInstance';
import { WithContext as ReactTags } from 'react-tag-input';
import { toast } from 'react-toastify';

const Parameters = () => {
	const [param, setParam] = useState({ data: [] })
	const [tags, setTags] = React.useState([
		{ id: 'Thailand', text: 'QSDQC' },
		{ id: 'India', text: 'India' },
		{ id: 'Vietnam', text: 'Vietnam' },
		{ id: 'Turkey', text: 'Turkey' }
	]);

	const handleDelete = (tag, index) => {
		let objParam = param.data[index];
		let data = param.data;
		let tagObj = objParam.values[tag];
		if (objParam.id != -1) {
			axiosInstance.delete(`${window.location.origin}/parameters/${objParam.id}?value=${tagObj.id}`).then(x => {
				let values = x.data.map(obj => ({ id: obj.name, text: obj.name }));
				data[index].values = values;
				setParam({ data: data });
				toast.success(tagObj.id + " has been successfully removed", { theme: 'colored' });
			})
		} else {
			toast.warning("Cannot modify a hardcoded parameter", { theme: 'colored' });
		}
	};

	const handleAddition = (tag, index) => {
		let objParam = param.data[index];
		let data = param.data;
		const formData = new FormData();
		formData.append("value", tag.id);
		if (objParam.id != -1) {
			axiosInstance.put(`${window.location.origin}/parameters/${objParam.id}`, formData).then(x => {
				let values = x.data.map(obj => ({ id: obj.name, text: obj.name }));
				data[index].values = values;
				setParam({ data: data });
				toast.success(tag.id + " has been successfully added", { theme: 'colored' });
			})
		} else {
			toast.warning("Cannot modify a hardcoded parameter", { theme: 'colored' });
		}
	};

	const handleDrag = (tag, currPos, newPos) => {
		const newTags = tags.slice();

		newTags.splice(currPos, 1);
		newTags.splice(newPos, 0, tag);

		// re-render
		setTags(newTags);
	};

	const KeyCodes = {
		comma: 188,
		enter: 13
	};

	const delimiters = [KeyCodes.comma, KeyCodes.enter];

	useEffect(() => {
		axiosInstance.get(`${window.location.origin}/parameters`).then(req => {
			let data = [];
			Object.keys(req.data).map(function (key, index) {
				let values = req.data[key].values != null ? req.data[key].values.map((obj, index) => ({ id: obj.name, text: obj.name })) : [];
				data.push({ name: key, id: req.data[key].paramTypeId, values: values });
			});
			setParam({ data: data });
		});
		document.title = "Parameters";
	}, [])

	useEffect(() => {

	}, [param])

	const renderSwitch = (param) => {
		switch (param) {
			case "contractKind": return "Contract Kinds";
			case 'paymentKinds': return "Payment Types";
			case 'paymentTypes': return "Payment Transfer Types";
			case 'contractStatuses': return "Contract Statuses";
			case 'contractTypes': return "Contract Types";
			case 'currencies': return "Currencies";
			case 'apas': return "APAs";
			case 'attachmentKinds': return "Attachment Types";
			case 'paymentRecipients': return "Payment Recipients";
			default: return "Unknown";
		}
	}

	if (param != null)
		return (
			<>
				{
					param.data.map((obj, index) => (
						<div className='mb-3 g-3'>
							<Card>
								<Card.Header className="border-bottom">
									<h5>
										{renderSwitch(obj.name)}
									</h5>
								</Card.Header>
								<Card.Body>
									<ReactTags
										tags={obj.values}
										delimiters={delimiters}
										handleDelete={(tag) => handleDelete(tag, index)}
										handleAddition={(tag) => handleAddition(tag, index)}
										allowDragDrop={false}
										allowDeleteFromEmptyInput={false}
										inputFieldPosition="inline"
										autocomplete
										readOnly={obj.id === -1}
									/>
								</Card.Body>
							</Card>
						</div>
					))
				}
			</>
		);

	return null;
};

export default Parameters;
