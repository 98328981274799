import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import PageHeader from 'components/common/PageHeader';
import DeleteItemModal from 'components/modalComponents/deleteItemModal';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosInstance from 'helpers/axiosInstance';
import { YachtForm } from 'viewModel/YachtForm';
import YachtFormBase from './YachtFormBase';

const EditYachtForm = () => {
	// State
	let form = '';
	const [validated, setValidated] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [attachmentsData, setAttachmentsData] = useState({ data: [] });
	const [formData, setFormData] = useState(new YachtForm());
	const [brokersData, setBrokersData] = useState({ data: [] });
	const [brokerData, setBrokerData] = useState({});
	const [officesData, setOfficesData] = useState({ data: [] });
	const [officeData, setOfficeData] = useState({});
	const [emailList, setEmailList] = useState({ data: [] });
	const [users, setUsers] = useState({ data: [] });
	const [Add, setAdd] = useState(-1);
	const [attachmentKind, setAttachmentKind] = useState({ data: [] });
	const [paymentRecipients, setPaymentRecepients] = useState({ data: [] });
	const currentId = searchParams.get("id");

	let navigate = useNavigate();

	const getUsers = () => {

		return new Promise((resolve, reject) => {
			axiosInstance.get(`${window.location.origin}/yachts/${searchParams.get("id")}/users`)
				.then((res) => {
					resolve(res.data);
				});
		});
	}
	const handleYachtUserDelete = (userId) => {

		axiosInstance.delete(`${window.location.origin}/yachts/${searchParams.get("id")}/users/${userId}`).then(x => {
			toast.success(`Removed yacht from user's access`, { theme: 'colored' });
			let userList = users.data;
			setUsers({
				data: userList.filter(function (obj) {
					return obj.id !== userId;
				})
			});
		}).catch(e => {
			toast.error(e.message, { theme: 'colored' });
			if (e.response.data != '')
				toast.error(e.response.data, { theme: 'colored' });
		})

	}

	useEffect(() => {
		setFormData({ ...formData, ["id"]: searchParams.get("id") });

		axiosInstance.get(`${window.location.origin}/uploadFiles/GetFiles?FilesFrom=${"yacht-" + searchParams.get("id")}`).then((result) => {
			setAttachmentsData({ data: result.data });
		});
		axiosInstance.get(`${window.location.origin}/yachts/${searchParams.get("id")}`)
			.then((res) => {
				if (res.data !== null) {
					setFormData(res.data);
					officeData.id = res.data.officeId;
					brokerData.id = res.data.mySeaBrokerId;
					setEmailList({ data: res.data.emails });
					setUsers({ data: res.data.users });
					document.title = res.data.name;

				} else {
					toast.error(`Error loading yacht information.`, {
						theme: 'colored'
					});
				}
			});
	}, []);


	//Handlers 
	const handleSubmit = (event) => {
		event.preventDefault();
		const form = $(".first-form-yacht")[0].checkValidity();
		const formSecond = $(".second-form-yacht")[0].checkValidity();
		const formThird = $(".third-form-yacht")[0].checkValidity();
		document.querySelectorAll("form").forEach(x => {
			x.classList.add("was-validated");
		})
		if (formData.length == "")
			formData.length = 0;
		if (form === false || formSecond === false || formThird === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			console.log(formData);
			axiosInstance.post(`${window.location.origin}/yachts`, YachtForm.getYachtPostForm(formData))
				.then((res) => {
					if (res.data.id !== '0') {
						toast.success(`Yacht information has successfully been updated. `, {
							theme: 'colored'
						});
						document.querySelectorAll(".was-validated").forEach(x => {
							x.classList.remove("was-validated");
						})
					} else {
						toast.error(`${res.data.message}`, {
							theme: 'colored'
						});
					}

					$('.save-spinner').attr('hidden', 'hidden');
				});
		}
		setValidated(true);
	};

	const loadYachtUserList = () => {
		if (users.data.length > 0) {
			return (
				<>
					{
						users.data.reverse().map(item => (<SoftBadge pill bg="primary" className='me-2'>
							{`${item.fullName}`}
							<FontAwesomeIcon color='red' onClick={() => { handleYachtUserDelete(item.id); }} style={{ paddingLeft: '5px', cursor: 'pointer' }} icon="fa-solid fa-xmark" />
						</SoftBadge>
						))
					}
				</>
			);

		} else return (<SoftBadge pill bg='secondary' style={{ lineHeight: 1 }} className='mt-2'>No users have been added. </SoftBadge>);
	}

	const handleYachtDetails = (user) => {
		axiosInstance.put(`${window.location.origin}/yachts/${searchParams.get("id")}/users/${user.id}`).then(x => {
			toast.success(`Added ${user.fullName} to the yacht's access`, { theme: 'colored' });
			getUsers().then(users => {
				setUsers({ data: users });
			});
		}).catch(e => {
			toast.error(e.message, { theme: 'colored' });
			if (e.response.data != '')
				toast.error(e.response.data, { theme: 'colored' });
		})
	}

	const handleDelete = (e) => {
		axiosInstance.delete(`${window.location.origin}/yachts/${searchParams.get("id")}`)
			.then((res) => {
				navigate("/components/yachts");
				$('.save-spinner').attr('hidden', 'hidden');
			}).catch((e) => {
				toast.error("There was an error deleting the yacht", { theme: 'colored' });
				toast.error(e.response.data, { theme: 'colored' });
			});
	}

	const handleFieldChange = e => {
		let toSet = e.target.value;
		if (e.target.id == "length")
			toSet = Number(toSet);
		setFormData({
			...formData,
			[e.target.id]: e.target.value
		});
	};

	return (
		<>
			<PageHeader className="mb-3 g-3" title="">
				<div className="d-inline">
					<h3 className="float-start">Edit yacht</h3>
					<div className="float-end">
						<Button
							variant='falcon-primary'
							className='me-2'
							type="submit"
							onClick={e => handleSubmit(e)}
						>
							<Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" />
							Save
						</Button>

						<DeleteItemModal item="Yacht" itemName={formData.name} function={handleDelete} />
					</div>
				</div>
			</PageHeader>

			<YachtFormBase
			attachmentsData={attachmentsData}
			setAttachmentsData={setAttachmentsData}
			handleSubmit={handleSubmit}
			handleFieldChange={handleFieldChange}
			formData={formData}
			loadYachtUserList={loadYachtUserList}
			handleYachtDetails={handleYachtDetails}
			setFormData={setFormData}
			validated={validated}
			currentId={currentId}
			emailList={emailList}
			setEmailList={setEmailList}
			>
			</YachtFormBase>


			{/*Contract list*/}

			{/*<YachtContracts yachtId={searchParams.get("id")} />*/}
		</>
	);

}


export default EditYachtForm;
