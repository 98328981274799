import React, { useEffect, useState } from 'react';
import { Card, Col, Image, Modal, Row, Table, Form, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectPaymentOrInstall from 'components/contracts/selectPaymentOrInstall';
import CountUp from 'react-countup';
import SoftBadge from 'components/common/SoftBadge';
import Flex from 'components/common/Flex';
import { getUserRole } from 'helpers/utils';
import axiosInstance from 'helpers/axiosInstance';
import { toast } from 'react-toastify';

const SingleItem = ({ title, value, formatter }) => {
	return (
		<div className="bg-transparent text-grey px-3 py-1">
			<div className='text-center'>
				<h5 className="mb-0">{formatter.format(value)}</h5>
				<p className="mb-0">{title}</p>
			</div>
		</div>
	);
};

const Timeline = (props) => {
	const today = new Date();
	const [formatter, setFormmater] = useState();
	const [show, setShow] = useState(false);
	const [editId, SetEditId] = useState(-1);
	const [editAmount, SetEditAmount] = useState(0);
	const [editDueDate, SetEditDueDate] = useState();
	const [editKind, SetEditKind] = useState();
	const [editPaymentDate, SetEditPaymentDate] = useState();
	const [editNote, setEditNote] = useState("");
	const [value, setValue] = useState(null);
	const [edit, setEdit] = useState(false);
	const role = getUserRole();
	useEffect(() => {
		if (props.currency != null) {
			setFormmater(new Intl.NumberFormat('fr-FR', { style: 'currency', currency: props.currency.name }));
		}
	}, [props.objects])

	const setReadyToPay = async (e, index) => {
		e.stopPropagation();
		let data = props.objects.data;
		let obj = data[index];

		try {
			let req = await axiosInstance.put(`${window.location.origin}/payments/${obj.Id}/payment-state`);
			if (req.status == 204) {
				data[index].ReadyToPay = !e.target.checked;
				props.setObjects({ data: data });
				toast.success("Payment state has been changed", { theme: 'colored' });
			} else {
				toast.error("There was an error changing the payment's state", { theme: 'colored' });
			}

		} catch {
			toast.error("There was an error changing the payment's state", { theme: 'colored' });
		}

	}

	useEffect(() => {
		if (props.currency != null) {
			setFormmater(new Intl.NumberFormat('fr-FR', { style: 'currency', currency: props.currency.name }));
		}
	}, [props.currency])
	return (
		<>
			{
				(formatter != null && <>
					<Card className="mb-0 h-100">
						<Card.Header
							style={props.title == "Incoming Payments" ? { backgroundColor: "rgb(0 210 122 / 64%)", color: 'white !important' } : props.title == "Outgoing Payments" ? { backgroundColor: "rgb(230 55 87 / 78%)" } : { backgroundColor: "white" }}
						>
							<div className='d-flex justify-content-between align-items-center'>
								<h5 className="mb-0" style={props.title == "Incoming Payments" ? { color: "white" } : props.title == "Outgoing Payments" ? { color: "white" } : null} >{props.title}</h5>
								<div className={`float-end ${role != "Administrator" && "d-none"}`}>
									<SelectPaymentOrInstall
										edit={edit}
										setEdit={setEdit}
										toShow={show}
										setToShow={setShow}
										value={value}
										objects={props.objects}
										objectType={props.objectType}
										setObjects={props.setObjects}
										type={props.title}
										editId={editId}
										editNote={editNote}
										setEditNote={setEditNote}
										editAmount={editAmount}
										SetEditAmount={SetEditAmount}
										editDueDate={editDueDate}
										SetEditDueDate={SetEditDueDate}
										editKind={editKind}
										SetEditKind={SetEditKind}
										editPaymentDate={editPaymentDate}
										SetEditPaymentDate={SetEditPaymentDate}
										emailList={props.emailList}
										YachtEmailList={props.YachtEmailList}
										setEmailList={props.setEmailList}
										setReadjustPayment={props.setReadjustPayment}
										currency={props.currency}
										contractType={props.contractType}
										brokerEmail={props.brokerEmail}
										contractId={props.contractId}
										paymentKinds={props.paymentKinds}
										paymentTypes={props.paymentTypes}
										paymentRecipients={props.paymentRecipients}
										setPaymentUpdated={props.setPaymentUpdated}
									/>
								</div>
							</div>


							<div className="bg-white flex-row justify-content-center mt-2">
								{props.title == "Installments" ? 
								<>
									<Col className="" >
										<div className='row'>
											{props.controlZone.map((item, index) => (
												<div className="col-6 text-center text-primary" key={index}>
													<div>
														{(item.name.includes("receive") ? <FontAwesomeIcon icon="fa-solid fa-wallet" className="fs-1 text-primary" /> : <FontAwesomeIcon icon="fa-solid fa-calendar-check" className="fs-1 text-primary" />)}
													</div>
													<CountUp
														start={0}
														end={(item.value)}
														duration={1.2}
														suffix={props.currency.name}
														prefix={''}
														separator=","
														decimals={true}
														decimal="."
													/>
													<div className='text-center text-dark'>{item.name}</div>
												</div>
											))}
										</div>
									</Col>
								</> : null}
								{props.title == "Outgoing Payments" ? <>

								</> : null}

							</div>
							{/*<RealTimeUsers data={realTimeUsers} />*/}
						</Card.Header>

						<Card.Body className="pb-0 bg-100">
							<div className="timeline-vertical pt-0 pb-0">

								{props.title == "Outgoing Payments" || props.title == "Incoming Payments" ? <>
									<Table borderless hover className="mb-0" responsive>
										<thead className="position-static">
											<tr style={{ textAlign: "center" }}>
												<th scope="col" >Amount to {props.objectType.subTypeVal == "In" ? "Receive" : "Pay"}</th>
												<th scope="col" >Date to {props.objectType.subTypeVal == "In" ? "Receive" : "Pay"}</th>
												<th scope="col" >Type</th>
												{
													props.objectType.subTypeVal == "Out" ? <th scope="col" >Ready to pay</th> : null
												}
												<th scope="col" >Amount {props.objectType.subTypeVal == "In" ? "Received" : "Paid"} </th>
												<th scope="col" >Date {props.objectType.subTypeVal == "In" ? "Received" : "Paid"} </th>
												<th scope="col" >Email Notification</th>
												<th scope="col" >C/R</th>
												<th scope="col" >Notes</th>
											</tr>
										</thead>
										<tbody>
											{
												props.objects.data.length == 0 ? <>
													<tr className="bg-200">
														<td colSpan={8} className="text-center">
															No payments were found.
														</td>
													</tr>
												</> : <>
														{
														props.objects.data.sort((a, b) => a.DueDate.getTime() - b.DueDate.getTime()).map((obj, index) => {
															return (
																<tr key={index} style={{ height: "46px", textAlign: "center", cursor: "pointer", borderLeft: obj.AmountReceived == 0 && obj.DueDate < new Date() ? "5px solid #e63757" : obj.AmountReceived == 0 && (obj.DueDate) < (new Date(new Date().getTime() + (5 * 24 * 60 * 60 * 1000))) ? "5px solid #f5803e" : null }} onClick={() => { setValue(obj); setShow(true); setEdit(true); SetEditAmount(obj.Amount); SetEditDueDate(((obj.dueDate))); SetEditId(index); SetEditKind(obj.Kind); SetEditPaymentDate(obj.PaymentDate); setEditNote(obj.Note) }}>
																	<td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{formatter.format(obj.AmountToPay)}</td>
																	<td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{obj.DueDate ? obj.DueDate.getFullYear() === 1970 ? '' : obj.DueDate.toLocaleDateString('es') : null}</td>
																	<td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{obj.Kind ? obj.Kind : null}</td>
																	{
																		props.objectType.subTypeVal == "Out" ?
																			<th scope="col" >
																				<Form.Check
																					disabled={role.includes("External")}
																					type='checkbox'
																					checked={obj.ReadyToPay}
																					value={obj.ReadyToPay}
																					onClick={(e) => setReadyToPay(e, index)}
																					onChange={(e) => { }}
																				/>
																			</th> : null
																	}
																	<td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{formatter.format(obj.AmountReceived)}</td>
																	<td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{obj.PaymentDate ? obj.PaymentDate.getFullYear() === 1970 ? '' : obj.PaymentDate.toLocaleDateString('es') : null}</td>
																	<td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{obj.EmailSentDate ? <SoftBadge pill bg="success" className="fs--2"> {new Date(obj.EmailSentDate).toLocaleString([], { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit' })} </SoftBadge> : <SoftBadge pill bg="danger" className="fs--2"> Email not sent. </SoftBadge>}</td>
																	<td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{obj.Cleared == true ? obj.Reconciled == true ? <FontAwesomeIcon icon="check" transform="shrink-1" className="text-success" /> : <span className="text-primary">C</span> : obj.Reconciled == true ? <span className="text-primary">R</span> : <FontAwesomeIcon icon="xmark" transform="shrink-1" className="text-danger" />}</td>
																	<td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{obj.Note.trim() != "" ? <FontAwesomeIcon icon="check" transform="shrink-1" className="text-success" /> : <FontAwesomeIcon icon="xmark" transform="shrink-1" className="text-danger" />}</td>
																</tr>
															);
														})
													}
												</>
											}
										</tbody>
									</Table>
								</> :
									<>

										{props.objects.data.map((obj, index) => {
											return (
												<div
													key={index}
													className={'timeline-item timeline-item mt-3'}
													onClick={() => { setValue(obj); setShow(true); setEdit(true); SetEditAmount(obj.Amount); SetEditDueDate(((obj.dueDate))); SetEditId(index); SetEditKind(obj.Kind); SetEditPaymentDate(obj.PaymentDate); setEditNote(obj.Note); }}
													style={{ cursor: 'pointer' }}
												>
													<Row className={` ${'timeline-item-end'}`}

													>
														<Col lg={2} className="d-flex align-items-center justify-content-center">
															<div className="d-flex justify-content-center align-items-center flex-column">
																<div className=" icon-item icon-item-lg text-primary border-300">
																	<FontAwesomeIcon icon={props.objectStyle.icon} className={`text-${props.objectStyle.variant}`} />
																</div>
																<p className="fs--1 mb-0 fw-semi-bold"> {obj.dueDate.getFullYear()}</p>
																<p className="fs--2 text-600">{obj.dueDate.getDate() + "/" + parseInt((new Date(obj.dueDate).getMonth()) + 1)}</p>

															</div>
														</Col>
														<Col lg={10}>
															<div className="timeline-item-content ms-0" style={props.objectStyle.style}>
																<div className="timeline-item-card" >
																	{
																		(props.objectType.name == "Installment" ?
																			<>
																				<h5 className=" mb-0">{formatter.format(obj.Amount)}</h5>
																			</>
																			:
																			<>
																				<h5 className="mb-2">{obj.Kind}</h5>
																				<p className="fs--1 mb-0">{props.objectType.name} of {formatter.format(obj.Amount)}  received on {obj.PaymentDate.getDate() + "/" + parseInt((new Date(obj.dueDate).getMonth()) + 1)}.</p>
																			</>
																		)
																	}
																</div>
															</div>
														</Col>
													</Row>
												</div>
											);
										})}
									</>

								}

							</div>
						</Card.Body>
						<Card.Footer className='border-top'>
							{props.title == "Incoming Payments" ?
								<>
									<Row className="mt-2">
										{props.controlZone.map((item, index) => (
											<Col className="mt-2" lg={6} key={index}>
												<SingleItem title={item.name} value={item.value} formatter={formatter} />
											</Col>

										))}
									</Row>
								</> : props.title == "Outgoing Payments" ?
									<>
										<Row className="mt-2">
											{props.controlZone.map((item, index) => (
												<Col className="mt-2" lg={4} key={index}>
													<SingleItem title={item.name} value={item.value} formatter={formatter} />
												</Col>

											))}
										</Row>
									</>
									: null}
						</Card.Footer>
					</Card>
				</>)
			}

		</>
	);
};

export default Timeline;
