export class ContractForm {
  emails = [];
  id = 0;
  contractDate = new Date();
  fileName = null;
  contractNumber = null;
  yachtId = null;
  yacht = null;
  brokerAdvancedCommission = new BrokerAdvanceCommissionForm();
  intermediary = null;
  intermediaryCommissionId = null;
  intermediaryCommission = new CommissionForm();
  officeId = null;
  office = null;
  officeCommissionId = null;
  officeCommission = new CommissionForm();
  mySeaBrokerId = null;
  mySeaBroker = null;
  mySeaBrokerCommissionId = null;
  mySeaBrokerCommission = new CommissionForm();
  broker = null;
  brokerCommissionId = null;
  brokerCommission = new CommissionForm();
  charter = new CharterForm();
  charterId = 0;
  installments = [];
  payments = [];
  notes = "";
  attachments = [];
  status = "On Going";
  type = "MYBA";
  kind = "CA";
  dateCreated = new Date();
  paymentInTotalClient = null;
  paymentOutTotalClient = null;
  vatRep = false;
  directToClient = false;
  userContractAccessLink = null;
  contactId = null;
  contact = null;
  caClientName = null;
}

export class BrokerAdvanceCommissionForm {
  id = 0;
  brokerCommission = 0;
  commBrokerToPay = 0;
  commBrokerToPayOneMore = 0;
  commNetMySea = 0;
  commNetMySeaOneMore = 0
}

export class CommissionForm {
  id = 0;
  comm = 0;
  amount = 0;
  vat = 0;
  isTaxIncluded = false;
  invoice = null;
  contractId = 0;
}

export class OfficeForm {
  constructor(name) {
    if (name != null)
      this.name = name;
    else
      this.name = 'Select office';
  }

  id = 0;
  address1 = "";
  address2 = "";
  city = "";
  country = "";
  name = "Select office";
  vat = "";
  rci = "";
  dsee = "";
  phone = "";
  fax = "";
  email = "";
  bankAddress = "";
  bankCode = "";
  codeGuichet = "";
  accountNumber = "";
  ribKey = "";
  iban = "";
  bic = "";
  comment = "";
  registrationNumber = "";
  invoicePrefix = "";
  caCommission = 0;
  retailCommission = 0;
}

export class PersonForm {
  id = 0;
  loginId = null;
  firstName = "";
  lastName = "";
  middleName = null;
  brokerId = null;
  phone = null;
  role = null;
  fullName = "";
}

export class BrokerForm {
  id = 0;
  caCommission = 0;
  retailCommission = 0;
  office = null;
  officeId = 0;
  personId = 0;
  person = new PersonForm();
  company = "";
};

export class SupplierForm {
  id = 0
  name = "";
  iban = "";
  swift = "";
  address = "";
  bankAddress = "";
  notes = null;
  companyRegistration = "";
  vatRegistration = "";
  retailCommission = 0;
}

export class ClientForm {
  id = 0;
  company = "";
  name = "";
  iban = "";
  swift = "";
  address = "";
  bankAddress = "";
  nationalite = null;
  notes = null;
  kyc = true
}

export class CharterForm {
  id = 0;
  client = null;
  clientId = null;
  supplier = null;
  supplierId = null;
  placeFrom = "";
  placeTo = "";
  startDate = null;
  endDate = null;
  kyc = true;
  currency = "EUR";
  charterFeeAmount = 0;
  deliveryFeeAmount = 0;
  deposit = 0;
  vat = 0;
  vatPaidTo = null;
  vatDate = null;
  apa = 0;
  apaPaidTo = null;
  apaDate = null;
  total = 0;
  contractId = 0;

  static getPostForm(obj) {
    return (({
      supplier,
      client,
      ...o }) => o)(obj);
  }
}

export class InstallmentForm {
  id = 0;
  amount = 0;
  dueDate = new Date();
  contractId = 0;
  payments = new PaymentForm()
}

export class PaymentForm {
  id = 0;
  installmentId = null;
  installment = null;
  note = "";
  reconciliationDetails = "";
  type = "";
  kind = "";
  paymentDate = null;
  dueDate = new Date();
  amountReceived = 0;
  amountToPay = 0;
  contractId = 0;
  emailSentDate = null;
  readyToPay = false;
  cleared = false;
  reconciled = false;
  paymentRecipient = null
}