import React from 'react';
import { Col, Row, Form, Card } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import AsyncSelect from 'react-select/async';

const YachtUserList = ({ handleYachtDetails, loadYachtOptions, loadYachtUserList }) => {

	return (
		<Row className="mb-3 g-3">
			<Col lg={12}>
				<Card>
					<Card.Header className="d-inline">
						<h5 className="float-start">Yacht User List</h5>

					</Card.Header>
					<Card.Body className="bg-light">
						<Row>
							<Col lg={12}>
								<Row>
									<Form.Group as={Col} lg={12}>
										<AsyncSelect
											getOptionLabel={option => { return `${option.fullName} - ${option.email}` }}
											defaultOptions
											onChange={handleYachtDetails}
											value={null}
											loadOptions={loadYachtOptions}
											className="h-100 w-100"
											placeholder="Search for a user"
											styles={{
												menuList: (baseStyles, state) => ({
													...baseStyles,
													backgroundColor: 'white',
												})
											}}
										/>
									</Form.Group>

								</Row>
								<div className="mt-3">
									{loadYachtUserList()}
								</div>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

export default YachtUserList;
