import React from 'react';
import { Col, Row, Form, FloatingLabel } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

const YachtHeaderForm = ({ handleSubmit, handleFieldChange, formData, validated, setFormData }) => {
	const form = '';

	return (
		<Form validated={validated} onSubmit={handleSubmit} className="first-form-yacht">
			<Row className="g-3">
				<FloatingLabel as={Col}
					controlId="name"
					label="Name"
				>
					<Form.Control
						type="text"
						value={formData.name}
						onChange={handleFieldChange}
						placeholder="Name"
						required
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a yacht name.
					</Form.Control.Feedback>
				</FloatingLabel>
				<Form.Group as={Col} lg={3} xs={12}>
					<FloatingLabel
						controlId="rE_CAAgentComm"
						label="Comm. %"
					>
						<Form.Control
							type="number"
							value={formData.rE_CAAgentComm}
							onChange={handleFieldChange}
							placeholder="Comm. %"
						/>
					</FloatingLabel>
				</Form.Group>
			</Row>
		</Form>
	);
};

export default YachtHeaderForm;
