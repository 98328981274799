import React, { useContext, useState, useEffect } from 'react';
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';


//var decoded = "";
//if (document.cookie.indexOf('jwtAuth=')!=-1) {
//    decoded = jwt_decode(localStorage.getItem('jwtAuth'));
//}
//console.log(decoded);

const TopNavRightSideNavItem = () => {
	const [getName, setName] = useState({
		firstName: '',
		lastName: ''
	});
   
	const {
		config: { isDark, isRTL },
		setConfig
	} = useContext(AppContext);
	return (
		<Nav
			navbar
			className="navbar-nav-icons ms-auto flex-row align-items-center"
			as="ul"
		>
			{/*<NotificationDropdown />*/}
			{/*<Link style={{ textDecoration: 'none', fontSize: '1rem' }} className="pb-1" to="/components/users/settings"></Link>*/}
			<ProfileDropdown />
		</Nav>
	);
};

export default TopNavRightSideNavItem;
