import React, { useEffect, useState } from 'react';
import { Card, Col, Modal, Row, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import AddNewInstallmentModal from './addNewInstallmentModal';
import { InstallmentForm } from 'viewModel/DtoClasses';
import DatePicker from 'react-datepicker';
import axiosInstance from 'helpers/axiosInstance';
import { toast } from 'react-toastify';

const ContractInstallments = ({
	formatter,
	controlZone,
	role,
	setFormData,
	formData,
	setReadjustPayment
}) => {
	let form = '';
	const [IsModalOpened, setIsModalOpened] = useState(false);
	const [newInstallment, setNewInstallment] = useState(new InstallmentForm());
	const [index, setIndex] = useState(-1);

	const handleSaveInstallment = async e => {
		if (formData.id > 0 && newInstallment.id > 0) {
			let res = await axiosInstance.put(`${window.location.origin}/installments`, newInstallment);
			if (res.status === 200) {
				toast.success(`Installment modified successfully.`, {
					theme: 'colored'
				});
				setReadjustPayment(true);
			}
			else {
				toast.error(`There was an error modifying the installment.`, {
					theme: 'colored'
				});
				return;
			}
		}
		formData.installments[index] = newInstallment;
		setFormData({ ...formData, installments: formData.installments })
		setIsModalOpened(false);
	}

	const handleDeleteInstallment = async e => {
		if (newInstallment.id > 0) {
			let res = await axiosInstance.delete(`${window.location.origin}/installments/${newInstallment.id}`);
			if (res.status === 204) {
				toast.success(`Installment deleted successfully.`, {
					theme: 'colored'
				});
				setReadjustPayment(true);
			}
		}
		formData.installments.splice(index, 1);
		setFormData({ ...formData, installments: formData.installments })
		setIsModalOpened(false);
	}

	const handleEditInstallment = (obj, index) => {
		setNewInstallment(obj);
		setIndex(index);
		setIsModalOpened(true);
	}

	return (
		<>
			<Card className='mb-3 g-3'>
				<Card.Header className="h-auto">
					<div className='d-flex justify-content-between align-items-center'>
						<h5>Installments</h5>
						<div className={`float-end ${role != "Administrator" && "d-none"}`}>
							{formData.installments.length < 3 &&
								<div className="float-end">
									<AddNewInstallmentModal
										variant="secondary"
										setFormData={setFormData}
										formData={formData}
										setReadjustPayment={setReadjustPayment}
									/>
								</div>
							}
						</div>
					</div>
					<div className="bg-white flex-row justify-content-center mt-4">
						<Col className="" >
							<div className='row'>
								{controlZone.map((item, index) => (
									<div className="col-6 text-center text-primary" key={index}>
										<div>
											{(item.name.includes("receive") ? <FontAwesomeIcon icon="fa-solid fa-wallet" className="fs-1 text-primary" /> : <FontAwesomeIcon icon="fa-solid fa-calendar-check" className="fs-1 text-primary" />)}
										</div>
										<CountUp
											start={0}
											end={(item.value)}
											duration={1.2}
											suffix={formData.charter.currency}
											prefix={''}
											separator=","
											decimals={true}
											decimal="."
										/>
										<div className='text-center text-dark'>{item.name}</div>
									</div>
								))}
							</div>
						</Col>
					</div>
				</Card.Header>
				<Card.Body>
					<div className="timeline-vertical py-0">
						{formData.installments.map((obj, index) => {
							return (
								<div
									key={index}
									className={'timeline-item timeline-item mt-0'}
									onClick={() => handleEditInstallment(obj, index)}
									style={{ cursor: 'pointer' }}
								>
									<Row className={` ${'timeline-item-end'}`}
									>
										<Col lg={2} className="d-flex align-items-center justify-content-center">
											<div className="d-flex justify-content-center align-items-center flex-column">
												<div className=" icon-item icon-item-lg text-primary border-300">
													<FontAwesomeIcon icon='fa-solid fa-calendar-check' className={`text-primary}`} />
												</div>
												<p className="fs--1 mb-0 fw-semi-bold"> {obj.dueDate.getFullYear()}</p>
												<p className="fs--2 text-600">{obj.dueDate.getDate() + "/" + parseInt((new Date(obj.dueDate).getMonth()) + 1)}</p>

											</div>
										</Col>
										<Col lg={10}>
											<div className="timeline-item-content ms-0" style={{ border: '1px solid #2c7be5' }}>
												<div className="timeline-item-card" >
													<h5 className=" mb-0">{formatter.format(obj.amount)}</h5>
												</div>
											</div>
										</Col>
									</Row>
								</div>
							);
						})}
					</div>
				</Card.Body>
			</Card>
			<Modal
				show={IsModalOpened}
				onHide={() => { setIsModalOpened(false) }}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Body>
					<h4>Edit Installment</h4>
					<hr />

					<Form.Group className="mt-3">
						<Form.Label className="mb-2">Amount</Form.Label>
						<Form.Control
							type="number"
							onWheel={(e) => e.target.blur()}
							value={newInstallment.amount}
							onChange={(e) => setNewInstallment({ ...newInstallment, amount: e.target.value })}
							name="amount"
						/>
					</Form.Group>

					<Form.Group className="mt-3">
						<Form.Label className="mb-2">Due date</Form.Label>
						<DatePicker
							selected={newInstallment.dueDate}
							onChange={(date) => setNewInstallment({ ...newInstallment, dueDate: date })}
							formatWeekDay={day => day.slice(0, 3)}
							className='form-control'
							value={newInstallment.dueDate}
							name="dueDate"
							dateFormat="dd.MM.yyyy"
							locale='es'
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					{role == "Administrator" && <>
						<Button
							variant="danger"
							onClick={() => handleDeleteInstallment()}
						>
							Delete
						</Button>
						<Button variant="secondary" onClick={() => { setIsModalOpened(false); }}>Cancel</Button>
						<Button
							onClick={() => handleSaveInstallment()}
							disabled={!newInstallment.dueDate || !newInstallment.amount}
						>
							Save
						</Button>
					</>
					}
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ContractInstallments;
