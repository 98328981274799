import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getToken } from '../helpers/utils';

// handle the private routes
function PrivateRoute() {

 if (!getToken()) {
  return <Navigate to="/login"/>
 }
  return (
    <Outlet></Outlet>
  )
}

export default PrivateRoute;