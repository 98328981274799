import React from 'react';
import { Col, Row, Form, FloatingLabel } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

const YachtCompanyForm = ({ handleSubmit, handleFieldChange, formData, validated, setFormData }) => {
	const form = '';

	return (
		<Form validated={validated} onSubmit={handleSubmit} className="second-form-yacht">
			<Row className="g-3">
				<FloatingLabel as={Col} lg={12}
					controlId="company"
					label="Company name"
				>
					<Form.Control
						type="text"
						value={formData.company}
						onChange={handleFieldChange}
						placeholder="Company name"
						required
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a company name.
					</Form.Control.Feedback>
				</FloatingLabel>
				<FloatingLabel as={Col} lg={12}
					controlId="address1"
					label="Address 1"
				>
					<Form.Control
						type="text"
						value={formData.address1}
						onChange={handleFieldChange}
						placeholder="Address 1"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter an address.
					</Form.Control.Feedback>
				</FloatingLabel>
				<FloatingLabel as={Col} lg={12}
					controlId="address2"
					label="Address 2"
				>
					<Form.Control
						type="text"
						value={formData.address2}
						onChange={handleFieldChange}
						placeholder="Address 2"
					/>
				</FloatingLabel>
				<FloatingLabel as={Col} lg={4}
					controlId="zipCode"
					label="Zip code"
				>
					<Form.Control
						type="text"
						value={formData.zipCode}
						onChange={handleFieldChange}
						placeholder="Zip code"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a Zip code.
					</Form.Control.Feedback>
				</FloatingLabel>
				<FloatingLabel as={Col} lg={4}
					controlId="city"
					label="City"
				>
					<Form.Control
						type="text"
						value={formData.city}
						onChange={handleFieldChange}
						placeholder="City"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a city.
					</Form.Control.Feedback>
				</FloatingLabel>
				<FloatingLabel as={Col} lg={4}
					controlId="country"
					label="Country"
				>
					<Form.Control
						type="text"
						value={formData.country}
						onChange={handleFieldChange}
						placeholder="Country"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a country.
					</Form.Control.Feedback>
				</FloatingLabel>
				<FloatingLabel as={Col}
					controlId="flag"
					label="Flag"
				>
					<Form.Control
						type="text"
						value={formData.flag}
						onChange={handleFieldChange}
						placeholder="Flag"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a flag.
					</Form.Control.Feedback>
				</FloatingLabel>
				<FloatingLabel as={Col}
					controlId="site"
					label="Site"
				>
					<Form.Control
						type="text"
						value={formData.site}
						onChange={handleFieldChange}
						placeholder="Site"
					/>
					<Form.Control.Feedback type="invalid">
						Please enter a site.
					</Form.Control.Feedback>
				</FloatingLabel>
				<FloatingLabel as={Col}
					controlId="length"
					label="Length"
				>
					<Form.Control
						onWheel={(e) => e.target.blur()}
						type="number"
						value={formData.length}
						onChange={handleFieldChange}
						placeholder="Length"
					/>
				</FloatingLabel>

			</Row>
		</Form>
	);
};

export default YachtCompanyForm;
