import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import SoftBadge from 'components/common/SoftBadge';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import { Card, Col, Row, Form, Button, Spinner, FloatingLabel, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import FalconComponentCard from 'components/common/FalconComponentCard';
import IconButton from 'components/common/IconButton';
import { PersonViewModel } from '../../viewModel/personView';
import { OfficeViewModel } from '../../viewModel/officeView';
import { BrokerViewModel } from '../../viewModel/brokerView';

import DeleteItemModal from 'components/modalComponents/deleteItemModal';
import ContractList from '../common/UserContracts';
import moment from 'moment';
import { Link } from 'react-router-dom';
import axiosInstance from 'helpers/axiosInstance';
import UserDetail from './UserDetail';


const UserUpdate = (props) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [formData, setFormData] = useState({
		email: '',
		phoneNumber: '',
		firstName: '',
		lastName: '',
		role: '',
		isEnabled: true,
		fileRead: false,
		address1: '',
		address2: '',
		fullName: '',
		deletingObject: ''
	});
	const [Ids, setIds] = useState({
		brokerId: 0,
		personId: 0
	});
	const [brokerId, setBrokerId] = useState(0);
	const [personId, setPersonId] = useState(0);
	const [role, setRole] = useState("User");
	const [commSection, setCommSection] = useState(false);
	const [officeSection, setOfficeSection] = useState(false);
	const [officeDetails, setOfficeDetails] = useState(false);
	const [fileReadPerm, setfileReadPerm] = useState(false);
	const [officeId, setOfficeId] = useState(0);
	const [offices, setOffices] = useState({ data: [] });
	const [officeData, setOfficeData] = useState(new OfficeViewModel('', ''));
	const [brokerData, setBrokerData] = useState(new BrokerViewModel(formData, offices));
	const [companySection, setCompanySection] = useState(false);
	const [triggerContracts, setTriggerContracts] = useState(false);
	const columns = [
		{
			accessor: 'fileName',
			Header: 'File number',
			Cell: rowData => {
				const { fileName } = rowData.row.original;
				return (
					<h6>{fileName}</h6>
				)
			}
		},
		{
			accessor: 'contractDate',
			Header: 'Contract date',
			Cell: rowData => {
				const { contractDate } = rowData.row.original;
				let date = moment(contractDate).format('DD/MM/YYYY HH:mm');
				return (
					<span>{date}</span>
				)
			}
		},
		{
			accessor: 'yacht',
			Header: 'Yacht',
			Cell: rowData => {
				const { yacht, yachtId } = rowData.row.original;
				return (
					<Link onClick={(e) => e.stopPropagation()} to={`/components/yachts/edit?id=${yachtId}`} className="fw-bold">{yacht}</Link>
				)
			}
		},
		{
			accessor: 'kyc',
			Header: 'KYC',
			Cell: rowData => {
				const { kyc } = rowData.row.original;
				const bg = kyc ? "success" : "danger";
				const text = ""
				return (
					<SoftBadge bg={bg} className="fs--2">
						{!kyc ? "No " : ""}KYC
					</SoftBadge>
				)
			}
		},
		{
			accessor: 'kind',
			Header: 'Kind',
			Cell: rowData => {
				const { kind } = rowData.row.original;
				const text = kind == "CA" ? "Central Agent" : "Retail";
				const badgeColor = kind == "CA" ? "primary" : "warning";
				return (
					<span className={`fw-bold fs--2 btn-sm btn-${badgeColor}`}>{text}</span>
				)
			}
		},
		{
			accessor: 'type',
			Header: 'Type',
			Cell: rowData => {
				const { type } = rowData.row.original;
				return (
					<span>{type}</span>
				);
			}
		},
		{
			accessor: 'status',
			Header: 'Status',
			Cell: rowData => {
				const { status } = rowData.row.original

				return (
					<SoftBadge pill bg={status == "On Going" ? "success" :
						status == "Cancel with comm." ? "warning" :
							status == "Cancel without comm." ? "warning" :
								status == "Closed" ? "danger" :
									status == "Postponed Y+1" ? "info" : ""
					}
					>
						<span style={{ fontSize: "12px" }}>{status}</span>
					</SoftBadge>
				)
			}
		},
		{
			accessor: 'totalFees',
			Header: 'Total',
			Cell: rowData => {
				const { currency, totalFees } = rowData.row.original
				let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: currency });
				return (
					<span>{formatter.format(totalFees)}</span>
				)
			}
		},
		{
			accessor: 'placeFrom',
			Header: 'Place from'
		},
		{
			accessor: 'placeTo',
			Header: 'Place to'
		},
		{
			Header: ' ',
			Cell: rowData => {
				const { id, fileName, userContractType } = rowData.row.original;
				let userId = searchParams.get("id");
				let personId = Ids.personId;
				let IdToRemove = personId == 0 ? userId : personId;
				return (
					<OverlayTrigger placement='top' overlay={
						<Tooltip>
							{userContractType == "Broker" ? "Unassign broker from contract" : 'Removing user access from contract'}
						</Tooltip>
					}>
						<Button variant={userContractType == "Broker" ? "danger" : "falcon-danger"} size='sm' onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleRemove(IdToRemove, id, fileName, userContractType);
						}
						}>Remove</Button>
					</OverlayTrigger>

				)
			}
		}
	];

	useEffect(() => {
		axiosInstance.get(`${window.location.origin}/offices`)
			.then((res) => {
				setOffices(o => ({
					data: res.data.data
				}));
			});

		setFormData({ ...formData, ["id"]: searchParams.get("id") });

		if (props.isBroker) {
			axiosInstance.get(`${window.location.origin}/brokers/${searchParams.get("id")}`)
				.then((res) => {
					setBrokerId(searchParams.get("id"));
					setRole("Broker");
					if (res.data.Office !== null) {

						setOfficeData({
							id: res.data.Office.Id,
							address1: res.data.Office.Address1,
							address2: res.data.Office.Address2,
							city: res.data.Office.City,
							country: res.data.Office.Country,
							vat: res.data.Office.Vat,
							registrationNumber: res.data.Office.RegistrationNumber,
							email: res.data.Office.Email,
							phone: res.data.Office.Phone,
							fax: res.data.Office.Fax,
							rci: res.data.Office.RCI,
							dsee: res.data.Office.DSEE,
							caCommission: res.data.Office.CaCommission,
							retailCommission: res.data.Office.RetailCommission
						});
					}

					setFormData({
						id: res.data.Id,
						email: res.data.Person.Email,
						phone: res.data.Person.Phone,
						firstName: res.data.Person.FirstName,
						lastName: res.data.Person.LastName,
						role: res.data.Person.Role,
						address1: res.data.Person.Address1,
						address2: res.data.Person.Address2,
						isEnabled: res.data.Person.IsEnabled,
						fullName: `${res.data.Person.FirstName} ${res.data.Person.LastName}`,
						deletingObject: 'Broker',
						login: {
							id: res.data.Person.Id,
							email: res.data.Person.Email,
							userName: res.data.Person.UserName,
							role: res.data.Person.Role
						}
					});
					setBrokerData({
						id: res.data.Id,
						person: res.data.formData,
						office: res.data.officeData,
						cacommission: res.data.CaCommission,
						retailcommission: res.data.RetailCommission,
						company: res.data.Company
					});

					setOfficeId(res.data.Office.Id);
					setIds({
						brokerId: res.data.Id,
						personId: res.data.Person.Id
					});
				});
		} else {
			const getUserData = async () => {
				const res = await axiosInstance.get(`${window.location.origin}/persons/${searchParams.get("id")}`);
				if (res.status == 200) {
					setFormData({
						id: res.data.Id,
						email: res.data.Email,
						phone: res.data.Phone,
						firstName: res.data.FirstName,
						lastName: res.data.LastName,
						role: res.data.Login.Role,
						isEnabled: res.data.IsEnabled,
						fileRead: res.data.Login.ReadPermission,
						login: {
							id: res.data.Login.Id,
							email: res.data.Email,
							password: res.data.Password,
							confirmPassword: res.data.Password,
							userName: res.data.UserName,
							role: res.data.Login.Role
						}
					});
					setfileReadPerm(res.data.Login.ReadPermission);
					if (res.data.Login.Role == "Broker") {
						const brokerReq = await axiosInstance.get(`${window.location.origin}/brokers/${res.data.Login.Id}?isLoginId=true`);
						if (brokerReq.status == 200) {
							if (brokerReq.data.Office !== null) {

								setOfficeData({
									id: brokerReq.data.Office.Id,
									address1: brokerReq.data.Office.Address1,
									address2: brokerReq.data.Office.Address2,
									city: brokerReq.data.Office.City,
									country: brokerReq.data.Office.Country,
									vat: brokerReq.data.Office.Vat,
									registrationNumber: brokerReq.data.Office.RegistrationNumber,
									email: brokerReq.data.Office.Email,
									fax: brokerReq.data.Office.Fax,
									phone: brokerReq.data.Office.Phone,
									rci: brokerReq.data.Office.RCI,
									dsee: brokerReq.data.Office.DSEE,
									caCommission: brokerReq.data.Office.CaCommission,
									retailCommission: brokerReq.data.Office.RetailCommission
								});
							}
							setBrokerData({
								id: brokerReq.data.Id,
								person: brokerReq.data.formData,
								office: brokerReq.data.officeData,
								cacommission: brokerReq.data.CaCommission,
								retailcommission: brokerReq.data.RetailCommission,
								company: brokerReq.data.Company
							});
							setOfficeId(brokerReq.data.Office.Id);
							setIds({
								brokerId: brokerReq.data.Id,
								personId: brokerReq.data.Person.Id
							});
						}
					}
					setRole(res.data.Login.Role);
				}
			}
			columns.push()
			getUserData().catch(e => console.log(e));
		}
	}, []);

	useEffect(() => {
		console.log(Ids);
	}, [Ids])

	///handlers
	let form = '';
	const [validated, setValidated] = useState(false);
	let navigate = useNavigate();
	const handleExit = (e) => {
		if (props.isBroker) {
			navigate("/components/brokers");
		} else {
			navigate("/components/users");
		}
	}
	const handleSubmit = (event) => {
		event.preventDefault();
		const form = $(".first-form")[0].checkValidity();
		const formSecond = commSection ? $(".second-form")[0]?.checkValidity() : true;
		const formThird = companySection ? $(".third-form")[0].checkValidity() : true;
		/*const formFourth = officeData.city.length>0 && officeData.country.length>0;*/

		if (form === false || formSecond === false || formThird === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			if (props.isBroker) {

				brokerData.Id = formData.id;
				brokerData.person = new BrokerViewModel();
				brokerData.person.firstName = formData.firstName;
				brokerData.person.lastName = formData.lastName;
				brokerData.person.phone = formData.phone;
				brokerData.person.email = formData.email;
				brokerData.person.Role = formData.role;
				brokerData.person.Id = formData.login.id;
				/*brokerData["person"] = person;*/
				brokerData["office"] = officeData;

				axiosInstance.put(`${window.location.origin}/brokers/${brokerData.Id}`, brokerData)
					.then((res) => {
						if (res.data.id !== '0') {
							navigate("/components/brokers");
							toast.success(`Broker information has successfully been modified.`, {
								theme: 'colored'
							});
						} else {
							toast.error(`Some problem occurred, please try later.`, {
								theme: 'colored'
							});
						}
					});
			} else {
				let person = {
					id: formData.id,
					firstName: formData.firstName,
					lastName: formData.lastName,
					phone: formData.phone,
					officeId: officeData.id,
					caComm: brokerData.cacommission,
					retailComm: brokerData.retailcommission,
					role: formData.role,
					readPermissions: formData.fileRead
				};
				axiosInstance.put(`${window.location.origin}/persons/${formData.login.id}`, person)
					.then((res) => {
						navigate("/components/users");
						toast.success(`User information has successfully been modified.`, {
							theme: 'colored'
						});

						$('.save-spinner').attr('hidden', 'hidden');
					}).catch(e => {
						toast.error("Error updating user info", { theme: 'colored' });
					});

			}
		}
		setValidated(true);
	}

	const handleDelete = (e) => {
		$('.delete-spinner').removeAttr('hidden');

		if (props.isBroker) {

			axiosInstance.delete(`${window.location.origin}/brokers/${searchParams.get("id")}`)
				.then((res) => {
					if (res.data.id !== '0') {
						navigate("/components/brokers");
					} else {
						toast.error(`${res.data.message}`, {
							theme: 'colored'
						});
					}
					$('.delete-spinner').attr('hidden', 'hidden');
				});
		} else {
			axiosInstance.delete(`${window.location.origin}/persons/${searchParams.get("id")}`)
				.then((res) => {
					if (res.data.id !== '0') {
						navigate("/components/users");
					} else {
						toast.error(`${res.data.message}`, {
							theme: 'colored'
						});
					}
					$('.delete-spinner').attr('hidden', 'hidden');
				});
		}
	}

	const handleRemove = (userId, contractId, fileName, userContractType) => {
		let url = `${window.location.origin}/persons/${userId}/contracts/${contractId}`;
		if (userContractType == "Broker")
			url = `${window.location.origin}/contracts/${contractId}/brokers`;
		axiosInstance.delete(url).then(x => {
			toast.success(`Removed contract ${fileName} from user's access`, { theme: 'colored' });
			setTriggerContracts(!triggerContracts);
		}).catch(e => {
			toast.error(e.message, { theme: 'colored' });
			if (e.response.data != '')
				toast.error(e.response.data, { theme: 'colored' });
		})
	}

	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.id]: e.target.value
		});
	};


	const handleFieldOfficeChange = e => {
		setOfficeData({
			...officeData,
			[e.target.id]: e.target.value
		});
	};

	const handleFieldBrokerChange = e => {
		setBrokerData({
			...brokerData,
			[e.target.id]: e.target.value
		});
	};


	const handleOfficeDetails = (id) => {
		let office = offices.data.find(x => x.id == id);
		setOfficeData(office);
	}

	const externalUserRadioButton = () => {
		return (
			<Form.Check
				type='radio'
				id='externalUser'
				label='External User'
				name='radio'
				onChange={(e) => {
					setFormData({ ...formData, role: "ExternalUser" });
					setOfficeSection(false);
					setOfficeDetails(false);
					setCompanySection(false);
				}}
				checked={formData.role == "ExternalBroker" || formData.role == "ExternalUser"}
			/>
		);
	}

	const adminRadioButton = () => {
		return (
			<Form.Check
				type='radio'
				id='administrator'
				label='Administrator'
				onChange={(e) => {
					setFormData({ ...formData, role: "Administrator" });
					setCommSection(false);
					setOfficeSection(false);
					setOfficeDetails(false);
					setCompanySection(false);
				}}
				name='radio'
				checked={formData.role == "Administrator"}
			/>
		);
	}

	const msBrokerRadioButton = () => {
		return (
			<Form.Check
				type='radio'
				id='broker'
				label='MySea Broker'
				name='radio'
				onChange={(e) => {
					setFormData({ ...formData, role: "Broker" });
					setCommSection(true);
					setOfficeSection(true);
					setCompanySection(false);
				}}
				checked={formData.role == "Broker"}
			/>
		);
	}

	const radioButton = () => {
		if (props.isBroker) {
			return (
				<>
					<h6><strong>User Role</strong></h6>

					{
						msBrokerRadioButton()
					}
				</>
			);
		}


		return (
			<>
				<h6><strong>User Role</strong></h6>
				{
					adminRadioButton()
				}
				{
					msBrokerRadioButton()
				}

				{
					externalUserRadioButton()
				}
			</>

		);
	}

	const loadOfficeOptions = () => {
		const officeOption = (office) => {
			return (
				<option value={office.id} key={office.id}>
					{office.name ? office.name : (office.city + ', ' + office.country)}
				</option>
			);
		}

		return (
			<>
				<option value={0} key={0}>Select office</option>

				{
					offices.data.map(office => (officeOption(office)))
				}
			</>
		);
	}

	{/*<Commissions*/ }
	const commissionSection = () => {
		if (formData.role == "Broker") {
			return (
				<>
					<Row className="mb-3 g-3">
						<Col lg={12}>
							<Card>
								<Card.Header >
									<h5>Commissions</h5>
								</Card.Header>
								<Card.Body className="bg-light">
									<Form validated={validated} onSubmit={handleSubmit} ref={ref => form = ref} className="second-form">
										<Row className="g-3">
											<FloatingLabel as={Col} lg={6}
												controlId="cacommission"
												label="CA commission %"
											>
												<Form.Control
													type="number"
													onWheel={(e) => e.target.blur()}
													step=".01"
													value={brokerData.cacommission}
													onChange={handleFieldBrokerChange}
													placeholder="CA commission %"
													required
												/>
												<Form.Control.Feedback type="invalid">
													Please enter a CA commission.
												</Form.Control.Feedback>
											</FloatingLabel>
											<FloatingLabel as={Col} lg={6}
												controlId="retailcommission"
												label="Retail commission %"
											>
												<Form.Control
													type="number"
													onWheel={(e) => e.target.blur()}
													step=".01"
													value={brokerData.retailcommission}
													onChange={handleFieldBrokerChange}
													placeholder="Retail commission %"
													required
												/>
												<Form.Control.Feedback type="invalid">
													Please enter a retail commission.
												</Form.Control.Feedback>
											</FloatingLabel>
										</Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</>
			);
		} else return null;
	};

	const officeSelectSection = () => {
		if (formData.role == "Broker") {
			return (<>
				<Row className="mb-3 g-3">
					<Col lg={12}>
						<Card>
							<Card.Header className="d-inline">
								<h5 className="float-start">Office</h5>
							</Card.Header>
							<Card.Body className="bg-light">
								<Form className="fourth-form">
									<Row className="g-3">
										<FloatingLabel controlId="floatingSelectGrid" label="Select office">
											<Form.Select aria-label="Select office" value={officeId}
												onChange={e => { setOfficeDetails(true); setOfficeId(e.target.value); handleOfficeDetails(e.target.value); }}>
												{loadOfficeOptions([offices, setOffices])}
											</Form.Select>
										</FloatingLabel>
									</Row>
								</Form>

							</Card.Body>
						</Card>
					</Col>
				</Row>
			</>);
		}
		else return null;
	}

	const officeDetailsSection = () => {
		if (formData.role == "Broker") {
			return (
				<Row className="mb-3 g-3">
					<Col lg={6}>
						<Card>
							<Card.Header >
								<h5>Office Details</h5>
							</Card.Header>
							<Card.Body className="bg-light">
								<Form>
									<Row className="g-3">
										<FloatingLabel as={Col} lg={12}
											controlId="address1"
											label="Address 1"
										>
											<Form.Control
												type="text"
												value={officeData.address1}
												onChange={handleFieldOfficeChange}
												placeholder="Address 1"
											/>
											<Form.Control.Feedback type="invalid">
												Please enter an address.
											</Form.Control.Feedback>
										</FloatingLabel>
										<FloatingLabel as={Col} lg={12}
											controlId="address2"
											label="Address 2"
										>
											<Form.Control
												type="text"
												value={officeData.address2}
												onChange={handleFieldOfficeChange}
												placeholder="Address 2"
											/>
										</FloatingLabel>
										<FloatingLabel as={Col} lg={6}
											controlId="city"
											label="City"
										>
											<Form.Control
												type="text"
												value={officeData.city}
												onChange={handleFieldOfficeChange}
												placeholder="City"
												required
											/>
											<Form.Control.Feedback type="invalid">
												Please enter a city.
											</Form.Control.Feedback>
										</FloatingLabel>
										<FloatingLabel as={Col} lg={6}
											controlId="country"
											label="Country"
										>
											<Form.Control
												type="text"
												value={officeData.country}
												onChange={handleFieldOfficeChange}
												placeholder="Country"
												required
											/>
											<Form.Control.Feedback type="invalid">
												Please enter a country.
											</Form.Control.Feedback>
										</FloatingLabel>
										<FloatingLabel as={Col} lg={12}
											controlId="email"
											label="Email"
										>
											<Form.Control
												type="text"
												value={officeData.email}
												onChange={handleFieldOfficeChange}
												placeholder="Email"
											/>
										</FloatingLabel>
										<FloatingLabel as={Col} lg={6}
											controlId="phone"
											label="Phone"
										>
											<Form.Control
												type="text"
												value={officeData.phone}
												onChange={handleFieldOfficeChange}
												placeholder="(+01) 23 45 67 89"
											/>
										</FloatingLabel>
										<FloatingLabel as={Col} lg={6}
											controlId="fax"
											label="Fax"
										>
											<Form.Control
												type="text"
												value={officeData.fax}
												onChange={handleFieldOfficeChange}
												placeholder="(+01) 23 45 67 89"
											/>
										</FloatingLabel>
									</Row>
								</Form>
							</Card.Body>
						</Card>
					</Col>
					<Col lg={6}>
						<Card className="mb-3 g-3">
							<Card.Header >
								<h5>Office Financial Details</h5>
							</Card.Header>
							<Card.Body className="bg-light">
								<Form validated={validated} onSubmit={handleSubmit} ref={ref => form = ref} className="second-form">
									<Row className="g-3">
										<FloatingLabel as={Col} lg={12}
											controlId="vat"
											label="VAT registration"
										>
											<Form.Control
												type="text"
												value={officeData.vat}
												onChange={handleFieldOfficeChange}
												placeholder="VAT"
											/>
											<Form.Control.Feedback type="invalid">
												Please enter a VAT.
											</Form.Control.Feedback>
										</FloatingLabel>
										<FloatingLabel as={Col} lg={12}
											controlId="registrationNumber"
											label="Company registration"
										>
											<Form.Control
												type="text"
												value={officeData.registrationNumber}
												onChange={handleFieldOfficeChange}
												placeholder="Company registration"
											/>
											<Form.Control.Feedback type="invalid">
												Please enter your company's registration number.
											</Form.Control.Feedback>
										</FloatingLabel>
										<FloatingLabel as={Col} lg={6}
											controlId="caCommission"
											label="CA commission"
										>
											<Form.Control
												type="number"
												onWheel={(e) => e.target.blur()}
												step=".01"
												value={officeData.caCommission}
												onChange={handleFieldOfficeChange}
												placeholder="CA commission"
											/>
											<Form.Control.Feedback type="invalid">
												Please enter a CA commission.
											</Form.Control.Feedback>
										</FloatingLabel>
										<FloatingLabel as={Col} lg={6}
											controlId="retailCommission"
											label="Retail commission"
										>
											<Form.Control
												type="number"
												value={officeData.retailCommission}
												onWheel={(e) => e.target.blur()}
												onChange={handleFieldOfficeChange}
												placeholder="Retail commission"
											/>
											<Form.Control.Feedback type="invalid">
												Please enter a retail commission.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Row>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			);
		} else return null;
	};

	const buttons = (role) => {
		return (
			<div className="float-end">
				<Button
					variant='falcon-primary'
					className='me-2 mr-1'
					type="submit"
					onClick={e => form.dispatchEvent(
						new Event("submit", { cancelable: true, bubbles: true })
					)}
				>
					<Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" /> Save
				</Button>

				<Button
					variant='falcon-warning'
					className='me-2'
					onClick={handleExit}
				>
					<Spinner animation="border" variant="warning" role="status" size="sm" hidden={true} className="cancel-spinner" />Cancel</Button>

				<DeleteItemModal item={props.isBroker ? "Broker" : "User"} itemName={formData.fullName} function={handleDelete} />
			</div>
		);
	};

	return (
		<>
			<PageHeader className="mb-3" title="">
				<div className="d-inline">
					<h3 className="float-start">Edit information</h3>
					{buttons()}
				</div>
			</PageHeader>


			{/*<User details*/}
			<Row className="mb-3 g-3">
				<Col lg={8}>
					<Card>
						<Card.Header >
							<h5>User Details</h5>
						</Card.Header>
						<Card.Body className="bg-light">
							<UserDetail
								validated={validated}
								handleSubmit={handleSubmit}
								setRef={ref => form = ref}
								handleFieldChange={handleFieldChange}
								formData={formData}
							>
							</UserDetail>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={4}>
					<Card>
						<Card.Header className='d-flex justify-content-between align-items-center'>
							<h5 className="float-start">Account Settings</h5>
							<span className='float-end d-flex'>
								<BootstrapSwitchButton
									type='switch'
									id='isEnabled'
									onlabel='Enabled'
									offlabel='Enabled'
									onstyle="primary"
									offstyle="light"
									width="120"
									checked={formData.isEnabled}
									onChange={(e) => {
										setFormData({ ...formData, "isEnabled": e });
									}} />
							</span>
						</Card.Header>
						<Card.Body className='bg-100'>
							{formData.role === "ExternalUser" &&
								<div className="d-flex flex-row mb-2 align-items-center justify-content-between">
									<h6 className="mb-0">File read permission</h6>
									<div className="d-flex align-items-center">
										<Form.Check
											style={{ height: "1.5rem" }}
											type='switch'
											id='fileRead'
											className="mb-0"
											label='Enabled'
											checked={fileReadPerm}
											onChange={(e) => {
												setfileReadPerm(e.target.checked);
												setFormData({ ...formData, "fileRead": e.target.checked });
											}}
										/>
									</div>
								</div>
							}
							{radioButton()}
						</Card.Body>
					</Card>
				</Col>
			</Row>


			{ /* Commission Section + Office Drop Down */}

			{commissionSection()}

			{/* Select office section*/}

			{officeSelectSection()}

			{/* Selected office*/}

			{officeDetailsSection()}

			{(role == "Broker" && Ids.brokerId != 0) && <ContractList type="brokers" triggerContracts={triggerContracts} columns={columns} BrokerId={Ids.brokerId} Id={Ids.personId} />}
			{(role == "ExternalBroker" || role == "ExternalUser") && <ContractList type="persons" triggerContracts={triggerContracts} columns={columns} Id={searchParams.get("id")} />}

		</>
	);
}

export default UserUpdate;
