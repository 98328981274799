import React, { useState, useEffect, useRef, useCallback } from 'react';
import UploadFormFiles from 'components/common/UploadFormFiles';
import { Card, Col, Row, Form, FloatingLabel, Button, Spinner, Table, InputGroup, ToggleButton, Modal } from 'react-bootstrap';
import $ from 'jquery';
import { toast } from 'react-toastify';
import axiosInstance from 'helpers/axiosInstance';
import axios from 'axios';
import { useSearchParams, useParams } from "react-router-dom";
import { ClientViewModel } from '../../viewModel/clientView';
import { SupplierViewModel } from '../../viewModel/supplierView';
import { CharterViewModel } from '../../viewModel/charterView';
import { CommissionViewModel } from '../../viewModel/commissionView';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import NotificationsWidget from 'components/dashboard-widgets/notifications';
import DatePicker from 'react-datepicker';
import PageHeader from 'components/common/PageHeader';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import Timeline from 'components/doc-components/Timeline.js';
import IconAlert from 'components/common/IconAlert';
import BeatLoader from "react-spinners/BeatLoader";
import Select from 'react-select';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { getUserRole, tryParseNumber, round } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import ContractDetailForm from './ContractDetailForm';

const EditContractSecondPageForm = (props) => {
	let navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [paymentKinds, setPaymentKinds] = useState({ data: [] });
	const [paymentTypes, setPaymentTypes] = useState({ data: [] });
	const [paymentRecipients, setPaymentRecipients] = useState({ data: [] });
	const [brokerEmail, setBrokerEmail] = useState("");
	const [paymentUpdated, setPaymentUpdated] = useState(false);
	const [installmentUpdated, setInstallmentUpdated] = useState(false);
	const [officeCommissionData, setOfficeCommissionData] = useState(new CommissionViewModel())
	const [retailBrokerCommissionData, setRetailBrokerCommissionData] = useState(new CommissionViewModel());
	const [mySeaBrokerCommissionData, setMySeaBrokerCommissionData] = useState(new CommissionViewModel());
	const [attachmentsData, setAttachmentsData] = useState({ data: [] });
	const [charterFeeData, setCharterFeeData] = useState({});
	const [brokerAdvancedCommission, setBrokerAdvancedCommission] = useState({});
	const [validated, setValidated] = useState(false);

	const [emailList, setEmailList] = useState({ data: [] });
	const [YachtEmailList, setYachtEmailList] = useState({ data: [] });
	const [input, setInput] = useState("");
	const [inputSave, setSave] = useState("");
	const [value, setValue] = useState("");
	const [brokerData, setBrokerData] = useState({});
	const [brokerId, setBrokerId] = useState(0);
	const [loaded, setLoaded] = useState(false);
	const [init, setInit] = useState(true);
	const [agents, setAgents] = useState(o => ({
		data: []
	}));
	const [appAffaires, setAppAffaires] = useState(o => ({
		data: []
	}));
	const [yacht, setYacht] = useState({
		country: '',
		contractDate: '',
		vatRep: false,
		city: '',
		id: -1,
		ca: true
	});
	const [clientData, setClientData] = useState(new ClientViewModel('', '', '', ''));
	const [supplierData, setSupplierData] = useState(new SupplierViewModel('', '', '', ''));
	const [charterData, setCharterData] = useState(new CharterViewModel());
	const [attachmentKind, setAttachmentKind] = useState({ data: [] });

	const [contractTypeData, setContractTypeData] = useState({ id: 0, name: '' });
	const [contractStatusesData, setContractStatusesData] = useState({ data: [] });
	const [currencies, setCurrencies] = useState({ data: [] });

	const [charterFeeTotal, setCharterFeeTotal] = useState(0);

	const [isCentralAgent, setIsCentralAgent] = useState(true);

	const [officeData, setOfficeData] = useState({});

	const [contractTypesData, setContractTypesData] = useState({ data: [] });

	const [paymentIn, setPaymentIn] = useState({
		data: [
		]
	});

	const [paymentOut, setPaymentOut] = useState({
		data: [
		]
	});

	const [installments, setInstallments] = useState({
		data: [
		]
	});

	const [secondContractPageData, setSecondContractPageData] = useState({
		notes: '',
		emailList: [],
		fileName: '',
		ContractNumber: '',
		yacht: {},
		officeCommission: {},
		ofCommission: 0,
		retailBrokerCommission: {},
		retCommission: 0,
		mySeaBrokerCommission: {},
		mSCommission: 0,
		charter: {},
		contractKind: { id: 1, name: '' },
		Kind: "",
		charterFee: 0,
		vat: 0,
		vatRep: false,
		otherInstallments: 0,
		toPayVAT: 0,
		currency: { id: 0, name: "" },
		contractType: "",
		Type: "",
		contractStatus: { name: 0 },
		installmentTotalClient: 0,
		installmentFirst: 0,
		installmentSecond: 0,
		installmentsOthers: 0,
		tPayInstallFirst: 0,
		toPayInstallSecond: 0,
		toPayVAT: 0,
		ownerToPay: 0,
		ownerToPayN: 0,
		ownerToPayNOneMore: 0,
		installmentToRecieve: 0,
		paymentInTotalClient: 0,
		paymentInToRecieve: 0,
		paymentOutTotalClient: 0,
		paymentOutToRecieve: 0,
		Intermediary: "",
		IntermediaryCommission: {
			id: 0,
			Comm: 0,
			IsTaxIncluded: false,
			Amount: null,
			VAT: null,
		}

	});

	const [paymentSummary, setPaymentSummary] = useState({
		totalClient: 0,
		totalInstall: 0,
		totalOwner: 0,
		toReceive: 0,
		toReceiveClient: 0,
		grossComm: 0,
		ownerToPay: 0,
		toRInstall1: 0,
		toRInstall2: 0,
		toRInstall3: 0,
		toPInstall1: 0,
		toPInstall2: 0,
		toPInstall3: 0,
	})
	const [warnings, setWarnings] = useState([]);
	const [formatter, setFormatter] = useState();
	const [show, setShow] = useState(false);
	const [readjustPayment, setReadjustPayment] = useState(false);
	const [loading, setLoading] = useState(false);
	const role = getUserRole();

	const [isClickComms, setIsOnClickComms] = useState({
		office: false,
		broker: false,
		agent: false,
		intermediary: false
	})
	const startRef = useRef();
	const endRef = useRef();


	useEffect(() => {
		calcSummary();
		calcBrokerAdvancedCommissionChange(brokerAdvancedCommission.brokerCommission, mySeaBrokerCommissionData.amount, officeCommissionData.amount);
	}, [installments, charterFeeData, charterFeeData.apa, paymentIn, paymentOut, secondContractPageData, officeCommissionData, mySeaBrokerCommissionData, retailBrokerCommissionData]);

	useEffect(
		() => {
			if ((role == "Administrator" || role == "Broker") && loaded && paymentUpdated) {
				axiosInstance.get(`${window.location.origin}/widgets/Notifications/${searchParams.get("id")}`).then(res => {
					setWarnings(res.data);
				})
				setPaymentUpdated(false)
			}

		}, [paymentUpdated]
	)

	useEffect(() => {
		let keys = Object.keys(isClickComms);
		keys.forEach(x => {
			try {
				let val = isClickComms[x];
				if (val)
					document.querySelector(`[name=${x}CommAmount]`).focus();
			} catch {

			}
		})
	}, [isClickComms])

	useEffect(() => {
		if (init && !loaded) {
			setInit(false);
			let requests = [axiosInstance.get(`${window.location.origin}/contracts/${searchParams.get("id")}`), axiosInstance.get(`${window.location.origin}/parameters`)];
			let notifResp
			if (role == "Administrator" || role == "Broker") {
				if (role == "Administrator")
					requests.push(axiosInstance.get(`${window.location.origin}/contracts/${searchParams.get("id")}/yacht`));
				requests.push(axiosInstance.get(`${window.location.origin}/widgets/Notifications/${searchParams.get("id")}`));
			}
			axiosInstance.get(`${window.location.origin}/uploadFiles/GetFiles?FilesFrom=${"contract-" + searchParams.get("id")}`).then(x => {
				setAttachmentsData(o => ({
					data: x.data
				}));
			});
			/* let req12 = axiosInstance.get(`${window.location.origin}/contracts/${searchParams.get("id")}/payments/email`);*/
			axios.all(requests).then(axios.spread((...responses) => {

				responses[0].data.contract.Installments.forEach((item, index) => {
					let data = installments.data;
					item.dueDate = new Date(item.DueDate);
					let theLastEmpty = data.findIndex(item => item.id === 0);

					if (theLastEmpty === -1) {
						data.push(item);
					} else {
						data[theLastEmpty] = item;
					}
					setInstallments({ data: data });
				});
				// PARAMETERS
				setInit(false);
				setContractTypesData(o => ({
					data: responses[1].data.contractTypes.values
				}));
				setContractStatusesData(o => ({
					data: responses[1].data.
						contractStatuses.values
				}));
				setCurrencies(o => ({
					data: responses[1].data.currencies.values
				}));

				setAttachmentKind({
					data: responses[1].data.attachmentKinds.values
				});
				setPaymentKinds({
					data: responses[1].data.paymentKinds.values
				});
				setPaymentRecipients({
					data: responses[1].data.paymentRecipients.values
				})

				setPaymentTypes({
					data: responses[1].data.paymentTypes.values
				});
				setAgents(o => ({
					data: responses[0].data.AgentList
				}));
				setAppAffaires(o => ({
					data: responses[0].data.APPaffairesList
				}));
				setEmailList(o => ({
					data: responses[0].data.contract.emails
				}));

				if (responses.length > 3 && responses[2].data.emails && responses[2].data.emails.length > 0) {
					setYachtEmailList({
						data: responses[2].data.emails.map((e) => {
							return {
								Email: e.Email,
								Type: e.Type,
								IsYacht: true
							}
						})
					});
				}

				let contractKinds = responses[1].data.contractKind.values;
				let contractTypes = responses[1].data.contractTypes.values;
				let tempContractKind = contractKinds.find(x => x.name == responses[0].data.contract.Kind);
				let tempContractType = contractTypes.find(x => x.name == responses[0].data.contract.Type);
				contractTypeData.name = tempContractType.name;
				contractTypeData.Id = tempContractType.id;


				yacht.name = responses[0].data.contract.Yacht.Name;
				yacht.company = responses[0].data.contract.Yacht.Company;
				yacht.vatRep = responses[0].data.contract.Yacht.VatRep;
				yacht.id = responses[0].data.contract.Yacht.Id;
				yacht.flag = responses[0].data.contract.Yacht.Flag;
				yacht.length = responses[0].data.contract.Yacht.Length + "m";
				yacht.address1 = responses[0].data.contract.Yacht.Address1;
				yacht.city = responses[0].data.contract.Yacht.City;
				yacht.country = responses[0].data.contract.Yacht.Country;
				yacht.zipCode = responses[0].data.contract.Yacht.ZipCode;

				yacht.address2 = responses[0].data.contract.Yacht.Address2;

				if (responses[0].data.contract.Charter.Client) {
					clientData["company"] = responses[0].data.contract.Charter.Client.Company;
					clientData.name = responses[0].data.contract.Charter.Client.Name;
					clientData.id = responses[0].data.contract.Charter.Client.Id;
				}

				if (responses[0].data.contract.Charter.Supplier) {
					supplierData.name = responses[0].data.contract.Charter.Supplier.Name;
					supplierData.id = responses[0].data.contract.Charter.Supplier.Id;
				}

				if (responses[0].data.contract.Office && !role.includes("External")) {
					officeData["office"] = responses[0].data.contract.Office.Name;
					officeData.id = responses[0].data.contract.OfficeId;
					officeData.name = responses[0].data.contract.Office.Name ? responses[0].data.contract.Office.Name : (responses[0].data.contract.Office.City + ", " + responses[0].data.contract.Office.Country);
					officeCommissionData.name = responses[0].data.contract.Office.Name;
					officeCommissionData.isTaxIncluded = responses[0].data.contract.OfficeCommission.IsTaxIncluded;
					officeCommissionData.comm = responses[0].data.contract.OfficeCommission.Comm;
					officeCommissionData.amount = Number(responses[0].data.contract.OfficeCommission.Amount);
					officeCommissionData.vat = responses[0].data.contract.OfficeCommission.VAT;
					officeCommissionData.commissionType = { id: 0, name: '' };
					officeCommissionData.id = responses[0].data.contract.OfficeCommission.Id;
					officeCommissionData.officeId = responses[0].data.contract.OfficeId;
					officeCommissionData.invoice = responses[0].data.contract.OfficeCommission.Invoice;
				} else { officeCommissionData.isTaxIncluded = false }

				charterData.id = responses[0].data.contract.Charter.Id;
				charterData.placeTo = responses[0].data.contract.Charter.PlaceTo;
				charterData.placeFrom = responses[0].data.contract.Charter.PlaceFrom;
				charterData.startDate = new Date(responses[0].data.contract.Charter.StartDate);
				charterData.endDate = new Date(responses[0].data.contract.Charter.EndDate);
				charterData.rentalPeriod = responses[0].data.contract.Charter.RentalPeriod;
				charterData.numberDays = responses[0].data.contract.Charter.NumberDays;
				charterData.KYC = responses[0].data.contract.Charter.KYC;
				charterData.currency = responses[1].data.currencies.values.find(x => x.name == responses[0].data.contract.Charter.Currency).name;
				charterData["client"] = clientData;
				charterData["supplier"] = supplierData;

				//brokerData.id = responses[0].data.contract.MySeaBrokerCommission.Id;
				//brokerData.id = responses[0].data.contract.MySeaBrokerCommission.Id;


				charterFeeData.charterFeeAmount = responses[0].data.contract.Charter.CharterFeeAmount ? Number(responses[0].data.contract.Charter.CharterFeeAmount) : 0;
				charterFeeData.currencyName = responses[0].data.contract.Charter.Currency;
				charterFeeData.vat = responses[0].data.contract.Charter.VAT != null ? responses[0].data.contract.Charter.VAT : 0;
				let contractCurrency = responses[1].data.currencies.values.find(x => x.name == responses[0].data.contract.Charter.Currency)
				charterFeeData.currencyId = contractCurrency.id;
				// charterFeeData.currenyName = contractCurrency.name;
				// charterFeeData.currency = contractCurrency;
				charterFeeData.currency = contractCurrency.name;
				charterFeeData.vatDueDateToString = responses[0].data.contract.Charter.VatDueDateToString ? `(Due date: ${responses[0].data.contract.Charter.VatDueDateToString})` : "";
				charterFeeData.apa = responses[0].data.contract.Charter.APA;
				charterFeeData.ApaDueDate = responses[0].data.contract.Charter.ApaDate ? new Date(responses[0].data.contract.Charter.ApaDate) : null;
				charterFeeData.VatDueDate = responses[0].data.contract.Charter.VatDate ? new Date(responses[0].data.contract.Charter.VatDate) : null;
				charterFeeData.apaDueDateToString = responses[0].data.contract.Charter.CharterFee && responses[0].data.contract.Charter.ApaDueDateToString ?
					`(Due date: ${responses[0].data.contract.Charter.ApaDueDateToString})` : "";
				charterFeeData.feesPeriod = responses[0].data.contract.Charter.RentalPeriod;
				charterFeeData.deposit = responses[0].data.contract.Charter.Deposit ? responses[0].data.contract.Charter.Deposit : 0;
				charterFeeData.deliveryFeeAmount = responses[0].data.contract.Charter.DeliveryFeeAmount != null ? responses[0].data.contract.Charter.DeliveryFeeAmount : "";
				charterFeeData.total = charterFeeData.deliveryFeeAmount + charterFeeData.deposit + charterFeeData.apa + charterFeeData.vat + charterFeeData.charterFeeAmount;

				charterData.charterFee = charterFeeData;

				/* console.log("sssss", responses[0].data.contract.MySeaBroker.Id );*/

				if (responses[0].data.contract.MySeaBroker && !role.includes("External")) {
					setBrokerEmail(responses[0].data.contract.MySeaBroker.Email);
					brokerData.id = responses[0].data.contract.MySeaBrokerId;
					brokerData.person = {
						fullName: responses[0].data.contract.MySeaBroker.Person.FirstName + " " + responses[0].data.contract.MySeaBroker.Person.LastName
					};
					setBrokerId(responses[0].data.contract.MySeaBrokerId);
					mySeaBrokerCommissionData.name = responses[0].data.contract.MySeaBroker.FirstName + responses[0].data.contract.MySeaBroker.LastName;
					mySeaBrokerCommissionData.isTaxIncluded = responses[0].data.contract.MySeaBrokerCommission.IsTaxIncluded;
					mySeaBrokerCommissionData.comm = responses[0].data.contract.MySeaBrokerCommission.Comm;
					mySeaBrokerCommissionData.amount = responses[0].data.contract.MySeaBrokerCommission.Amount;

					mySeaBrokerCommissionData.vat = responses[0].data.contract.MySeaBrokerCommission.VAT;
					mySeaBrokerCommissionData.commissionType = { id: 0, name: '' };
					mySeaBrokerCommissionData.id = responses[0].data.contract.MySeaBrokerCommission.Id;
					mySeaBrokerCommissionData.invoice = responses[0].data.contract.MySeaBrokerCommission.Invoice;
				}

				if (responses[0].data.contract.Broker && !role.includes("External")) {
					retailBrokerCommissionData.name = responses[0].data.contract.Broker;
					setSave(responses[0].data.contract.Broker);
					retailBrokerCommissionData.isTaxIncluded = responses[0].data.contract.BrokerCommission.IsTaxIncluded;
					retailBrokerCommissionData.comm = responses[0].data.contract.BrokerCommission.Comm;
					retailBrokerCommissionData.amount = responses[0].data.contract.BrokerCommission.Amount != null ? Number(responses[0].data.contract.BrokerCommission.Amount) : 0;
					retailBrokerCommissionData.vat = responses[0].data.contract.BrokerCommission.VAT;
					retailBrokerCommissionData.commissionType = { id: 0, name: '' };
					retailBrokerCommissionData.id = responses[0].data.contract.BrokerCommission.Id;
					retailBrokerCommissionData.invoice = responses[0].data.contract.BrokerCommission.Invoice;
				} else { retailBrokerCommissionData.isTaxIncluded = false }


				let intermediaryComm = secondContractPageData.IntermediaryCommission;
				if (responses[0].data.contract.IntermediaryCommission)
					intermediaryComm = responses[0].data.contract.IntermediaryCommission;


				// charterFeeData.total = responses[0].data.contract.Charter.CharterFee ? responses[0].data.contract.Charter.Total : "";
				setCharterFeeTotal(Number(charterFeeData.total));
				let currencyObj = responses[1].data.currencies.values.find(x => x.name == responses[0].data.contract.Charter.Currency);
				setSecondContractPageData({
					...secondContractPageData,
					loginId: responses[0].data.contract.LoginId == null ? "" : responses[0].data.contract.LoginId,
					contractId: responses[0].data.contract.Id,
					fileName: responses[0].data.contract.FileName,
					ContractNumber: responses[0].data.contract.ContractNumber,
					contractDate: new Date(responses[0].data.contract.ContractDate),
					dateCreated: new Date(responses[0].data.contract.DateCreated),
					contractType: responses[0].data.contract.Type,
					Type: responses[0].data.contract.Type,
					installmentTotalClient: responses[0].data.contract.InstallmentTotalClien != null ? responses[0].data.contract.InstallmentTotalClient : 0,
					contractStatus: { name: responses[0].data.contract.Status },
					Status: responses[0].data.contract.Status,
					currency: currencyObj,
					notes: responses[0].data.contract.Notes,
					yacht: responses[0].data.contract.Yacht,
					charter: charterData,
					mySeaBrokerCommissionName: responses[0].data.contract.MySeaBrokerCommission != null ? mySeaBrokerCommissionData.name : "",
					retailBrokerCommissionName: responses[0].data.contract.RetailBrokerCommission != null ? mySeaBrokerCommissionData.name : "",
					Intermediary: responses[0].data.contract.Intermediary,
					IntermediaryCommission: intermediaryComm,
					Kind: responses[0].data.contract.Kind === "CA" ? 0 : 1,
					DirectToClient: responses[0].data.contract.DirectToClient,
					vatRep: responses[0].data.contract.VatRep
				});

				setIsCentralAgent(responses[0].data.contract.Kind === "CA");
				if (responses[0].data.contract.BrokerAdvancedCommission) {
					calcBrokerAdvancedCommissionChange(responses[0].data.contract.BrokerAdvancedCommission.BrokerCommission, mySeaBrokerCommissionData.amount, officeCommissionData.amount)

				}

				responses[0].data.contract.Payments.forEach((item, index) => {
					if (item.Type.includes("In")) {
						addNewPaymentLine(paymentIn, setPaymentIn, item);
					} else {
						addNewPaymentLine(paymentOut, setPaymentOut, item);
					}
				});





				let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: currencyObj.name });
				setFormatter(formatter);
				setLoaded(true);

				if (!role.includes("External"))
					setWarnings(responses[responses.length - 1].data);
			}
			))
		}
	}, [searchParams.get("id")])

	/// formules
	const calcSummary = () => {
		try {
			let totalInstall = Number(installments.data.reduce((acc, cur) => Number(acc) + Number(cur.Amount), 0));
			let totalClient = Number(paymentIn.data.reduce((acc, curr) => Number(acc) + Number(curr.AmountReceived), 0));
			//let vatPayment = paymentOut.data.filter(x => x.Kind == "VAT").reduce((p, a) => p + a.Amount, 0);
			//let apaPayment = paymentOut.data.filter(x => x.Kind == "APA").reduce((p, a) => p + a.Amount, 0);
			let totalOwner = Number(paymentOut.data.reduce((acc, curr) => Number(acc) + Number(curr.AmountReceived), 0));/* + ((charterFeeData.ApaDueDate != null) ? Number(charterFeeData.apa) : 0) + (!yacht.vatRep ? 0 : (charterFeeData.VatDueDate != null ? Number(charterFeeData.vat) : 0));*/


			if (secondContractPageData.Kind == "0") {
				let toReceive = totalInstall - (!secondContractPageData.DirectToClient ? (Number(retailBrokerCommissionData.amount) + Number(retailBrokerCommissionData.vat)) : 0);
				let toReceiveClient = toReceive - totalClient;
				let grossComm = totalClient - totalOwner;
				let ownerToPay = (totalClient == 0 ? 0 : grossComm - officeCommissionData.amount - officeCommissionData.vat);
				let toRInstall1 = (installments.data.length > 0 ? Number(installments.data[0].Amount) : 0) - Number(retailBrokerCommissionData.amount) - (retailBrokerCommissionData.isTaxIncluded ? Number(retailBrokerCommissionData.vat) : 0);
				let toRInstall2 = (installments.data.length > 1 ? installments.data[1].Amount : 0);
				let toRInstall3 = (installments.data.length > 2 ? installments.data[2].Amount : 0);
				let toPVat = Number(charterFeeData.vat - officeCommissionData.vat - retailBrokerCommissionData.vat);
				let toPInstall1 = (charterFeeData.charterFeeAmount / 2) - Number(officeCommissionData.amount) - Number(retailBrokerCommissionData.amount) + Number(charterFeeData.deliveryFeeAmount);
				let toPInstall2 = (!yacht.vatRep ? Number((charterFeeData.charterFeeAmount / 2)) + toPVat : Number(charterFeeData.charterFeeAmount) / 2);
				let toPInstall3 = null;

				setPaymentSummary({
					totalClient: totalClient,
					totalInstall: totalInstall,
					totalOwner: totalOwner,
					toReceive: toReceive,
					toReceiveClient: toReceiveClient,
					grossComm: grossComm,
					ownerToPay: ownerToPay,
					toRInstall1: toRInstall1,
					toRInstall2: toRInstall2,
					toRInstall3: toRInstall3,
					toPInstall1: toPInstall1,
					toPInstall2: toPInstall2,
					toPInstall3: toPInstall3,
					toPVat: toPVat
				});

			} else {

				/* let totalOwnerR = Number(paymentOut.data.reduce((acc, curr) => Number(acc) + Number(curr.Amount), 0)) + Number(charterFeeData.apa);*//*+ (apaPayment ? Number(charterFeeData.apa) : 0) + (yacht.vatRep ? Number(charterFeeData.VatDueDate) : 0 );*/
				let toReceiveClient = totalInstall - totalClient;
				let grossComm = totalClient - totalOwner;
				let ownerToPay = (totalClient == 0 ? 0 : (installments.data.length <= 2 || (installments.data.length > 2 && installments.data[2].amount == 0) ? grossComm - officeCommissionData.amount - (officeCommissionData.isTaxIncluded ? officeCommissionData.vat : 0) : (paymentIn.data.length <= 1 ? grossComm - (officeCommissionData.amount / 2) - (officeCommissionData.isTaxIncluded ? officeCommissionData.vat / 2 : 0) : grossComm - officeCommissionData.amount - officeCommissionData.vat)));
				ownerToPay = ownerToPay.toFixed(2);
				grossComm = grossComm.toFixed(2);
				let toRInstall1 = (installments.data.length > 0 ? installments.data[0].Amount : 0);
				let toRInstall2 = (installments.data.length > 1 ? installments.data[1].Amount : 0);
				let toRInstall3 = (installments.data.length > 2 ? installments.data[2].Amount : 0);
				let toPInstall1 = (installments.data.length <= 2 || installments.data[2].Amount == 0) ? (installments.data.length > 0 ? installments.data[0].Amount : 0) - officeCommissionData.amount - (officeCommissionData.isTaxIncluded ? officeCommissionData.vat : 0) : (installments.data.length > 0 ? installments.data[0].Amount : 0) - officeCommissionData.amount / 2 - (officeCommissionData.isTaxIncluded ? officeCommissionData.vat / 2 : 0);
				let toPInstall2 = (installments.data.length <= 2 || installments.data[2].Amount == 0) ? (installments.data.length > 1 ? installments.data[1].Amount : 0) : (installments.data.length > 1 ? installments.data[1].Amount : 0) - officeCommissionData.amount / 2 - officeCommissionData.vat / 2;
				let toPInstall3 = !yacht.vatRep ? toRInstall3 : toRInstall3 - charterFeeData.vat;
				setPaymentSummary({
					totalClient: totalClient,
					totalInstall: totalInstall,
					totalOwner: totalOwner,
					toReceive: toReceiveClient,
					toReceiveClient: toReceiveClient,
					grossComm: grossComm,
					ownerToPay: ownerToPay,
					toRInstall1: toRInstall1,
					toRInstall2: toRInstall2,
					toRInstall3: toRInstall3,
					toPInstall1: toPInstall1,
					toPInstall2: toPInstall2,
					toPInstall3: toPInstall3,
					toPVat: 0
				});
			}



		} catch {

		}
	}


	const retailCommissionVatCalculation = (comm, vat) => {
		let vatComm = vat / 100 * comm;
		if (isNaN(vatComm)) {
			retailBrokerCommissionData.vat = 0;
		} else {
			vatComm = (Math.round(vatComm * 100) / 100).toFixed(2);
			retailBrokerCommissionData.vat = vatComm;
		}
	}

	//Handlers

	const handleBrokerAdvancedCommissionChange = e => {
		calcBrokerAdvancedCommissionChange(e.target.value, mySeaBrokerCommissionData.amount, officeCommissionData.amount);
	}

	const onSelect = (value) => {
		setValue(value);
	};



	const loadBrokersOptions = (input, callback) => {
		if (role == "Administrator") {
			axiosInstance.get(`${window.location.origin}/brokers/FindBrokers?Name=${input}&page=0&take=5`).then(x => {
				callback(x.data.data);
			}).catch((e => {
				let errMsg = "Error getting brokers"
				if (e.response.status == 403)
					errMsg = "You are not authorized";
				toast.error(errMsg, { theme: 'colored' })
			}))
		} else {
			callback([]);
		}
	}

	const handleBrokerDetails = (value) => {
		setBrokerId(value.id);
		setBrokerEmail(value.person.email);
		setBrokerData({
			id: value.id,
			person: value.person,
			office: value.office,
			cacommission: value.caCommission,
			retailcommission: value.retailCommission,
			company: value.company
		});
		let msCommission = round((officeCommissionData.amount - secondContractPageData.IntermediaryCommission.Amount) / 100 * value.caCommission, 2);

		setMySeaBrokerCommissionData({
			...mySeaBrokerCommissionData,
			name: `${value.person.firstName} ${value.person.lastName}`,
			comm: isCentralAgent ? value.caCommission : value.retailCommission,
			amount: (isNaN(msCommission) || msCommission === 0) ? '' : msCommission,
			brokerId: value.id
		});

		calcBrokerAdvancedCommissionChange(
			brokerAdvancedCommission.brokerCommission,
			(isNaN(msCommission) || msCommission === 0) ? 0 : msCommission,
			officeCommissionData.amount);

	}


	const handleMySeaBrokerCommissionChange = e => {
		setMySeaBrokerCommissionData({
			...mySeaBrokerCommissionData,
			[e.target.id]: e.target.value
		});

		if (e.target.id === 'comm' || e.target.id == "amount") {
			let msComm = 0;
			let comm = 0;
			if (e.target.id == "comm") {
				msComm = round((officeCommissionData.amount - secondContractPageData.IntermediaryCommission.Amount) / 100 * e.target.value, 2);
				comm = (e.target.value);
			} else {
				msComm = (e.target.value);
				comm = round((msComm / (officeCommissionData.amount - secondContractPageData.IntermediaryCommission.Amount)) * 100, 2);
			}

			if (isNaN(msComm) || msComm === 0) {
				setMySeaBrokerCommissionData({ ...mySeaBrokerCommissionData, amount: 0, comm: 0 });
			} else {
				setMySeaBrokerCommissionData({ ...mySeaBrokerCommissionData, amount: msComm, comm: comm });
			}

			calcBrokerAdvancedCommissionChange(
				brokerAdvancedCommission.brokerCommission,
				(isNaN(msComm)) ? 0 : msComm,
				officeCommissionData.amount);
		}
	}

	const handleIntermidiaryCommissionChange = e => {
		if (e.target.id == "name")
			setSecondContractPageData({ ...secondContractPageData, Intermediary: e.target.value });

		if (e.target.id === 'Comm' || e.target.id === "IsTaxIncludedApp" || e.target.id == "amount") {
			let commission = 0;
			let comm = 0;

			if (e.target.id === 'Comm' || e.target.id === "IsTaxIncludedApp") {
				comm = e.target.id === "Comm" ? e.target.value : secondContractPageData.IntermediaryCommission.Comm;
				commission = round(charterFeeData.charterFeeAmount / 100 * comm, 2);
			} else {
				commission = e.target.value;
				comm = round((commission / charterFeeData.charterFeeAmount) * 100, 2);
			}
			let vatComm = charterFeeData.vat / 100 * comm;
			vatComm = vatComm.toFixed(2);
			setSecondContractPageData({
				...secondContractPageData,
				IntermediaryCommission: {
					...secondContractPageData.IntermediaryCommission,
					Amount: commission,
					VAT: (e.target.id === "IsTaxIncludedApp") ? (e.target.checked ? vatComm : 0) : (!secondContractPageData.IntermediaryCommission.IsTaxIncluded) ? 0 : vatComm,
					IsTaxIncluded: e.target.id === "IsTaxIncludedApp" ? e.target.checked : secondContractPageData.IntermediaryCommission.IsTaxIncluded,
					Comm: comm
				}

			});
			let officeCommission = officeCommissionData.amount;
			let msComm = (Math.round((officeCommission - commission) / 100 * mySeaBrokerCommissionData.comm * 100) / 100);
			setMySeaBrokerCommissionData({ ...mySeaBrokerCommissionData, amount: (isNaN(msComm) || msComm === 0) ? 0 : Number(msComm) });

			calcBrokerAdvancedCommissionChange(secondContractPageData.brokerAdvanceComm, secondContractPageData.mySeaBrokerCommission.amount, secondContractPageData.officeCommission.amount, secondContractPageData.IntermediaryCommission.Amount);
		}

	}

	const handleRetailBrokerCommissionChange = e => {
		if (e.target.id === 'name') {
			setRetailBrokerCommissionData({
				...retailBrokerCommissionData,
				[e.target.id]: e.target.value,
			});
		}


		if (e.target.id === 'comm' || e.target.id === "isTaxIncludedAgent" || e.target.id == "amount") {
			let commission = 0;
			let comm = 0;

			if (e.target.id === 'comm' || e.target.id === "isTaxIncludedAgent") {
				commission = round(charterFeeData.charterFeeAmount / 100 * (e.target.id === "isTaxIncludedAgent" ? retailBrokerCommissionData.comm : e.target.value), 2);
				comm = e.target.id === "comm" ? e.target.value : retailBrokerCommissionData.comm;
				//if (installments.data.length >= 3) {
				//    let i1 = installments.data[0].Amount;
				//    let i2 = installments.data[1].Amount;
				//    commission = ((i1*(comm/2)) + (i2*(comm/2)))/100;
				//}
			} else {
				commission = e.target.value;
				comm = round((commission / charterFeeData.charterFeeAmount) * 100, 2);
				//if (installments.data.length >= 3) {
				//    let i1 = Number(installments.data[0].Amount);
				//    let i2 = Number(installments.data[1].Amount);
				//    comm = (100 * commission * 2)/(i1+i2)
				//}
			}
			let vatComm = (charterFeeData.vat / 100 * comm).toFixed(2);

			setRetailBrokerCommissionData({
				...retailBrokerCommissionData,
				amount: commission,
				vat: (e.target.id === "isTaxIncludedAgent") ? (e.target.checked ? vatComm : 0) : (!retailBrokerCommissionData.isTaxIncluded) ? 0 : vatComm,
				isTaxIncluded: e.target.id === "isTaxIncludedAgent" ? e.target.checked : retailBrokerCommissionData.isTaxIncluded,
				comm: comm
			});
		}
		setReadjustPayment(true);

	}



	const handleOfficeCommissionChange = e => {
		if (e.target.id === 'comm' || e.target.id === "isTaxIncludedOffice" || e.target.id == "amount") {
			let officeCommission = 0;
			let comm = 0;

			if (e.target.id === 'comm' || e.target.id === "isTaxIncludedOffice") {
				officeCommission = charterFeeData.charterFeeAmount / 100 * (e.target.id === "isTaxIncludedOffice" ? officeCommissionData.comm : e.target.value);
				officeCommission = (Math.round(officeCommission * 100) / 100).toFixed(2);
				comm = e.target.id === "comm" ? e.target.value : officeCommissionData.comm;
			} else {
				officeCommission = e.target.value;
				comm = (officeCommission / charterFeeData.charterFeeAmount) * 100;
			}
			let vatComm = charterFeeData.vat / 100 * comm;
			vatComm = vatComm.toFixed(2);

			setOfficeCommissionData({
				...officeCommissionData,
				amount: officeCommission,
				vat: (e.target.id === "isTaxIncludedOffice") ? (e.target.checked ? vatComm : 0) : (!officeCommissionData.isTaxIncluded) ? 0 : vatComm,
				isTaxIncluded: e.target.id === "isTaxIncludedOffice" ? e.target.checked : officeCommissionData.isTaxIncluded,
				comm: comm
			});
			/*console.log("value", secondContractPageData.IntermediaryCommission.Amount);*/
			let msComm = (Math.round((officeCommission - secondContractPageData.IntermediaryCommission.Amount) / 100 * mySeaBrokerCommissionData.comm * 100) / 100).toFixed(2);
			setMySeaBrokerCommissionData({ ...mySeaBrokerCommissionData, amount: (isNaN(msComm) || msComm === 0) ? 0 : Number(msComm) });

			calcBrokerAdvancedCommissionChange(
				brokerAdvancedCommission.brokerCommission,
				mySeaBrokerCommissionData.amount,
				(isNaN(officeCommission) || officeCommission === 0) ? 0 : officeCommission);
		}
		setReadjustPayment(true);

	}

	const loadOfficeOptions = (input, callback) => {
		if (role == "Administrator") {
			axiosInstance.get(`${window.location.origin}/offices/simplified?name=${input}&take=15`).then(x => {
				callback(x.data);
			}).catch((e => {
				let errMsg = "Error getting offices"
				if (e.response.status == 403)
					errMsg = "You are not authorized";
				toast.error(errMsg, { theme: 'colored' })
			}))
		} else {
			callback([]);
		}
	}

	const handleOfficeDetails = (value) => {
		setOfficeData({
			id: value.id,
			city: value.city,
			country: value.country,
			CACommission: value.caCommission,
			retailCommission: value.retailCommission,
			name: value.name
		});

		/*console.log("ss", officeData  .id);*/
		let officeCommission = charterFeeData.charterFeeAmount * (isCentralAgent ? value.caCommission / 100 : value.retailCommission / 100);
		officeCommission = (Math.round(officeCommission * 100) / 100).toFixed(2);
		let vatComm = charterFeeData.vat / 100 * isCentralAgent ? value.caCommission : value.retailCommission;
		vatComm = (Math.round(vatComm * 100) / 100).toFixed(2);

		setOfficeCommissionData({
			...officeCommissionData,
			comm: isCentralAgent ? Number(value.caCommission) : Number(value.retailCommission),
			name: value.city + ', ' + value.country,
			amount: (isNaN(officeCommission) || officeCommission === 0) ? 0 : Number(officeCommission),
			vat: (isNaN(vatComm) || vatComm === 0 || !officeCommissionData.isTaxIncluded) ? 0 : Number(vatComm),
			officeId: Number(value.id)
		});

		let msComm = (Math.round((officeCommission - secondContractPageData.IntermediaryCommission.Amount) / 100 * mySeaBrokerCommissionData.comm * 100) / 100).toFixed(2);
		msComm = (Math.round(msComm * 100) / 100).toFixed(2);
		msComm = (Math.round(msComm * 100) / 100).toFixed(2);
		setMySeaBrokerCommissionData({ ...mySeaBrokerCommissionData, amount: (isNaN(msComm) || msComm === 0) ? 0 : Number(msComm) });

		calcBrokerAdvancedCommissionChange(
			brokerAdvancedCommission.brokerCommission,
			mySeaBrokerCommissionData.amount,
			(isNaN(officeCommission) || officeCommission === 0) ? 0 : Number(officeCommission));

		setReadjustPayment(true);
	}


	const handleCurrencyDetails = (value) => {
		let currency = currencies.data.find(x => x.id == value);
		setSecondContractPageData({
			...secondContractPageData,
			currency: currency
		});
		let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: currency.name });
		setFormatter(formatter);
	}

	const handleCharterChange = e => {
		if (role == "Administrator") {
			setCharterData({
				...charterData,
				[e.target.id]: e.target.id === 'KYC' ? e.target.checked : e.target.value
			});
		}

		/*        console.log(charterData);*/
	}

	const handleClientDetails = (value) => {
		if (value) {
			setClientData({
				id: value.id,
				company: value.company,
				name: value.name,
				IBAN: value.iban,
				SWIFT: value.swift,
				address: value.Address,
				bankAddress: value.bankaddress,
				kyc: value.kyc
			});
			setCharterData({ ...charterData, client: value, KYC: isCentralAgent ? charterData.KYC : value.kyc });
		}
	}

	const handleSupplierDetails = (value) => {
		if (value) {
			setSupplierData({
				id: value.id,
				name: value.name,
				IBAN: value.iban,
				SWIFT: value.swift,
				address: value.Address,
				bankAddress: value.bankaddress
			});
			setCharterData({ ...charterData, supplier: value });
		}
	}

	const handleYachtDetails = (value) => {
		if (value.id != -1) {
			setYacht({
				id: value.id,
				company: value.company,
				name: value.name,
				ca: value.ca,
				vatIntraCom: value.vatIntraCom,
				vatRep: value.vatRep,
				mySeaVatApplicable: value.mySeaVatApplicable,
				flag: value.flag,
				length: value.length,
				city: value.city,
				country: value.country,
				address1: value.address1,
				address2: value.address2,
				zipCode: value.zipCode
			});
			setIsCentralAgent(value.ca);
			secondContractPageData["yacht"] = value;
			setSecondContractPageData({
				...secondContractPageData,
				["Intermediary"]: value.intermediary ? value.intermediary : "",
				IntermediaryCommission: {
					Amount: value.intermediaryComm != 0 && charterData.charterFee.charterFeeAmount != 0 ? value.intermediaryComm * (charterData.charterFee.charterFeeAmount / 100) : 0,
					Comm: value.intermediaryComm ? value.intermediaryComm : 0,
					IsTaxIncluded: value.intermediaryVAT != null ? value.intermediaryVAT : false,
					VAT: value.intermediaryVAT == true ? charterData.charterFee.vat * (value.intermediaryComm / 100) : 0
				},
				["vatRep"]: value.vatRep
			});
			setCharterData({ ...charterData, KYC: value.ca ? value.kyc : clientData.kyc });
			if (value.id != -1) {
				axiosInstance.get(`${window.location.origin}/yachts/${value.id}`)
					.then(res => {
						setYachtEmailList({
							data: res.data.emails
						});
					}).catch((e) => {
						toast.error("Error")
					});
			}
		}

	}

	const handleContractDataChange = e => {
		if (role == "Administrator") {
			let valToAssign = e.target.value;
			if (e.target.id == "vatRep")
				valToAssign = e.target.checked;
			setSecondContractPageData({
				...secondContractPageData,
				[e.target.id]: valToAssign
			});
		}
	}

	const handleContractTypeDetails = (value) => {
		if (role == "Administrator") {
			if (value) {
				setSecondContractPageData({
					...secondContractPageData,
					contractType: value,
					Type: value

				});
			}
		}
	}


	const addNewPaymentLine = (payments, setPayments, payment) => {

		let data = payments.data;
		let dueToDate = new Date(payment.DueDate);
		let paymentToDate = new Date(payment.PaymentDate);
		payment.DueDate = dueToDate.getFullYear() === 1970 ? null : dueToDate;
		payment.dueDate = dueToDate.getFullYear() === 1970 ? null : dueToDate;
		payment.PaymentDate = paymentToDate.getFullYear() === 1970 ? null : paymentToDate;

		let theLastEmpty = data.findIndex(item => item.id === 0);

		if (theLastEmpty === -1) {
			data.push(payment);
		} else {
			data[theLastEmpty] = payment;
		}

		setPayments({ data: data });
	}

	const handleFieldChange = e => {
		if (role == "Administrator") {
			setSecondContractPageData({
				...secondContractPageData,
				[e.target.id]: e.target.value
			});
		}
	}

	const setDisplay = (bool) => {
		document.getElementById("displayInfo").hidden = bool;
		document.getElementById("editInfo").hidden = !bool;
	}

	//const handleAttachmentKindDetails = (value) => {
	//    if (value) {
	//        setAttachmentKind({
	//            id: value,
	//            name: attachmentKinds.data[value].name
	//        });
	//    }

	//}

	const loadContractStatusOptions = () => {
		if (contractStatusesData.data) {
			return (
				<>
					<option value={0} key={0}>Select status</option> //By default On going
					{
						contractStatusesData.data.map(status => (<option value={status.name} key={status.name} >
							{`${status.name}`}
						</option>))
					}
				</>
			);
		}
	}

	const handleCharterFeeChange = e => {
		setCharterFeeData({
			...charterFeeData,
			[e.target.id]: e.target.value
		});

		if (e.target.name === 'charterFeeAmount') {
			officeCommissionCalculation(officeCommissionData.comm, e.target.value);
			retailCommissionCalculation(retailBrokerCommissionData.comm, e.target.value);
			calcBrokerAdvancedCommissionChange(brokerAdvancedCommission.brokerCommission, mySeaBrokerCommissionData.amount, officeCommissionData.amount);
		}

		if (e.target.name === 'vat') {
			officeCommissionVatCalculation(officeCommissionData.comm, e.target.value);
			retailCommissionVatCalculation(retailBrokerCommissionData.comm, e.target.value)
			intermediaryVatCalculation(secondContractPageData.IntermediaryCommission.Comm, e.target.value)
		}
		/*setCharterFeeTotal(e.target.value);*/
		let total = 0;
		for (const key in charterFeeData) {

			if (key != e.target.id && key != 'apaDueDate' && key != 'currency' && key != 'currencyId' && key != 'total' && key != 'vatDueDateToString' && key != 'apaDueDateToString' && key != 'currencyName' && key != 'feesPeriod' && key != 'vatDueDate' && key != "feesPeriod" && typeof charterFeeData[key] != 'object' && key != "currenyName") {
				total += parseFloat(charterFeeData[key]);
			}
		}
		total += parseFloat(e.target.value);
		setCharterFeeTotal(total);
		setReadjustPayment(true);
	}

	const officeCommissionVatCalculation = (comm, vat) => {
		let vatComm = vat / 100 * comm;
		if (isNaN(vatComm) || vatComm === 0) {
			setOfficeCommissionData({ ...officeCommissionData, vat: 0 });

		} else {
			vatComm = (Math.round(vatComm * 100) / 100).toFixed(2);
			setOfficeCommissionData({ ...officeCommissionData, vat: vatComm });
			/*  let msComm = vatComm / 100 * mySeaBrokerCommissionData.comm;*/
			/*setMySeaBrokerCommissionData({ ...mySeaBrokerCommissionData, amount: (isNaN(msComm) || msComm === 0) ? 0 : msComm });*/
		}
	}

	const intermediaryVatCalculation = (comm, vat) => {
		let vatComm = vat / 100 * comm;
		let appComm = (Math.round(vatComm * 100) / 100).toFixed(2);
		setSecondContractPageData({ ...secondContractPageData, IntermediaryCommission: { ...secondContractPageData.IntermediaryCommission, VAT: Number(appComm) } });

	}

	const calcBrokerAdvancedCommissionChange = (brokerAdvanceComm, myseaBrComm, officeComm) => {

		//removed cuz laurent asked why the fuck it was like this in july 2023.
		//let commBrToPayC = (!mySeaBrokerCommissionData.invoice ? myseaBrComm - (isNaN(brokerAdvanceComm) ? 0 : brokerAdvanceComm) : 0);
		let commBrToPayC = (myseaBrComm - (isNaN(brokerAdvanceComm) ? 0 : brokerAdvanceComm));
		let commNetMySeaC = ((officeComm - myseaBrComm - secondContractPageData.IntermediaryCommission.Amount) * 100) / 100;
		if (!isNaN(brokerAdvanceComm)) {
			setBrokerAdvancedCommission({
				...brokerAdvancedCommission,
				brokerCommission: Number(brokerAdvanceComm),
				commBrokerToPay: secondContractPageData.contractStatus.name != "Postponed Y+1" ? Number(commBrToPayC) : 0,
				commBrokerToPayOneMore: secondContractPageData.contractStatus.name === "Postponed Y+1" ? Number((Math.round((myseaBrComm - (isNaN(brokerAdvanceComm) ? 0 : brokerAdvanceComm)) * 100) / 100).toFixed(2)) : 0,
				commNetMySea: secondContractPageData.contractStatus.name !== "Postponed Y+1" ? Number((Math.round((officeComm - myseaBrComm - secondContractPageData.IntermediaryCommission.Amount) * 100) / 100).toFixed(2)) : 0,
				commNetMySeaOneMore: secondContractPageData.contractStatus.name === "Postponed Y+1" ? Number((Math.round((officeComm - myseaBrComm - secondContractPageData.IntermediaryCommission.Amount) * 100) / 100).toFixed(2)) : 0
			});
		}

	}

	const officeCommissionCalculation = (comm, charterFee) => {
		let officeCommission = charterFee / 100 * comm;

		if (isNaN(officeCommission) || officeCommission === 0) {
			officeCommissionData.amount = 0;
		} else {
			officeCommission = (Math.round(officeCommission * 100) / 100).toFixed(2);
			officeCommissionData.amount = officeCommission;
		}

		let msComm = (Math.round((officeCommission - secondContractPageData.IntermediaryCommission.Amount) / 100 * mySeaBrokerCommissionData.comm * 100) / 100).toFixed(2);
		msComm = (Math.round(msComm * 100) / 100).toFixed(2);
		setMySeaBrokerCommissionData({ ...mySeaBrokerCommissionData, amount: (isNaN(msComm) || msComm === 0) ? '' : msComm });
	}

	const retailCommissionCalculation = (comm, charterFee) => {
		let retComm = charterFee / 100 * comm;
		if (isNaN(retComm) || retComm === 0) {
			retailBrokerCommissionData.amount = 0;
		} else {
			retComm = (Math.round(retComm * 100) / 100).toFixed(2);
			retailBrokerCommissionData.amount = retComm;
		}
	}

	const handleContractStatusDetails = (value) => {
		if (value) {
			if (value === "Postponed Y+1") {
				secondContractPageData.ownerToPayN = '0.00';
				secondContractPageData.ownerToPayNOneMore = secondContractPageData.ownerToPay;
			} else {
				secondContractPageData.ownerToPayN = secondContractPageData.ownerToPay;
				secondContractPageData.ownerToPayNOneMore = '0.00';
			}

			setSecondContractPageData({
				...secondContractPageData,
				contractStatus: {
					name: value
				}
			});

		}
	}

	const handleDelete = (e) => {

		axiosInstance.delete(`${window.location.origin}/contracts/${secondContractPageData.contractId}`)
			.then((res) => {
				if (res.status == 200) {

					$('.save-spinner').attr('hidden', 'hidden');
					toast.success(`Contract ${secondContractPageData.fileName} has been deleted.`, {
						theme: 'colored'
					});
					navigate("/components/contracts");
				} else {
					toast.error(`There was an error deleting the contract: ${res.data.message}`, {
						theme: 'colored'
					});
					$('.save-spinner').attr('hidden', 'hidden');
				}
			});

	}

	const handleSubmit = (e, regenPayment) => {



		let attachments = attachmentsData.data;

		/*secondContractPageData['attachments'] = attachments;*/

		let paymentInData = paymentIn.data;
		let paymentInLastEmpty = paymentInData.findIndex(item => item.id === 0);

		if (paymentInLastEmpty !== -1) {
			paymentInData.splice(paymentInLastEmpty);
		}

		let paymentOutData = paymentOut.data;
		let paymentOutLastEmpty = paymentOutData.findIndex(item => item.id === 0);

		if (paymentOutLastEmpty !== -1) {
			paymentOutData.splice(paymentOutLastEmpty);
		}

		let paymentsData = paymentInData.concat(paymentOutData);
		paymentsData.forEach((item) => {
			item["dueDate"] = moment(item["dueDate"]).format("YYYY-MM-DDTHH:mm:ss.000");
			if (item["paymentDate"]) {
				item["paymentDate"] = moment(item["dueDate"]).format("YYYY-MM-DDTHH:mm:ss.000");
			}
		});

		let installmentsData = installments.data;

		let theLastEmpty = installmentsData.findIndex(item => item.id === 0);

		if (theLastEmpty !== -1) {
			installmentsData.splice(theLastEmpty);
		}
		installmentsData.forEach((item) => {

			item["dueDate"] = new Date(item["DueDate"]);
		});

		secondContractPageData['installments'] = installmentsData;
		secondContractPageData['payments'] = paymentsData;


		charterData.startDate = (new Date(charterData.startDate.getTime() - charterData.startDate.getTimezoneOffset() * 60000).toISOString());
		charterData.endDate = (new Date(charterData.endDate.getTime() - charterData.endDate.getTimezoneOffset() * 60000).toISOString());


		secondContractPageData['charter'] = charterData;
		//if (officeCommissionData != null) {
		//    officeCommissionData.isTaxIncluded = officeCommissionData.isTaxIncluded == "YES";
		//}
		if (mySeaBrokerCommissionData != null) {
			mySeaBrokerCommissionData.BrokerId = brokerId;
		}

		//if (retailBrokerCommissionData != null) {
		//    retailBrokerCommissionData.isTaxIncluded = retailBrokerCommissionData.isTaxIncluded == "YES";
		//}

		charterFeeData.total = charterFeeTotal;
		secondContractPageData['officeCommission'] = officeCommissionData;
		secondContractPageData['mySeaBrokerCommission'] = mySeaBrokerCommissionData;
		retailBrokerCommissionData.name = (inputSave ? inputSave : value.label);
		secondContractPageData['retailBrokerCommission'] = retailBrokerCommissionData;
		secondContractPageData['brokerAdvancedCommission'] = brokerAdvancedCommission;
		secondContractPageData['charterFeeData'] = charterFeeData;
		if (emailList.data != null) {
			console.log(emailList.data);
			try {
				secondContractPageData['RecipientList'] = emailList.data.map(i => i.Email);
			} catch {

			}
		}


		let { installmentToRecieve, paymentInToRecieve, paymentOutToRecieve, paymentInTotalClient, paymentOutTotalClient,
			installmentFirst, installmentSecond, installmentsOthers, toPayInstallSecond, tPayInstallFirst, toPayVAT, ownerToPay,
			ownerToPayN, ownerToPayNOneMore, ofCommission, retCommission, mSCommission, contractKind, contractStatus, contractType,
			charterFee, bank, ...postData } = secondContractPageData;
		postData.charter.charterFee = null;

		postData.yacht.emailList = YachtEmailList.data;
		postData.yacht.emails = YachtEmailList.data;
		postData.Kind = postData.yacht?.ca ? "CA" : "Retail";
		postData.charter.currency = secondContractPageData.currency.name;
		postData.Status = secondContractPageData.contractStatus.name;
		console.log(postData);
		if (brokerId == 0)
			postData.mySeaBrokerCommission = null;

		axiosInstance.post(`${window.location.origin}/contracts/ContractSecondPage?regenPayment=${regenPayment}`, postData)
			.then((res) => {
				if (res.data) {

					$('.save-spinner').attr('hidden', 'hidden');
					toast.success(`Your changes have been saved.`, {
						theme: 'colored'
					});
					navigate("/components/contracts");
					/*window.location.href = `${window.location.origin}/components/contracts`;*/
				} else {
					toast.error(`There was an error saving your changes : ${res.data.message}`, {
						theme: 'colored'
					});
					$('.save-spinner').attr('hidden', 'hidden');
				}
			});

	}

	const handleCommAmountOnClick = (type) => {
		setIsOnClickComms({ ...isClickComms, [type]: !isClickComms[type] })
	}

	/// Partial views

	const loadCurrencyOptions = () => {
		if (currencies.data) {
			return (
				<>
					{
						currencies.data.map(currency => (<option value={currency.id} key={currency.id} >
							{`${currency.name}`}
						</option>))
					}
				</>
			);
		}
	}

	const loadClientOptionsAsync = (inputValue, callback) => {
		if (role == "Administrator") {
			axiosInstance.get(`${window.location.origin}/clients?Name=${inputValue}&page=0&take=15`).then(x => {
				callback(x.data.data);
			}).catch((e => {
				let errMsg = "Error getting clients"
				if (e.response.status == 403)
					errMsg = "You are not authorized";
				toast.error(errMsg, { theme: 'colored' })
			}))
		} else {
			callback([]);
		}
	}

	const loadSupplierOptionsAsync = (inputValue, callback) => {
		if (role == "Administrator") {
			axiosInstance.get(`${window.location.origin}/suppliers?Name=${inputValue}&page=0&take=15`).then(x => {
				callback(x.data.data);
			}).catch((e => {
				let errMsg = "Error getting external brokers"
				if (e.response.status == 403)
					errMsg = "You are not authorized";
				toast.error(errMsg, { theme: 'colored' })
			}))
		} else {
			callback([]);
		}
	}

	const loadYachtOptions = (input, callback) => {
		if (role == "Administrator") {
			axiosInstance.get(`${window.location.origin}/yachts?Name=${input}&Company=&Country=&page=0&take=15`).then(x => {
				x.data.data.push({ name: "Search for more yachts", id: -1 })
				callback(x.data.data);
			}).catch((e => {
				let errMsg = "Error getting yachts"
				if (e.response.status == 403)
					errMsg = "You are not authorized";
				toast.error(errMsg, { theme: 'colored' })
			}))
		} else {

			callback([]);
		}
	}

	const loadContractTypeOptions = () => {
		if (contractTypesData.data) {
			return (
				<>
					<option value={0} key={0}>Select Type</option>
					{
						contractTypesData.data.map(type => (<option value={type.name} key={type.name} >
							{`${type.name}`}
						</option>))
					}
				</>
			);
		}
	}

	const loadWarnings = () => {

		if (warnings.length > 0) {

			return (
				warnings.map((warning, index) =>
					<IconAlert key={index} variant={warning.variant}>
						<p className="mb-0" dangerouslySetInnerHTML={{ __html: warning.message }}></p>
					</IconAlert>
				)
			);
		} else return (<>This contract has no notifications.</>);
	}

	const loadInstallmentFeeWarning = () => {
		let installmentTotal = installments.data.reduce((acc, cur) => acc + cur.Amount, 0);
		let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: secondContractPageData.currency.name });
		if (installmentTotal != parseFloat(charterFeeTotal).toFixed(2)) {
			let warning = ({ message: `The installments total <strong>(${(formatter.format(installmentTotal))})</strong> does not equal to the charter fees total <strong>(${(formatter.format(charterFeeTotal))})</strong>.`, variant: 'danger' });
			return (
				<IconAlert variant={warning.variant} className='mb-2'>
					<p className="mb-0" dangerouslySetInnerHTML={{ __html: warning.message }}></p>
				</IconAlert>
			);
		}
		return (<></>);

	}

	const readjustPaymentsWarning = () => {
		if (readjustPayment) {
			let warning = ({ message: `The charter's/commissions' numbers have been changed. Would you like to readjust the payments?</br><strong>This will delete all your current payments!</strong>`, variant: 'warning' });
			return (
				<IconAlert variant={warning.variant} className='mb-2'>
					<div className='d-flex justify-content-between'>
						<p className="mb-0" dangerouslySetInnerHTML={{ __html: warning.message }}></p>
						<Button variant='warning' className='ms-2' onClick={(e) => { handleSubmit(e, true) }}>
							{loading ? (<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>) : "Confirm changes and regenerate"}
						</Button>
					</div>
				</IconAlert>
			);
		}
	}

	const handleSelectChange = (e, type) => {
		switch (type) {
			case "client": {
				let company = e;
				if (company != null) {
					let kyc = secondContractPageData.kyc;
					if (!isCentralAgent)
						kyc = company.kyc;
					else {
						if (secondContractPageData.yacht.kyc != null)
							kyc = secondContractPageData.yacht.kyc
					}
					setSecondContractPageData({ ...secondContractPageData, client: { id: company.id, name: company.name, company: company.company, kyc: company.kyc }, kyc: kyc });
				}
				break;
			}
			case "supplier": {
				setSecondContractPageData({
					...secondContractPageData,
					supplier: e,
					contactId: null,
					retailOrCABrokerCommission: {
						...secondContractPageData.retailOrCABrokerCommission,
						comm: e.centralAgent != null ? e.centralAgent.retailCommission : 0,
					},
					RetailOrCABroker: {
						...secondContractPageData.RetailOrCABroker,
						name: e.centralAgent?.name,
					}
				});
				break;
			}
			case "contractType": {
				let type = contractTypesData.data.find(x => x.id == e.target.value);
				if (type != null)
					setSecondContractPageData({ ...secondContractPageData, Type: type.name });
				break;
			}
			case "contractStatus": {
				let type = contractStatusesData.data.find(x => x.id == e.target.value);
				if (type != null)
					setSecondContractPageData({ ...secondContractPageData, Status: type.name });
				break;
			}
			case "currency": {
				let company = currencies.data.find(x => x.id == e.target.value);
				if (company != null) {
					setSecondContractPageData({ ...secondContractPageData, currency: company.name });
					let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: company.name });
					setFormatter(formatter);
				}
				break;
			}
		}
	}

	if (loaded) {
		return (
			<>
				<div className="d-inline">
					<PageHeader className="mb-3 g-3" title="">
						<div className="d-flex justify-content-between align-items-center">
							<div className='d-flex flex-column'>
								<h4 className="float-start d-block mb-0">{secondContractPageData.fileName}{/*, Date: {secondContractPageData.contractDate}*/} </h4>
								<Form.Label className="me-3 d-block mb-0">{isCentralAgent ? " CA Contract" : " Retail Contract"}</Form.Label>
							</div>
							<div className="float-end d-flex">
								{
									(role == "Administrator" || role == "Broker" || role == "ExternalUser") && (
										<Link to={`/components/contracts/${searchParams.get("id")}/sac`} className="d-flex align-items-center btn btn-falcon-default me-2 mt-1">Statement Of Account</Link>
									)
								}
								<FloatingLabel
									label="Status"
									className="mt-1 mx-2"
									style={{ maxWidth: "200px" }}

								>
									<Form.Select disabled={role != "Administrator"} onChange={e => {
										handleContractStatusDetails
											(e.target.value);
									}} value={secondContractPageData.contractStatus.name}>
										{loadContractStatusOptions()}
									</Form.Select>
								</FloatingLabel>
								{
									((role == "Administrator") && (<>
										<Button
											variant='falcon-danger'
											className='me-2 mt-1'
											type="submit"
											onClick={(e) => { setShow(true) }}
										>
											<Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" />
											Delete</Button>
										<Button
											variant='falcon-default'
											className='me-2 mt-1'
											type="submit"
											onClick={(e) => { navigate(-1) }}
										>
											Cancel</Button>
										<Button
											variant='falcon-primary'
											className='me-2 mt-1'
											type="submit"
											onClick={(e) => { handleSubmit(e, false) }}
										>
											<Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" />
											Save
										</Button>

									</>))
								}
								{
									((role == "Administrator") && (<>

									</>))
								}
							</div>
						</div>
					</PageHeader>
				</div>

				{/*<div className="w-100 mt-3">
					{notifications.data.map(n => (
						<div style={{ cursor: 'pointer' }} onClick={(e) => e.target.style.display = "none"} className={n.problemRef == "contract_status" ? "w-100 mb-2 alert alert-warning" : n.problemRef == "dueDateLess5" ? "w-100 mb-2 alert alert-warning alert-dismissible fade show " : n.problemRef == "dueDatePast" ? "w-100 mb-2 alert alert-danger" : n.problemRef == "noKYC" ? "w-100 mb-2 alert alert-warning" : ""}>
							<span style={{ pointerEvents: 'none' }} dangerouslySetInnerHTML={{ __html: n.Children }}></span>
						</div>))}
				</div>*/}
				{
					loadInstallmentFeeWarning()
				}
				{
					readjustPaymentsWarning()
				}
				<Row className="g-3">
					<Col lg={8}>
						<div id="displayInfo" className='mb-3 g-3' hidden={false}>
							<Card>
								<Card.Header className="d-flex justify-content-between align-items-center">
									<h5>Contract Details</h5>
									{role == "Administrator" &&
										<button className='btn btn-primary btn-sm' onClick={() => { setDisplay(true) }}><FontAwesomeIcon icon={faPenToSquare} className="me-2"></FontAwesomeIcon>Edit</button>
									}
								</Card.Header>
								<Card.Body className="bg-light">
									<div className="" style={{ fontSize: '0.9rem' }}>
										<div className="d-flex justify-content-around">
											<div>Date: {moment(secondContractPageData.contractDate).format("DD/MM/YYYY")}</div>
											<div>Number: {secondContractPageData.ContractNumber}</div>
											<div>Type: {secondContractPageData.contractType}</div>
										</div>
										<hr />
										<div className='row'>
											<span className="col-lg-6">Name of Vessel: <Link target="_blank" className="text-primary fw-bold" to={`/components/yachts/edit?id=${yacht.id}`}>{yacht.name}</Link></span>
											<span className='d-none'>Owner: {yacht.company}</span>
											<span className="col-lg-4">Length: {yacht.length}</span>
											<span className="col-lg-2">Flag: {yacht.flag}</span>
										</div>
										<div className='d-none'>
											Address:<br />
											<span>
												{yacht.address1}<br />
												{yacht.city} {yacht.zipCode}, {yacht.country}
											</span>
										</div>
										<div className='row'>
											<span className="col-lg-6">Client: <Link target="_blank" className="text-primary fw-bold" to={`/components/clients/edit?id=${clientData.id}`}>{clientData.name}</Link></span>
											<span className="col-lg-4">External broker: <Link target="_blank" className="text-primary fw-bold" to={`/components/externalbrokers/edit?id=${supplierData.id}`}>{supplierData.name}</Link></span>
											<span className="col-lg-2">KYC: <span className={(!charterData.KYC ? "text-danger" : "text-success") + " fw-bold"}>{charterData.KYC ? "YES" : "NO"}</span></span>
										</div>
										<div className='row'>
											<span className="col-lg-6">Period: {moment(charterData.startDate).format("DD MMMM YYYY hh:mm")}&ndash;{moment(charterData.endDate).format("DD MMMM YYYY hh:mm")}</span>
											<span className="col-lg-4">From: {charterData.placeFrom} to {charterData.placeTo}</span>
											<span className="col-lg-2">VAT Rep: <span className={(!secondContractPageData.vatRep ? "text-danger" : "text-success") + " fw-bold"}>{secondContractPageData.vatRep ? "YES" : "NO"}</span></span>
										</div>
										<hr />
										<div>
											<div className='row mx-0 text-center'>
												<span className='col px-2 ps-0'>Charter fee: <br />{formatter.format(charterFeeData.charterFeeAmount)} </span>
												<span className='col px-2'>VAT: <br />{formatter.format(charterFeeData.vat)}</span>
												<span className='col px-2'>APA: <br />{formatter.format(charterFeeData.apa)}</span>
												<span className='col px-2'>Delivery fees: <br />{formatter.format(charterFeeData.deliveryFeeAmount)}</span>
												<span className='col px-2'><span style={{ whiteSpace: "nowrap" }}>Security deposit:</span> <br />{formatter.format(charterFeeData.deposit)}</span>
												<span className='col px-2 d-xl-block d-md-none fw-bold'>Total: <br />{formatter.format(charterFeeTotal)}</span>

											</div>
											<div className='row mt-3 mx-0 justify-content-end'>
												<div className='col d-flex justify-content-end d-md-block d-xl-none'>
													<div className='text-end'>
														TOTAL: <span className='mx-3 fw-bold fs--1rem'>{formatter.format(charterFeeTotal)}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Card.Body>
							</Card>
						</div>
						<div id="editInfo" hidden={true}>
							<div className="row-sm-auto mb-3 g-3">
								<ContractDetailForm
									formData={secondContractPageData}
									setFormData={handleContractDataChange}
									handleFieldChange={handleFieldChange}
									handleSelectChange={handleSelectChange}
									handleYachtDetails={handleYachtDetails}
									loadContractTypeOptions={loadContractTypeOptions}
									loadYachtOptions={loadYachtOptions}
									isCentralAgent2={isCentralAgent}
									validated={validated}
								/>
							</div>
							<div className="row-sm-auto mb-3 g-3">
								<Card>
									<Card.Header className="d-flex justify-content-between align-items-center">
										<h5 className="float-start">Charter details</h5>
										<span className="float-end d-flex">
											<BootstrapSwitchButton
												type='switch'
												id='KYC'
												name='KYC'
												onlabel='KYC'
												offlabel='KYC'
												onstyle="primary"
												offstyle="light"
												width="100"
												disabled={role != "Administrator"}
												checked={charterData.KYC}
												onChange={(e) => {
													setCharterData({ ...charterData, "KYC": e });
												}} />
										</span>
									</Card.Header>
									<Card.Body className="bg-light py-3">
										<Row>
											<Col lg={12}>
												<Row>
													<Form.Group as={Col} sm="auto" lg={4}>
														<AsyncSelect
															className="h-100 pb-2"
															getOptionLabel={option => { return `${option.name}` }}
															loadOptions={loadClientOptionsAsync}
															placeholder="Select client"
															isDisabled={role != "Administrator"}
															value={clientData}
															getOptionValue={option => option}
															defaultOptions
															onChange={handleClientDetails}>
														</AsyncSelect>
													</Form.Group>
													<Form.Group as={Col} lg={4}>
														<FloatingLabel
															controlId="placeFrom"
															label="Place from"
															className="mb-2"
														>
															<Form.Control
																type="text"
																value={charterData.placeFrom} //to change
																disabled={role != "Administrator"}
																onChange={handleCharterChange}
																placeholder='Place from'
															/>
														</FloatingLabel>
													</Form.Group>
													<Form.Group as={Col} lg={4}>
														<DatePicker
															selected={charterData.startDate}
															onSelect={(date) => startRef.current.setOpen(false)}
															onChange={(date) => setCharterData({ ...charterData, startDate: date })}
															disabled={role != "Administrator"}
															ref={startRef}
															formatWeekDay={day => day.slice(0, 3)}
															className='form-control datepickerHeight'
															showTimeSelect
															value={charterData.startDate}
															name="startDate"
															timeInputLabel="Time:"
															dateFormat="dd/MM/yyyy HH:mm"
															placeholderText="Start date"
															autoComplete="off"
														/>
													</Form.Group>
												</Row>
												<Row>
													<Form.Group as={Col} sm="auto" lg={4} className="mb-2">
														<AsyncSelect
															className="h-100 pb-2"
															getOptionLabel={option => { return `${option.name}` }}
															loadOptions={loadSupplierOptionsAsync}
															placeholder="Select supplier"
															isDisabled={role != "Administrator"}
															value={supplierData}
															getOptionValue={option => option}
															defaultOptions
															onChange={handleSupplierDetails}>
														</AsyncSelect>
													</Form.Group>
													<Form.Group as={Col} lg={4} className="mb-2">
														<FloatingLabel
															controlId="placeTo"
															label="Place to"
															className="mb-2"
														>
															<Form.Control
																type="text"
																value={charterData.placeTo}
																onChange={handleCharterChange}
																disabled={role != "Administrator"}
																placeholder="Place to"
															/>
														</FloatingLabel>
													</Form.Group>
													<Form.Group as={Col} lg={4} className="mb-2">
														<DatePicker
															id="endDatePicker"
															selected={charterData.endDate}
															ref={endRef}
															onSelect={(date) => endRef.current.setOpen(false)}
															onChange={(date) => { setCharterData({ ...charterData, endDate: date }) }}
															autoComplete="off"
															disabled={role != "Administrator"}
															formatWeekDay={day => day.slice(0, 3)}
															className='form-control datepickerHeight'
															value={charterData.endDate}
															closeOnScroll={true}
															showTimeSelect
															timeInputLabel='Time:'
															name="endDate"
															dateFormat="dd/MM/yyyy HH:mm"
															locale='es'
															placeholderText="End date"
														/>

													</Form.Group>
												</Row>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</div>
							<div className="row-sm-auto mb-3 g-3">
								<Card>
									<Card.Header className="d-flex justify-content-between align-items-center">
										<h5 className="float-start">Fees: <span className='text-danger'>{formatter.format(charterFeeTotal)}</span></h5>
										<div>
											<Form.Group>
												<Form.Select disabled={role != "Administrator"} className='form-select-sm' onChange={(e) => { handleCurrencyDetails(e.target.value); }} value={secondContractPageData.currency.id} >
													{loadCurrencyOptions()}
												</Form.Select>
											</Form.Group>
										</div>
									</Card.Header>
									<Card.Body className="bg-light">
										<Row>
											<Col lg={12}>
												<Row>
													<Form.Group as={Col} lg={3}>
														<FloatingLabel
															controlId="charterFeeAmount"
															label="Charter fee"
															className="mb-1"
														>
															<Form.Control
																type="number"
																value={charterFeeData.charterFeeAmount}
																onChange={(e) => { handleCharterFeeChange(e); }}
																name="charterFeeAmount"
																disabled={role != "Administrator"}
																placeholder="Charter fee"
																className="mb-1"
																onWheel={(e) => e.target.blur()}
															/>
														</FloatingLabel>
													</Form.Group>

													<Form.Group as={Col} lg={3}>
														<FloatingLabel
															controlId="vat"
															label="VAT"
															className="mb-1"
														>
															<Form.Control
																type="number"
																value={charterFeeData.vat}
																onChange={(e) => { handleCharterFeeChange(e); }}
																disabled={role != "Administrator"}
																placeholder="VAT"
																name="vat"
																onWheel={(e) => e.target.blur()}
															/>
														</FloatingLabel>
													</Form.Group>

													<Form.Group as={Col} lg={3}>
														<FloatingLabel
															controlId="apa"
															label="APA"
															className="mb-1"
														>
															<Form.Control
																type="number"
																disabled={role != "Administrator"}
																value={charterFeeData.apa} //to change
																onChange={(e) => { handleCharterFeeChange(e); }}
																placeholder="APA"
																name="apa"
																onWheel={(e) => e.target.blur()}
															/>
														</FloatingLabel>
													</Form.Group>

													<Form.Group as={Col} lg={3}>
														<FloatingLabel
															controlId="deliveryFeeAmount"
															label="Delivery fee"
															className="mb-1"
														>
															<Form.Control
																type="number"
																onWheel={(e) => e.target.blur()}
																disabled={role != "Administrator"}
																value={charterFeeData.deliveryFeeAmount}
																onChange={(e) => { handleCharterFeeChange(e); }}
																name="deliveryFeeAmount"
																placeholder="Delivery fee"
															/>
														</FloatingLabel>
													</Form.Group>

													<Form.Group as={Col} lg={3}>
														<FloatingLabel
															controlId="deposit"
															label="Security deposit"
															className="mb-1"
														>
															<Form.Control
																type="number"
																disabled={role != "Administrator"}
																onWheel={(e) => e.target.blur()}
																value={charterFeeData.deposit}
																onChange={(e) => { handleCharterFeeChange(e); }}
																name="deposit"
																placeholder="Security deposit"
															/>
														</FloatingLabel>
													</Form.Group>

													<Form.Group as={Col} lg={3}>
														<DatePicker
															selected={charterFeeData.VatDueDate}
															onChange={(date) => setCharterFeeData({ ...charterFeeData, VatDueDate: date })}
															formatWeekDay={day => day.slice(0, 3)}
															className='form-control datepickerHeight'
															disabled={role != "Administrator"}
															value={charterFeeData.VatDueDate}
															name="vatDueDate"
															placeholderText="VAT due date"
															dateFormat="dd/MM/yyyy"
															locale='es'
															autoComplete="off"
														/>
													</Form.Group>

													<Form.Group as={Col} lg={3}>
														<DatePicker
															selected={charterFeeData.ApaDueDate}
															onChange={(date) => setCharterFeeData({ ...charterFeeData, ApaDueDate: date })}
															formatWeekDay={day => day.slice(0, 3)}
															disabled={role != "Administrator"}
															value={charterFeeData.ApaDueDate}
															name="startDate"
															className="form-control datepickerHeight"
															placeholderText="APA due date"
															dateFormat="dd/MM/yyyy"
															locale='es'
															autoComplete="off"
														/>
													</Form.Group>

													{
														(isCentralAgent &&
															<Col sm={12} md={6} lg={3} className='mt-1 d-flex align-items-center'>
																<Form.Check
																	type='checkbox'
																	id='DirectToClient'
																	label='Direct from Client'
																	disabled={role != "Administrator"}
																	name='DirectToClient'
																	defaultChecked={secondContractPageData.DirectToClient}
																	value={secondContractPageData.DirectToClient}
																	onChange={(e) => { setSecondContractPageData({ ...secondContractPageData, DirectToClient: e.target.checked }) }}
																/>
															</Col>
														)
													}
												</Row>
												<Row>
												</Row>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</div>
						</div>
						<Row className="mb-3 g-3">
							<Col lg={12}>
								<div className="row-sm-auto mb-3 h-100">
									<Timeline
										title={"Incoming Payments"}
										objects={paymentIn}
										objectType={{ name: "Payment", subType: "Client", subTypeVal: "In" }}
										objectStyle={{ style: { border: '1px solid #41AF38' }, icon: 'arrow-right', variant: 'success' }}
										currency={secondContractPageData.currency}
										setObjects={setPaymentIn}
										emailList={emailList}
										YachtEmailList={YachtEmailList}
										setEmailList={setEmailList}
										controlZone={[{ name: "Total client", value: paymentSummary.totalClient.toFixed(2) }, { name: "To receive", value: paymentSummary.toReceiveClient ? paymentSummary.toReceiveClient.toFixed(2) : 0 }
										]}
										setPaymentUpdated={setPaymentUpdated}
										paymentKinds={paymentKinds}
										paymentTypes={paymentTypes}
										paymentRecipients={paymentRecipients}
										contractType={isCentralAgent}
										brokerEmail={brokerEmail}
										contractId={secondContractPageData.contractId}
									/>
								</div>
							</Col>
							<Col lg={12}>
								<div className="row-sm-auto mb-2 h-100">
									<Timeline
										title={"Outgoing Payments"}
										objects={paymentOut}
										objectType={{ name: "Payment", subType: "Owner", subTypeVal: "Out" }}
										objectStyle={{ style: { border: '1px solid #dd1f1f' }, icon: 'arrow-left', variant: 'danger' }}
										currency={secondContractPageData.currency}
										setObjects={setPaymentOut}
										paymentKinds={paymentKinds}
										paymentTypes={paymentTypes}
										paymentRecipients={paymentRecipients}
										emailList={emailList}
										setPaymentUpdated={setPaymentUpdated}
										YachtEmailList={YachtEmailList}
										setEmailList={setEmailList}
										contractType={isCentralAgent}
										brokerEmail={brokerEmail}
										controlZone={[{ name: "Total owner", value: paymentSummary.totalOwner.toFixed(2) }, { name: "Gross Comm", value: parseFloat(paymentSummary.grossComm).toFixed(2) }, { name: "Owner to pay", value: parseFloat(paymentSummary.ownerToPay).toFixed(2) }]}
										contractId={secondContractPageData.contractId}
									/>
								</div>
							</Col>
						</Row>
						{
							!role.includes("External") && (
								<>
									<Row className="mb-3 g-3">
										<Col lg={12}>
											<Card>
												<Card.Header>
													<div className="d-flex justify-content-between align-items-center">
														<h5>Commissions Details</h5>
														{
															(role == "Administrator" &&
																(<Link className='btn btn-sm btn-primary' state={{ InvoiceString: officeCommissionData.invoice, OfficeAmount: officeCommissionData.amount, OfficePercent: officeCommissionData.comm }} to={`/components/contracts/${searchParams.get("id")}/invoice`}>Preview office invoice</Link>))
														}
													</div>
												</Card.Header>
												<Card.Body className="bg-light">
													<Table responsive>
														<thead>
															<tr>
																<th scope="col"></th>
																<th scope="col">Comm</th>
																<th scope="col">Amount</th>
																<th scope="col">Tax</th>
																<th scope="col">Invoice</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td scope="col">
																	<AsyncSelect
																		placeholder='Select an office'
																		getOptionLabel={o => o.name ? o.name : (o.city + ', ' + o.country)}
																		onChange={handleOfficeDetails}
																		loadOptions={loadOfficeOptions}
																		getOptionValue={option => option}
																		defaultOptions
																		value={officeData}>
																	</AsyncSelect>
																</td>
																<td scope="col" style={{ width: "200px", display: "block" }}>
																	<InputGroup className='customInputGroupText w-100'>
																		<ToggleButton
																			id="isTaxIncludedOffice"
																			type="checkbox"
																			variant="outline-primary"
																			onChange={handleOfficeCommissionChange}
																			checked={officeCommissionData.isTaxIncluded}
																		>
																			Tax
																		</ToggleButton>
																		<Form.Control
																			type="number"
																			onWheel={(e) => e.target.blur()}
																			id="comm"
																			value={officeCommissionData.comm}
																			onChange={handleOfficeCommissionChange}
																			name="comm"
																			placeholder="Comm. %"
																		/>
																		<InputGroup.Text>%</InputGroup.Text>
																	</InputGroup>
																</td>
																<td scope="col">
																	{
																		(isClickComms.office ?
																			<Form.Control
																				id="amount"
																				name="officeCommAmount"
																				type='number'
																				onWheel={(e) => e.target.blur()}
																				onBlur={(e) => { setIsOnClickComms({ ...isClickComms, office: !isClickComms.office }) }}
																				value={officeCommissionData.amount}
																				onChange={handleOfficeCommissionChange}
																				placeholder="Comm. %"
																			/>
																			:
																			<span onClick={(e) => { handleCommAmountOnClick("office") }}>{formatter.format(officeCommissionData.amount)}</span>)
																	}
																</td>
																<td scope="col">
																	<span>{formatter.format(officeCommissionData.vat)}</span>
																</td>
																<td scope="col">
																	<Form.Control
																		type="text"
																		value={officeCommissionData.invoice}
																		onChange={(e) => setOfficeCommissionData({ ...officeCommissionData, invoice: e.target.value })}
																		placeholder="Invoice"
																		disabled={role != "Administrator"}
																		id="invoice"
																	/>
																</td>
															</tr>
															<tr>
																<td scope="col">
																	<AsyncSelect
																		getOptionLabel={option => option.person ? option.person.fullName : null}
																		getOptionValue={option => option}
																		onChange={handleBrokerDetails}
																		value={brokerData}
																		loadOptions={loadBrokersOptions}
																		defaultOptions
																		placeholder="Select a broker"
																		className="h-100 pb-2"
																	>
																	</AsyncSelect>
																</td>
																<td scope="col" style={{ width: "200px", display: "block" }}>
																	<InputGroup className='customInputGroupText w-100'>
																		<Form.Control
																			type="number"
																			onWheel={(e) => e.target.blur()}
																			id="comm"
																			value={mySeaBrokerCommissionData.comm}
																			onChange={handleMySeaBrokerCommissionChange}
																			placeholder="Comm. %"
																		/><InputGroup.Text>%</InputGroup.Text>
																	</InputGroup>
																</td>
																<td scope="col">
																	{
																		(isClickComms.broker ?
																			<Form.Control
																				id="amount"
																				name="brokerCommAmount"
																				lang='fr'
																				onWheel={(e) => e.target.blur()}
																				onBlur={(e) => { setIsOnClickComms({ ...isClickComms, broker: !isClickComms.broker }) }}
																				value={mySeaBrokerCommissionData.amount}
																				onChange={handleMySeaBrokerCommissionChange}
																				placeholder="Comm. %"
																			/>
																			:
																			<span onClick={(e) => { handleCommAmountOnClick("broker") }}>{formatter.format(mySeaBrokerCommissionData.amount)}</span>)
																	}
																</td>
																<td scope="col">

																</td>
																<td scope="col">
																	<Form.Control
																		type="text"
																		value={mySeaBrokerCommissionData.invoice}
																		onChange={(e) => setMySeaBrokerCommissionData({ ...mySeaBrokerCommissionData, invoice: e.target.value })}
																		placeholder="Invoice"
																		disabled={role != "Administrator"}
																		id="invoice"
																	/>
																</td>
															</tr>
															<tr>
																<td scope="col">
																	<Form.Group as={Col} sm="auto" lg={12}>
																		<Select
																			placeholder={inputSave || value.label || "Agent"} // when blurred & value == "" this shows
																			value="" // always show placeholder
																			inputValue={input} // allows you continue where you left off
																			onInputChange={setInput} // allows for actually typing
																			options={agents.data.map((obj, index) => ({ label: obj, value: index }))}
																			onChange={onSelect}
																			onMenuClose={() => setSave(input)} // saves the input before it's cleared
																			onFocus={() => {
																				setInput(inputSave); // keeps the state
																				setSave(""); // prevents keeping initial value as placeholder
																			}} // input will be cleared regardless
																			onKeyDown={(e) => {
																				if (e.keyCode === 13) {
																					e.target.blur();
																				}
																			}}
																			blurInputOnSelect // actually allows for ^^ to work
																			className="h-100 pb-2"
																		>
																		</Select>
																	</Form.Group>
																</td>
																<td scope="col" style={{ width: "200px", display: "block" }}>
																	<InputGroup className='customInputGroupText'>
																		<ToggleButton
																			id='isTaxIncludedAgent'
																			name="isTaxIncluded"
																			type="checkbox"
																			variant="outline-primary"
																			onChange={handleRetailBrokerCommissionChange}
																			checked={retailBrokerCommissionData.isTaxIncluded}

																		>
																			Tax
																		</ToggleButton>
																		<Form.Control
																			type="number"
																			onWheel={(e) => e.target.blur()}
																			id="comm"
																			value={retailBrokerCommissionData.comm}
																			onChange={handleRetailBrokerCommissionChange}
																			placeholder="Comm. %"
																		/><InputGroup.Text>%</InputGroup.Text>
																	</InputGroup>

																</td>
																<td scope="col">
																	{
																		(isClickComms.agent ?
																			<Form.Control
																				id="amount"
																				name="agentCommAmount"
																				lang="fr"
																				onWheel={(e) => e.target.blur()}
																				onBlur={(e) => { setIsOnClickComms({ ...isClickComms, agent: !isClickComms.agent }) }}
																				value={retailBrokerCommissionData.amount}
																				onChange={handleRetailBrokerCommissionChange}
																				placeholder="Comm. %"
																			/>
																			:
																			<span onClick={(e) => { handleCommAmountOnClick("agent") }}>{formatter.format(retailBrokerCommissionData.amount)}</span>)
																	}
																</td>
																<td scope="col">
																	<span>{formatter.format(retailBrokerCommissionData.vat)}</span>
																</td>
																<td>
																	<Form.Control
																		type="text"
																		value={retailBrokerCommissionData.invoice}
																		onChange={(e) => setRetailBrokerCommissionData({ ...retailBrokerCommissionData, invoice: e.target.value })}
																		placeholder="Invoice"
																		disabled={role != "Administrator"}
																		id="invoice"
																	/>
																</td>

															</tr>
															<tr>
																<td scope="col">
																	<Form.Control
																		type="text"
																		value={secondContractPageData.Intermediary}
																		onChange={handleIntermidiaryCommissionChange}
																		placeholder="Referral"
																		id="name"
																	/>
																</td>
																<td scope="col" style={{ width: "200px", display: "block" }}>
																	<InputGroup className='customInputGroupText w-100'>
																		<ToggleButton
																			id="IsTaxIncludedApp"
																			type="checkbox"
																			variant="outline-primary"
																			onChange={handleIntermidiaryCommissionChange}
																			checked={secondContractPageData.IntermediaryCommission.IsTaxIncluded}
																		>
																			Tax
																		</ToggleButton>
																		<Form.Control
																			onWheel={(e) => e.target.blur()}
																			id="Comm"
																			name="comm"
																			type='number'
																			value={secondContractPageData.IntermediaryCommission.Comm}
																			onChange={handleIntermidiaryCommissionChange}
																			placeholder="Comm. %"
																			required
																		/><InputGroup.Text>%</InputGroup.Text>
																	</InputGroup>

																</td>
																<td scope="col">
																	{
																		(isClickComms.intermediary ?
																			<Form.Control
																				id="amount"
																				name="intermediaryCommAmount"
																				type='number'
																				onWheel={(e) => e.target.blur()}
																				onBlur={(e) => { setIsOnClickComms({ ...isClickComms, intermediary: !isClickComms.intermediary }) }}
																				value={secondContractPageData.IntermediaryCommission.Amount}
																				onChange={handleIntermidiaryCommissionChange}
																				placeholder="Comm. %"
																			/>
																			:
																			<span onClick={(e) => { handleCommAmountOnClick("intermediary") }}>{formatter.format(secondContractPageData.IntermediaryCommission.Amount)}</span>)
																	}
																</td>
																<td scope="col">
																	<span>{formatter.format(secondContractPageData.IntermediaryCommission.VAT)}</span>
																</td>
																<td scope="col">
																	<Form.Control
																		type="text"
																		value={secondContractPageData.IntermediaryCommission.Invoice}
																		onChange={(e) => setSecondContractPageData({
																			...secondContractPageData,
																			IntermediaryCommission: {
																				...secondContractPageData.IntermediaryCommission,
																				Invoice: e.target.value
																			}
																		})}
																		placeholder="Invoice"
																		disabled={role != "Administrator"}
																		id="invoice"
																	/>
																</td>
															</tr>
															{
																(role == "Administrator" && (
																	<tr>
																		<td>
																			<FloatingLabel label="Advanced Comm">
																				<Form.Control
																					type="number"
																					onWheel={(e) => e.target.blur()}
																					value={brokerAdvancedCommission.brokerCommission}
																					onChange={handleBrokerAdvancedCommissionChange}
																					name="brokerCommission" />
																			</FloatingLabel>

																		</td>
																	</tr>
																))
															}
														</tbody>
													</Table>
												</Card.Body>
											</Card>
										</Col>
									</Row>
									<Row>
										<Col lg={12}>
											{
												(attachmentsData != null && (<UploadFormFiles
													attachmentsData={attachmentsData}
													setAttachmentsData={setAttachmentsData}
													attachmentKinds={attachmentKind}
													uploadTo={"contract-" + searchParams.get("id")}
												/>))
											}
										</Col>
									</Row>
								</>
							)
						}
						{
							role.includes("External") && (
								<>
									<Row>
										<Col lg={12}>
											{
												(attachmentsData != null && (<UploadFormFiles
													attachmentsData={attachmentsData}
													setAttachmentsData={setAttachmentsData}
													attachmentKinds={attachmentKind}
													disableModifications={true}
													uploadTo={"contract-" + searchParams.get("id")}
												/>))
											}
										</Col>
									</Row>
								</>
							)
						}
					</Col>
					<Col lg={4}>
						{
							!role.includes("External") && (<>
								<Col lg={12} className='mb-3 g-3'>
									<NotificationsWidget show={true} dash={false} notifications={warnings} />
								</Col>
							</>)
						}
						<Col lg={12}>
							<div className="row-sm-auto mb-3 g-3">
								<Timeline
									title={"Installments"}
									objects={installments}
									objectType={{ name: "Installment", subType: null }}
									objectStyle={{ style: { border: '1px solid #2c7be5' }, icon: 'fa-solid fa-calendar-check', variant: 'primary' }}
									controlZone={[{ name: "Total Installment", value: paymentSummary.totalInstall }, { name: "To receive", value: paymentSummary.toReceive }]}
									currency={secondContractPageData.currency}
									paymentKinds={paymentKinds}
									paymentTypes={paymentTypes}
									paymentRecipients={paymentRecipients}
									setReadjustPayment={setReadjustPayment}
									setObjects={setInstallments}
									emailList={emailList}
									setEmailList={setEmailList}
									contractId={secondContractPageData.contractId}
								/>
							</div>
						</Col>
						{
							(role == "Administrator" && <Col >
								<Card className='h-100 mb-3 g-3'>
									<Card.Body className='px-0 py-1'>
										<table className="fs--1 mb-0 overflow-hidden table">
											<thead className="bg-100 text-800">
												<tr>
													<th className="text-nowrap"></th>
													<th className="text-nowrap text-end">Amount</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className="text-truncate fw-bold">Installment Total</td>
													<td className="text-truncate text-end">{formatter.format(paymentSummary.toReceive)}</td>
												</tr>
												<tr className="bg-100 text-800 bg-success">
													<th className="text-nowrap text-white">To receive</th>
													<th className="text-nowrap text-end"></th>
												</tr>
												<tr>
													<td className="text-truncate fw-bold">Installment 1</td>
													<td className="text-truncate text-end">{formatter.format(paymentSummary.toRInstall1)}</td>
												</tr>
												<tr>
													<td className="text-truncate fw-bold">Installment 2</td>
													<td className="text-truncate text-end">{formatter.format(paymentSummary.toRInstall2)}</td>
												</tr>
												<tr>
													<td className="text-truncate fw-bold">Installment 3</td>
													<td className="text-truncate text-end">{formatter.format(paymentSummary.toRInstall3)}</td>
												</tr>
												<tr className="bg-100 text-800 bg-danger">
													<th className="text-nowrap text-white">To pay</th>
													<th className="text-nowrap text-end">
													</th>
												</tr>
												{
													(isCentralAgent &&
														<tr>
															<td className="text-truncate fw-bold">VAT</td>
															<td className="text-truncate text-end">{formatter.format(paymentSummary.toPVat)}</td>
														</tr>
													)
												}

												<tr>
													<td className="text-truncate fw-bold">Installment 1</td>
													<td className="text-truncate text-end">{formatter.format(paymentSummary.toPInstall1)}</td>
												</tr>
												<tr>
													<td className="text-truncate fw-bold">Installment 2</td>
													<td className="text-truncate text-end">{formatter.format(paymentSummary.toPInstall2)}</td>
												</tr>
												<tr>
													<td className="text-truncate fw-bold">Installment 3</td>
													<td className="text-truncate text-end">{formatter.format(paymentSummary.toPInstall3)}</td>
												</tr>
												<tr>
													<td className="text-truncate fw-bold">Comm. Broker to Pay</td>
													<td className="text-truncate text-end">{formatter.format(brokerAdvancedCommission.commBrokerToPay)}</td>
												</tr>
												<tr>
													<td className="text-truncate fw-bold">Comm. Net MySea</td>
													<td className="text-truncate text-end">{formatter.format(brokerAdvancedCommission.commNetMySea)}</td>
												</tr>
											</tbody>
										</table>
									</Card.Body>
								</Card>
							</Col>
							)
						}

						<Col lg={12} className='my-2'>
							<Card>
								<Card.Header className="bg-white">
									<h5>Notes</h5>
								</Card.Header>
								<Card.Body className="bg-light">
									<Form.Group as={Col}>
										<Form.Control
											style={{ minHeight: "150px" }}
											type="text"
											as="textarea"
											disabled={role != "Administrator"}
											rows={5}
											value={secondContractPageData.notes}
											onChange={handleFieldChange}
											id="notes"
										/>
									</Form.Group>
								</Card.Body>
							</Card>
						</Col>
					</Col>
				</Row>
				<Modal
					show={show}
					onHide={() => { setShow(false); }}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"

				>
					<Modal.Header
						closeButton
						className="bg-light px-card border-bottom-0"
					>
						<Modal.Title>
							<h5>Delete contract</h5>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h5 className='text-center'>
							Are you sure you want to delete this contract?

						</h5>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary'>
							Cancel
						</Button>
						<Button variant='danger' onClick={handleDelete}>
							Delete
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		)
	} else
		return (
			<div className="d-flex flex-column min-vh-100 justify-content-center align-items-center"><BeatLoader color={"#9B9B9B"} size={25} /></div>
		)
}

export default EditContractSecondPageForm;
