import UserForm from 'components/users/UserForm';


const CreateUserForm = () => {
     return (
        <>
             <UserForm isExternalUser={true}/>
        </>
    );
};

export default CreateUserForm;

