import axiosInstance from 'helpers/axiosInstance';
import IconButton from 'components/common/IconButton';
import fr from 'date-fns/locale/fr';
import React, { useEffect, useState, useRef } from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import DatePicker, { registerLocale } from 'react-datepicker';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { getUserRole } from 'helpers/utils';

const SelectPaymentOrInstall = (props) => {
    registerLocale('fr', fr);
    const [modalShow, setModalShow] = useState(false);
    const [content, setContent] = useState('');
    const [width, setWidth] = useState(0);
    const ref = useRef();
    const [paymentKinds, setPaymentKinds] = useState({ data: [] });
    const [paymentTypes, setPaymentTypes] = useState({ data: [] });
    const [paymentRecipients, setPaymentRecepients] = useState({ data: [] });
    const [newObject, setNewObject] = useState({ Amount: 0, DueDate: null });
    const [type, setType] = useState(-1);
    const [value, setValue] = useState(null);
    const [editKindID, SetEditKindID] = useState(-1);
    const [initState, setInitState] = useState(false);
    const [Add, setAdd] = useState(false);
    const [emailtmpExclude, setExcludeEmail] = useState([]);
    const role = getUserRole();
    let init = false;


    useEffect(() => {
        if (props.paymentTypes && props.paymentKinds) {
            {
                setPaymentKinds(o => ({
                    data: props.paymentKinds.data
                }));

                setPaymentTypes({ data: props.paymentTypes.data })
                setInitState(true);
            }
            if (props.editKind) {
                let tempPaymentKind = (paymentKinds.data.find(x => x.name == props.editKind));
                SetEditKindID(tempPaymentKind.id);
                setNewObject({ ...newObject, Amount: props.editAmount, DueDate: props.editDueDate, paymentKind: tempPaymentKind })
            }
            setWidth(ref.offsetWidth);

        }

    }, [props.editKind, content, props.paymentTypes, props.paymentKinds]);

    useEffect(() => {
        if (props.paymentRecipients) {
            setPaymentRecepients(o => ({
                data: props.paymentRecipients.data
            }));
        }
    }, [props.paymentRecipients])

    useEffect(() => {
        if (props.edit) {
            setValue(props.value);
            let paymentKind = (paymentKinds.data.find(x => x.name == props.editKind));
            setNewObject({ ...newObject, paymentKind: paymentKind });
        }
    }, [props.value, props.edit]);

    const changeHandler = evt => {
        setContent(evt.target.value);
    };

    const handlePaymentKindDetails = (value) => {

        let val = Number(value);
        let type = paymentTypes.data.find(x => x.name == props.objectType.subTypeVal);
        setNewObject({ ...newObject, paymentKind: { id: value, name: paymentKinds.data[val].name }, contractPaymentType: type });

    }

    const getAllEmails = () => {
        let allEmails = props.YachtEmailList.data.concat(props.emailList.data);
        let result = [];
        
        let type = props.objectType.subTypeVal == "In" ? 0 : 1;
        result = result.concat(allEmails.filter(obj => { return obj.Type === type || obj.Type === 2 }));
        if (props.brokerEmail) {
            console.log("before", result);
            result = result.filter(function (ar) { console.log(ar.Email," = ", props.brokerEmail," ", ar.Email === props.brokerEmail.trim()); return ar.Email !== props.brokerEmail.trim() });
            console.log("after", result);
            result.push({ Email: props.brokerEmail, Type: 2 });
        }
        result = result.filter(ar => !emailtmpExclude.find(rm => (rm.Email === ar.Email && ar.Type === rm.Type)))
        return result;
        
    }

    const handleResendEmail = async (CreatedPID) => {
        let data = props.objects.data;
        let toSetPaymentId = CreatedPID;
        let toSetPaymentKind = "";
        if (props.edit) {
            toSetPaymentId = data[props.editId].Id;
            toSetPaymentKind = paymentKinds.data[editKindID].name;
            if (value["AmountReceived"] == null || value["PaymentDate"] == null || value["Kind"] == null) {
                toast.error(`Error sending email. Please make sure that you entered all the fields correctly.`, {
                    theme: 'colored'
                });
                return;
            }
        } else {
            if (value["AmountToPay"] == null || value["DueDate"] == null || value["Kind"] == null) {
                toast.error(`Error sending email. Please make sure that you entered all the fields correctly.`, {
                    theme: 'colored'
                });
                return;
            }
            toSetPaymentKind = newObject.paymentKind.name;
        }

        let emails = getAllEmails();

        let recipients = emails.map(o => o.Email);

        const formData = {
            "Id": value["Id"],
            "PaymentDate": value["PaymentDate"],
            "Amount": value["AmountReceived"],
            "TransactionType": props.type,
            "PaymentKind": toSetPaymentKind,
            "ContractId": value["ContractId"],
            "PaymentTo": value["PaymentRecipient"] ? value["PaymentRecipient"] : "",
            "Recipients": recipients,
            "currency": props.currency.name,
            "PaymentId": toSetPaymentId,
            "Note": value["Note"]
        }
        try {
            let res = await axiosInstance.post(`${window.location.origin}/emails/PaymentNotification`, formData);
            if (res.status === 200) {
                toast.success(`Email sent successfully.`, {
                    theme: 'colored'
                });
                value["EmailSentDate"] = new Date();
            }
            else {
                toast.error(`There was an error sending the email.`, {
                    theme: 'colored'
                });
            }
        } catch {
            toast.error(`There was an error sending the email.`, {
                theme: 'colored'
            });
        }
    }

    const handleAddObj = async (sendemail) => {
        let err = true;
        let data = props.objects.data;
        if (props.edit) {
            if (props.objectType.name == "Payment")
                value.Kind = paymentKinds.data.find(x => x.id == editKindID).name;
            value.Emails = props.objectType.name == "Payment" ? getAllEmails().filter(o => !o.IsYacht && o.Email != props.brokerEmail) : null;
            try {
                
                if (value.dueDate) {
                    console.log("old date", value.dueDate);
                    value.dueDate = new Date(value.dueDate.getTime() - (value.dueDate.getTimezoneOffset() * 60000))
                    console.log("new date", value.dueDate);
                    console.log("value",value);
                }
                else { value.dueDate = null; }

                if (value.PaymentDate) {
                    console.log("old payment date", value.PaymentDate);
                    value.PaymentDate = new Date(value.PaymentDate.getTime() - value.PaymentDate.getTimezoneOffset() * 60000);
                    console.log("new payment date", value.PaymentDate);
                }
                else { value.PaymentDate = null; }
                let res = await axiosInstance.put(`${window.location.origin}/${props.objectType.name.toLowerCase()}s`, value);
                if (res.status === 200) {
                    toast.success(`${props.objectType.name} modified successfully.`, {
                        theme: 'colored'
                    });
                    if (sendemail) {
                        await handleResendEmail(res.data.Id);
                    }
                    data[props.editId] = value;
                    err = false;
                }
                else {
                    toast.error(`There was an error modifying the ${props.objectType.name.toLowerCase()}.`, {
                        theme: 'colored'
                    });
                }
            }
            catch (err) {
                console.error(err);
                toast.error(`Error modifying the ${props.objectType.name.toLowerCase()}.`, {
                    theme: 'colored'
                });
            }
        } else {
            if (props.objectType.name == "Payment") {
                value["PaymentKindName"] = newObject.paymentKind.name;
                value["Kind"] = newObject.paymentKind.name;
                value["Type"] = props.objectType.subTypeVal;
            }
            value["ContractId"] = props.contractId;

            try {
                let res = await axiosInstance.post(`${window.location.origin}/${props.objectType.name.toLowerCase()}s`, value);
                if (res.status === 200) {
                    toast.success(`${props.objectType.name} created successfully.`, {
                        theme: 'colored'
                    });
                    if (sendemail) {
                        await handleResendEmail(res.data.Id);
                    }
                    value.Id = res.data.Id;
                    data.push(value);
                    err = false;
                }
                else {
                    toast.error(`There was an error creating the ${props.objectType.name.toLowerCase()}.`, {
                        theme: 'colored'
                    });
                }
            }
            catch (err) {
                toast.error(`Error creating the ${props.objectType.name.toLowerCase()}.`, {
                    theme: 'colored'
                });
            }

        }
        if (!err) {
            props.setObjects({ data: data });
            setNewObject({ Amount: 0 });
            props.setToShow(false);
            if (props.objectType.name == "Payment") {
                props.setPaymentUpdated(true);
            } else {
                props.setReadjustPayment(true);
            }
        }
    }

    const handleDelete = async () => {
        let data = props.objects.data;
        try {
            let obj = data[props.editId];
            let res = await axiosInstance.delete(`${window.location.origin}/${props.objectType.name.toLowerCase()}s/${obj.Id}`);
            if (res.status === 204) {
                toast.success(`${props.objectType.name} deleted successfully.`, {
                    theme: 'colored'
                });
                data.splice(props.editId, 1);
                props.setObjects({ data: data });
                setNewValue("Payment");
                setNewObject({ Amount: 0 });
                setModalShow(false);
                props.setToShow(false);
            }
            else {
                toast.error(`There was an error modifying the ${props.objectType.name.toLowerCase()}.`, {
                    theme: 'colored'
                });
            }

        }
        catch (err) {
            toast.error(`Error deleting the ${props.objectType.name.toLowerCase()}.`, {
                theme: 'colored'
            });
        }
    }




    // Partials
    const loadPaymentKindOptions = () => {
        if (paymentKinds.data) {

            return (
                <>
					<option value={-1} key={-1}>Select payment type</option>
                    {
                        paymentKinds.data.map(payment => (<option value={payment.id} key={payment.id} >
                            {`${payment.name}`}
                        </option>))
                    }
                </>
            );

        }
    }

    const loadPaymentRecepientOptions = () => {
        if (paymentRecipients.data) {

            return (
                <>
                    <option value={-1} key={-1}>{props.objectType.subTypeVal == "In" ? "Select Payment From" : "Select Payment To"}</option>
                    {
                        paymentRecipients.data.map(payment => (<option value={payment.name} key={payment.id} >
                            {`${payment.name}`}
                        </option>))
                    }
                </>
            );

        }
    }

    const loadEmailList = () => {
        let allMails = getAllEmails();
        let result = allMails.filter((v, i, a) => a.findIndex(v2 => (v2.Email === v.Email)) === i);
        if (!Add || result.length == 0)
            return (<SoftBadge pill bg='secondary' style={{ lineHeight: 1 }}>No email addresses found. </SoftBadge>);
        return (
            <>
                { 
                    result.reverse().map(item => (
                        <SoftBadge pill bg='primary' className='me-2'>
                            {`${item.Email ? item.Email : item}`}
                            {/*{(props.emailList.data.some(e => e.Email === item.Email && e.Type == item.Type)) && role == "Administrator" ?*/}
                                <FontAwesomeIcon color='red' onClick={() => { handleEmailDelete(item); }} style={{ paddingLeft: '5px', cursor: 'pointer' }} icon="fa-solid fa-xmark" />
                            {/*: null}*/}
                        </SoftBadge>
                    ))
                }
            </>
        );
        // if (props.contractType || props.type == "Outgoing Payments") {
        //     if (props.type == "Outgoing Payments") {
        //         if (props.YachtEmailList.data.concat(props.emailList.data).length > 0) {
        //             let emailsFromYachtOut = props.YachtEmailList.data.filter(obj => { return obj.Type === 2 });
        //             if (props.brokerEmail)
        //                 emailsFromYachtOut.push({ Email: props.brokerEmail, Type: 2 })
        //             console.log(props.emailList)
        //             return (
        //                 <>
        //                     {
        //                         emailsFromYachtOut.concat(props.emailList.data).slice(0).reverse().map(item => (<SoftBadge pill bg='primary' className='me-2'>
        //                             {`${item.Email ? item.Email : item}`}
        //                             {!(emailsFromYachtOut.some(e => e.Email === item.Email)) && role == "Administrator" ?
        //                                 <FontAwesomeIcon color='red' onClick={() => { handleEmailDelete(item.Email); }} style={{ paddingLeft: '5px', cursor: 'pointer' }} icon="fa-solid fa-xmark" />
        //                                 : null}
        //                         </SoftBadge>
        //                         ))
        //                     }
        //                 </>
        //             );

        //         }
        //     }
        //     if (props.type == "Incoming Payments") {
        //         if (props.YachtEmailList.data.concat(props.emailList.data).length > 0) {
        //             let emailsFromYachtIn = props.YachtEmailList.data.filter(obj => { return obj.Type === 1 });
        //             if (props.brokerEmail)
        //                 emailsFromYachtIn.push({ Email: props.brokerEmail, Type: 1 })
        //             console.log("tets", props.emailList, emailsFromYachtIn)

        //             return (
        //                 <>
        //                     {
        //                         emailsFromYachtIn.concat(props.emailList.data).slice(0).reverse().map(item => (<SoftBadge pill bg='primary' className='me-2'>
        //                             {`${item.Email ? item.Email : item}`}
        //                             {!(emailsFromYachtIn.some(e => e.Email === item.Email)) && role == "Administrator" ?
        //                                 <FontAwesomeIcon color='red' onClick={() => { handleEmailDelete(item.Email); }} style={{ paddingLeft: '5px', cursor: 'pointer' }} icon="fa-solid fa-xmark" />
        //                                 : null}
        //                         </SoftBadge>
        //                         ))
        //                     }
        //                 </>
        //             );

        //         }
        //     }
        // }
    }
    const handleEmailDelete = (emailToDelete) => {
        let data = props.emailList.data;
        console.log(emailToDelete);
        var objIndex = data.findIndex((email => email.Email == emailToDelete.email ? emailToDelete.email : emailToDelete.Email));
        if (emailToDelete.IsYacht || emailToDelete.Email == props.brokerEmail) {
            setExcludeEmail(oldArray => [...oldArray, { Email: emailToDelete.Email, Type: emailToDelete.Type }]);
        } else {
            data.splice(objIndex, 1);
            props.setEmailList(
                {
                    data: data
                });
        }

        
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (/\S+@\S+\.\S+/.test(event.target.value)) {
                let data = props.emailList.data;
                if (!data.includes(event.target.value)) {
                    let type = props.objectType.subTypeVal == "In" ? 0 : 1;
                    data.push({ Email: event.target.value, Type: type });
                    props.setEmailList(
                        {
                            data: data
                        });
                    setAdd(false);
                }
                else {
                    toast.error(`That email is already in the list.`, {
                        theme: 'colored'
                    });
                }
            }
            else {
                toast.error(`Please enter a valid email.`, {
                    theme: 'colored'
                });
            }
        }
    }

    const handleModalClose = () => {
        setAdd(false);
        if (props.edit) props.setToShow(false);
        else setModalShow(false);
    }

    const setNewValue = (type) => {
        props.setEdit(false);
        SetEditKindID(-1);
        switch (type) {
            case "Installment": {
                setValue({
                    "Amount": 0,
                    "DueDate": (new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
                    "dueDate": (new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
                });
                break;
            }
            case "Payment": {
                setValue({
                    "Kind": "Installment",
                    "PaymentKindName": "Installment",
                    "AmountToPay": 0,
                    "AmountReceived": 0,
                    "PaymentDate": null,
                    "DueDate": null,
                    "Type": props.objectType.subTypeVal,
                    "Note": "",
                    "EmailSentDate": null,
                    "ReadyToPay": false,
                    "dueDate": null
                });
                break;
            }
        }
    }
    return (
        <>
            <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={() => { setNewValue(props.objectType.name); setType(-1); setModalShow(true); props.setToShow(true); }}
            >
                <span className="d-none d-sm-inline-block ms-1" >New</span>
            </IconButton>

            <Modal
                show={props.toShow}
                onHide={() => { props.setToShow(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton><h4>{props.type == "Installments" ? props.edit ? "Edit an installment" : "Add a new installment" : props.type == "Incoming Payments" ? props.edit ? "Edit an incoming payment" : "Add a new incoming payment" : props.type == "Outgoing Payments" ? props.edit ? "Edit an outgoing payment" : "Add a new outgoing payment" : "How did you get here ?"}</h4></Modal.Header>
                <Modal.Body >
                    <div className='row mx-0'>
                        {
                            (value != null) && <>
                                <Form.Group className="mt-3 col-12 d-none">
                                    <Form.Label>Transaction type</Form.Label>
                                    <Form.Select disabled onChange={e => { setType(e.target.value); }}>
                                        <option value={0} key={0}>Select transaction type</option>
                                        <option selected={props.type == "Installments" ? "1" : ""} value={1} disabled={props.type == "Incoming Payments"}>Installment</option>
                                        <option selected={props.type == "Incoming Payments" ? "3" : ""} value={2}>Incoming Payment</option>
                                        <option selected={props.type == "Outgoing Payments" ? "2" : ""} value={3}>Outgoing Payment</option>
                                    </Form.Select>
                                </Form.Group>
                                {
                                    (props.objectType.name == "Payment" &&
                                        <Form.Group className="col-6">
                                            <Form.Label className="mb-2">Amount to {props.objectType.subTypeVal == "In" ? "Receive" : "Pay"}</Form.Label>
                                            <Form.Control
                                                onWheel={(e) => e.target.blur()}
                                                disabled={role != "Administrator"}
                                                value={value.AmountToPay}
                                                onChange={(e) => {
                                                    const rawValue = e.target.value.replace(/[^0-9.,]/g, ''); // Remove non-numeric, non-comma, non-dot characters
                                                    const numericValue = rawValue.replace(/,/g, '.'); // Replace commas with dots
                                                    // Only allow one dot or comma
                                                    if ((numericValue.match(/\./g) || []).length > 1 || (numericValue.match(/,/g) || []).length > 1) {
                                                        return;
                                                    }
                                                    setValue({ ...value, AmountToPay: numericValue });
                                                    props.SetEditAmount(numericValue);
                                                    setNewObject({ ...newObject, Amount: numericValue });
                                                }}
                                                name="amount"
                                            />
                                        </Form.Group>

                                    )
                                }
                                <Form.Group className="col-6">
                                    <Form.Label className="mb-2">
                                        Amount {props.objectType.name === "Payment" ?
                                            (props.objectType.subTypeVal === "In" ? "Received" : "Paid") : ""}
                                    </Form.Label>
                                    <Form.Control
                                        disabled={role !== "Administrator"}
                                        onWheel={(e) => e.target.blur()}
                                        value={props.objectType.name === "Payment" ? value.AmountReceived : value.Amount}
                                        onChange={(e) => {
                                            const rawValue = e.target.value.replace(/[^0-9.,]/g, ''); // Remove non-numeric, non-comma, non-dot characters
                                            const numericValue = rawValue.replace(/,/g, '.'); // Replace commas with dots

                                            // Only allow one dot or comma
                                            if ((numericValue.match(/\./g) || []).length > 1 || (numericValue.match(/,/g) || []).length > 1) {
                                                return;
                                            }
                                            setValue({ ...value, Amount: numericValue, AmountReceived: numericValue });
                                            props.SetEditAmount(numericValue);
                                            setNewObject({ ...newObject, Amount: numericValue });
                                        }}
                                        name="amount"
                                    />
                                </Form.Group>
                                {
                                    (props.objectType.name == "Payment" &&
                                        <>
                                            <Form.Group className="mt-3 col-6">
                                                <Form.Label className="mb-2">Due date</Form.Label>
                                                <DatePicker
                                                    autoComplete="off"
                                                    selected={value.DueDate}
                                                onChange={(date) => { console.log("ogdate",date,"datechange", new Date(date.getTime() - date.getTimezoneOffset() * 60000)); setValue({ ...value, DueDate: new Date(date.getTime() - date.getTimezoneOffset() * 60000), dueDate: new Date(date.getTime() - date.getTimezoneOffset() * 60000) }); setNewObject({ ...newObject, DueDate: new Date(date.getTime() - date.getTimezoneOffset() * 60000), dueDate: new Date(date.getTime() - date.getTimezoneOffset() * 60000) }) }}
                                                    formatWeekDay={day => day.slice(0, 3)}
                                                    className='form-control'
                                                    value={value.DueDate}
                                                    disabled={role != "Administrator"}
                                                    name="dueDate"
                                                format="dd/MM/yyyy"

                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mt-3 col-6">
                                                <Form.Label className="mb-2">Payment date</Form.Label>
                                                <DatePicker
                                                    disabled={role != "Administrator"}
                                                    autoComplete="off"
                                                onChange={(date) => {  setValue({ ...value, PaymentDate: date }); props.SetEditPaymentDate(date); setNewObject({ ...newObject, PaymentDate: date }) }}
                                                    formatWeekDay={day => day.slice(0, 3)}
                                                    className='form-control'
                                                    selected={value.PaymentDate}
                                                    name="PaymentDate"
                                                    format="dd/MM/yyyy"
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </Form.Group>
                                            <Form.Group className="mt-3 col-6">
                                                <Form.Label className="mb-2">Payment type</Form.Label>
                                                <Form.Select disabled={role != "Administrator"} onChange={e => { SetEditKindID(e.target.value); handlePaymentKindDetails(e.target.value); }} value={editKindID}>

                                                    {loadPaymentKindOptions()}

                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mt-3 col-6">
                                                <Form.Label className="mb-2">{props.objectType.subTypeVal == "In" ? "Payment From" : "Payment To"}</Form.Label>
                                                <Form.Select disabled={role != "Administrator"} value={value.PaymentRecipient} onChange={e => { setValue({ ...value, PaymentRecipient: e.target.value }); setNewObject({ ...newObject, PaymentRecipient: e.target.value }) }} >

                                                    {loadPaymentRecepientOptions()}

                                                </Form.Select>
                                            </Form.Group>
                                        </>)
                                }
                                {
                                    (props.objectType.name == "Installment" &&
                                        <>
                                            <Form.Group className="col-6">
                                                <Form.Label className="mb-2">Due date</Form.Label>
                                                <DatePicker
                                                    autoComplete="off"
                                                    disabled={role != "Administrator"}
                                                    selected={value.dueDate}
                                                    onChange={(date) => { setValue({ ...value, dueDate: date, DueDate: date }); props.SetEditDueDate(date); setNewObject({ ...newObject, DueDate: date.toISOString(), dueDate: date }) }}
                                                    formatWeekDay={day => day.slice(0, 3)}
                                                    className='form-control'
                                                    value={value.dueDate}
                                                    name="dueDate"
                                                    format="dd/MM/yyyy"
                                                />
                                            </Form.Group>
                                        </>
                                    )
                                }
                                {
                                    (props.objectType.name == "Payment" &&
                                        <>

                                            <Form.Group className='d-block w-100 my-2'>
                                                <Form.Label className="mb-2">Note</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    disabled={role != "Administrator"}
                                                    as="textarea"
                                                    rows={2}
                                                    value={value.Note}
                                                onChange={(e) => { setValue({ ...value, Note: e.target.value }); props.setEditNote(e.target.value === null ? "" : e.target.value); setNewObject({ ...newObject, Note: e.target.value === null ? "" : e.target.value }) }}
                                                    id="Note"
                                                />
                                            </Form.Group>
                                            <Form.Group className='d-block w-100 my-2'>
                                                <Form.Label style={{ marginRight: "10px" }} className="mb-2">Email Recipient List

                                            </Form.Label>
                                            {role == "Administrator" || role == "Broker" ?
                                                <a onClick={() => { setAdd(!Add); }} style={{ cursor: 'pointer' }}>
                                                    <SoftBadge pill bg='success' style={{ lineHeight: 1 }} className='ml-2'>
                                                        <FontAwesomeIcon icon="fa-solid fa-plus" size="xl" color="#5cb85c" className="fs-1" /> Add
                                                    </SoftBadge>
                                                </a> : null}

                                                <div id="emaillist">
                                                    {Add ? <SoftBadge ref={ref} style={{ lineHeight: 3, width: width, backgroundColor: "#c8cfd89c" }} backgroundcolor="#c8cfd89c" pill className='me-2'>
                                                        <input autoFocus id="emailInput" style={{ background: 'transparent', border: 'none', outline: 'none' }} type="email" name="name" onChange={changeHandler} onKeyDown={handleKeyDown} />

                                                        <BsArrowReturnLeft color='grey' size='12' />
                                                    </SoftBadge> : null}
                                                    {loadEmailList()}
                                                </div>
                                                <div className='mt-2' style={{ fontSize: "15px" }}>
                                                    {value.EmailSentDate != null ?
                                                        "Email notification for this payment was sent on " + new Date(value.EmailSentDate).toLocaleString()
                                                        : "Email notification has not been sent for this payment."
                                                    }

                                                </div>
                                            </Form.Group>


                                        </>)
                                }
                            </>
                        }
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Row className="w-100">

                        {
                            (role == "Administrator" && (<>
                                <Col className="justify-content-start">
                                    {props.edit ?
                                        <Button
                                            variant="danger"
                                            onClick={() => { handleDelete(); setNewObject({ paymentKind: {}, Amount: 0 }); }}
                                        >
                                            Delete
                                        </Button> : null
                                    }
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    {props.objectType.name == "Installment" ? null :
                                        <Button
                                            variant="primary"
                                            className="me-2"
                                            onClick={() => {
                                                handleAddObj(true);
                                            }
                                            }
                                            disabled={!value || (props.objectType.name == "Payment" ? !value.AmountToPay || !value.DueDate || !newObject.paymentKind : !value.Amount || !value.DueDate)}
                                        >
                                            {props.edit ? "Save" : "Add"} & send email

                                        </Button>}
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            handleAddObj(false);
                                        }
                                        }
                                        disabled={value == null || (props.objectType.name == "Payment" ? !value.AmountToPay || !value.DueDate || !newObject.paymentKind : !value.Amount || !value.dueDate)}
                                    >
                                        {props.edit ? "Save" : "Add"}

                                    </Button>

                                    {/*<Button onClick={() => { setModalShow(false); setNewObject({ paymentKind: {}, Amount: 0 }); }}>Cancel</Button>*/}
                                </Col>
                            </>))
                        }

                    </Row>
                </Modal.Footer>
            </Modal>

        </>
    );
}


export default SelectPaymentOrInstall;
