import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';


const TextFilter = ({ label, id, placeholder, value }) => {
    const [textValue, setTextValue] = useState(value || "");

    const handleFieldChange = e => {
        setTextValue(e.target.value);
    };


    return (
        <>
            <Form.Group>
                <Form.Label htmlFor={id}>{label}</Form.Label>
                <Form.Control type="text" id={id} name={id} value={textValue} placeholder={placeholder} onChange={handleFieldChange} />
            </Form.Group>
        </>
    );
}

TextFilter.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string
};

export default TextFilter;