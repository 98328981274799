import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import Background from 'components/common/Background';
import SoftBadge from 'components/common/SoftBadge';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';

const StatisticsCard = ({ stat, ...rest }) => {
	const {
		title,
		value,
		value2,
		decimal,
		suffix,
		suffix2,
		prefix,
		valueClassName,
		linkText,
		link,
		badgeText,
		badgeBg = "primary",
		image,
		className
	} = stat;
	return (
		<Card className={classNames(className, 'overflow-hidden')} {...rest}>
			<Background image={image} className="bg-card" />
			<Card.Header className='pt-3 pb-2 border-bottom'>
			<h6 className='mb-0'>
					{title}
					{badgeText && (
						<div>
						<SoftBadge bg={badgeBg} pill >
							{badgeText}
						</SoftBadge>
						</div>
					)}
				</h6>
			</Card.Header>
			<Card.Body className="position-relative pb-0 pt-2">
				<div style={{lineHeight: "1.2"}}
					className={classNames(
						valueClassName,
						'mb-2 fw-normal font-sans-serif'
					)}
				>
					<div>
					<CountUp
						className='fs-2'
						start={0}
						end={value}
						duration={1}
						suffix={suffix}
						prefix=""
						separator=" "
						decimals={decimal ? 2 : 0}
						decimal=","
					/>
					</div>
					<CountUp
						className="d-block fs-2"
						start={0}
						end={value2}
						duration={1}
						suffix={suffix2}
						prefix={prefix}
						separator=" "
						decimals={decimal ? 2 : 0}
						decimal=","
					/>
				</div>
			</Card.Body>
		</Card>
	);
};

StatisticsCard.propTypes = {
	stat: PropTypes.shape({
		title: PropTypes.string,
		value: PropTypes.number.isRequired,
		value2: PropTypes.number,
		decimal: PropTypes.bool,
		suffix: PropTypes.string.isRequired,
		suffix2: PropTypes.string,
		prefix: PropTypes.string.isRequired,
		valueClassName: PropTypes.string,
		linkText: PropTypes.string,
		link: PropTypes.string,
		badgeText: PropTypes.string,
		badgeBg: PropTypes.string,
		image: PropTypes.string,
		className: PropTypes.string
	})
};

export default StatisticsCard;
