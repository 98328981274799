import React, { useEffect} from 'react';
import LoginForm from 'components/authentication/LoginForm';

import AuthCardLayout from 'layouts/AuthCardLayout';

const Login = ({ setToken }) => {

  return (
    <AuthCardLayout>
      <h3>Account Login</h3>
      <LoginForm layout="card" hasLabel setToken={setToken} />
    </AuthCardLayout>
  );
};

export default Login;
