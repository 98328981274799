import React, { useState, useEffect, useCallback, createRef } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from 'helpers/axiosInstance';
import moment from 'moment';
import { cloneObject, getCurrentUTCTime, getUserRole } from 'helpers/utils';
import { ContractForm } from 'viewModel/DtoClasses';
import ContractFormBase from './ContractFormBase';

const CreateContractFirstPageForm = (props) => {
	const [formData, setFormData] = useState(new ContractForm());
	const [creationText, setCreationText] = useState("Save");
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [attachmentsData, setAttachmentsData] = useState({ data: [] });
	const CreateContract = async (event) => {
		setLoading(true);
		setCreationText("Creating contract...");
		const form = formData.contractDate != null;
		const formSecond = formData.fileName != "";
		const formThird = !(formData.yachtId == 0 || !formData.yachtId);
		const formFourth = (!formData.yacht?.ca && !(formData.charter.clientId == 0 || !formData.charter.clientId)) || (formData.yacht?.ca && formData.caClientName != null);
		const formFifth = formData.charter.placeFrom != "";
		const formSixth = formData.charter.placeTo != "";
		const formSeventh = formData.charter.startDate != null;
		const formEighth = formData.charter.endDate != null;

		var forms = document.querySelectorAll('form');
		Array.prototype.slice.call(forms)
			.forEach(function (form) {
				form.checkValidity();
				form.classList.add('was-validated');
			})

		if (form === false || formSecond === false || formThird === false || formFourth === false || formFifth === false
			|| formSixth === false || formSeventh === false || formEighth === false) {
			if (!formThird)
				document.getElementById("yachtAsyncSelector").lastChild.classList.add("border-danger");
			if (!formFourth && !formData.yacht?.ca)
				document.getElementById("clientAsyncSelector").lastChild.classList.add("border-danger");
		}
		else {
			document.getElementById("yachtAsyncSelector").lastChild.classList.remove("border-danger");
			if (!formData.yacht?.ca)
				document.getElementById("clientAsyncSelector").lastChild.classList.remove("border-danger");
			let postData = cloneObject(formData);
			postData.charter.endDate = moment(formData.charter.endDate).utcOffset(0, true).format("YYYY-MM-DDT") + moment(formData.charter.endTime).utcOffset(0, true).format("HH:mm:ss") + "Z";
			postData.charter.startDate = moment(formData.charter.startDate).utcOffset(0, true).format("YYYY-MM-DDT") + moment(formData.charter.startTime).utcOffset(0, true).format("HH:mm:ss") + "Z";
			postData.charter.apaDate = getCurrentUTCTime(postData.charter.apaDate);
			postData.charter.vatDate = getCurrentUTCTime(postData.charter.vatDate);
			postData.contractDate = getCurrentUTCTime(postData.contractDate);
			if (postData.installments.length > 0) {
				postData.installments.forEach(x => {
					x.dueDate = getCurrentUTCTime(x.dueDate);
				})
			}
			try {
				let res = await axiosInstance.post(`${window.location.origin}/contracts`, postData);
				if (res.data) {
					toast.success(`The contract has successfully been created.`, {
						theme: 'colored'
					});
					if (attachmentsData.data.length != 0) {
						setCreationText("Uploading files...");
						const formData = new FormData();
						for (let x = 0; x < attachmentsData.data.length; x++) {
							let obj = attachmentsData.data[x];
							formData.append("files[" + x + "].file", obj.theFile);
							formData.append("files[" + x + "].type", obj.type);
						}
						formData.append("uploadTo", "contract-" + res.data.Id);
						let test = await axiosInstance.post(`${window.location.origin}/uploadFiles/multiple`, formData);
						toast.success("Files uploaded", { theme: 'colored' });
					}
					navigate(`/components/contracts/editSecondPage?id=${res.data.Id}`);

				} else {
					toast.error(`${res.data.message}`, {
						theme: 'colored'
					});
				}


			} catch (e) {

				if (e.response) {
					console.log(e.response.data);
					if (e.response.data.some(v => v.includes("duplicate"))) {
						toast.error("A contract already exists with this name.", { theme: 'colored' });
					} else {
						toast.error("An error has occurred while creating the contract", { theme: 'colored' });
						e.response.data.forEach(msg => {
							toast.error(msg, { theme: 'colored' });
						})
					}
				}
				console.warn(e);
			}
		}
		setLoading(false);
		setCreationText("Save");
	};

	return (
		<ContractFormBase
			handleSubmit={CreateContract}
			formData={formData}
			setFormData={setFormData}
			creationText={creationText}
			loading={loading}
			attachmentsData={attachmentsData}
			setAttachmentsData={setAttachmentsData}
		/>
	)
};

export default CreateContractFirstPageForm;
