import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Modal, Spinner } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { reactBootstrapDocsUrl } from 'helpers/utils';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { Link } from 'react-router-dom';


const DeleteItemModal = (props) => {
	const [title, setTitle] = useState({ title: ` Deleting ${props.item}`})
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const message = `The ${props.item} will be deleted, continue?`;

	const handleDeleteModal = (e) => {
		e.preventDefault();
		props.function()
	}

	return (
		<>
			<Button
				variant='falcon-danger'
				onClick={ handleShow }
			>
				<Spinner animation="border" variant="danger" size="sm" hidden={true} className="delete-spinner" />
				Delete</Button>


			<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
				<Modal.Header>
					<Modal.Title>{title.title}</Modal.Title>
					<FalconCloseButton onClick={handleClose} />
				</Modal.Header>
				<Modal.Body>
					{message}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleDeleteModal}>Confirm</Button>
					<Button variant="secondary" onClick={handleClose}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}


export default DeleteItemModal;
