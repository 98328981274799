import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import avatar from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

  const ProfileDropdown= () => {
    return (
      <Dropdown navbar={true} as="li">
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          to="#"
          className="ps-2 p-0 nav-link"
        >
          <Avatar src={avatar} />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end py-2 text-primary">
          <div className="bg-white rounded-2 dark__bg-1000 text-primary">
            <Dropdown.Item as={Link} to="/components/users/settings" className='text-primary'>
              <FontAwesomeIcon icon="circle-user" className='pe-2'></FontAwesomeIcon>
              My profile
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/logout" className='text-primary'>
            <FontAwesomeIcon icon="arrow-right-from-bracket" className='pe-2'></FontAwesomeIcon>
              Logout
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
export default ProfileDropdown;
