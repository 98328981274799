import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Card, Col, Row, Form, Button, Spinner, FloatingLabel } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import FalconComponentCard from 'components/common/FalconComponentCard';
import IconButton from 'components/common/IconButton';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';



import { LoginViewModel } from '../../viewModel/loginView';
import { PersonViewModel } from '../../viewModel/personView';
import { OfficeViewModel } from '../../viewModel/officeView';
import { BrokerViewModel } from '../../viewModel/brokerView';
import axiosInstance from 'helpers/axiosInstance';
import UserDetail from './UserDetail';


const UserForm = (props) => {
	// var decoded = jwt_decode(localStorage.getItem('jwtAuth'));
	// let role = decoded['role'];
	let role = "Administrator";

	// State
	const [formData, setFormData] = useState({
		email: '',
		phoneNumber: '',
		firstName: '',
		lastName: '',
		fileRead: false,
		role: 'Administrator',
		isEnabled: true,
		id: '0',
		address1: '',
		address2: ''
	});

	const [commSection, setCommSection] = useState(false);
	const [officeSection, setOfficeSection] = useState(false);
	const [officeDetails, setOfficeDetails] = useState(false);
	const [fileReadPerm, setfileReadPerm] = useState(false);
	const [officeId, setOfficeId] = useState(0);
	const [offices, setOffices] = useState({ data: [] });
	const [officeData, setOfficeData] = useState(new OfficeViewModel('', ''));
	const [brokerData, setBrokerData] = useState(new BrokerViewModel(formData, offices));
	const [companySection, setCompanySection] = useState(false);

	useEffect(() => {
		if (props.isBroker) {
			setCommSection(true);
			setOfficeSection(true);
			setFormData({ ...formData, role: "broker" });
		} else {
			setFormData({ ...formData, role: "externalUser" });
		};
	}, []);

	useEffect(() => {
		console.log("data", officeData);
		console.log("iban", officeData.iban);
	}, [officeData])

	const brokerRadioButton = () => {

		if (props.isBroker) {
			return (
				<Form.Check
					type='radio'
					id='broker'
					label='MySea Broker'
					onChange={(e) => {
						setFormData({ ...formData, "role": e.target.id });
						setCommSection(true);
						setOfficeSection(true);
						setCompanySection(false);
						setOfficeDetails(true);
					}}
					name='radio'
					defaultChecked
				/>
			);
		} else {
			return (
				<Form.Check
					type='radio'
					id='broker'
					label='MySea Broker'
					onChange={(e) => {
						setFormData({ ...formData, "role": e.target.id });
						setCommSection(true);
						setOfficeSection(true);
						setCompanySection(false);
					}}
					name='radio'
				/>
			);
		}
	}

	const externalUserRadioButton = () => {
		return (
			<Form.Check
				type='radio'
				id='externalUser'
				label='External User'
				name='radio'
				onChange={(e) => {
					setFormData({ ...formData, "role": e.target.id })
					setCommSection(false);
					setOfficeSection(false);
					setOfficeDetails(false);
					setCompanySection(false);
				}}
				defaultChecked={!props.isBroker}
			/>
		);
	}

	const radioButton = () => {

		return (
			<>
				<h6><strong>User Role</strong></h6>
				<Form.Check
					type='radio'
					id='administrator'
					label='Administrator'
					onChange={(e) => {
						setFormData({ ...formData, "role": e.target.id })
						setCommSection(false);
						setOfficeSection(false);
						setOfficeDetails(false);
						setCompanySection(false);
					}}
					name='radio'
				/>

				{
					brokerRadioButton()
				}
				{
					externalUserRadioButton()
				}
			</>
		);
	}

	const companyInput = () => {
		if (companySection) {
			return (<>
				<Row className="mb-3 g-3">
					<Col lg={12}>
						<Card>
							<Card.Header >
								<h5>Company</h5>
							</Card.Header>
							<Card.Body className="bg-light">
								<Form validated={validated} onSubmit={handleSubmit} ref={ref => form = ref} className="third-form">
									<Row className="g-3">
										<FloatingLabel as={Col}
											controlId="company"
											label="Company"
										>
											<Form.Control
												type="text"
												value={brokerData.company}
												onChange={handleFieldBrokerChange}
												placeholder="Company name"
												required
											/>
											<Form.Control.Feedback type="invalid">
												Please enter a company name.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Row>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</>);
		} else return null;
	}


	const loadOfficeOptions = ([offices, setOffices]) => {

		if (offices.data.length === 0) {
			axiosInstance.get(`${window.location.origin}/offices`)
				.then((res) => {
					setOffices(o => ({
						data: res.data.data
					}));
				});
		}

		return (
			<>
				<option value="">Select office from created earlier</option>
				{
					offices.data.map(office => (<option value={office.id} key={office.id}>
						{office.name ? office.name : (office.city + ', ' + office.country)}
					</option>))
				}
			</>
		);
	}

	/*{*//*<Commissions*//* } Account Settings*/
	const commissionSection = () => {
		if (commSection) {
			return (
				<>
					<Row className="mb-3 g-3">
						<Col lg={12}>
							<Card>
								<Card.Header >
									<h5>Broker's Commission</h5>
								</Card.Header>
								<Card.Body className="bg-light">
									<Form validated={validated} onSubmit={handleSubmit} ref={ref => form = ref} className="second-form">
										<Row className="g-3">
											<FloatingLabel as={Col} lg={6}
												controlId="cacommission"
												label="CA commission %"
											>
												<Form.Control
													type="number"
													onWheel={(e) => e.target.blur()}
													step=".01"
													value={brokerData.cacommission}
													onChange={handleFieldBrokerChange}
													placeholder="CA commission %"
													required
												/>
												<Form.Control.Feedback type="invalid">
													Please enter a CA commission.
												</Form.Control.Feedback>
											</FloatingLabel>
											<FloatingLabel as={Col} lg={6}
												controlId="retailcommission"
												label="Retail commission %"
											>
												<Form.Control
													type="number"
													onWheel={(e) => e.target.blur()}
													step=".01"
													value={brokerData.retailcommission}
													onChange={handleFieldBrokerChange}
													placeholder="Retail commission %"
													required
												/>
												<Form.Control.Feedback type="invalid">
													Please enter a retail commission.
												</Form.Control.Feedback>
											</FloatingLabel>
										</Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</>
			);
		} else return null;
	};

	{/*<Office*/ }
	const officeSelectSection = () => {
		if (officeSection) {
			return (<>
				<Row className="mb-3 g-3">
					<Form validated={validated} onSubmit={handleSubmit} ref={ref => form = ref} className="fifth-form">
						<Col lg={12}>
							<Card>
								<Card.Header className="d-inline">
									<h5 className="float-start">Office</h5>
								</Card.Header>
								<Card.Body className="bg-light">
									<Row className="g-3">
										<FloatingLabel controlId="floatingSelectGrid" label="Select office">
											<Form.Select required aria-label="Select office" value={officeId}
												onChange={e => { setOfficeDetails(true); setOfficeId(e.target.value); handleOfficeDetails(e.target.value); }}>
												{loadOfficeOptions([offices, setOffices])}
											</Form.Select>
										</FloatingLabel>
										<Form.Control.Feedback type="invalid">
											Please select an office.
										</Form.Control.Feedback>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Form>
				</Row>
			</>);
		}
		else return null;
	}

	const officeDetailsSection = () => {
		if (officeDetails) {
			return (
				<Row className="mb-3 g-3">
					<Col lg={6}>
						<Card>
							<Card.Header >
								<h5>Office Details</h5>
							</Card.Header>
							<Card.Body className="bg-light">
								<Form validated={validated} onSubmit={handleSubmit} ref={ref => form = ref} className="fourth-form">
									<Row className="g-3">
										<FloatingLabel as={Col}
											controlId="name"
											label="Name"
											className="mb-3"
										>
											<Form.Control
												type="text"
												value={officeData.name}
												onChange={handleFieldOfficeChange}
												placeholder="Company name"
											/>
											<Form.Control.Feedback type="invalid">
												Please enter a company name.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Row>
									<Row className="g-3">
										<FloatingLabel as={Col}
											controlId="address1"
											label="Address 1"
											className="mb-3"
										>
											<Form.Control
												type="text"
												value={officeData.address1}
												onChange={handleFieldOfficeChange}
												placeholder="Address 1"
											/>
											<Form.Control.Feedback type="invalid">
												Please enter an address.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Row>
									<Row className="g-3">
										<FloatingLabel as={Col}
											controlId="address2"
											label="Address 2"
											className="mb-3"
										>
											<Form.Control
												type="text"
												value={officeData.address2}
												onChange={handleFieldOfficeChange}
												placeholder="Address 2"
											/>
										</FloatingLabel>
									</Row>
									<Row className="g-3">
										<FloatingLabel as={Col} xs={6}
											controlId="city"
											label="City"
											className="mb-3"
										>
											<Form.Control
												type="text"
												value={officeData.city}
												onChange={handleFieldOfficeChange}
												placeholder="City"
												required
											/>
											<Form.Control.Feedback type="invalid">
												Please enter a city.
											</Form.Control.Feedback>
										</FloatingLabel>
										<FloatingLabel as={Col} xs={6}
											controlId="country"
											label="Country"
											className="mb-1"
										>
											<Form.Control
												type="text"
												value={officeData.country}
												onChange={handleFieldOfficeChange}
												placeholder="Country"
												required
											/>
											<Form.Control.Feedback type="invalid">
												Please enter a country.
											</Form.Control.Feedback>
										</FloatingLabel>
									</Row>
									<Row className="g-3">
										<FloatingLabel as={Col}
											controlId="email"
											label="Email"
											className="mb-3"
										>
											<Form.Control
												type="text"
												value={officeData.email}
												onChange={handleFieldOfficeChange}
												placeholder="Email"
											/>
										</FloatingLabel>
									</Row>
									<Row className="g-3">
										<FloatingLabel as={Col} xs={6}
											controlId="phone"
											label="Phone"
											className="mb-3"
										>
											<Form.Control
												type="text"
												value={officeData.phone}
												onChange={handleFieldOfficeChange}
												placeholder="(+01) 23 45 67 89"
											/>
										</FloatingLabel>
										<FloatingLabel as={Col} xs={6}
											controlId="fax"
											label="Fax"
											className="mb-3"
										>
											<Form.Control
												type="text"
												value={officeData.fax}
												onChange={handleFieldOfficeChange}
												placeholder="(+01) 23 45 67 89"
											/>
										</FloatingLabel>
									</Row>
								</Form>
							</Card.Body>
						</Card>
					</Col>
					<Col lg={6}>
						<Card className="mb-3 g-3">
							<Card.Header >
								<h5>Financial Details</h5>
							</Card.Header>
							<Card.Body className="bg-light">
								<Form validated={validated} onSubmit={handleSubmit} ref={ref => form = ref} className="second-form">
									<Row className="g-3">
										<FloatingLabel as={Col} xs={6}
											controlId="vat"
											label="VAT registration"
											className="mb-3"
										>
											<Form.Control
												type="text"
												value={officeData.vat}
												onChange={handleFieldOfficeChange}
												placeholder="VAT"
											/>
											<Form.Control.Feedback type="invalid">
												Please enter a VAT.
											</Form.Control.Feedback>
										</FloatingLabel>
										<FloatingLabel as={Col} xs={6}
											controlId="registrationNumber"
											label="Company registration"
											className="mb-3"
										>
											<Form.Control
												type="text"
												value={officeData.registrationNumber}
												onChange={handleFieldOfficeChange}
												placeholder="12345789"
											/>
											<Form.Control.Feedback type="invalid">
												Please enter your company's registration number.
											</Form.Control.Feedback>
										</FloatingLabel>
										<FloatingLabel as={Col} xs={12}
											controlId="bankAddress"
											label="Bank address"
											className="mb-3 mt-0"
										>
											<Form.Control
												type="text"
												value={officeData.bankAddress}
												onChange={handleFieldOfficeChange}
												placeholder="12345789"
											/>
										</FloatingLabel>
										<FloatingLabel as={Col} xs={6}
											controlId="bankCode"
											label="Bank code"
											className="mb-3 mt-0"
										>
											<Form.Control
												type="text"
												value={officeData.bankCode}
												onChange={handleFieldOfficeChange}
												placeholder="12345789"
											/>
										</FloatingLabel>
										<FloatingLabel as={Col} xs={6}
											controlId="codeGuichet"
											label="Agency code"
											className="mb-3 mt-0"
										>
											<Form.Control
												type="text"
												value={officeData.codeGuichet}
												onChange={handleFieldOfficeChange}
												placeholder="12345789"
											/>
										</FloatingLabel>
										<FloatingLabel as={Col} xs={8}
											controlId="accountNumber"
											label="Account Number"
											className="mb-3 mt-0"
										>
											<Form.Control
												type="text"
												value={officeData.accountNumber}
												onChange={handleFieldOfficeChange}
												placeholder="12345789"
											/>
										</FloatingLabel>
										<FloatingLabel as={Col} xs={4}
											controlId="ribKey"
											label="RIB"
											className="mb-3 mt-0"
										>
											<Form.Control
												type="text"
												value={officeData.ribKey}
												onChange={handleFieldOfficeChange}
												placeholder="12345789"
											/>
										</FloatingLabel>
										<FloatingLabel as={Col} xs={12}
											controlId="iban"
											label="IBAN"
											className="mb-3 mt-0"
										>
											<Form.Control
												type="text"
												value={officeData.iban}
												onChange={handleFieldOfficeChange}
												placeholder="12345789"
											/>
										</FloatingLabel>
										<FloatingLabel as={Col} xs={12}
											controlId="bic"
											label="BIC"
											className="mb-3 mt-0"
										>
											<Form.Control
												type="text"
												value={officeData.bic}
												onChange={handleFieldOfficeChange}
												placeholder="12345789"
											/>
										</FloatingLabel>
									</Row>

								</Form>
							</Card.Body>
						</Card>
					</Col>
					<Col lg={12} className="mt-3 mb-3 g-3">
						<Card>
							<Card.Header>
								<h5>Office Commissions</h5>
							</Card.Header>
							<Card.Body className="bg-100">
								<Row className="g-3">
									<FloatingLabel as={Col} xs={6}
										controlId="caCommission"
										label="CA commission"
										className="mb-3"
									>
										<Form.Control
											type="number"
											onWheel={(e) => e.target.blur()}
											step=".01"
											value={officeData.caCommission}
											onChange={handleFieldOfficeChange}
											placeholder="CA commission"
										/>
										<Form.Control.Feedback type="invalid">
											Please enter a CA commission.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} xs={6}
										controlId="retailCommission"
										label="Retail commission"
										className="mb-1"
									>
										<Form.Control
											onWheel={(e) => e.target.blur()}
											type="number"
											value={officeData.retailCommission}
											onChange={handleFieldOfficeChange}
											placeholder="Retail commission"
										/>
										<Form.Control.Feedback type="invalid">
											Please enter a retail commission.
										</Form.Control.Feedback>
									</FloatingLabel>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			);
		}
		else return null;
	};

	const buttons = (role) => {
		if (role === "Administrator") {
			return (
				<div className="float-end">
					<Button
						variant='falcon-primary'
						className='me-2 mr-1'
						onClick={e => form.dispatchEvent(
							new Event("submit", { cancelable: true, bubbles: true })
						)}
					>
						<Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" /> Save</Button>

					<Button variant='falcon-warning' onClick={handleCancel}>
						<Spinner animation="border" variant="warning" role="status" size="sm" hidden={true} className="cancel-spinner" />
						Cancel</Button>

				</div>
			);
		}
	};

	//Handlers
	let form = '';
	const [validated, setValidated] = useState(false);
	let navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		const form = $(".first-form")[0].checkValidity();
		const formSecond = commSection ? $(".second-form")[0].checkValidity() : true;
		const formThird = companySection ? $(".third-form")[0].checkValidity() : true;
		const formFourth = officeDetails ? $(".fourth-form")[0].checkValidity() : true;
		const formFifth = officeSection ? $(".fifth-form")[0].checkValidity() : true;

		if (form === false || formSecond === false || formThird === false || formFourth === false || formFifth === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			if (props.personId !== undefined) {
				formData.id = props.personId;
			}

			let person = new PersonViewModel(formData.firstName, formData.lastName, formData.phone);
			person['Id'] = formData.id;
			person.login = new LoginViewModel(formData.email, 'no pass');
			person.login["role"] = `${formData.role.charAt(0).toUpperCase()}${formData.role.slice(1)}`;

			person.login.isEnabled = formData.isEnabled;

			person.login.address1 = formData.address1;
			person.login.address2 = formData.address2;
			person.login.email = formData.email;

			let office = officeData;
			office.id = officeId;

			let broker = {
				email: person.login.email,
				phone: person.phone,
				firstName: person.firstName,
				lastName: person.lastName,
				role: person.login.role,
				isEnabled: true,
				id: 0,
				address1: person.login.address1,
				address2: person.login.address2,
				cacommission: brokerData.cacommission,
				retailcommission: brokerData.retailcommission,
				company: brokerData.company,
				office: office
			};

			$('.save-spinner').removeAttr('hidden');

			if (commSection) {
				axiosInstance.post(`${window.location.origin}/brokers`, broker)
					.then((res) => {
						if (res.data.id !== '0') {
							toast.success(`New broker has successfully been created.`, {
								theme: 'colored'
							});

							navigate("/components/brokers");
						} else {
							toast.error(`Some problem occurred, please try later.`, {
								theme: 'colored'
							});
						}

						$('.save-spinner').attr('hidden', 'hidden');
					});
			} else {
				let creationForm = {
					id: 0,
					firstName: person.firstName,
					lastName: person.lastName,
					email: person.login.email,
					phone: person.phone,
					role: person.login.role,
					ReadPermissions: formData.fileRead
				}
				axiosInstance.post(`${window.location.origin}/persons`, creationForm)
					.then((res) => {
						if (res.data.id !== '0') {
							toast.success(`User has successfully been created.`, {
								theme: 'colored'
							});
							navigate("/components/users");
							/*window.location.href = `${window.location.origin}/components/users`;*/
						} else {
							toast.error(`That email is in use.`, {
								theme: 'colored'
							});
						}

						$('.save-spinner').attr('hidden', 'hidden');
					});
			}
		}
		setValidated(true);
	}


	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.id]: e.target.value
		});
	};

	const handleFieldOfficeChange = e => {
		setOfficeData({
			...officeData,
			[e.target.id]: e.target.value
		});
	}

	const handleFieldBrokerChange = e => {
		setBrokerData({
			...brokerData,
			[e.target.id]: e.target.value
		});
	}

	const handleCancel = (e) => {
		window.history.back();
	}

	const handleOfficeDetails = (id) => {
		let item = new OfficeViewModel('', '');

		if (id > 0) {
			axiosInstance.get(`${window.location.origin}/offices/${parseInt(id)}`).then((res) => {
				item = res.data;
				setOfficeData(item);
			});
		} else {
			setOfficeData(new OfficeViewModel('', ''));
		}
	}

	return (
		<>
			<PageHeader className="mb-3" title="">
				<div className="d-inline">
					<h3 className="float-start">Create user</h3>
					{buttons(role)}
				</div>
			</PageHeader>


			{/*<User details*/}
			<Row className="mb-3 g-3">
				<Col lg={8}>
					<Card>
						<Card.Header >
							<h5>User Details</h5>
						</Card.Header>
						<Card.Body className="bg-light">
							<UserDetail 
							validated={validated}
							handleSubmit={handleSubmit} 
							setRef={ref => form = ref} 
							handleFieldChange={handleFieldChange}
							formData={formData}
							>
							</UserDetail>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={4}>
					<Card>
						<Card.Header className="d-flex justify-content-between align-items-center">
							<h5 className="float-start">Account Settings</h5>
							<div className='float-end d-flex'>
								<BootstrapSwitchButton
									type='switch'
									id='isEnabled'
									onlabel='Enabled'
									offlabel='Enabled'
									onstyle="primary"
									offstyle="light"
									width="120"
									checked={formData.isEnabled}
									onChange={(e) => {
										setFormData({ ...formData, "isEnabled": e });
									}} />
							</div>
						</Card.Header>
						<Card.Body className='bg-100'>
							{formData.role == "externalUser" &&
								<div className="d-flex flex-row mb-2 align-items-center justify-content-between">
									<h6 className="mb-0">File read permission</h6>
									<div className="d-flex align-items-center">
										<Form.Check
											style={{ height: "1.5rem" }}
											type='switch'
											id='fileRead'
											className="mb-0"
											label='Enabled'
											checked={fileReadPerm}
											onChange={(e) => {
												setfileReadPerm(e.target.checked);
												setFormData({ ...formData, "fileRead": e.target.checked });
											}}
										/>
									</div>
								</div>
							}
							{radioButton()}
						</Card.Body>
					</Card>
				</Col>
			</Row>

			{ /* Commission Section */}

			{commissionSection()}


			{ /* Company for extarnal broker */}
			{companyInput()}

			{/* Select office section*/}

			{officeSelectSection()}

			{/* Selected office details*/}

			{officeDetailsSection()}

		</>
	);
};

export default UserForm;

