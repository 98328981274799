import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import mySeaLogo from 'assets/img/logos/my-sea-logo.png';
import axiosInstance from 'helpers/axiosInstance';
import { useParams, Link } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import IconButton from 'components/common/IconButton';
import moment from 'moment';
import { getUserRole } from 'helpers/utils';
import { toast } from 'react-toastify';

const StatementOfAccount = () => {
    const [contract, setContract] = useState();
    const [formatter, setFormatter] = useState();
    const [totalClient, setTotalClient] = useState(0);
    const [totalOwner, setTotalOwner] = useState(0);
    const [days, setDays] = useState(0);
    const param = useParams();
    const role = getUserRole();
    useEffect(() => {
        axiosInstance.get(`${window.location.origin}/contracts/${param.id}`).then(x => {
            let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: x.data.contract.charter.currency });
            setFormatter(formatter);
            setContract(x.data.contract);
            let days = Math.floor(new Date(x.data.contract.charter.endDate) - new Date(x.data.contract.charter.startDate)) / (1000 * 60 * 60 * 24);
            let daysToSet = (days.toFixed(2));
            let Nine = Math.floor(daysToSet) + 0.9; // 2.9
            console.log("nine", Nine, "daysToSet", daysToSet);
            if (daysToSet > Nine) {
                setDays(Math.round(daysToSet))
            }
            else {
                setDays(daysToSet);
            }
        })
    }, [])
    const getCharterFeeTotal = () => {
        return Number(contract.charter.charterFeeAmount) + Number(contract.charter.deliveryFeeAmount) + Number(contract.charter.apa) + Number(contract.charter.vat) + Number(contract.charter.deposit);
    }

    useEffect(() => {
        if (contract) {
            setTotalClient(contract.payments.filter((o) => o.type.includes("In") && o.paymentDate && o.amountReceived && o.amountReceived != 0).reduce((a, b) => a + b.amountReceived, 0));
            setTotalOwner(contract.payments.filter((o) => o.type.includes("Out") && o.paymentDate && o.amountReceived && o.amountReceived != 0).reduce((a, b) => a + b.amountReceived, 0));
        }
        console.log("concon", contract);
    }, [contract])


    
    if (contract && formatter) {
        return (
            <>
                <Card className="mb-3 hideOnPrint">
                    <Card.Body>
                        <Row className="justify-content-between align-items-center">
                            <Col md>
                                <Link className="mb-2 mb-md-0 h5 text-primary" to={`/components/contracts/editSecondPage?id=${param.id}`}>Contract #{contract.fileName}</Link>
                            </Col>
                            <Col xs="auto" className='d-flex'>
                                <IconButton
                                    variant="falcon-primary"
                                    size="sm"
                                    icon="download"
                                    className="mb-2 mb-sm-0 ms-4"
                                    onClick={() => { window.print() }}
                                >
                                    Download PDF
                                </IconButton>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="mb-3" style={{ boxShadow: "none" }}>
                    <Card.Body className='pt-2 pb-0'>
                        <Col sm={12} className="d-flex pb-7 justify-content-center" >
                            <img src={mySeaLogo} alt="invoice" width={200} />
                        </Col>
                        <Row className="border-bottom pb-5">
                            <Col xs={6} className="pt-4 row">
                                <div className="row h5">
                                    <div className="col-6 text-sm-start fs-1 text-center d-flex align-items-center">
                                        <div className=''>YACHT:  <b>{contract.yacht.name}</b></div>
                                    </div>
                                </div>
                                <div className="row h5">
                                    <div className="col-12">
                                        Financial Breakdown
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} className="pt-4">
                                <div className="row">
                                    <div className="col-12 text-end text-sm-end mt-3 mt-sm-0">
                                        <div className="">MYSEA<br />
                                            6, Bd des Moulins<br />
                                            98000, MONACO
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="border-bottom pb-3">
                            <Col xs={6} className="pt-4 row">
                                <div className="row h5">
                                    <div className="col-6">
                                        <span className='fw-light' style={{ 'fontSize': '1rem' }}>REF</span>
                                    </div>
                                    <div className="col-6 text-end">
                                        {contract.fileName}
                                    </div>
                                </div>
                                <div className="row h5">
                                    <div className="col-6">
                                        <span className='fw-light' style={{ 'fontSize': '1rem' }}>Signed On</span>
                                    </div>
                                    <div className="col-6 text-end">
                                        {moment(new Date(contract.contractDate)).format("DD/MM/YYYY")}
                                    </div>
                                </div>
                                <div className="row h5">
                                    <div className="col-6">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>In Charge</span>
                                    </div>
                                    <div className="col-6 text-end">
                                        {contract.mySeaBroker != null ? <>{contract.mySeaBroker?.person?.firstName} {contract.mySeaBroker?.person?.lastName}</> : ""}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} className="pt-4">
                                <div className="row h5">
                                    <div className="col-6">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Broker</span>
                                    </div>
                                    <div className="col-6 text-end">
                                        {contract.broker}
                                    </div>
                                </div>
                                <div className="row h5">
                                    <div className="col-3">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Period</span>
                                    </div>
                                    <div className="col-9 text-end">
                                        {moment(new Date(contract.charter.startDate)).format("DD/MM/YYYY")} <span className=' fw-light'>to</span> {moment(new Date(contract.charter.endDate)).format("DD/MM/YYYY")}
                                        <span className=''> - </span><span>{days} Days</span>
                                    </div>
                                </div>
                                <div className="row h5">
                                    <div className="col-6">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Client</span>
                                    </div>
                                    <div className="col-6 text-end">
                                        {contract.charter.client != null ? <>{contract.charter.client.name}</> : ""}
                                    </div>
                                </div>
                                <div className="row h5">
                                    <div className="col-6">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Cruising area</span>
                                    </div>
                                    <div className="col-6 text-end">
                                        {contract.charter.placeFrom} <span className='fw-light'>to</span> {contract.charter.placeTo}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="border-bottom pb-3 justify-content-between align-items-center">
                            <Col xs={4} className="pt-4 row">
                                <div className="row" style={{ 'fontSize': '1rem' }}>
                                    <div className="col-5">
                                        <span className='fw-light' >charter fee</span>
                                    </div>
                                    <div className="col-7 text-end fw-bold">
                                        {formatter.format(contract.charter.charterFeeAmount)}
                                    </div>
                                </div>
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col-5">
                                        <span className='fw-light' style={{ 'fontSize': '1rem' }}>VAT</span>
                                    </div>
                                    <div className="col-7 text-end fw-bold">
                                        {formatter.format(contract.charter.vat)}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={4} className="pt-4 row">
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col-5">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>APA</span>
                                    </div>
                                    <div className="col-7 text-end fw-bold">
                                        {formatter.format(contract.charter.apa)}
                                    </div>
                                </div>
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col-6">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Delivery fees</span>
                                    </div>
                                    <div className="col-6 text-end fw-bold">
                                        {formatter.format(contract.charter.deliveryFeeAmount)}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={4} className="pt-4 row">
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col-5">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Security deposit</span>
                                    </div>
                                    <div className="col-7 text-end fw-bold">
                                        {formatter.format(contract.charter.deposit)}
                                    </div>
                                </div>
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col-5">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Total</span>
                                    </div>
                                    <div className="col-7 text-end fw-bold">
                                        {formatter.format(getCharterFeeTotal())}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="border-bottom pb-4">
                            <Col xs={12} className="mt-3">
                                <Row>
                                    <Col xs={6} className="">
                                        <Card className="h-100">
                                            <Card.Header className="border-bottom">
                                                <h5 className='text-center'>INCOMING PAYMENTS</h5>
                                            </Card.Header>
                                            <Card.Body className="pt-0">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Payment date</th>
                                                            <th>Amount</th>
                                                            <th>Type</th>
                                                            <th>From</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            contract.payments.filter((o) => o.type.includes("In") && o.paymentDate && o.amountReceived && o.amountReceived != 0).sort((a, b) => new Date(a.paymentDate).getTime() - new Date(b.paymentDate).getTime()).map((o, index) => {
                                                                return (<tr key={index}>
                                                                    <td>{moment(new Date(o.paymentDate)).format("DD/MM/YYYY")}</td>
                                                                    <td>{formatter.format(o.amountReceived)}</td>
                                                                    <td>{o.kind}</td>
                                                                    <td>{o.paymentRecipient}</td>
                                                                </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                            <Card.Footer className="border-top">
                                                <h5 className='text-end d-block fs-0'>Total : {formatter.format(totalClient)}</h5>
                                            </Card.Footer>
                                        </Card>

                                    </Col>
                                    <Col xs={6} className="">
                                        <Card className='h-100'>
                                            <Card.Header className="border-bottom">
                                                <h5 className='text-center'>OUTGOING PAYMENTS</h5>
                                            </Card.Header>
                                            <Card.Body className="pt-0">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Payment date</th>
                                                            <th>Amount</th>
                                                            <th>Type</th>
                                                            <th>To</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            contract.payments.filter((o) => o.type.includes("Out") && o.paymentDate && o.amountReceived && o.amountReceived != 0).sort((a, b) => new Date(a.paymentDate).getTime() - new Date(b.paymentDate).getTime()).map((o, index) => {
                                                                return (<tr key={index}>
                                                                    <td>{moment(new Date(o.paymentDate)).format("DD/MM/YYYY")}</td>
                                                                    <td>{formatter.format(o.amountReceived)}</td>
                                                                    <td>{o.kind}</td>
                                                                    <td>{o.paymentRecipient}</td>
                                                                </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                            <Card.Footer className="border-top d-flex justify-content-between">
                                                <h5 className='text-primary text-end d-block fs-0'>Balance : {formatter.format(totalClient - totalOwner)}</h5>
                                                <h5 className='text-end d-block fs-0'>Total : {formatter.format(totalOwner)}</h5>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            {
                                !role.includes("External") && (
                                    <>
                                        <Col className='py-4' xs={12}>
                                            <Card>
                                                <Card.Header className=''>
                                                    <h5 className='text-center'>Commissions</h5>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>Invoice</th>
                                                                <th>Broker</th>
                                                                <th>Commission %</th>
                                                                <th>H.T</th>
                                                                <th>VAT</th>
                                                                <th>TTC</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                contract.office != null ?

                                                                    <tr>
                                                                        <td>{contract.officeCommission.invoice}</td>
                                                                        <td>MYSEA</td>
                                                                        <td>{contract.officeCommission.comm} %</td>
                                                                        <td>{formatter.format((contract.officeCommission.comm / 100) * contract.charter.charterFeeAmount)}</td>
                                                                        <td>{contract.officeCommission.isTaxIncluded ? (formatter.format((contract.officeCommission.comm / 100) * contract.charter.vat)) : ""}</td>
                                                                        <td>{formatter.format(((contract.officeCommission.comm / 100) * contract.charter.charterFeeAmount) + (contract.officeCommission.isTaxIncluded ? ((contract.officeCommission.comm / 100) * contract.charter.vat) : 0))}</td>
                                                                    </tr> : <></>
                                                            }

                                                            {
                                                                (contract.Kind != "Retail" && contract.Broker != null) ?
                                                                    contract.Broker.trim() !== '' ?
                                                                        <tr>
                                                                            <td>{contract.brokerCommission.invoice}</td>
                                                                            <td>{contract.Broker}</td>
                                                                            <td>{contract.brokerCommission.comm} %</td>
                                                                            <td>{formatter.format((contract.brokerCommission.comm / 100) * contract.charter.charterFeeAmount)}</td>
                                                                            <td>{contract.brokerCommission.isTaxIncluded ? (formatter.format((contract.brokerCommission.comm / 100) * contract.charter.vat)) : ""}</td>
                                                                            <td>{formatter.format(((contract.brokerCommission.comm / 100) * contract.charter.charterFeeAmount) + (contract.brokerCommission.isTaxIncluded ? ((contract.brokerCommission.comm / 100) * contract.charter.vat) : 0))}</td>
                                                                        </tr>
                                                                        : null : <></>
                                                            }

                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </>
                                )
                             }
                        </Row>

                    </Card.Body>
                    <Card.Footer className="bg-light pdffooter border-top w-100">
                        <div className="row">
                            <div className="col-6">
                                {
                                    contract.office != null ? <p className="fs--1 mb-0">
                                        {contract.office.address1} <br />
                                        {contract.office.address2} <br />
                                        {contract.office.city}, {contract.office.country}<br />
                                    </p> : <></>
                                }
                            </div>
                            <div className="col-6 text-end">
                                {
                                    contract.office != null ? <p className="fs--1 mb-0">
                                        Tel: {contract.office.phone} | Fax: {contract.office.fax} | Email: {contract.office.email}<br />
                                        Company registration number: {contract.office.registrationNumber} <br />VAT registration number: {contract.office.vat}
                                    </p> : <></>
                                }
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            </>
        );
    }


    else
        return (
            <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center"><BeatLoader color={"#9B9B9B"} size={25} /></div>
        )
};

export default StatementOfAccount;
