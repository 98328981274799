export class ClientViewModel {
    company: string;
    name: string;
    iban: string;
    swift: string;
    address: string;
    bankAddress: string;
    kyc: boolean;

    constructor(company, name, swift, iban) {
        this.company = company;
        this.name = name;
        this.iban = iban;
        this.swift = swift;
        this.address = this.bankAddress = '';
        this.kyc = false;
    }
}