import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';


const NumberFilter = ({ label, id }) => {
    return (
        <>
            <Form.Group>
                <Form.Label htmlFor={id}>{label}</Form.Label>
                <div className="d-flex">
                    <Form.Control id={id + "_Min"} name={id + "_Min"} type="text" placeholder="Min" />
                    <span style={{ width: "30%", textAlign: "center" }}>-</span>
                    <Form.Control id={id + "_Max"} name={id + "_Max"} type="text" placeholder="Max" />
                </div>
            </Form.Group>
        </>
    );
}

NumberFilter.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string
};

export default NumberFilter;