import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav, Dropdown, DropdownButton, NavDropdown, } from 'react-bootstrap';
import classNames from 'classnames';
import AppContext from 'context/Context';
import Logo from 'components/common/Logo';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { useLocation } from 'react-router';
import routes from 'routes/routes';
import { Link } from 'react-router-dom';
import { getUserRole } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavbarTop = () => {
	const {
		config: { showBurgerMenu, navbarPosition, navbarCollapsed },
		setConfig
	} = useContext(AppContext);

	const { pathname } = useLocation();
	const isChat = pathname.includes('chat');

	const [showDropShadow, setShowDropShadow] = useState(false);

	const handleBurgerMenu = () => {
		navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
		(navbarPosition === 'vertical' || navbarPosition === 'combo') &&
			setConfig('showBurgerMenu', !showBurgerMenu);
	};

	const setDropShadow = () => {
		const el = document.documentElement;
		if (el.scrollTop > 0) {
			setShowDropShadow(true);
		} else {
			setShowDropShadow(false);
		}
	};

	const role = getUserRole();

	useEffect(() => {
		window.addEventListener('scroll', setDropShadow);
		return () => window.removeEventListener('scroll', setDropShadow);
	}, []);

	return (
		<Navbar bg="light" expand="lg" className='navbar-glass-shadow bg-100 hideOnPrint' fixed="top">
			<div className="container-lg">
				<Logo at="navbar-top" width={80} id="topLogo" />
				<Navbar.Toggle aria-controls="navbarScroll" />
				<Navbar.Collapse id="navbarScroll">
					<Nav className="w-100 my-2 my-lg-0">
						{
							routes.map((route, index) => {
								if (route.roles && !route.roles.includes(role))
									return null
								else {
									if (route.children)
										return (
											<NavDropdown style={{ fontSize: "1rem" }} title={route.label} key={index}>
												<div className='py-2 bg-light rounded-3'>
													{
														route.children.map((r, i) => {
															if (r.roles && !r.roles.includes(role))
																return null;
															else if (!r.name)
																return (<NavDropdown.Divider key={i} />);
															else
																return (<NavDropdown.Item as={Link} className="text-primary" to={r.to} key={i}><FontAwesomeIcon icon={r.icon} className="pe-2" />{r.name}</NavDropdown.Item>)
														}
														)
													}
												</div>
											</NavDropdown>
										)
									else
										return (
											<Nav.Link href="#action2" key={index}>{route.label}</Nav.Link>
										)
								}
							})
						}
						<TopNavRightSideNavItem />
					</Nav>
				</Navbar.Collapse>
			</div>
		</Navbar>
	);
};

export default NavbarTop;
