import React, { useRef, useState } from 'react';
import { Col, Row, Form, FloatingLabel, Card, InputGroup, Modal, Button } from 'react-bootstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import AsyncSelect from 'react-select/async';
import CreateClientForm from 'components/suppliers/create';

const YachtCADetail = ({ formData, handleExternalBrokerChange, loadEBOptions, setFormData}) => {
	const [showModal, setShowModal] = useState(false);
	const modalBodyCompanyContent = useRef();

	const handleOnModalCompanySave = (company) => {
		setFormData({ ...formData, centralAgent: company, rE_CAAgentComm: company.retailCommission, centralAgentId: company.id })
		setShowModal(false);
	}

	return (
		<>
			<Card className="mb-3">
				<Card.Header className="d-inline">
					<h5 className="float-start">External Broker</h5>

				</Card.Header>
				<Card.Body className="bg-light">
					<Row>
						<Col lg={12} className=''>
							<Row className="g-3">
								<Col lg={9} xs={12}>

									<InputGroup className="h-100">
										<AsyncPaginate
											value={formData.centralAgent}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option}
											onChange={handleExternalBrokerChange}
											loadOptions={loadEBOptions}
											additional={{
												page: 0,
											}}
											className="h-100c h-100 flex-fill w-50 fs--1rem"
											placeholder="Search for an external broker"
										/>
										<Button style={{ zIndex: '0' }} variant="primary" id="button-addon1" onClick={() => { setShowModal(true) }}>
											<span className=''>+</span>
										</Button>
									</InputGroup>
								</Col>
								<Form.Group as={Col} lg={3} xs={12}>
									<FloatingLabel
										controlId="Comm"
										label="Comm. %"
									>
										<Form.Control
											type="number"
											disabled
											value={formData.centralAgent?.retailCommission}
											placeholder="Comm. %"
										/>
									</FloatingLabel>
								</Form.Group>
							</Row>
						</Col>
						{
							(formData.centralAgentId == null && formData.rE_CAAgent != null) &&
							<Form.Group as={Col} lg={12} className='mt-3'>
								<FloatingLabel
									controlId=""
									label="Original external broker name"
								>
									<Form.Control
										type="text"
										disabled
										value={formData.rE_CAAgent}
										placeholder="Comm. %"
									/>
								</FloatingLabel>
							</Form.Group>
						}
					</Row>
				</Card.Body>
			</Card>
			<Modal
				show={showModal}
				onHide={() => { setShowModal(false) }}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header className='border-bottom-0'>
					<h4>Create external broker</h4>
				</Modal.Header>
				<Modal.Body className='bg-light'>
					<CreateClientForm
						ref={modalBodyCompanyContent}
						InModal={true}
						onSave={handleOnModalCompanySave}
					/>
				</Modal.Body>
				<Modal.Footer>

					<Button variant="secondary" onClick={() => { setShowModalCompany(false); }}>Cancel</Button>
					<Button variant="primary" onClick={() => modalBodyCompanyContent.current.save()}>
						<span id="">Save</span>
					</Button>
				</Modal.Footer>
			</Modal>
		</>

	);
};

export default YachtCADetail;
