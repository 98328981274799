import { LoginViewModel } from './loginView'

export class PersonViewModel {
    login: LoginViewModel;
    firstName: string;
    lastName: string;
    phone: string;

    constructor(firstName: firstName, lastName: lastName, phone: phone) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.phone = phone;
    }
};