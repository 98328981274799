import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Card, Col, Row, Form, FloatingLabel, Button, Spinner, Table, InputGroup, ToggleButton, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import DatePicker, { registerLocale } from 'react-datepicker';
import CreateYachtForm from 'components/yachts/create';
import CreateSupplierForm from 'components/suppliers/create';
import CreateClientForm from 'components/clients/create';
import { asyncSelectStyles, getUserRole } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import ContactDetail from 'components/contacts/contactForm';
import { SimpleUserForm } from 'viewModel/userForm';
import axiosInstance from 'helpers/axiosInstance';

const ContractDetailForm = ({
	formData,
	setFormData,
	loadContractTypeOptions,
	handleFieldChange,
	handleYachtDetails,
	loadYachtOptions,
	handleSelectChange,
	isCentralAgent2,
	validated,
	loadSupplierOptions,
	loadClientOptions,
	handleCharterFieldChange,
	setReadjustPayment
}) => {

	let form = '';
	const modalBodyContent = useRef();
	const newContactRef = React.createRef();

	const setModalBodyRef = useCallback((node) => {
		modalBodyContent.current = node;
	})
	const [showModalCompany, setShowModalCompany] = useState(false);
	const [showModalEB, setShowModalEB] = useState(false);
	const [userFormData, setUserFormData] = useState(new SimpleUserForm());
	const [modalCreateTitle, setModalCreateTitle] = useState("");
	const [showModal, setShowModal] = useState(false);
	const modalBodyEBContent = useRef();
	const modalBodyCompanyContent = useRef();
	const [userValidated, setUserValidated] = useState(false);

	const saveModal = (res) => { // save yacht
		setFormData({ ...formData, yacht: { id: res.id, name: res.name, company: res.company, kyc: res.kyc }, vatRep: res.vatRep, kyc: res.kyc })
		setShowModal(false);
		document.getElementById("ModalSaveBtn").innerText = "Save";
	}

	const SetModalSaveBtn = (text) => {
		document.getElementById("ModalSaveBtn").innerText = text;
	}

	const handleOnModalSave = async (event) => {
		document.getElementById("ModalSaveBtn").innerText = "Creating...";
		await modalBodyContent.current?.test();
	}
	const setDisplay = (bool) => {
		document.getElementById("displayInfo").hidden = bool;
		document.getElementById("editInfo").hidden = !bool;
	}
	const role = getUserRole();


	const [ebContacts, setEbContacts] = useState([]);
	useEffect(() => {
		if (formData.charter.supplier != null && formData.charter.supplier.id > 0) {
			getEbContacts();
		}
	}, [formData.charter.supplier])

	const getEbContacts = () => {
		if (role == "Administrator") {
			axiosInstance.get(`${window.location.origin}/suppliers/${formData.charter.supplier.id}/contacts`).then(x => {
				setEbContacts(x.data);
			}).catch(e => {
				toast.error("An error occurred while fetching data", { theme: 'colored' });
			})
		}
	}

	const loadEbContact = (input, callback) => {
		if (ebContacts.length != 0) {
			return (
				<>
					<option>Select contact</option>
					{
						ebContacts.map(c => (<option data-obj={JSON.stringify(c)} value={c.id} key={c.id}>
							{(c.firstName)} {c.lastName}
						</option>))
					}
				</>
			);
		} else {
			return (<option>No contact found</option>)
		}

	}

	const handleOnModalCompanySave = (company) => {
		setFormData({ ...formData, client: { id: company.id, name: company.name, company: company.company } })
		setShowModalCompany(false);
	}

	const onEbSave = (supplier) => {
		setFormData({ ...formData, supplier: { id: supplier.id, name: supplier.name } })
		setShowModalEB(false);
	}

	const handleContact = (e) => {
		let option = e.target.options[e.target.selectedIndex];
		let contact = JSON.parse(option.getAttribute("data-obj"));
		console.log(contact);
		if (contact != null) {
			setFormData({ ...formData, contactId: e.target.value, contact: contact, broker: contact.fullName });
		} else {
			setFormData({ ...formData, contactId: null, contact: null });
		}
	}

	const handleContactSubmit = (event) => {
		axiosInstance.post(`${window.location.origin}/suppliers/${formData.charter.supplier.id}/contacts`, userFormData)
			.then((res) => {
				let contact = res.data;
				contact.supplierContacts = null;
				newContactRef.current.setShowModal(false);
				setUserFormData(new SimpleUserForm());
				setFormData({ ...formData, contactId: res.data.id, contact: res.data, broker: contact.fullName });
				let contacts = [...ebContacts];
				contacts.push(res.data);
				setEbContacts(contacts);
			});
	};

	const setReadjust = () => {
		if (formData.id > 0)
			setReadjustPayment(true);
	}

	return (
		<>
			<Card className="h-100">
				<Card.Header className="d-flex justify-content-between align-items-center">
					<h5 className="float-start">Contract Details</h5>
					<span className="float-end d-flex">
						{isCentralAgent2 ? <div class="me-2">
							<Form.Check
								type='checkbox'
								id='kyc'
								label='KYC'
								name='kyc'
								checked={formData.charter.kyc}
								value={formData.charter.kyc}
								onChange={handleCharterFieldChange}
							/>
						</div>
							: ""}
						<BootstrapSwitchButton
							type='switch'
							id='vatRep'
							name='vatRep'
							onlabel='VAT Rep'
							offlabel='VAT Rep'
							onstyle="primary"
							offstyle="light"
							width="150"
							checked={formData.vatRep}
							onChange={(e) => {
								setFormData({ ...formData, vatRep: e });
								setReadjust();
							}} />
						{(role == "Administrator" && formData.id > 0) &&
							<button className='btn btn-primary btn-sm ms-2' onClick={() => { setDisplay(false) }}><FontAwesomeIcon icon={faPenToSquare} className="me-2"></FontAwesomeIcon>Close</button>
						}
					</span>
				</Card.Header>
				<Card.Body className="bg-light">
					<Form noValidate validated={validated} ref={ref => form = ref} className="first-form needs-validation">
						{
							role == "Administrator" &&
							<Row>
								<Col lg={12}>
									<Row className="g-3">
										<Form.Group as={Col} sm="auto" lg={4}>
											<FloatingLabel
												controlId="fileName"
												label="File number"
											>
												<Form.Control
													type="text"
													value={formData.fileName}
													onChange={handleFieldChange}
													placeholder="File number"
													required
												/>
											</FloatingLabel>
											<Form.Control.Feedback type="invalid">
												Please enter a file number.
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group as={Col} sm="auto" lg={4}>
											<DatePicker
												autoComplete='off'
												selected={formData.contractDate}
												onChange={(date) => setFormData({ ...formData, contractDate: date })}
												formatWeekDay={day => day.slice(0, 3)}
												className='form-control datepickerHeight'
												value={formData.contractDate}
												name="contractDate"
												timeIntervals={5}
												dateFormat="dd/MM/yyyy"
												locale='es'
												placeholderText="Contract date"
												required
												portalId="root-portal"
											/>
										</Form.Group>
										<Form.Group as={Col} sm="auto" lg={4}>
											<FloatingLabel
												label="Contract type"
											>
												<Form.Select
													required
													default={1}
													onChange={e => { handleSelectChange(e, "contractType"); }}>
													{loadContractTypeOptions()}
												</Form.Select>
												<Form.Control.Feedback type="invalid">
													Please select a type.
												</Form.Control.Feedback>
											</FloatingLabel>
										</Form.Group>
										<Form.Group as={Col} sm="auto" lg={4}>
											<InputGroup className="h-100">
												<AsyncSelect
													id="yachtAsyncSelector"
													getOptionLabel={option => { return `${option.name}  ${option.ca ? "- CA" : "- RETAIL"}` }}
													getOptionValue={option => option}
													onChange={handleYachtDetails}
													value={formData.yacht}
													loadOptions={loadYachtOptions}
													className="h-100c h-100 flex-fill w-50 fs--1rem"
													placeholder="Select a yacht"
													defaultOptions
													styles={{
														menuList: (baseStyles, state) => ({
															...baseStyles,
															backgroundColor: 'white',
														})
													}}
												>
												</AsyncSelect>
												<Button style={{ zIndex: '0' }} variant="primary" id="button-addon1" onClick={() => { setShowModal(true) }}>
													<span className=''>+</span>
												</Button>
											</InputGroup>
										</Form.Group>
										<Form.Group as={Col} sm="auto" lg={4}>
											<FloatingLabel
												controlId="owner"
												label="Owner"
											>
												<Form.Control
													type="text"
													value={formData.yacht?.company}
													onChange={handleFieldChange}
													placeholder="Owner"
													disabled
												/>
											</FloatingLabel>
											<Form.Control.Feedback type="invalid">
												Please select a yacht.
											</Form.Control.Feedback>
										</Form.Group>
										<Form.Group as={Col} sm md lg={4}>
											<FloatingLabel
												label="Contract number"
												controlId='contractNumber'
											>
												<Form.Control
													type="text"
													name="contractNumber"
													value={formData.contractNumber}
													onChange={handleFieldChange}
												/>
											</FloatingLabel>
										</Form.Group>
										<Form.Group as={Col} lg={4}>
											<InputGroup className="h-100">
												<AsyncSelect
													id="supplierAsyncSelector"
													getOptionLabel={option => option.name ?? option.value.name}
													getOptionValue={option => option}
													onChange={e => { handleSelectChange(e, "supplier") }}
													value={formData.charter.supplier}
													loadOptions={loadSupplierOptions}
													className="h-100c h-100 flex-fill w-50 fs--1rem"
													placeholder="Select ext. broker"
													styles={{
														menuList: (baseStyles, state) => ({
															...baseStyles,
															backgroundColor: 'white',
														})
													}}
													defaultOptions
												>
												</AsyncSelect>
												<Button style={{ zIndex: '0' }} variant="primary" id="button-addon1" onClick={() => { setShowModalEB(true) }}>
													<span className=''>+</span>
												</Button>
											</InputGroup>
										</Form.Group>
										<Form.Group as={Col} sm="auto" lg={4}>
											<InputGroup className="h-100">
												<Form.Select onChange={e => { handleContact(e); }} value={formData.contactId} disabled={(formData.charter.supplier == null || formData.charter.supplier.id <= 0)}>
													{loadEbContact()}
												</Form.Select>
												<Button style={{ zIndex: '0' }} variant="primary" id="button-addon1" onClick={() => { newContactRef.current.setShowModal(true) }}>
													<span className=''>+</span>
												</Button>
											</InputGroup>
										</Form.Group>
										<Form.Group as={Col} sm="auto" lg={4} style={{ height: "58px" }}>
											{
												(!isCentralAgent2 ? <>
													<InputGroup className="h-100">
														<AsyncSelect
															id="clientAsyncSelector"
															getOptionLabel={option => option.name ?? option.value.name}
															getOptionValue={option => option}
															onChange={e => { handleSelectChange(e, "client") }}
															value={formData.charter.client}
															loadOptions={loadClientOptions}
															className="h-100c h-100 flex-fill w-50 fs--1rem"
															placeholder="Select retail client"
															defaultOptions
															styles={{
																menuList: (baseStyles, state) => ({
																	...baseStyles,
																	backgroundColor: 'white',
																})
															}}
														>
														</AsyncSelect>
														<Button style={{ zIndex: '0' }} variant="primary" id="button-addon1" onClick={() => { setShowModalCompany(true) }}>
															<span className=''>+</span>
														</Button>
													</InputGroup>
												</> : <>
													<FloatingLabel
														label="Client name"
														controlId='caClientName'
														className=""
													>
														<Form.Control
															type="text"
															name="caClientName"
															value={formData.caClientName}
															onChange={handleFieldChange}
															required
														/>
													</FloatingLabel>
												</>)
											}
										</Form.Group>
									</Row>
								</Col>
							</Row>
						}

					</Form>
				</Card.Body>
			</Card>
			<Modal
				show={showModal}
				onHide={() => { setShowModal(false) }}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header className='border-bottom-0'>
					<h4>Create Yacht</h4>
				</Modal.Header>
				<Modal.Body className='bg-light'>
					<CreateYachtForm ref={modalBodyContent} InModal={true} onYachtSave={saveModal} SetModalSaveBtn={SetModalSaveBtn}></CreateYachtForm> :
				</Modal.Body>
				<Modal.Footer>

					<Button variant="secondary" onClick={() => { setShowModal(false); }}>Cancel</Button>
					<Button variant="primary" onClick={handleOnModalSave}>
						<span id="ModalSaveBtn">Save</span>
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showModalCompany}
				onHide={() => { setShowModalCompany(false) }}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header className='border-bottom-0'>
					<h4>Create external broker</h4>
				</Modal.Header>
				<Modal.Body className='bg-light'>
					<CreateClientForm
						ref={modalBodyCompanyContent}
						InModal={true}
						onSave={handleOnModalCompanySave}
					/>
				</Modal.Body>
				<Modal.Footer>

					<Button variant="secondary" onClick={() => { setShowModalCompany(false); }}>Cancel</Button>
					<Button variant="primary" onClick={() => modalBodyCompanyContent.current.save()}>
						<span id="">Save</span>
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showModalEB}
				onHide={() => { setShowModalEB(false) }}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header className='border-bottom-0'>
					<h4>Create external broker</h4>
				</Modal.Header>
				<Modal.Body className='bg-light'>
					<CreateSupplierForm
						ref={modalBodyEBContent}
						InModal={true}
						onSave={onEbSave}
					></CreateSupplierForm>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => { setShowModalEB(false); }}>Cancel</Button>
					<Button variant="primary" onClick={() => modalBodyEBContent.current.save()}>
						<span id="ModalSaveBtn">Save</span>
					</Button>
				</Modal.Footer>
			</Modal>

			<ContactDetail
				ref={newContactRef}
				handleContactSubmit={handleContactSubmit}
				setUserFormData={setUserFormData}
				userFormData={userFormData}
				userValidated={userValidated}
			></ContactDetail>
		</>
	);
};

export default ContractDetailForm;
