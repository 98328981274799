import { setUserSession, getToken, removeUserSession } from "./utils";
import axios from 'axios'
import history from "helpers/history";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};
const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;

function isIsoDateString(value) {
  return value && typeof value === "string" && isoDateFormat.test(value);
}
const dateKeyRx = /date/i;
const axiosInstanceWithDate = axios.create({
  transformResponse: (data) => {
    if (data)
      return JSON.parse(data, (key, value) => {
        return (dateKeyRx.test(key) && value != null) ? new Date(value) : value;
      }
      );
    return null;
  }
});

axiosInstanceWithDate.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstanceWithDate.interceptors.response.use(response => {
  return response;
}, err => {
  const originalRequest = err.config;
  console.log(err);
  if (err.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return axios(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise(function (resolve, reject) {
      axiosInstanceWithDate
        .get("/Auth/refresh-token")
        .then(rs => {
          let { jwtToken, ...user } = rs.data;
          axiosInstanceWithDate.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
          setUserSession(rs.data.jwtToken, user);
          originalRequest.headers['Authorization'] = 'Bearer ' + jwtToken;
          processQueue(null, jwtToken);
          resolve(axios(originalRequest));
        })
        .catch(err => {
          processQueue(err, null);
          removeUserSession();
          history.push("/login");
          reject(err);
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  }

  return Promise.reject(err);
}
);

// axiosInstanceWithDate.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (error) => {
//     if (error.response) {
//       const originalConfig = error.config;
//       if (error.response) {
//         // Access Token was expired
//         if ((error.response.status === 401) && !originalConfig._retry) {
//           originalConfig._retry = true;
//           try {
//             console.log("fetching token");
//             const rs = await refreshToken();
//             let { jwtToken, ...user } = rs.data;
//             setUserSession(rs.data.jwtToken, user);
//             return axiosInstanceWithDate(originalConfig);
//           } catch (_error) {
//             if (_error.response && _error.response.data) {
//               removeUserSession();
//               history.push("/login");
//               return Promise.reject(_error.response.data);
//             }
//             history.push("/login");
//             return Promise.reject(_error);
//           }
//         }
//         if (error.response.status === 403 && error.response.data) {
//           history.push("/login");
//           return Promise.reject(err.response.data);
//         }
//       }
//     }
//     return Promise.reject(error);
//   }
// );

function refreshToken() {
  return axios.get("/Auth/refresh-token");
}

export default axiosInstanceWithDate;