import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import mySeaLogo from 'assets/img/logos/my-sea-logo.png';
import axiosInstance from 'helpers/axiosInstance';
import { useParams, Link } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import IconButton from 'components/common/IconButton';
import moment from 'moment';
import { getUserRole } from 'helpers/utils';
import { toast } from 'react-toastify';
import { PaymentForm } from 'viewModel/DtoClasses';

const Remittance = () => {
    const [contract, setContract] = useState();
    const [payment, setPayment] = useState(new PaymentForm());
    const [formatter, setFormatter] = useState();
    const [totalClient, setTotalClient] = useState(0);
    const [totalOwner, setTotalOwner] = useState(0);
    const [days, setDays] = useState(0);
    const param = useParams();
    const role = getUserRole();
    useEffect(() => {
        axiosInstance.get(`${window.location.origin}/payments/${param.id}`).then(x => {
            let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: x.data.contract.charter.currency });
            setFormatter(formatter);
            setContract(x.data.contract);
            setPayment(x.data);
            let days = Math.floor(new Date(x.data.contract.charter.endDate) - new Date(x.data.contract.charter.startDate)) / (1000 * 60 * 60 * 24);
            let daysToSet = (days.toFixed(2));
            let Nine = Math.floor(daysToSet) + 0.9; // 2.9
            console.log("nine", Nine, "daysToSet", daysToSet);
            if (daysToSet > Nine) {
                setDays(Math.round(daysToSet))
            }
            else {
                setDays(daysToSet);
            }
        })
    }, [])
    const getCharterFeeTotal = () => {
        return Number(contract.charter.charterFeeAmount) + Number(contract.charter.deliveryFeeAmount) + Number(contract.charter.apa) + Number(contract.charter.vat) + Number(contract.charter.deposit);
    }

    useEffect(() => {
        if (contract) {
            setTotalClient(contract.payments.filter((o) => o.type.includes("In") && o.paymentDate && o.amountReceived && o.amountReceived != 0).reduce((a, b) => a + b.amountReceived, 0));
            setTotalOwner(contract.payments.filter((o) => o.type.includes("Out") && o.paymentDate && o.amountReceived && o.amountReceived != 0).reduce((a, b) => a + b.amountReceived, 0));
        }
        console.log("concon", contract);
    }, [contract])



    if (contract && formatter) {
        return (
            <>
                <Card className="mb-3 hideOnPrint">
                    <Card.Body>
                        <Row className="justify-content-between align-items-center">
                            <Col md>
                                <Link className="mb-2 mb-md-0 h5 text-primary" to={`/components/contracts/editSecondPage?id=${contract.id}`}>Contract #{contract.fileName}</Link>
                            </Col>
                            <Col xs="auto" className='d-flex'>
                                <IconButton
                                    variant="falcon-primary"
                                    size="sm"
                                    icon="download"
                                    className="mb-2 mb-sm-0 ms-4"
                                    onClick={() => { window.print() }}
                                >
                                    Download PDF
                                </IconButton>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="mb-3" style={{ boxShadow: "none" }}>
                    <Card.Body className='pt-2 pb-0'>
                        <Col sm={12} className="d-flex pb-7 justify-content-center" >
                            <img src={mySeaLogo} alt="invoice" width={200} />
                        </Col>
                        <Row className="border-bottom pb-5">
                            <Col xs={6} className="pt-4 row">
                                <div className="row h5">
                                    <div className="col-6 text-sm-start fs-1 text-center d-flex align-items-center">
                                        <div className=''>YACHT:  <b>{contract.yacht.name}</b></div>
                                    </div>
                                </div>
                                <div className="row h5">
                                    <div className="col-12">
                                        Remittance Advice
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} className="pt-4">
                                <div className="row">
                                    <div className="col-12 text-end text-sm-end mt-3 mt-sm-0">
                                        <div className="">MYSEA<br />
                                            6, Bd des Moulins<br />
                                            98000, MONACO
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="border-bottom pb-3">
                            <Col xs={6} className="pt-4 row">
                                <div className="row h5">
                                    <div className="col-6">
                                        <span className='fw-light' style={{ 'fontSize': '1rem' }}>REF</span>
                                    </div>
                                    <div className="col-6 text-end">
                                        {contract.FileName}
                                    </div>
                                </div>
                                <div className="row h5">
                                    <div className="col-6">
                                        <span className='fw-light' style={{ 'fontSize': '1rem' }}>Signed On</span>
                                    </div>
                                    <div className="col-6 text-end">
                                        {moment(new Date(contract.contractDate)).format("DD/MM/YYYY")}
                                    </div>
                                </div>
                                <div className="row h5">
                                    <div className="col-6">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>In Charge</span>
                                    </div>
                                    <div className="col-6 text-end">
                                        {contract.mySeaBroker != null ? <>{contract.mySeaBroker.firstName} {contract.mySeaBroker.lastName}</> : ""}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} className="pt-4">
                                <div className="row h5">
                                    <div className="col-6">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Broker</span>
                                    </div>
                                    <div className="col-6 text-end">
                                        {contract.Broker}
                                    </div>
                                </div>
                                <div className="row h5">
                                    <div className="col-3">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Period</span>
                                    </div>
                                    <div className="col-9 text-end">
                                        {moment(new Date(contract.charter.startDate)).format("DD/MM/YYYY")} <span className=' fw-light'>to</span> {moment(new Date(contract.charter.endDate)).format("DD/MM/YYYY")}
                                        <span className=''> - </span><span>{days} Days</span>
                                    </div>
                                </div>
                                <div className="row h5">
                                    <div className="col-6">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Client</span>
                                    </div>
                                    <div className="col-6 text-end">
                                        {contract.charter.client != null ? <>{contract.charter.client.name}</> : ""}
                                    </div>
                                </div>
                                <div className="row h5">
                                    <div className="col-6">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Cruising area</span>
                                    </div>
                                    <div className="col-6 text-end">
                                        {contract.charter.placeFrom} <span className='fw-light'>to</span> {contract.charter.placeTo}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="border-bottom pb-3 justify-content-between align-items-center">
                            <Col xs={4} className="pt-4">
                                <div className="row" style={{ 'fontSize': '1rem' }}>
                                    <div className="col-5">
                                        <span className='fw-light' >Charter Fee</span>
                                    </div>
                                    <div className="col-7 text-end fw-bold">
                                        {formatter.format(contract.charter.charterFeeAmount)}
                                    </div>
                                </div>
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col-5">
                                        <span className='fw-light' style={{ 'fontSize': '1rem' }}>VAT</span>
                                    </div>
                                    <div className="col-7 text-end fw-bold">
                                        {formatter.format(contract.charter.vat)}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={4} className="pt-4">
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col-5">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>APA</span>
                                    </div>
                                    <div className="col-7 text-end fw-bold">
                                        {formatter.format(contract.charter.apa)}
                                    </div>
                                </div>
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col-6">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Delivery fees</span>
                                    </div>
                                    <div className="col-6 text-end fw-bold">
                                        {formatter.format(contract.charter.deliveryFeeAmount)}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={4} className="pt-4">
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col-5">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Security deposit</span>
                                    </div>
                                    <div className="col-7 text-end fw-bold">
                                        {formatter.format(contract.charter.deposit)}
                                    </div>
                                </div>
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col-5">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Total</span>
                                    </div>
                                    <div className="col-7 text-end fw-bold">
                                        {formatter.format(getCharterFeeTotal())}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="border-bottom pb-4">
                            <Col xs={4} className="mt-3">
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Amount to pay</span>
                                    </div>
                                    <div className="col-auto text-end fw-bold">
                                        {formatter.format(payment.amountToPay)}
                                    </div>
                                </div>
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col-5">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Due date</span>
                                    </div>
                                    <div className="col-7 text-end fw-bold">
                                        {moment(payment.dueDate).format("DD/MM/yyyy")}
                                    </div>
                                </div>
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col-5">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Payment type</span>
                                    </div>
                                    <div className="col-7 text-end fw-bold">
                                        {payment.kind}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={4}></Col>
                            <Col xs={4} className="mt-3">
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Amount paid</span>
                                    </div>
                                    <div className="col-auto text-end fw-bold">
                                        {formatter.format(payment.amountReceived)}
                                    </div>
                                </div>
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Payment date</span>
                                    </div>
                                    <div className="col-auto text-end fw-bold">
                                        {moment(payment.paymentDate).format("DD/MM/yyyy")}
                                    </div>
                                </div>
                                <div className="row " style={{ 'fontSize': '1rem' }}>
                                    <div className="col">
                                        <span className='fw-light me-3' style={{ 'fontSize': '1rem' }}>Payment recipient</span>
                                    </div>
                                    <div className="col-auto text-end fw-bold">
                                        {payment.paymentRecipient}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Form.Group className='d-block w-100 my-2'>
                                <span className='fw-light me-3 mb-3' style={{ 'fontSize': '1rem' }}>Payment details</span>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    className='hideOnPrint'
                                    rows={5}
                                    value={payment.reconciliationDetails}
                                    onChange={(e) => {
                                        setPayment({ ...payment, reconciliationDetails: e.target.value });
                                    }}
                                />
                                <div>
                                    <span style={{ whiteSpace: 'pre-wrap' }} className='showOnPrint'>
                                        {payment.reconciliationDetails}
                                    </span>
                                </div>
                            </Form.Group>
                        </Row>
                        <Form.Group className='d-block w-100 my-2'>
                            <span className='fw-light me-3 mb-3' style={{ 'fontSize': '1rem' }}>Notes</span>
                            <div>
                                <span style={{ whiteSpace: 'pre-wrap' }} className=''>
                                    {payment.note}
                                </span>
                            </div>
                        </Form.Group>
                    </Card.Body>
                    <Card.Footer className="bg-light pdffooter border-top w-100">
                        <div className="row">
                            <div className="col-6">
                                {
                                    contract.office != null ? <p className="fs--1 mb-0">
                                        {contract.office.address1} <br />
                                        {contract.office.address2} <br />
                                        {contract.office.city}, {contract.office.country}<br />
                                    </p> : <></>
                                }
                            </div>
                            <div className="col-6 text-end">
                                {
                                    contract.office != null ? <p className="fs--1 mb-0">
                                        Tel: {contract.office.phone} | Fax: {contract.office.fax} | Email: {contract.office.email}<br />
                                        Company registration number: {contract.office.registrationNumber} <br />VAT registration number: {contract.office.vat}
                                    </p> : <></>
                                }
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            </>
        );
    }


    else
        return (
            <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center"><BeatLoader color={"#9B9B9B"} size={25} /></div>
        )
};

export default Remittance;
