import { PersonViewModel } from './personView';
import { OfficeViewModel } from './officeView';

export class BrokerViewModel {
    person: PersonViewModel;
    office: OfficeViewModel;
    cacommission: number;
    retailcommission: number;
    company: string;

    constructor(person: person, office: office) {
        this.office = office;
        this.person = person;
        this.cacommission = this.retailcommission = 0;
        this.company = '';
    }
};