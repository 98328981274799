export const dashboardRoutes = {
  label: 'Dashboard',

  children: [
	{
	  name: 'Dashboard',
	  active: true,
		  icon: 'chart-line',
		  to: '/',
		  exact: true,
		  active: true
	}
  ]
};

export const adminRoutes = {
	label: 'Management',
	roles: ["Administrator"],
	children: [
		{
			name: 'Yachts',
			icon: 'anchor',
			to: '/components/yachts',
			active: true
		},
		{
			name: 'Retail clients',
			icon: 'user',
			to: '/components/clients',
			active: true
		},
		{
			name: 'External brokers',
			icon: 'user-tie',
			to: '/components/externalbrokers',
			active: true
		},
		{
			name: 'Retail brokers',
			icon: 'user-shield',
			to: '/components/brokers',
			active: true
		},
		{
			name: 'Users',
			icon: 'user-lock',
			to: '/components/users',
			active: true,
			exact: true,
			roles: ["Administrator"]
		},
		{
			name: 'Offices',
			icon: 'building',
			to: '/components/offices',
			active: true
		}
	]
};

export const external_userRoutes = {
	label: 'Contracts',
	children: [
		{
			name: 'Contracts',
			icon: 'file-signature',
			to: '/components/contracts',
			active: true,
			exact: true,
		}
	]
};

export const contractRoutes = {
	label: 'Contracts',
	children: [
		{
			name: 'All contracts',
			icon: 'file-signature',
			to: '/components/contracts',
			active: true,
			exact: true
		},
		// {
		//     name: 'Sales',
		//     icon: 'file-archive',
		//     to: '/components/contracts/sales',
		//     active: true,
		//     roles: ["Administrator", "Broker"]
		// },
		{
			name: 'Transactions',
			icon: 'file-shield',
			to: '/components/transactions',
			active: true,
			roles: ["Administrator", "Broker"]
		},
		{
			name: 'Contract resume',
			icon: 'file-code',
			to: '/components/resume',
			active: true,
			roles: ["Administrator"]
		},
		{
			name: 'Commissions invoices',
			icon: 'file-invoice-dollar',
			to: '/components/invoices',
			active: true,
			roles: ["Administrator"]
		},
		{
			name: null,
			active: true,
			roles: ["Administrator"]
		},
		{
			name: 'New CA contract',
			icon: 'file-circle-plus',
			to: '/components/contracts/create#CA',
			active: true,
			roles: ["Administrator"]
		},
		{
			name: 'New Retail contract',
			icon: 'file-circle-plus',
			to: '/components/contracts/create#RETAIL',
			active: true,
			roles: ["Administrator"]
		}
	],
	roles: ["Administrator", "Broker"]
};

export const systemRoutes = {
	label: 'Settings',
	children: [
		{
		   name: 'Parameters',
		   icon: 'wrench',
		   to: '/components/settings',
		   active: true,
		   roles: ["Administrator"]
		}
	],
	roles: ["Administrator"]
};

export const nomenclatureRoutes = {
	label: 'Internal lists',
	children: [
		{
			name: 'Application objects',
			icon: 'book',
			children: [
				{
					name: 'Contract kind',
					icon: 'chart-pie',
					to: '/components/contractKind',
					active: true
				},
				{
					name: 'Contract type',
					icon: 'chart-pie',
					to: '/components/contractType',
					active: true
				},
				{
					name: 'Contract status',
					icon: 'chart-pie',
					to: '/components/contractStatus',
					active: true
				},
				{
					name: 'Payment status',
					icon: 'chart-pie',
					to: '/components/paymentStatus',
					active: true
				},
				{
					name: 'Commission type',
					icon: 'chart-pie',
					to: '/components/commissionType',
					active: true
				},
				{
					name: 'APA',
					icon: 'chart-pie',
					to: '/components/apa',
					active: true
				},
				{
					name: 'Currency',
					icon: 'chart-pie',
					to: '/components/currency',
					active: true
				},
				{
					name: 'Yacht owner',
					icon: 'user',
					to: '/components/yachtOwner',
					active: true
				},
				{
					name: 'Role',
					icon: 'chart-pie',
					to: '/components/role',
					active: true
				}
			]
		}
	]
};

export default [
  //brokerRoutes,  
  //yachtRoutes,
  //officeRoutes,
  //clientRoutes,
  contractRoutes,
  adminRoutes,
  systemRoutes
];
