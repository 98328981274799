import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Button, Modal, Dropdown, DropdownButton, FloatingLabel, Spinner } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import FalconComponentCard from 'components/common/FalconComponentCard';
import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import TextFilter from 'components/filters/textFilter';
import DateRangeFilter from 'components/filters/daterangeFilter';
import NumberFilter from 'components/filters/numberFilter';
import SoftBadge from 'components/common/SoftBadge';
import { useNavigate } from "react-router-dom";
import { getToken, getUserRole } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosInstance from 'helpers/axiosInstance';
import { toast } from 'react-toastify';
import moment from 'moment'
import { BeatLoader } from 'react-spinners';

axios.defaults.headers.common = { 'Authorization': `Bearer ${getToken()}` }

const columns = [
	{
		accessor: 'fileName',
		Header: 'File number',
		sort: true,
		Cell: rowData => {
			const { fileName } = rowData.row.original
			return (
				<div style={{ color: "blue", textDecoration: "underline" }}>
					{fileName}
				</div>
			)
		}
	},
	{
		accessor: 'contractDate',
		Header: 'Contract date',
		sort: true,
		Cell: rowData => {
			const { contractDate, contractDateToString } = rowData.row.original;
			return (
				<>
					<span>{contractDateToString}</span>
				</>
			)
		}
	},
	{
		accessor: 'yachtName',
		sort: true,
		Header: 'Yacht',
		Cell: rowData => {
			const { yachtId, yachtName } = rowData.row.original

			return (
				<Link target="_blank" onClick={(e) => e.stopPropagation()} to={`/components/yachts/edit?id=${yachtId}`}>{yachtName}</Link>
			)
		}
	},
	{
		accessor: 'contractKind',
		sort: true,
		Header: 'Type',
		Cell: rowData => {
			const { contractKind } = rowData.row.original;
			let text = "Central Agent";
			if (contractKind == "Retail")
				text = "Retail";
			return (
				<>
					<SoftBadge pill bg={contractKind == "CA" ? "primary" : "warning"}>{text}</SoftBadge>
				</>
			)
		}
	},
	{
		accessor: 'clientName',
		sort: true,
		Header: 'Client'
	},
	{
		accessor: 'supplierName',
		sort: true,
		Header: 'External broker'
	},
	{
		accessor: 'startDate',
		sort: true,
		Header: 'Rental period',
		Cell: rowData => {
			const { rentalPeriod } = rowData.row.original;
			return (
				<span>{rentalPeriod}</span>
			)
		}
	},
	{
		accessor: 'nights',
		sort: true,
		Header: 'Days'
	},
	{
		accessor: 'status',
		sort: true,
		Header: 'Status',
		Cell: rowData => {
			const { status } = rowData.row.original

			return (
				<SoftBadge pill bg={status == "On Going" ? "success" :
					status == "Cancel with comm." ? "warning" :
						status == "Cancel without comm." ? "warning" :
							status == "Closed" ? "danger" :
								status == "Postponed Y+1" ? "info" : ""
				}
				>
					<span style={{ fontSize: "12px" }}>{status}</span>
				</SoftBadge>
			)
		}
	}
];

const ContractList = () => {
	const [contractStatuses, setContractStatuses] = useState({ data: [] });
	const [selectedStatus, setSelectedStatus] = useState({ id: 0, name: "" });
	const [exportLoading, setExportLoading] = useState(false);
	const [allOffices, setAllOffices] = useState({ data: [] });
	const [loaded, setLoaded] = useState(false);
	useEffect(
		() => {
			let req1 = axiosInstance.get(`${window.location.origin}/parameters`);
			let req2 = axiosInstance.get(`${window.location.origin}/offices/simplified?take=200`)
			axios.all(
				[
					req1,
					req2
				]
			).then(axios.spread((...responses) => {
				setContractStatuses(o => ({
					data: responses[0].data.contractStatuses.values
				}));
				// setSelectedStatus(responses[0].data.contractStatuses.values[0]);
				setAllOffices(o => ({
					data: responses[1].data
				}));
				let type = responses[0].data.contractStatuses.values.find(x => x.name == "On Going");
				setSelectedStatus(type)
				setLoaded(true);
			}
			))

			document.title = "All contracts";
		},
		[],
	);
	const role = getUserRole();
	const [state, setState] = useState({
		sortBy: [
			{ id: 'contractDate', desc: true }
		],
		total: 0,
		totalPages: 0,
		remoteData: [],
		pageSize: 10,
		currentPage: 0
	});
	const [isLoading, showLoading] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const fetchIdRef = React.useRef(0);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [showYachtType, setShowYachtType] = useState("CA_RETAIL");

	const getContracts = React.useCallback(({ page, size, sortBy }) => {
		// Give this fetch an ID
		const fetchId = ++fetchIdRef.current;
		// Only update the data if this is the latest fetch
		if (fetchId === fetchIdRef.current) {
			showLoading(true);
			let formQuery = $("#tableFilters").serialize();
			const statusType = $("#statusFilter").val();
			if (statusType != 0) formQuery += "&StatusName=" + $("#statusFilter option:selected").text().trim();
			const yachtType = $("#typeFilter").val();
			if (yachtType != "CA_RETAIL" && typeof yachtType != "undefined")
				formQuery += "&YachtType=" + yachtType
			formQuery += `${(formQuery.length > 0 ? "&" : "")}page=${page}`;
			formQuery += `&take=${size}`;
			let officeId = $("#officeFilter").val();
			if (officeId) formQuery += "&officeId=" + officeId;
			if (sortBy && sortBy.length > 0) {
				let sortQuery = encodeURIComponent(sortBy.map(x => `${x.id} ${x.desc ? "desc" : "asc"}`).join(','));
				formQuery += `&sort=${sortQuery}`;
			}
			axiosInstance.get(`${window.location.origin}/contracts/all?${formQuery}`)
				.then((res) => {
					setState({
						sortBy: sortBy,
						total: res.data.count,
						totalPages: Math.ceil(res.data.count / (size * 1.0)),
						remoteData: res.data.data,
						pageSize: size,
						currentPage: page
					});
					showLoading(false);
				});
		}

	}, []);

	const loadContractStatusOptions = () => {
		if (contractStatuses.data) {

			return (
				<>
					<option value={0} key={"NonSelectorT"}>All status</option> //By default On going
					{
						contractStatuses.data.map(status => (<option value={status.id} key={status.id} >
							{`${status.name}`}
						</option>))
					}
				</>
			);
		}
	}

	const handleSelectChange = (e, type) => {
		console.log(e.target.value);
		switch (type) {
			case "contractStatus": {
				let type = contractStatuses.data.find(x => x.id == e.target.value);
				if (type)
					setSelectedStatus({ id: type.id, name: type.name });
				else {
					setSelectedStatus({ id: 0, name: null });
				}
				break;
			}
		}
	}

	const search = () => {
		getContracts({ page: 0, size: state.pageSize, sortBy: state.sortBy });
	};

	const handleYachtTypeChange = (event) => {
		setShowYachtType(event.target.value);
	}


	/*Filters*/
	const renderFilters = (visible) => {
		if (visible) {
			return (<></>);
		} else {
			return (
				<>
					<form id="tableFilters">
						<Row className="mb-3 g-3">
							<Col lg={2}>
								<TextFilter label="File number" id="FileName" placeholder="search..." />
							</Col>
							<Col lg={2}>
								<DateRangeFilter label="Contract date" id='ContractDate' />
							</Col>
							<Col lg={2}>
								<TextFilter label="Yacht" id="YachtName" placeholder="search..." />
							</Col>
							<Col lg={2}>
								<Form.Group>
									<Form.Label>Type</Form.Label>
									<Form.Select id="typeFilter" className="me-2" onChange={handleYachtTypeChange} placeholder={"CA or Retail"}>
										<option selected="selected" value={"CA_RETAIL"}>
											CA & Retail
										</option>
										<option value={"CA"}>
											CA
										</option>
										<option value={"Retail"}>
											Retail
										</option>
									</Form.Select>
								</Form.Group>
							</Col>
							<Col lg={2}>
								<TextFilter label="Client" id="ClientName" placeholder="search..." />
							</Col>
							<Col lg={2}>
								<TextFilter label="External broker" id="SupplierName" placeholder="search..." />
							</Col>
							<Col lg={2}>
								<DateRangeFilter label="Rental period" id="RentalPeriod" placeholder="search..." />
							</Col>
							<Col lg={2}>
								<NumberFilter label="Number of days" id="Nights" />
							</Col>
							{/*<Col lg={1}>
								<TextFilter label="From" id="PlaceFrom" placeholder="search..." />
							</Col>
							<Col lg={1}>
								<TextFilter label="To" id="PlaceTo" placeholder="search..." />
							</Col>*/}
							<Col lg={2}>
								<TextFilter label="Retail broker" id="Broker" placeholder="search..." />
							</Col>
							<Col lg={2}>
								<TextFilter label="MySea broker" id="MySeaBroker" placeholder="search..." />
							</Col>
							<Col lg={2}>
								<Form.Group>
									<Form.Label>Office</Form.Label>
									<Form.Select id="officeFilter" className="me-2">
										<option selected="selected" value={""}>
											All offices
										</option>
										{allOffices.data.map((x, index) =>
											<option key={x.id} value={x.id}>
												{x.name ? x.name : (x.city + ", " + x.country)}
											</option>
										)}
									</Form.Select>
								</Form.Group>
							</Col>
							<Col lg={2}>
								<Form.Group>
									<Form.Label>Status</Form.Label>
									<Form.Select id="statusFilter" className="me-2" required onChange={e => { handleSelectChange(e, "contractStatus") }} value={selectedStatus.id}>
										{loadContractStatusOptions()}
									</Form.Select>
								</Form.Group>
							</Col>
						</Row>
					</form>
				</>);
		}
	}


	/*Table Layout*/
	const contractTable = () => {
		return (
			<>
				<AdvanceTableWrapper
					columns={columns}
					sortable
					initialSortBy={state.sortBy}
					pagination
					perPage={state.pageSize}
					data={state.remoteData}
					pageCount={state.totalPages}
					fetchData={getContracts}
					initialPage={state.currentPage}
					isLoading={isLoading}
				>
					{/*Filters row*/}
					{renderFilters(showFilters)}

					<AdvanceTable
						trLinkById={`${window.location.origin}/components/contracts/editSecondPage`}
						table
						headerClassName="bg-200 text-900 text-nowrap align-middle"
						rowClassName="align-middle white-space-nowrap"
						tableProps={{
							bordered: true,
							striped: true,
							className: 'fs--1 mb-0 overflow-hidden'
						}}
					/>
					<div className="mt-3">
						<AdvanceTableFooter
							rowCount={state.total}
							pageCount={state.totalPages}
							table
							rowInfo
							navButtons
							rowsPerPageSelection
						/>
					</div>
				</AdvanceTableWrapper>
			</>
		);
	}
	let navigate = useNavigate();

	const newRetailContract = (e) => {
		navigate("/components/contracts/create#RETAIL");
	}

	const newCAContract = (e) => {
		navigate("/components/contracts/create#CA");
	}

	const exportTableToExcel = () => {
		setExportLoading(true);
		let formQuery = $("#tableFilters").serialize();
		let officeId = $("#officeFilter").val();
		if (officeId) formQuery += "&officeId=" + officeId;
		if (selectedStatus.name) formQuery += "&StatusName=" + selectedStatus.name
		if (showYachtType != "CA_RETAIL" && typeof showYachtType != "undefined") formQuery += "&YachtType=" + showYachtType
		axiosInstance.get(`${window.location.origin}/contracts/export?${formQuery}`,
			{ responseType: 'blob' })
			.then(response => {
				let blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = `contracts${moment(new Date()).format("DDMMYYYY")}.xlsx`
				link.click()
				setExportLoading(false);
			}).catch(e => {
				toast.error("There was an error exporting the data", { theme: 'colored' })
				setExportLoading(false);
			})
	}
	if (loaded)
		return (
			<>
				{loaded ? <></> : <></>}
				<FalconComponentCard>
					<FalconComponentCard.Header
						light={false}
						className="border-bottom border-200"
					>
						{/*Action Buttons*/}
						<div className="d-flex flex-between-center">
							<Col md="auto" lg={6}>
								<h4>Contracts</h4>
							</Col>
							<Col md="auto" lg={6} className="text-end">
								{!showFilters && <Button variant='secondary' size='sm' className='me-2 px-4' onClick={search}>
									<FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className='pe-1' />Search</Button>}
								<IconButton
									variant="falcon-default"
									size="sm"
									icon="fa-filter"
									transform="shrink-3"
									className='me-2'
									onClick={() => setShowFilters(!showFilters)}

								>
									<span className="d-none d-sm-inline-block ms-1">Filters</span>
								</IconButton>
								{
									!role.includes("External") && (
										<DropdownButton className='me-2' style={{ display: 'inline-block' }} size="sm" id="dropdown-basic-button" title="+ New">
											<Dropdown.Item onClick={newCAContract}>CA Contract</Dropdown.Item>
											<Dropdown.Item onClick={newRetailContract}>Retail Contract</Dropdown.Item>
										</DropdownButton>
									)
								}
								{/*<Link to="/components/contracts">*/}
								{/*<IconButton*/}
								{/*    variant="falcon-default"*/}
								{/*    size="sm"*/}
								{/*    icon="plus"*/}
								{/*    transform="shrink-3"*/}
								{/*    className='me-2'*/}
								{/*    >*/}

								{/*<span  className="d-none d-sm-inline-block ms-1">New</span>*/}
								{/*</IconButton>*/}
								{/* </Link>*/}
								<IconButton
									variant="falcon-default"
									size="sm"
									icon="external-link-alt"
									transform="shrink-3"
									onClick={exportTableToExcel}
								>
									{exportLoading ? (<Spinner animation="border" role="status" size="sm" className='ms-2'>
										<span className="visually-hidden">Loading...</span>
									</Spinner>) : "Export"}
								</IconButton>
							</Col>
						</div>
					</FalconComponentCard.Header>
					<FalconComponentCard.Body
						children={contractTable()}
						scope={{
							AdvanceTableWrapper,
							AdvanceTable,
							AdvanceTableFooter,
							AdvanceTableSearchBox,
							IconButton,
							Link
						}}
						language="jsx"
						noInline
						noLight
					/>
				</FalconComponentCard>


				{/*<Modal show={show} onHide={handleClose} animation={true}>
				<Modal.Header closeButton>
					<Modal.Title>Choose a contract type</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col lg={6}>
							<Link to="/components/contracts/create#RETAIL" style={{ textDecoration: 'none' }}>
							<Row>
							<div className=" d-flex align-items-center justify-content-center">
									
										<FontAwesomeIcon size="10x" color="grey" icon={faFileContract} />
								   
								</div>
							</Row>
							<Row>
									<div className=" d-flex align-items-center justify-content-center pt-2" style={{ textDecoration: 'none' }}>
										<Modal.Title>Retail Contract</Modal.Title>
								</div>
								</Row>
							</Link>
						</Col>
						<Col lg={6}>
							<Link to="/components/contracts/create#CA" style={{ textDecoration: 'none' }}>
								<Row>
									<div className=" d-flex align-items-center justify-content-center">

										<FontAwesomeIcon size="10x" color="grey" icon={faFileSignature} className="" />

									</div>
								</Row>
								<Row>
									<div className=" d-flex align-items-center justify-content-center pt-2" style={{ textDecoration: 'none' }}>
										<Modal.Title >CA Contract</Modal.Title>
									</div>
								</Row>
							</Link>
						</Col>
					</Row>
				</Modal.Body>
				
			</Modal> */}
			</>
		);
	else
		return (
			<div className="d-flex flex-column min-vh-100 justify-content-center align-items-center"><BeatLoader color={"#9B9B9B"} size={25} /></div>
		)
}
export default ContractList;
