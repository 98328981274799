import React, { useState, useEffect } from 'react';
import { Col, Card, Button } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import axiosInstance from 'helpers/axiosInstance';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';

const ContractList = (props) => {
    const [contracts, setContracts] = useState({ data: [] });
    const [loaded, setLoaded] = useState(false);
    const getContracts = () => {
        let id = props.Id;
        if (props.BrokerId)
            id = props.BrokerId;
        return new Promise((resolve, reject) => {
            axiosInstance.get(`${window.location.origin}/${props.type}/${id}/contracts`)
                .then((res) => {
                    resolve(res.data);
                });
        });
    }

    useEffect(() => {
        getContracts().then(contractReq => {
            setContracts({ data: contractReq });
            setLoaded(true);
        });
    }, []);

    useEffect(() => {
        if (loaded) {
            getContracts().then(contractReq => {
                setContracts({ data: contractReq });
                setLoaded(true);
            });
        }
    }, [props.triggerContracts])

    const loadContractOptions = (input, callback) => {
        axiosInstance.get(`${window.location.origin}/contracts/all?FileName=${input}&take=15`).then(x => {
            callback(x.data.data);
        }).catch(e => {
            toast.error("An error occurred while fetching data.", { theme: 'colored' });
        })
    }

    const handleOnChange = (contract) => {
        axiosInstance.put(`${window.location.origin}/persons/${props.Id}/contracts/${contract.id}`).then(x => {
            toast.success(`Added contract ${contract.fileName} to user's access`, { theme: 'colored' });
            getContracts().then(contractReq => {
                setContracts({ data: contractReq });
                setLoaded(true);
            });
        }).catch(e => {
            toast.error(e.message, { theme: 'colored' });
            if (e.response.data != '')
                toast.error(e.response.data, { theme: 'colored' });
        })
    }



    /*Table Layout*/
    const contractTable = () => {

        return (
            <>
                <AdvanceTableWrapper
                    columns={props.columns}
                    data={contracts.data}
                    sortable
                    pagination
                    perPage={10}
                >
                    <AdvanceTable
                        trLinkById={`${window.location.origin}/components/contracts/editSecondPage`}
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                    <div className="mt-3">
                        <AdvanceTableFooter
                            rowCount={contracts.data.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </div>
                </AdvanceTableWrapper>
            </>
        );
    }

    if (loaded) {
        return (
            <Card>
                <Card.Header
                    light={false}
                    className="border-bottom border-200"
                >
                    {/*Action Buttons*/}
                    <div className="d-flex flex-between-center">
                        <Col sm="auto" lg={6}>
                            <h4>Contracts</h4>
                        </Col>
                        <Col sm="auto" lg={6} className="text-end d-flex justify-content-end">
                            <div style={{ width: 250 }}>
                                <AsyncSelect
                                    defaultOptions
                                    getOptionLabel={option => { return `Add ${option.fileName}` }}
                                    onChange={handleOnChange}
                                    loadOptions={loadContractOptions}
                                    value={null}
                                    className="h-100 px-2 w-100"
                                    placeholder="Type to select contract"
                                    styles={{
                                        menuList: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: 'white',
                                        })
                                    }}
                                >
                                </AsyncSelect>
                            </div>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="external-link-alt"
                                transform="shrink-3"
                            >
                                <span className="d-none d-sm-inline-block ms-1"
                                    onClick={() => { window.location.replace(`${window.location.origin}/contracts/export-data?fileName=Contracts`); }}>Export</span>
                            </IconButton>
                        </Col>
                    </div>
                </Card.Header>
                <Card.Body>
                    {
                        contractTable()
                    }
                </Card.Body>
            </Card>
        )
    }
    else
        return null;

};

export default ContractList;
