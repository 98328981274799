import axiosInstance from 'helpers/axiosInstance';
import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';

const YachtRetailContactDetail = ({ formData, setFormData, externalBrokerId }) => {

	const [ebContacts, setEbContacts] = useState([]);	
	useEffect(() => {
		if (externalBrokerId != null) {
			getEbContacts();
		}
	}, [externalBrokerId])

	const getEbContacts = () => {
		axiosInstance.get(`${window.location.origin}/suppliers/${externalBrokerId}/contacts`).then(x => {
			setEbContacts(x.data);
		}).catch(e => {
			toast.error("An error occurred while fetching data", { theme: 'colored' });
		})
	}

	const loadEbContact = (input, callback) => {
		if (ebContacts.length != 0) {
			return (
				<>
					<option>Select contact</option>
					{
						ebContacts.map(c => (<option value={c.id} key={c.id}>
							{(c.firstName)} {c.lastName}
						</option>))
					}
				</>
			);
		} else {
			return (<option>No contact found</option>)
		}
		
	}

	const handleContact = (contactId) => {
		setFormData({ ...formData, contactId: contactId });
	}

	return (
		<Card className="mb-3">
			<Card.Header className="d-inline">
				<h5 className="float-start">Contact</h5>

			</Card.Header>
			<Card.Body className="bg-light">
				<Row>
					<Form.Group as={Col} lg={12}>
						<Form.Select onChange={e => { handleContact(e.target.value); }} value={formData.contactId} disabled={(externalBrokerId == null || externalBrokerId == 0)}>
							{loadEbContact()}
						</Form.Select>
					</Form.Group>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default YachtRetailContactDetail;
