import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import createMarkup from 'helpers/createMarkup';
import Avatar from 'components/common/Avatar';
import { useParams } from 'react-router-dom';
import SoftBadge from 'components/common/SoftBadge';
//function RedirectTo(link) {
//    this.props.history.replace(`?id=${linkTo}`);
//}

const Notification = ({
	avatar,
	time,
	className,
	unread,
	flush,
	emoji,
	children,
	Children,
	linkTo,
	toShow,
	dash,
	titles,
	charterDate,
	problemRef
}) => {
	let navigate = useNavigate();
	const navigateTo = (e) => {
		linkTo && navigate(linkTo);
	}
	const message = /<strong>(.*?):?<\/strong>:?(.*)/g.exec(!dash && Children ? Children : children) || ['', '', children];
	return (
		<div
			className={classNames(
				'notification pt-1 pb-0 mb-1',
				{ 'notification-unread': unread, 'notification-flush': flush },
				className
			)}
			onClick={navigateTo} style={problemRef == "warning" ? { border: "none", borderLeft: "5px solid #f5803e", cursor: "pointer" } : problemRef == "important" ? { border: "none", borderLeft: "5px solid #e63757", cursor: "pointer" } : { border: "none", borderLeft: "5px solid green" }}
		>
			<div className="notification-body">
				{message[1] && <SoftBadge pill bg={problemRef === 'warning' ? 'warning' : problemRef == "important" ? 'danger' : 'success'} className='me-2 mt-0'>{titles !== false ? message[1] + ' ' + (charterDate ? " " + charterDate : '') : problemRef}</SoftBadge>}
				<p className="ms-1 mb-0" dangerouslySetInnerHTML={createMarkup(message[2])} />
				<span className="notification-time">
					{time}
				</span>
			</div>
		</div>
	);
}

Notification.propTypes = {
	avatar: PropTypes.shape(Avatar.propTypes),
	time: PropTypes.string,
	className: PropTypes.string,
	unread: PropTypes.bool,
	flush: PropTypes.bool,
	emoji: PropTypes.string,
	children: PropTypes.node
};

Notification.defaultProps = { unread: false, flush: false };

export default Notification;
