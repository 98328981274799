import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import SettingsPanel from 'components/settings-panel/SettingsPanel';

import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import Dashboard from 'components/dashboards/default';
import AppContext from 'context/Context';

// -- Added BO
import UserList from 'components/users';
import Yacht from 'components/yachts';
import Office from 'components/offices';
import ClientList from 'components/clients';
import SupplierList from 'components/suppliers';
import Contract from 'components/contracts';
import Broker from 'components/brokers';
import Parameters from 'components/pages/Settings';

import CreateUserForm from 'components/users/create';
import EditUserForm from 'components/users/edit';
import UserSettingsPage from 'components/users/settings';

import CreateClientForm from 'components/clients/create';
import EditClientForm from 'components/clients/edit';

import CreateSupplierForm from 'components/suppliers/create';
import EditSupplierForm from 'components/suppliers/edit';

import CreateBrokerForm from 'components/brokers/create';
import EditBrokerForm from 'components/brokers/edit';

import CreateOfficeForm from 'components/offices/create';
import EditOfficeForm from 'components/offices/edit';
import CreateYachtForm from 'components/yachts/create';
import EditYachtForm from 'components/yachts/edit';

import CreateContractFirstPageForm from 'components/contracts/create';
import EditContractSecondPageForm from 'components/contracts/editSecondPage';
import SalesCurrentYearList from 'components/contracts/sales';

import CardLogin from 'components/authentication/card/Login';
import Logout from 'components/authentication/card/Logout';
import CardRegistration from 'components/authentication/card/Registration';
import CardForgetPassword from 'components/authentication/card/ForgetPassword';
import TransactionList from 'components/transactions';
import Resume from 'components/resume';
import CommisssionInvoiceList from 'components/invoices';
import DownloadPage from 'components/invoices/download';
import PrivateRoute from './PrivateRoute';
import OfficeInvoicePreview from 'components/contracts/OfficeInvoicePreview';
import StatementOfAccount from 'components/contracts/statementOfAccount';
import PasswordReset from 'components/authentication/card/PasswordReset';
import RoleBasedRoute from './RoleBasedRoute';
import EditContract from 'components/contracts/edit';
import Remittance from 'components/contracts/remittance';

//let role = ""
//if (document.cookie.indexOf('token=') != -1) {
//   role = JSON.parse(window.localStorage.getItem('token')).Role;
//}

////temporary way for monday version
////will be changed

const Layout = (props) => {
	const HTMLClassList = document.getElementsByTagName('html')[0].classList;
	useContext(AppContext);

	useEffect(() => {
		if (is.windows()) {
			HTMLClassList.add('windows');
		}
		if (is.chrome()) {
			HTMLClassList.add('chrome');
		}
		if (is.firefox()) {
			HTMLClassList.add('firefox');
		}
	}, [HTMLClassList]);

	return (
		<>
			<Routes>
				<Route path="/login" element={<CardLogin setToken={props.setToken} />} />
				<Route path="/register" element={<CardRegistration />} />
				<Route path="/forgot-password" element={<CardForgetPassword />} />
				<Route path="/components/reset-password" element={<PasswordReset />} />
				<Route path="/logout" element={<Logout />} />
				<Route path="/components/invoices/download" element={<DownloadPage />} />

				<Route element={<PrivateRoute />}>
					<Route element={<MainLayout />}>
						<Route exact path="/" element={<RoleBasedRoute roles={["Administrator", "Broker"]} component={Dashboard} />} />

						{ /* //--- MySea Dashboard */}

						<Route>
							<Route path="components/users" element={<RoleBasedRoute roles={["Administrator"]} component={UserList} />} />
							<Route path="components/users/create" element={<RoleBasedRoute roles={["Administrator"]} component={CreateUserForm} />} />
							<Route path="components/users/edit" element={<RoleBasedRoute roles={["Administrator"]} component={EditUserForm} />} state="id=" />
							<Route path="components/users/settings" element={<UserSettingsPage/>} state="id="/>

							<Route path="components/brokers/create" element={<RoleBasedRoute roles={["Administrator"]} component={CreateBrokerForm} />} />
							<Route path="components/brokers/edit" element={<RoleBasedRoute roles={["Administrator"]} component={EditBrokerForm} />} state="id=" />

							<Route path="components/clients/create" element={<RoleBasedRoute roles={["Administrator"]} component={CreateClientForm} />} />
							<Route path="components/clients/edit" element={<RoleBasedRoute roles={["Administrator"]} component={EditClientForm} />} state="id=" />

							<Route path="components/externalbrokers/create" element={<RoleBasedRoute roles={["Administrator"]} component={CreateSupplierForm} />} />
							<Route path="components/externalbrokers/edit" element={<RoleBasedRoute roles={["Administrator"]} component={EditSupplierForm} />} state="id=" />

							<Route path="components/offices/create" element={<RoleBasedRoute roles={["Administrator"]} component={CreateOfficeForm} />} />
							<Route path="components/offices/edit" element={<RoleBasedRoute roles={["Administrator"]} component={EditOfficeForm} />} state="id="/>

							<Route path="components/yachts/create" element={<RoleBasedRoute roles={["Administrator"]} component={CreateYachtForm} />} />
							<Route path="components/yachts/edit" element={<RoleBasedRoute roles={["Administrator"]} component={EditYachtForm} />} state="id=" />

							<Route path="components/contracts/create" element={<RoleBasedRoute roles={["Administrator"]} component={CreateContractFirstPageForm} />} />
							<Route path="components/contracts/editSecondPage" element={<EditContract />} state="id=" />
							<Route path="components/contracts/:id" element={<EditContractSecondPageForm />} />
							<Route path="components/contracts/sales" element={<RoleBasedRoute roles={["Administrator", "Broker"]} component={SalesCurrentYearList} />} />
							<Route path="components/contracts/:id/invoice" element={<RoleBasedRoute roles={["Administrator", "Broker"]} component={OfficeInvoicePreview} />}></Route>
							<Route path="components/contracts/:id/sac" element={<RoleBasedRoute roles={["Administrator", "Broker", "ExternalUser"]} component={StatementOfAccount} />} ></Route>

							<Route path="components/payments/:id/remittance" element={<RoleBasedRoute roles={["Administrator", "Broker", "ExternalUser"]} component={Remittance} />} />

							<Route path="components/brokers" element={<RoleBasedRoute roles={["Administrator"]} component={Broker} />} />
							<Route path="components/yachts" element={<RoleBasedRoute roles={["Administrator"]} component={Yacht} />} />
							<Route path="components/offices" element={<RoleBasedRoute roles={["Administrator"]} component={Office} /> } />
							<Route path="components/clients" element={<RoleBasedRoute roles={["Administrator"]} component={ClientList} /> } />
							<Route path="components/externalbrokers" element={<RoleBasedRoute roles={["Administrator"]} component={SupplierList} />} />
							<Route path="components/transactions" element={<RoleBasedRoute roles={["Administrator","Broker"]} component={TransactionList} /> } />
							<Route path="components/invoices" element={<RoleBasedRoute roles={["Administrator"]} component={CommisssionInvoiceList} />} />

							<Route path="components/resume" element={<RoleBasedRoute roles={["Administrator"]} component={Resume} />} />
							<Route path="components/contracts" element={<Contract />} />
							<Route path="components/settings" element={<RoleBasedRoute roles={["Administrator"]} component={Parameters} />} />
						</Route>
					</Route>
				</Route>
				<Route element={<ErrorLayout />}>
					<Route path="errors/404" element={<Error404 />} />
					<Route path="errors/500" element={<Error500 />} />
				</Route>
				<Route path="*" element={<Error404 />}></Route>
			</Routes>
			{/*<SettingsToggle />*/}
			<SettingsPanel />
			<ToastContainer
				closeButton={CloseButton}
				icon={false}
				position={toast.POSITION.BOTTOM_LEFT}
			/>
		</>
	);
};

export default Layout;
