import UserUpdate from 'components/users/UserEditForm';


const EditUserForm = (props) => {

    return (
        <>
            <UserUpdate isUser={ true }/>
        </>
    );
};


export default EditUserForm;