import React, { useState, useEffect } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import FalconDropzone from 'components/common/FalconDropzone';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Col, Row, Button, Spinner, Modal, Table, Dropdown, Form } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';
import TextFilter from 'components/filters/textFilter';
import NumberFilter from 'components/filters/numberFilter';
import DateRangeFilter from 'components/filters/daterangeFilter';
import SoftBadge from 'components/common/SoftBadge';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosInstance from 'helpers/axiosInstance';
import { toast } from 'react-toastify';
import { cloneObject, getUserRole } from 'helpers/utils';
import { PaymentForm } from 'viewModel/DtoClasses';

const MAX_FILESIZE = 2;	// in Mo

const TransactionList = () => {
    const [state, setState] = useState({
        sortBy: [
            { id: 'dueDate', desc: true }
        ],
        total: 0,
        totalPages: 0,
        remoteData: [],
        pageSize: 10,
        currentPage: 0
    });

    const [historyState, setHistoryState] = useState({
        sortBy: [
            { id: 'uploadedDate', desc: true }
        ],
        total: 0,
        totalPages: 0,
        remoteData: [],
        pageSize: 10,
        currentPage: 0
    });

    const [checkboxes, setCheckboxes] = useState([]);
    const [isLoading, showLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showStatementHistory, setShowStatementHistory] = useState(false);
    const [bankStatement, setBankStatement] = useState(null);
    const fetchIdRef = React.useRef(0);
    const fetchIdHistoryRef = React.useRef(0);
    const [paymentKinds, setPaymentKinds] = useState({ data: [] });
    const [paymentRecipients, setPaymentRecipients] = useState({ data: [] });
    const [statementFile, setStatementFile] = useState(null);

    useEffect(() => {
        axiosInstance.get(`${window.location.origin}/parameters`).then(response => {
            setPaymentKinds({
                data: response.data.paymentKinds.values
            });
            setPaymentRecipients({
                data: response.data.paymentRecipients.values
            })
        })
        document.title = "Transactions";
    }, [])

    const handlePaymentClearedOrReconciled = async (ev, id, type, paymentDetail) => {
        if (ev)
            ev.stopPropagation();
        try {
            let form = new FormData();
            if (paymentDetail != null && paymentDetail.trim() != "")
            form.append("details", paymentDetail);
            let req = await axiosInstance.put(`${window.location.origin}/payments/${id}/payment-state?type=${type}`, form);
            if (req.status == 204) {
                toast.success("Payment state has been changed", { theme: 'colored' });
                console.log(state);
                const updatedList = state.remoteData.map(obj => {
                    if (obj.id === id) {
                        if (type == "recon") return { ...obj, reconciled: !obj.reconciled, reconciliationDetails: paymentDetail != null ? paymentDetail : obj.reconciliationDetails };
                        else if (type == "clear") return { ...obj, cleared: !obj.cleared, reconciliationDetails: paymentDetail };
                    }
                    return obj;
                });
                setState({
                    ...state,
                    remoteData: updatedList,
                });
                let cb = document.getElementById(`${type}_${id}`);
                if (cb)
                    cb.checked = !cb.checked;
            } else {
                toast.error("There was an error changing the payment's state", { theme: 'colored' });
            }
        } catch (ex) {
            console.error(ex);
            toast.error("There was an error changing the payment's state", { theme: 'colored' });
        }

    }

    const columns = [
        {
            accessor: 'fileName',
            sort: true,
            Header: 'File number',
            Cell: rowData => {
                const { fileName, contractId } = rowData.row.original;
                return (
                    <Link target="_blank" className='fw-bold text-primary' to={`/components/contracts/editSecondPage?id=${contractId}`}>{fileName}</Link>
                )
            }
        },
        {
            accessor: 'yachtName',
            sort: true,
            Header: 'Yacht',
            Cell: rowData => {
                const { yachtName, yachtId } = rowData.row.original;
                return (
                    <Link target="_blank" className='fw-bold text-primary' to={`/components/yachts/edit?id=${yachtId}`}>{yachtName}</Link>
                )
            }
        },
        {
            accessor: 'cleared',
            sort: true,
            Header: 'Cleared',
            Cell: rowData => {
                const { cleared, id } = rowData.row.original;
                return (

                    <div style={{ 'textAlign': 'center' }}>
                        <Form.Check
                            type='checkbox'
                            id={`clear_${id}`}
                            checked={cleared}
                            onClick={(e) => handlePaymentClearedOrReconciled(e, rowData.row.original.id, "clear", null)}
                        />
                    </div>)
            }
        },
        {
            accessor: 'reconciled',
            sort: true,
            Header: 'Reconciled',
            Cell: rowData => {
                const { reconciled, id } = rowData.row.original;
                return (
                    <div style={{ 'textAlign': 'center' }}>
                        <Form.Check
                            type='checkbox'
                            id={`recon_${id}`}
                            checked={reconciled}
                            onClick={(e) => handlePaymentClearedOrReconciled(e, rowData.row.original.id, "recon", null)}
                        />
                    </div>)
            }
        },
        {
            accessor: 'kind',
            sort: true,
            Header: 'Kind'
        },
        {
            accessor: 'type',
            sort: true,
            Header: 'Type'
        },

        {
            accessor: 'amountToPay',
            sort: true,
            Header: 'Amount to pay',
            Cell: rowData => {
                const { amountToPay, currency } = rowData.row.original;
                let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: currency });
                return (
                    <span>{formatter.format(amountToPay)}</span>
                )
            }
        },
        {
            accessor: 'dueDate',
            sort: true,
            Header: 'Due date',
            Cell: rowData => {
                const { dueDate } = rowData.row.original;
                let date = moment(dueDate).format('DD/MM/YYYY HH:mm');
                return (
                    <span>{date}</span>
                )
            }
        },
        {
            accessor: 'amountReceived',
            sort: true,
            Header: 'Amount received',
            Cell: rowData => {
                const { amountReceived, currency, amountToPay } = rowData.row.original;
                let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: currency });
                return (
                    <span>{formatter.format(amountReceived)} {amountToPay != amountReceived ? (<FontAwesomeIcon icon="fa-solid fa-circle-exclamation" className='mx-2 text-warning' />) : ("")}</span>
                )
            }
        },
        {
            accessor: 'paymentDate',
            sort: true,
            Header: 'Payment date',
            Cell: rowData => {
                const { paymentDate } = rowData.row.original;
                if (paymentDate != null) {
                    let date = moment(paymentDate).format('DD/MM/YYYY HH:mm');
                    return (
                        <span>{date}</span>
                    )
                } else {
                    return (null)
                }

            }
        },
        {
            accessor: "office",
            Header: "Office"
        },
    ];

    const statementColumns = [
        {
            accessor: 'fileName',
            sort: true,
            Header: 'File Name',
            Cell: rowData => {
                const { name, id } = rowData.row.original;
                return (
                    <>
                        <i class="fa-solid fa-file-csv"></i>
                        <FontAwesomeIcon icon="fa-solid fa-file-csv" className='pe-1' />
                        <b className='text-primary cursor-pointer' onClick={() => { downloadStatement(id) }}>{name}</b>
                    </>
                )
            }
        },
        {
            accessor: 'length',
            sort: true,
            Header: 'File size',
            Cell: rowData => {
                const { length } = rowData.row.original;
                return (
                    <span><b>{Math.round(length / 1024)} kB</b></span>
                )
            }
        },
        {
            accessor: 'personName',
            sort: true,
            Header: 'Uploaded by',
            Cell: rowData => {
                const { personName, id } = rowData.row.original;
                return (

                    <div style={{ 'textAlign': 'center' }}>
                        <span>{personName}</span>
                    </div>)
            }
        },
        {
            accessor: 'uploadedDate',
            sort: false,
            Header: 'Upload date',
            Cell: rowData => {
                const { uploadedDate } = rowData.row.original;
                return (

                    <div className='fw-bolder' style={{ 'textAlign': 'center' }}>
                        <FontAwesomeIcon icon="fa-solid fa-cloud-arrow-up" className='pe-1' />
                        {moment(uploadedDate).utcOffset(0).format('DD/MM/YYYY - HH:mm')}
                    </div>)
            }
        }
    ];

    const downloadStatement = (id) => {
        axiosInstance.get(`${window.location.origin}/payments/statement-history/${id}`, { responseType: 'blob' })
            .then((response) => {
                console.log(response);
                const contentDisposition = response.headers["content-disposition"];
                let fileName = response.headers["content-disposition"]
                    .split("filename*=UTF-8''")[1];
                fileName = decodeURI(fileName.trim()).trim();
                const contentType = response.headers["content-type"];
                const blob = new Blob([response.data], { type: contentType });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            });
    }

    const getTransactions = React.useCallback(({ page, size, sortBy }) => {
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;

        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
            showLoading(true);
            let formQuery = $("#tableFilters").serialize();
            formQuery += `${(formQuery.length > 0 ? "&" : "")}page=${page}`;
            formQuery += `&take=${size}`;
            if (sortBy && sortBy.length > 0) {
                let sortQuery = encodeURIComponent(sortBy.map(x => `${x.id} ${x.desc ? "desc" : "asc"}`).join(','));
                formQuery += `&sort=${sortQuery}`;
            }
            axiosInstance.get(`${window.location.origin}/payments/Transactions?${formQuery}`)
                .then((res) => {
                    setState({
                        sortBy: sortBy,
                        total: res.data.count,
                        totalPages: Math.ceil(res.data.count / (size * 1.0)),
                        remoteData: res.data.data,
                        pageSize: size,
                        currentPage: page
                    });
                    setCheckboxes((res.data.data.map(x => ({ id: x.id, reconciled: x.reconciled, cleared: x.cleared }))));
                    showLoading(false);
                });
        }
    }, []);

    const exportTableToExcel = () => {
        setExportLoading(true);
        let formQuery = $("#tableFilters").serialize();
        axiosInstance.get(`${window.location.origin}/payments/Transactions/Export-data?${formQuery}`,
            { responseType: 'blob' })
            .then(response => {
                let blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `transactions${moment(new Date()).format("DDMMYYYY")}.xlsx`
                link.click()
                setExportLoading(false);
            }).catch(e => {
                toast.error("There was an error exporting the data", { theme: 'colored' })
                setExportLoading(false);
            })
    }

    const search = () => {
        getTransactions({ page: 0, size: state.pageSize, sortBy: state.sortBy });
    };

    const transactionTable = () => {

        /*Filters*/
        const renderFilters = (visible) => {
            if (visible) {
                return (<></>);
            } else {
                return (
                    <>
                        <form id="tableFilters">
                            <Row className="mb-3 g-3 justify-content-end">
                                <Col lg={2}>
                                    <TextFilter label="File number" id="FileName" placeholder="search..." />
                                </Col>
                                <Col lg={2}>
                                    <TextFilter label="Yacht" id="Yacht" placeholder="search..." />
                                </Col>
                                <Col lg={2}>
                                    <TextFilter label="Type" id="Kind" placeholder="search..." />
                                </Col>
                                <Col lg={2}>
                                    <NumberFilter label="Amount to pay" id="AmountToPay" />
                                </Col>
                                <Col lg={2}>
                                    <DateRangeFilter label="Due date" id="DueDate" placeholder="search..." />
                                </Col>
                                <Col lg={2}>
                                    <TextFilter label="Office" id="Office" placeholder="search..." />
                                </Col>
                                <Col lg={2}>
                                    <NumberFilter label="Amount received" id="AmountReceived" />
                                </Col>
                                <Col lg={2}>
                                    <DateRangeFilter label="Payment date" id="PaymentDate" placeholder="search..." />
                                </Col>
                                <Col lg={1} md={2}>
                                    <Form.Group>
                                        <Form.Label>Cleared</Form.Label>
                                        <Form.Select className="me-2 px-2" id="Cleared" name="Cleared">
                                            <option value="">Both</option>
                                            <option value="true">Yes</option>
                                            <option value="false" selected="true">No</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col lg={1} md={2}>
                                    <Form.Group>
                                        <Form.Label>Reconciled</Form.Label>
                                        <Form.Select className="me-2 px-2" id="Reconciled" name="Reconciled">
                                            <option value="">Both</option>
                                            <option value="true">Yes</option>
                                            <option value="false" selected="true">No</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </form>
                    </>);
            }
        }

        /*Table layout*/
        return (
            <>
                <AdvanceTableWrapper
                    columns={columns}
                    sortable
                    initialSortBy={state.sortBy}
                    pagination
                    perPage={state.pageSize}
                    data={state.remoteData}
                    pageCount={state.totalPages}
                    fetchData={getTransactions}
                    initialPage={state.currentPage}
                    isLoading={isLoading}
                >
                    {/*Filters row*/}
                    {renderFilters(showFilters)}

                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                        rowClick={handleEditPayment}
                    />
                    <div className="mt-3">
                        <AdvanceTableFooter
                            rowCount={state.total}
                            pageCount={state.totalPages}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                        />
                    </div>
                </AdvanceTableWrapper>
            </>
        );
    }

    const loadStatement = (files) => {
        const file = files[0];
        if (file.size > MAX_FILESIZE * 1024 * 1024) {
            toast.error('File too large', { theme: 'colored' });
            return;
        }
        if (file.type !== 'text/csv') {
            toast.error('Invalid file format', { theme: 'colored' });
            return;
        }
        setBankStatement([]);
        const formData = new FormData();
        formData.append('content', file.base64);
        formData.append('name', file.path);
        formData.append('size', file.size);
        formData.append('type', file.type);
        axiosInstance.post(`${window.location.origin}/payments/LoadBankStatement`, formData).then((result) => {
            setBankStatement(result.data);
            setStatementFile(file);
        }).catch(err => {
            setBankStatement(null);
            toast.error(err.response.data, { theme: 'colored' })
        });
    };

    const handleOnStatementSelect = (key, index) => {
        setBankStatement(bankStatement.map((line, i) => {
            if (i === index)
                return { ...line, transaction: key ? parseInt(key) : null };
            else if (key && line.transaction === parseInt(key))
                return { ...line, transaction: null };
            else
                return line;
        }));
    };

    const handleOnModalClose = _ => {
        setShowModal(false);
        setBankStatement(null);
    };

    const handleOnModalSave = _ => {
        const formData = new FormData();
        console.log(bankStatement.filter(x => x.transaction));
        let statementJson = JSON.stringify(bankStatement.filter(x => x.transaction));
        formData.append('content', statementFile.base64);
        formData.append('name', statementFile.path);
        formData.append('size', statementFile.size);
        formData.append('type', statementFile.type);
        formData.append('statementJson', statementJson);
        axiosInstance.post(`${window.location.origin}/payments/statement/file`, formData).then((r) => {
            toast.success(`CSV File saved.`, {
                theme: 'colored'
            });
        });
        bankStatement.forEach(line => {
            if (line.transaction)
                handlePaymentClearedOrReconciled(null, line.transaction, 'recon', line.details);
        });
        setShowModal(false);
        setStatementFile(null);
        setBankStatement(null);
    };

    const [IsModalOpened, setIsModalOpened] = useState(false);
    const [newPayment, setNewPayment] = useState(new PaymentForm());
    const role = getUserRole();
    const handleAddObj = async () => {
        // try {
        let res = await axiosInstance.put(`${window.location.origin}/payments`, newPayment);
        if (res.status === 200) {
            toast.success(`Payment successfully ${newPayment.id != 0 ? 'saved' : 'created'}.`, {
                theme: 'colored'
            });
            let data = [...state.remoteData];
            let i = data.findIndex(x => x.id == newPayment.id);
            data[i] = newPayment;
            setState({ ...state, remoteData: data });

            setIsModalOpened(false);
        }
        else {
            toast.error(`An error occurred.`, {
                theme: 'colored'
            });
        }
    }

    const handleDelete = async e => {
        try {
            let res = await axiosInstance.delete(`${window.location.origin}/payments/${newPayment.id}`);
            if (res.status === 204) {
                toast.success(`Payment deleted successfully.`, {
                    theme: 'colored'
                });
                setNewPayment(new PaymentForm());
            }
            else {
                toast.error(`There was an error modifying the payment.`, {
                    theme: 'colored'
                });
            }

        }
        catch (err) {
            console.error(err);
            toast.error(`Error deleting the payment.`, {
                theme: 'colored'
            });
        }
        setIsModalOpened(false);
    }

    const handleEditPayment = (obj) => {
        let object = cloneObject(obj.original);
        if (object.dueDate)
            object.dueDate = new Date(object.dueDate);
        if (object.paymentDate)
            object.paymentDate = new Date(object.paymentDate);
        console.log(object);
        setNewPayment(object);
        setIsModalOpened(true);
    }

    const loadPaymentKindOptions = () => {
        if (paymentKinds.data) {

            return (
                <>
                    <option value={-1} key={-1}>Select payment type</option>
                    {
                        paymentKinds.data.map(payment => (<option value={payment.name} key={payment.id} >
                            {`${payment.name}`}
                        </option>))
                    }
                </>
            );

        }
    }

    const loadPaymentRecepientOptions = () => {
        if (paymentRecipients.data) {

            return (
                <>
                    <option value={-1} key={-1}>{newPayment.type == "In" ? "Select payment from" : "Select payment to"}</option>
                    {
                        paymentRecipients.data.map(payment => (<option value={payment.name} key={payment.id} >
                            {`${payment.name}`}
                        </option>))
                    }
                </>
            );

        }
    }

    const switchHistoryModal = () => {
        setShowModal(!showModal);
        setShowStatementHistory(!showStatementHistory);
    }

    const getHistories = React.useCallback(({ page, size, sortBy }) => {
        // Give this fetch an ID
        const fetchId = ++fetchIdHistoryRef.current;

        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdHistoryRef.current) {
            showLoading(true);
            let formQuery = `page=${page}&take=${size}`;
            if (sortBy && sortBy.length > 0) {
                let sortQuery = encodeURIComponent(sortBy.map(x => `${x.id} ${x.desc ? "desc" : "asc"}`).join(','));
                formQuery += `&sort=${sortQuery}`;
            }
            axiosInstance.get(`${window.location.origin}/payments/statement-history?${formQuery}`)
                .then((res) => {
                    setHistoryState({
                        sortBy: sortBy,
                        total: res.data.count,
                        totalPages: Math.ceil(res.data.count / (size * 1.0)),
                        remoteData: res.data.data,
                        pageSize: size,
                        currentPage: page
                    });
                    setCheckboxes((res.data.data.map(x => ({ id: x.id, reconciled: x.reconciled, cleared: x.cleared }))));
                    showLoading(false);
                });
        }
    }, []);


    return (
        <>
            <FalconComponentCard>
                <FalconComponentCard.Header
                    light={false}
                    className="border-bottom border-200"
                >
                    <div className="d-inline">
                        <h4 className="float-start">Transactions</h4>
                        <div className="float-end">
                            {!showFilters && <Button variant='secondary' size='sm' className='me-2 px-4' onClick={search}>
                                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className='pe-1' />Search</Button>}
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="fa-filter"
                                transform="shrink-3"
                                className='me-2'
                                onClick={() => setShowFilters(!showFilters)}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Filters</span>
                            </IconButton>
                            <IconButton
                                variant="primary"
                                size="sm"
                                icon="fa-cloud-arrow-up"
                                transform="shrink-3"
                                className='me-2'
                                onClick={() => setShowModal(true)}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Import</span>
                            </IconButton>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="external-link-alt"
                                transform="shrink-3"
                                onClick={exportTableToExcel}
                            >
                                {exportLoading ? (<Spinner animation="border" role="status" size="sm" className='ms-2'>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>) : "Export"}
                            </IconButton>
                        </div>
                    </div>
                </FalconComponentCard.Header>
                <FalconComponentCard.Body
                    children={transactionTable()}
                    scope={{
                        AdvanceTableWrapper,
                        AdvanceTable,
                        AdvanceTableFooter,
                        AdvanceTableSearchBox,
                        IconButton,
                        Link
                    }}
                    language="jsx"
                    noInline
                    noLight
                />
            </FalconComponentCard>

            <Modal
                show={showModal}
                onHide={handleOnModalClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header className='border-bottom-0'>
                    <h4>Bank Reconciliation</h4>
                </Modal.Header>
                <Modal.Body className='bg-light'>
                    {bankStatement === null ?
                        <FalconDropzone
                            /*accept={{ 'CSV': ['.csv'] }} - Not working! */
                            multiple={false}
                            placeholder={
                                <>
                                    <FontAwesomeIcon icon="fa-cloud-arrow-up" className="me-2" />
                                    <span className="fs-0 mb-0 text-700">Upload your bank statement</span>
                                    <p className="mb-0 w-75 mx-auto text-500">Upload a CSV file with a maximum size of {MAX_FILESIZE}Mo</p>
                                </>
                            }
                            onChange={loadStatement}
                        />
                        :
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Details</th>
                                    <th style={{ textAlign: 'right' }}>Amount</th>
                                    <th>Value Date</th>
                                    <th style={{ textAlign: 'center' }}>Transaction</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bankStatement.map((line, i) => {
                                    const formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: line.currency });
                                    return <tr key={i} style={{ color: line.transaction ? 'green' : line.transactions.length ? 'initial' : 'silver' }}>
                                        <td>{moment(line.date).format('DD/MM/YYYY')}</td>
                                        <td>{line.details}</td>
                                        <td style={{ textAlign: 'right' }}>{formatter.format(line.amount)}</td>
                                        <td>{moment(line.valueDate).format('DD/MM/YYYY')}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <Dropdown onSelect={k => handleOnStatementSelect(k, i)}>
                                                <Dropdown.Toggle
                                                    variant={line.transaction ? 'success' : line.transactions.length ? 'warning' : 'light'}
                                                    disabled={!line.transactions.length}
                                                    style={{ padding: '0 6px' }}
                                                    size="sm"
                                                >
                                                    {line.transaction ? 'Selected' : line.transactions.length ? 'Select' : 'No match'}
                                                    {line.transactions.length > 0 && <FontAwesomeIcon icon='fa-caret-down' size="sm" className="ms-2" />}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item active={!line.transaction}>None</Dropdown.Item>
                                                    {line.transactions.map(t =>
                                                        <Dropdown.Item key={t.id} eventKey={t.id} active={t.id === line.transaction}>
                                                            <b>Contract {t.fileName} - Yacht {t.yachtName}</b><br />
                                                            Kind: {t.kind}<br />
                                                            Office: {t.office}<br />
                                                            Amount: {formatter.format(t.amountReceived)}<br />
                                                            Payment date: {moment(t.paymentDate).format('DD/MM/YYYY')}
                                                        </Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>;
                                }
                                )}
                            </tbody>
                        </Table>
                    }
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <div>
                        {
                            role == "Administrator" && (<Button variant="secondary" onClick={switchHistoryModal}>Saved statement files</Button>)
                        }

                    </div>
                    <div>
                        <Button variant="secondary" onClick={handleOnModalClose} className='me-2'>Cancel</Button>
                        <Button variant="success" onClick={handleOnModalSave} disabled={!bankStatement?.find(x => x.transaction)}>Validate</Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showStatementHistory}
                onHide={setShowStatementHistory}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header className='border-bottom-0'>
                    <h4>Bank Reconciliation CSV files history</h4>
                </Modal.Header>
                <Modal.Body className='bg-light'>
                    <AdvanceTableWrapper
                        columns={statementColumns}
                        sortable
                        initialSortBy={historyState.sortBy}
                        pagination
                        perPage={historyState.pageSize}
                        data={historyState.remoteData}
                        pageCount={historyState.totalPages}
                        fetchData={getHistories}
                        initialPage={historyState.currentPage}
                        isLoading={isLoading}
                    >

                        <AdvanceTable
                            table
                            headerClassName="d-none"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                        <div className="mt-3">
                            <AdvanceTableFooter
                                rowCount={historyState.total}
                                pageCount={historyState.totalPages}
                                table
                                rowInfo
                                navButtons
                                rowsPerPageSelection
                            />
                        </div>
                    </AdvanceTableWrapper>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={switchHistoryModal}>Import new file</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={IsModalOpened}
                onHide={() => { setIsModalOpened(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <h4>{newPayment.type == "In" ? "Incoming payment" : "Outgoing payment"}</h4>
                </Modal.Header>
                <Modal.Body >
                    <div className='row mx-0'>
                        <Form.Group className="mt-3 col-12 d-none">
                            <Form.Label>Transaction type</Form.Label>
                            <Form.Select disabled onChange={e => { setType(e.target.value); }}>
                                <option value={0} key={0}>Select transaction type</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="col-6">
                            <Form.Label className="mb-2">Amount to {newPayment.type == "In" ? "Receive" : "Pay"}</Form.Label>
                            <Form.Control
                                onWheel={(e) => e.target.blur()}
                                disabled={role != "Administrator"}
                                value={newPayment.amountToPay}
                                onChange={(e) => {
                                    const rawValue = e.target.value.replace(/[^0-9.,]/g, ''); // Remove non-numeric, non-comma, non-dot characters
                                    const numericValue = rawValue.replace(/,/g, '.'); // Replace commas with dots
                                    // Only allow one dot or comma
                                    if ((numericValue.match(/\./g) || []).length > 1 || (numericValue.match(/,/g) || []).length > 1) {
                                        return;
                                    }
                                    setNewPayment({ ...newPayment, amountToPay: numericValue });
                                }}
                                name="amount"
                            />
                        </Form.Group>
                        <Form.Group className="col-6">
                            <Form.Label className="mb-2">
                                Amount {newPayment.type === "In" ? "Received" : "Paid"}
                            </Form.Label>
                            <Form.Control
                                disabled={role !== "Administrator"}
                                onWheel={(e) => e.target.blur()}
                                value={newPayment.amountReceived}
                                onChange={(e) => {
                                    const rawValue = e.target.value.replace(/[^0-9.,]/g, ''); // Remove non-numeric, non-comma, non-dot characters
                                    const numericValue = rawValue.replace(/,/g, '.'); // Replace commas with dots

                                    // Only allow one dot or comma
                                    if ((numericValue.match(/\./g) || []).length > 1 || (numericValue.match(/,/g) || []).length > 1) {
                                        return;
                                    }
                                    setNewPayment({ ...newPayment, amountReceived: numericValue });

                                }}
                                name="amount"
                            />
                        </Form.Group>
                        <Form.Group className="mt-3 col-6">
                            <Form.Label className="mb-2">Due date</Form.Label>
                            <DatePicker
                                autoComplete="off"
                                selected={newPayment.dueDate}
                                onChange={(date) => {
                                    setNewPayment({ ...newPayment, dueDate: new Date(date.getTime() - date.getTimezoneOffset() * 60000) });
                                }}
                                formatWeekDay={day => day.slice(0, 3)}
                                className='form-control'
                                value={newPayment.dueDate}
                                disabled={role != "Administrator"}
                                name="dueDate"
                                format="dd/MM/yyyy"

                                dateFormat="dd/MM/yyyy"
                            />
                        </Form.Group>
                        <Form.Group className="mt-3 col-6">
                            <Form.Label className="mb-2">Payment date</Form.Label>
                            <DatePicker
                                disabled={role != "Administrator"}
                                autoComplete="off"
                                onChange={(date) => {
                                    setNewPayment({ ...newPayment, paymentDate: new Date(date.getTime() - date.getTimezoneOffset() * 60000) });
                                }}
                                formatWeekDay={day => day.slice(0, 3)}
                                className='form-control'
                                selected={newPayment.paymentDate}
                                name="PaymentDate"
                                format="dd/MM/yyyy"
                                dateFormat="dd/MM/yyyy"
                            />
                        </Form.Group>
                        <Form.Group className="mt-3 col-6">
                            <Form.Label className="mb-2">Payment type</Form.Label>
                            <Form.Select disabled={role != "Administrator"}
                                onChange={(e) => {
                                    setNewPayment({ ...newPayment, kind: e.target.value });
                                }}
                                value={newPayment.kind}
                            >

                                {loadPaymentKindOptions()}

                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mt-3 col-6">
                            <Form.Label className="mb-2">{newPayment.type == "In" ? "Payment From" : "Payment To"}</Form.Label>
                            <Form.Select disabled={role != "Administrator"} value={newPayment.paymentRecipient} onChange={e => { setValue({ ...value, paymentRecipient: e.target.value }); setNewObject({ ...newObject, paymentRecipient: e.target.value }) }} >

                                {loadPaymentRecepientOptions()}

                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='d-block w-100 my-2'>
                            <Form.Label className="mb-2">Note</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={role != "Administrator"}
                                as="textarea"
                                rows={2}
                                value={newPayment.note}
                                onChange={(e) => {
                                    setNewPayment({ ...newPayment, note: e.target.value });
                                }}
                                id="note"
                            />
                        </Form.Group>
                        {
                            <Form.Group className='d-block w-100 my-2'>
                                <Form.Label className="mb-2">Payment details</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={role != "Administrator"}
                                    as="textarea"
                                    rows={3}
                                    value={newPayment.reconciliationDetails}
                                    onChange={(e) => {
                                        setNewPayment({ ...newPayment, reconciliationDetails: e.target.value });
                                    }}
                                    id="note"
                                />
                            </Form.Group>
                        }

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="w-100">

                        {
                            role == "Administrator" && (
                                <Col className="justify-content-start">
                                    {newPayment.id != 0 ?
                                        <Button
                                            variant="danger"
                                            onClick={() => { handleDelete(); }}
                                        >
                                            Delete
                                        </Button> : null
                                    }
                                </Col>)
                        }

                        <Col className="d-flex justify-content-end">
                            {newPayment.type == "Out" &&
                                <Link target="_blank" className='btn fw-bold btn-primary me-2' to={`/components/payments/${newPayment.id}/remittance`}>Remittance</Link>
                            }
                            {role == "Administrator" && (
                                <Button
                                    variant="primary"
                                    className="me-2"
                                    onClick={() => {
                                        handleAddObj();
                                    }
                                    }
                                    disabled={(!newPayment.amountToPay || !newPayment.dueDate || !newPayment.kind)}
                                >
                                    Save

                                </Button>
                            )
                            }
                            {/*<Button onClick={() => { setModalShow(false); setNewObject({ kind: {}, Amount: 0 }); }}>Cancel</Button>*/}
                        </Col>


                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TransactionList;
