import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosInstanceWithDate from 'helpers/axiosInstanceWithDate';
import ContractFormBase from './ContractFormBase';
import { ContractForm } from 'viewModel/DtoClasses';
import axiosInstance from 'helpers/axiosInstance';
import { toast } from 'react-toastify';
import { cloneObject, getUserRole } from 'helpers/utils';
import moment from 'moment';

const EditContract = (props) => {
	const [formData, setFormData] = useState(new ContractForm());
	const [creationText, setCreationText] = useState("Save");
	let navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [attachmentsData, setAttachmentsData] = useState({ data: [] });
	const [searchParams, setSearchParams] = useSearchParams();
	const [emails, setEmails] = useState([]);
	const [defaultEmails, setDefaultEmails] = useState([]);
	const role = getUserRole();

	const getData = () => {
		let requests = [axiosInstanceWithDate.get(`${window.location.origin}/contracts/${searchParams.get("id")}`)]
		axiosInstanceWithDate.get(`${window.location.origin}/uploadFiles/GetFiles?FilesFrom=${"contract-" + searchParams.get("id")}`).then(x => {
			setAttachmentsData({ data: x.data });
		});
		axios.all(requests).then(axios.spread((...responses) => {
			let contract = responses[0].data.contract;
			contract.charter.startTime = new Date(contract.charter.startDate.getTime() + contract.charter.startDate.getTimezoneOffset() * 60000)
			contract.charter.endTime = new Date(contract.charter.endDate.getTime() + contract.charter.endDate.getTimezoneOffset() * 60000);
			setFormData(contract);
			setEmails(responses[0].data.emails);
			setDefaultEmails(responses[0].data.defaultEmails);
			document.title = contract.fileName;
		}));
	}

	useEffect(() => {
		getData();
	}, [])

	const handleSubmit = async (regenPayment) => {
		setCreationText("Updating contract...");
		const form = formData.contractDate != null;
		const formSecond = formData.fileName != "";
		const formThird = !(formData.yacht.id == 0 || !formData.yacht.id);
		const formFourth = (!formData.yacht?.ca && !(formData.charter.clientId == 0 || !formData.charter.clientId)) || (formData.yacht?.ca && formData.caClientName != null);
		const formFifth = formData.charter.placeFrom != "";
		const formSixth = formData.charter.placeTo != "";
		const formSeventh = formData.charter.startDate != null;
		const formEighth = formData.charter.endDate != null;
		var forms = document.querySelectorAll('form');
		Array.prototype.slice.call(forms)
			.forEach(function (form) {
				form.checkValidity();
				form.classList.add('was-validated');
			})
		if (form === false || formSecond === false || formThird === false || formFourth === false || formFifth === false
			|| formSixth === false || formSeventh === false || formEighth === false) {
			if (!formThird)
				document.getElementById("yachtAsyncSelector").lastChild.classList.add("border-danger");
			if (!formFourth && !formData.yacht?.ca)
				document.getElementById("clientAsyncSelector").lastChild.classList.add("border-danger");
		}
		else {
			document.getElementById("yachtAsyncSelector").lastChild.classList.remove("border-danger");
			if (!formData.yacht?.ca)
				document.getElementById("clientAsyncSelector").lastChild.classList.remove("border-danger");
			let postData = cloneObject(formData);
			postData.charter.endDate = moment(formData.charter.endDate).utcOffset(0, true).format("YYYY-MM-DDT") + moment(formData.charter.endTime).utcOffset(0, true).format("HH:mm:ss") + "Z";
			postData.charter.startDate = moment(formData.charter.startDate).utcOffset(0, true).format("YYYY-MM-DDT") + moment(formData.charter.startTime).utcOffset(0, true).format("HH:mm:ss") + "Z";
			try {
				setLoading(true);
				let id = formData.id.toString();
				let res = await axiosInstance.put(`${window.location.origin}/contracts/${id}?regenPayment=${regenPayment}`, postData);
				console.log(res);
				if (res.status == 200) {
					toast.success(`The contract has successfully been updated.`, {
						theme: 'colored'
					});
					document.querySelectorAll(".was-validated").forEach(x => {
						x.classList.remove("was-validated");
					})
					if (attachmentsData.data.length != 0) {
						setCreationText("Uploading files...");
						const attachmentForms = new FormData();
						for (let x = 0; x < attachmentsData.data.length; x++) {
							let obj = attachmentsData.data[x];
							attachmentForms.append("files[" + x + "].file", obj.file);
							attachmentForms.append("files[" + x + "].type", obj.type);
						}
						attachmentForms.append("uploadTo", "contract-" + res.data.Id);
						let test = await axiosInstance.post(`${window.location.origin}/uploadFiles/multiple`, attachmentForms);
						toast.success("Files uploaded", { theme: 'colored' });
					}

				} else {
					toast.error(`${res.data.message}`, {
						theme: 'colored'
					});
				}
				if (regenPayment)
					getData();

			} catch (e) {
				console.log(e);
				if (e.response?.data) {
					console.log(e.response.data);
					if (e.response.data.some(v => v.includes("duplicate"))) {
						toast.error("A contract already exists with this name.", { theme: 'colored' });
					} else {
						toast.error("An error has occurred while creating the contract.", { theme: 'colored' });
						e.response.data.forEach(msg => {
							toast.error(msg, { theme: 'colored' });
						})
					}
				}
				console.warn(e);
			} finally {
				setLoading(false);
				setCreationText("Save");
			}
		}
	};
	// useEffect(
	// 	() => {
	// 		if ((role == "Administrator" || role == "Broker") && loaded && paymentUpdated) {
	// 			axiosInstanceWithDate.get(`${window.location.origin}/widgets/Notifications/${searchParams.get("id")}`).then(res => {
	// 				setWarnings(res.data);
	// 			})
	// 			setPaymentUpdated(false)
	// 		}

	// 	}, [paymentUpdated]
	// )

	return (
		<ContractFormBase
			attachmentsData={attachmentsData}
			formData={formData}
			setFormData={setFormData}
			setAttachmentsData={setAttachmentsData}
			creationText={creationText}
			handleSubmit={handleSubmit}
			loading={loading}
			emails={emails}
			setEmails={setEmails}
			defaultEmails={defaultEmails}
		/>
	)
};

export default EditContract;
