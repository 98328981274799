import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from 'assets/img/illustrations/my-sea-logo.png';

const Logo = ({ at, width, className, textClass, ...rest }) => {
    return (
        <Link
            to="/"
            className={classNames(
                'text-decoration-none',
                { 'navbar-brand text-left': at === 'navbar-vertical' },
                { 'navbar-brand text-left': at === 'navbar-top' }
            )}
            {...rest}
        >
            <div
                className={classNames(
                    'd-flex',
                    {
                        'align-items-center': at === 'navbar-vertical',
                        'align-items-center': at === 'navbar-top',
                        'flex-center fw-bolder fs-5 mb-4': at === 'auth'
                    },
                    'justify-content-center',
                    className
                )}
            >
                <img src={logo} alt="Logo" width='100px'/>
            </div>
        </Link>
    );
};

Logo.propTypes = {
    at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
    width: PropTypes.number,
    className: PropTypes.string,
    textClass: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 120 };

export default Logo;
