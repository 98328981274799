import React, { useEffect, useRef } from 'react';
import { Card, Col, Row, Form, FloatingLabel, Button, Spinner, Table, InputGroup, ToggleButton, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import DatePicker, { registerLocale } from 'react-datepicker';
import { formatInCurrency } from 'helpers/utils';

const CharterFeesForm = ({
	formData,
	setFormData,
	handleFieldChange,
	handleSelectChange,
	isCentralAgent2,
	validated,
	loadCurrencyOptions,
	charterFeeTotal,
	handleCharterFieldChange,
	formatter,
	getCharterFeeTotal,
	setReadjustPayment
}) => {
	let form = '';

	const setWarning = () => {
		if (formData.id > 0)
			setReadjustPayment(true);
	}

	return (
		<Row className="mb-3 g-3">
			<Col sm="auto" lg={12}>
				<Card>
					<Card.Header className="d-flex justify-content-between align-items-center">
						<h5 className="float-start">Fees: <span className='text-danger'>{formatter.format(getCharterFeeTotal())}</span></h5>
						<div>
							<Form.Group>
								<Form.Select className='form-select-sm' onChange={(e) => { handleSelectChange(e, "currency"); }} value={formData.charter.currency}>
									{loadCurrencyOptions()}
								</Form.Select>
							</Form.Group>
						</div>

					</Card.Header>
					<Card.Body className="bg-light">
						<Form noValidate validated={validated} ref={ref => form = ref} className="third-form">
							<Row className="g-3">
								<Col sm={12} md={6} lg={3}>
									<Form.Group>
										<FloatingLabel
											controlId="charterFeeAmount"
											label="Charter fee"
										>
											<Form.Control
												type="number"
												onWheel={(e) => e.target.blur()}
												value={formData.charter.charterFeeAmount}
												min={0}

												onChange={handleCharterFieldChange}
												name="charterFeeAmount"
												placeholder="Charter fee"
												required
											/>
										</FloatingLabel>
										<Form.Control.Feedback type="invalid">
											Please enter a charter fee.
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col sm={12} md={6} lg={3}>
									<Form.Group>
										<FloatingLabel
											controlId="vat"
											label="VAT"
										>
											<Form.Control
												type="number"
												name="vat"
												onWheel={(e) => e.target.blur()}
												value={formData.charter.vat}
												min={0}
												onChange={handleCharterFieldChange}
												placeholder="VAT"
											/>
										</FloatingLabel>
										<Form.Control.Feedback type="invalid">
											Please enter a VAT.
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col sm={12} md={6} lg={3}>

									<Form.Group>
										<FloatingLabel
											controlId="apa"
											label="APA"
										>
											<Form.Control
												type="number"
												name="apa"
												onWheel={(e) => e.target.blur()}
												value={formData.charter.apa}
												min={0}
												onChange={handleCharterFieldChange}
												placeholder="APA"
											/>
										</FloatingLabel>
										<Form.Control.Feedback type="invalid">
											Please enter a APA.
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col sm={12} md={6} lg={3}>
									<Form.Group>
										<FloatingLabel
											controlId="deliveryFeeAmount"
											label="Delivery fee"
										>
											<Form.Control
												type="number"
												onWheel={(e) => e.target.blur()}
												value={formData.charter.deliveryFeeAmount}
												min={0}
												onChange={handleCharterFieldChange}
												name="deliveryFeeAmount"
												placeholder="Delivery fee"
												required
											/>
										</FloatingLabel>
										<Form.Control.Feedback type="invalid">
											Please enter a delivery fee.
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col sm={12} md={6} lg={3}>

									<Form.Group>
										<FloatingLabel
											controlId="deposit"
											label="Security deposit"
										>
											<Form.Control
												type="number"
												value={formData.charter.deposit}
												onWheel={(e) => e.target.blur()}
												min={0}
												onChange={handleCharterFieldChange}
												name="deposit"
												placeholder="Security deposit"
												required
											/>
										</FloatingLabel>
										<Form.Control.Feedback type="invalid">
											Please enter a security deposit.
										</Form.Control.Feedback>
									</Form.Group>
								</Col>

								<Col sm={12} md={6} lg={3}>
									<Form.Group>
										<DatePicker
											autoComplete='off'
											disabled={formData.vatRep}
											selected={formData.charter.vatDate}
											onChange={(e) => setFormData({
												...formData, charter: {
													...formData.charter,
													vatDate: e
												}
											})}
											formatWeekDay={day => day.slice(0, 3)}
											className='form-control datepickerHeight'
											name="vatDate"
											placeholderText="VAT due date"
											dateFormat="dd/MM/yyyy"
											locale='es'
											style="z-index:10"
										/>
									</Form.Group>
								</Col>
								<Col sm={12} md={6} lg={3}>
									<Form.Group>
										<DatePicker
											autoComplete='off'
											selected={formData.charter.apaDate}
											onChange={(e) => setFormData({
												...formData, charter: {
													...formData.charter,
													apaDate: e
												}
											})}
											formatWeekDay={day => day.slice(0, 3)}
											name="startDate"
											className="form-control datepickerHeight"
											placeholderText="APA due date"
											dateFormat="dd/MM/yyyy"
											locale='es'
										/>
									</Form.Group>
								</Col>
								{
									(isCentralAgent2 &&
										<Col sm={12} md={6} lg={3} className='d-flex align-items-center'>
											<Form.Check
												type='checkbox'
												id='directToClient'
												label='Direct from Client'
												name='directToClient'
												checked={formData.directToClient}
												value={formData.directToClient}
												onChange={(e) => { setFormData({ ...formData, directToClient: e.target.checked }); setWarning(); }}
											/>
										</Col>
									)
								}
							</Row>
						</Form>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

export default CharterFeesForm;
