import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const PasswordResetForm = ({ hasLabel, token, id }) => {
  // State
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [errorMsg, setErrorMsg] = useState(undefined);
  const [formatPasswordError, setFormatPasswordError] = useState(true);

  // Handler
  const handleSubmit = async e => {
    let err = false;
    e.preventDefault();
    var re = new RegExp("^(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,}$");
    if (formData.password && formData.confirmPassword) {
      if (re.test(formData.password)) {
        err = true;
        setFormatPasswordError(true);
      } else {
        setFormatPasswordError(false);
      }
      if (formData.password != formData.confirmPassword) {
        setErrorMsg("The passwords do not match");
        err = true;
      }
      if (!err) {
        setErrorMsg(undefined);
        let form = new FormData();
        form.append("token", decodeURI(token));
        form.append("password", formData.password);
        form.append("confirmPassword", formData.confirmPassword);
        try {
          await axios.post(`${window.location.origin}/Auth/reset-password/${id}?type=token`, form);
          toast.success('Your password has successfully been reset', {
            theme: 'colored'
          });
          navigate("/login");
        } catch {
    
        }
      }
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          value={formData.password}
          pattern='^(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,}$'
          title='Minimum eight characters, at least one letter and one number'
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-2">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          value={formData.confirmPassword}
          pattern='^(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,}$'
          title='Minimum eight characters, at least one letter and one number'
          name="confirmPassword"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>
      
      <Button
        type="submit"
        className="w-100 my-2"
        disabled={!formData.password || !formData.confirmPassword}
      >
        Set password
      </Button>
      {
        errorMsg != undefined && 
        (<div className="text-danger mb-1">{errorMsg}</div>)
      }
      <div className={`${formatPasswordError ? "text-danger" : "text-success"}`}>Minimum eight characters, at least one letter and one number</div>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool,
  token: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default PasswordResetForm;
