import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import Footer from 'components/footer/Footer';
import classNames from 'classnames';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { RoleContext } from '../../src/context/RoleContext';


const MainLayout = () => {
    const { hash, pathname } = useLocation();
    const isKanban = pathname.includes('kanban');
    const navigate = useNavigate();

    // const isChat = pathname.includes('chat');

    const { config: { isFluid, navbarPosition } } = useContext(AppContext);
    const roleContext = useContext(RoleContext);

    useEffect(() => {
        setTimeout(() => {
            if (hash) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
            }
        }, 0);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="noSidePaddingPrint container-xl">
            {/* {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
                <NavbarVertical />
            )} */}
            <div className={classNames('noSidePaddingPrint', 'content', { 'pb-0': isKanban })}>
                <NavbarTop />
                {/*------ Main Routes ------*/}
                <div className='noSidePaddingPrint pt-6 mt-3'>
                    <Outlet />
                </div>
                {!isKanban && <Footer />}
            </div>
        </div>
    );
};


export default MainLayout;
