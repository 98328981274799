import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Row, Form, FloatingLabel, Button, Spinner, Table, InputGroup, ToggleButton, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import DatePicker, { registerLocale } from 'react-datepicker';
import axiosInstance from 'helpers/axiosInstance';
import { SimpleUserForm } from 'viewModel/userForm';
import { toast } from 'react-toastify';

const CharterDetailForm = ({
	formData,
	setFormData,
	handleFieldChange,
	loadClientOptions,
	handleSelectChange,
	isCentralAgent2,
	validated,
	loadSupplierOptions,
	handleCharterFieldChange,
	calcBrokerAdvancedCommissionChange
}) => {
	

	const startRef = useRef();
	let form = '';
	const charterDetailsDays = () => {
		if (formData.charter.startDate && formData.charter.endDate){
			let startMilliseconds = formData.charter.startDate.getTime();
			let endMilliseconds = formData.charter.endDate.getTime();
	
			// Calculate the difference in milliseconds
			let differenceMilliseconds = Math.abs(endMilliseconds - startMilliseconds);
	
			// Convert the difference back to days
			let days = Math.ceil(differenceMilliseconds / (1000 * 3600 * 24));
			if (isNaN(days)) {
				return 0;
			} else {
				return (<>{days.toFixed(2)}</>);
			}
		}
		return 0;
	}


	const handleStartDateChange = (e) => {
		setFormData({
			...formData,
			charter: {
				...formData.charter,
				startDate: e,
				startTime: (formData.charter.startTime ? formData.charter.startTime : new Date(e.setHours(12, 0, 0))),
				endDate: (formData.charter.endDate && formData.charter.endDate > e) ? formData.charter.endDate : e,
				endTime: (formData.charter.endTime ? formData.charter.endTime : e),
				apaDate: new Date(new Date(e).setDate(e.getDate() - 10)),
				vatDate: formData.charter.vatDate ? formData.charter.vatDate : e
			}
		})
	}

	const handleStartTimeChange = (e) => {
		setFormData({
			...formData,
			charter: {
				...formData.charter,
				startTime: e
			}
		})
	}

	const handleEndDateChange = (e) => {
		setFormData({
			...formData,
			charter: {
				...formData.charter,
				endDate: e,
				vatDate: e,
				endTime: (formData.endTime ? formData.endTime : new Date(e.setHours(12, 0, 0)))
			}
		})
	}

	const handleEndTimeChange = (e) => {
		setFormData({
			...formData,
			charter: {
				...formData.charter,
				endTime: e
			}
		})
	}

	return (
		<>
			<Card>
				<Card.Header className="d-inline">
					<h5 className="float-start">Charter Details</h5>
					<span className="float-end ms-4">Day: <b>{charterDetailsDays()}</b></span>
					{!isCentralAgent2 ?
						<span className="float-end">
							<Form.Check
								type='checkbox'
								id='kyc'
								label='KYC'
								name='kyc'
								checked={formData.charter.kyc}
								onChange={handleCharterFieldChange}
							/>
						</span>
						: ""}
				</Card.Header>
				<Card.Body className="bg-light">
					<Form noValidate validated={validated} ref={ref => form = ref} className="second-form">
						<Row className="g-3">
							<Col lg={6}>
								<Row className="g-0">
									<Form.Group as={Col} lg={8}>
										<DatePicker
											autoComplete='off'
											selected={formData.charter.startDate}
											ref={startRef}
											onSelect={(e) => { startRef.current.setOpen(false) }}
											onChange={(e) => { handleStartDateChange(e) }}
											formatWeekDay={day => day.slice(0, 3)}
											className='form-control datepickerHeight'
											name="startDate"
											dateFormat="dd/MM/yyyy"
											locale='es'
											placeholderText="Date From"
											required
										/>
									</Form.Group>
									<Form.Group as={Col} lg={4}>
										<DatePicker
											autoComplete='off'
											selected={formData.charter.startTime}
											onChange={(e) => { handleStartTimeChange(e) }}
											formatWeekDay={day => day.slice(0, 3)}
											className='form-control datepickerHeight'
											name="startDate"
											placeholderText="Time"
											timeIntervals={5}
											dateFormat="HH:mm"
											showTimeSelect
											showTimeSelectOnly
											required
										/>
									</Form.Group>
								</Row>
							</Col>
							<Form.Group as={Col} lg={6}>
								<FloatingLabel
									controlId="placeFrom"
									label="Place from"
								>
									<Form.Control
										type="text"
										value={formData.charter.placeFrom}
										onChange={handleCharterFieldChange}
										placeholder='Place from'
										name="placeFrom"
										required
									/>
								</FloatingLabel>
								<Form.Control.Feedback type="invalid">
									Please select a place.
								</Form.Control.Feedback>
							</Form.Group>
							<Col lg={6}>
								<Row className="g-0">
									<Form.Group as={Col} lg={8}>
										<DatePicker
											autoComplete='off'
											selected={formData.charter.endDate}
											onChange={(e) => { handleEndDateChange(e) }}
											formatWeekDay={day => day.slice(0, 3)}
											className='form-control datepickerHeight'
											name="endDate"
											timeIntervals={5}
											dateFormat="dd/MM/yyyy"
											locale='es'
											placeholderText="Date To"
											required
										/>
									</Form.Group>
									<Form.Group as={Col} lg={4}>
										<DatePicker
											autoComplete='off'
											selected={formData.charter.endTime}
											onChange={(e) => { handleEndTimeChange(e) }}
											formatWeekDay={day => day.slice(0, 3)}
											className='form-control datepickerHeight'
											value={formData.charter.endTime}
											name="endDate"
											timeIntervals={5}
											dateFormat="HH:mm"
											locale='es'
											showTimeSelect
											showTimeSelectOnly
											placeholderText="Time"
											required
										/>
									</Form.Group>
								</Row>
							</Col>
							<Form.Group as={Col} lg={6}>
								<FloatingLabel
									controlId="placeTo"
									label="Place to"
								>
									<Form.Control
										type="text"
										value={formData.charter.placeTo}
										onChange={handleCharterFieldChange}
										name="placeTo"
										placeholder="Place to"
										required
									/>
								</FloatingLabel>
								<Form.Control.Feedback type="invalid">
									Please select a place.
								</Form.Control.Feedback>
							</Form.Group>
						</Row>
					</Form>
				</Card.Body>
			</Card>
		</>
	);
};

export default CharterDetailForm;
