import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Form, CloseButton, Table, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import axiosInstance from 'helpers/axiosInstance';
import { toast } from 'react-toastify';


const UploadFormFiles = (props) => {
    const [type, setType] = useState("None");
    const changeHandler = (event) => {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        formData.append("UploadTo", props.uploadTo)
        formData.append("Type", type);
        if (props.uploadTo != null) {
            if (event.target.files[0].name.length < 200) {
                axiosInstance.post(`${window.location.origin}/uploadFiles`, formData).then((result) => {
                    axiosInstance.get(`${window.location.origin}/uploadFiles/GetFiles?FilesFrom=${props.uploadTo}`).then((result) => {
                        props.setAttachmentsData({ data: result.data });
                    });
                });
            } else {
                toast.error("File name must not exceed 200 characters.", { theme: 'colored' });
            }
        } else {
            console.log(event);
            let attachmentDatas = props.attachmentsData.data;
            attachmentDatas.push({
                name: event.target.files[0].name,
                length: event.target.files[0].size,
                type: type,
                createdOn: new Date(),
                theFile: event.target.files[0]
            })
            props.setAttachmentsData({ data: attachmentDatas });
        }
    };


    const deleteFile = (e) => {
        e.preventDefault();
        let fileName = e.currentTarget.getAttribute('data-key');
        if (props.uploadTo != null) {
            axiosInstance.delete(`${window.location.origin}/uploadFiles?from=${props.uploadTo + "\\" + fileName.split('/')[0]}`)
                .then((res) => {
                }).catch(x => {
                    return;
                });
        }
        let uploadedFiles = props.attachmentsData.data;
        let theDeletingFile = uploadedFiles.findIndex(item => item.name === fileName);
        if (theDeletingFile !== -1) {
            uploadedFiles.splice(theDeletingFile, 1);
            props.setAttachmentsData({ data: uploadedFiles });
        }
    }

    const downloadFile = (e) => {
        e.preventDefault();
        let fileNameOrg = e.currentTarget.getAttribute('data-key');
        let body = {
            fileName: props.uploadTo + "\\" + fileNameOrg.split('/')[0],
            client: 'congratulations, you found an easter egg, I was too lazy to remove this -An Pham'
        };
        if (props.uploadTo != null) {
            axiosInstance.post(`${window.location.origin}/uploadFiles/file`, body, { responseType: 'blob' })
                .then((response) => {
                    console.log(response);
                    const contentDisposition = response.headers["content-disposition"];
                    let fileName = response.headers["content-disposition"]
                        .split("filename*=UTF-8''")[1];
                    fileName = decodeURI(fileName.trim()).trim();
                    const contentType = response.headers["content-type"];
                    const blob = new Blob([response.data], { type: contentType });
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                });
        } else {
            var file = props.attachmentsData.data.find(x => x.name == fileNameOrg);
            var blob = new Blob([file.theFile]);
            var url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = file.name;
            link.href = url;
            link.click();
        }

    }

    const updateFileType = (theFile, type, index) => {
        let attachmentDatas = props.attachmentsData.data;
        const formData = new FormData();
        formData.append("filePath", props.uploadTo + "\\" + theFile.name);
        formData.append("type", type);
        if (props.uploadTo != null) {
            axiosInstance.put(`${window.location.origin}/uploadFiles/file`, formData).then((result) => {
                toast.success("File type updated", { theme: 'colored' });
            }).catch(e => {
                return;
            });
        }
        theFile.type = type;
        attachmentDatas[index] = theFile;
        props.setAttachmentsData({ data: attachmentDatas });
    }

    const LoadAttachments = () => {
        if (props.attachmentOptions) {
            return (
                props.attachmentsData.data.map((theFile, index) =>
                    <tr key={index}>
                        <td >Attachment Type: <b>{theFile.attachmentKind.name}</b></td>
                        <td >File: <b>{theFile.fileForm.fullName}</b></td>
                        <td >Size: <b>{theFile.fileForm.length} bytes</b></td>
                        {props.disableModifications != true ? <td className="text-end"> <CloseButton aria-label="Hide" data-key={theFile.fileForm.name} onClick={deleteFile} />  </td> : null}
                    </tr>
                )
            );
        } else if (props.attachmentsData.data.length) {
            return (
                props.attachmentsData.data.map((theFile, index) =>
                    <tr key={index}>
                        <td className="text-truncate" style={{ maxWidth: "12.5rem" }} data-bs-toggle="tooltip" data-bs-placement="top" title={theFile.name}><b>{theFile.name}</b></td>
                        <td ><b>{new Date(theFile.createdOn).toLocaleDateString().toString() + " " + new Date(theFile.createdOn).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toString()}</b></td>
                        <td ><b>{Math.round(theFile.length / 1024)} kB</b></td>
                        {props.disableModifications != true ? <><td >
                            <Form.Select value={theFile.type} onChange={(e) => { updateFileType(theFile, e.target.value, index) }}>
                                {attachmentOptions()}
                            </Form.Select></td></> : <><td ><Form.Select value={theFile.type} disabled > {attachmentOptions()}</Form.Select></td></>}
                        {props.disableModifications != true ? <>
                            <td className="text-end"> <CloseButton aria-label="Hide" data-key={theFile.name} onClick={deleteFile} />  </td></> : null}
                        <td className="text-end"> <button style={{ border: 'none' }} aria-label="Hide" data-key={theFile.name} onClick={downloadFile} download className="ml-3"><FontAwesomeIcon data-key={theFile.name} color="grey" className="mb-1" icon={faDownload} /></button> </td>

                    </tr>
                )
            );
        }
    }


    const attachmentOptions = () => {
        if (props.attachmentKinds != null)
            return (
                <><option>No Type</option>
                    {props.attachmentKinds.data.map((obj) => (<option key={obj.id} value={obj.name}>{obj.name}</option>))}
                </>
            );
        else
            <option>No Type</option>
    }

    const partialUploadFile = () => {
        return (
            <>
                {props.disableModifications != true ?
                    <div >
                        <InputGroup>
                            <Form.Control type="file" title="Add new file" className="w-50" onChange={changeHandler} />
                            <Form.Select value={type} onChange={(e) => setType(e.target.value == "No Type" ? "None" : e.target.value)}>
                                {attachmentOptions()}
                            </Form.Select>
                        </InputGroup>
                    </div>
                    : null}
            </>);
    }

    return (
        <>
            <Row className="mb-3 g-3">
                <Col lg={12}>
                    <Card>
                        <Card.Header className="d-inline">
                            <h5 className="float-start">Attachments</h5>

                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Row className="g-3 table-responsive">
                                <Table className="table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th>Size</th>
                                            <th>Type</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {LoadAttachments()}
                                    </tbody>
                                </Table>


                                <Form.Group as={Col}
                                    children={partialUploadFile()}
                                >
                                </Form.Group>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}


export default UploadFormFiles;
