import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Card, Col, Row, Form, Button, Spinner, FloatingLabel } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import PageHeader from 'components/common/PageHeader';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import UploadFormFiles from 'components/common/UploadFormFiles';
import { useNavigate } from "react-router-dom";
import axiosInstance from 'helpers/axiosInstance';

const CreateClientForm = (props, ref) => {
	useEffect(() => {
		console.log(ref);
	})

	useImperativeHandle(ref, () => ({
		save: async () => {
			handleSubmit();
		}
	}))

	// State
	let form = '';
	const [validated, setValidated] = useState(false);

	const [formData, setFormData] = useState({
		company: '',
		nationalite: '',
		name: '',
		IBAN: '',
		SWIFT: '',
		address: '',
		bankAddress: '',
		kyc: false
	});
	const [attachmentsData, setAttachmentsData] = useState({ data: [] });


	//Handlers
	let navigate = useNavigate();

	const handleSubmit = (event) => {
		event?.preventDefault();
		if (form.checkValidity() === false) {
			event?.preventDefault();
			event?.stopPropagation();
		}
		else {
			formData["attachments"] = attachmentsData.data;
			axiosInstance.post(`${window.location.origin}/clients`, formData)
				.then((res) => {
					if (res.data.id !== '0') {
						toast.success(`Client has been created.`, {
							theme: 'colored'
						});
						if (props.InModal) {
							props.onSave(res.data);
						} else {
							navigate("/components/clients/edit?id=" + res.data.id);
						}
					} else {
						toast.error(`${res.data.message}`, {
							theme: 'colored'
						});
					}

					$('.save-spinner').attr('hidden', 'hidden');
				});
		}
		setValidated(true);
	};

	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.id]: e.target.value
		});
	};

	return (
		<>
			{
				props.InModal ? null :
					<PageHeader className="mb-3 g-3" title="">
						<div className="d-inline">
							<h3 className="float-start">Create client</h3>
							<div className="float-end">
								<Button
									variant='falcon-primary'
									className='me-2'
									type="submit"
									onClick={e => form.dispatchEvent(
										new Event("submit", { cancelable: true, bubbles: true })
									)}
								>
									<Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" />
									Save</Button>
								<Button variant='falcon-warning' onClick={() => { window.history.back(); }}>
									<Spinner animation="border" variant="warning" role="status" size="sm" hidden={true} className="cancel-spinner" />
									Cancel</Button>
							</div>
						</div>
					</PageHeader>
			}
			<Row className="mb-3 g-3">
				<Col lg={12}>
					<Card>
						<Card.Header className="d-flex justify-content-between align-items-center">
							<h5>Client Details</h5>
							<div className='d-flex'>
								<Form.Group as={Col} lg={4} className="mt-0">
									<div>
										<BootstrapSwitchButton
											type='switch'
											id='kyc'
											onlabel='KYC'
											offlabel='KYC'
											onstyle="primary"
											offstyle="light"
											width="100"
											checked={formData.kyc}
											onChange={(e) => {
												setFormData({ ...formData, "kyc": e });
											}} />
									</div>
								</Form.Group>
							</div>
						</Card.Header>
						<Card.Body className="bg-light">
							<Form validated={validated} onSubmit={handleSubmit} ref={ref => form = ref}>
								<Row className="g-3">
									<FloatingLabel as={Col} lg={6}
										controlId="name"
										label="Client name"
									>
										<Form.Control
											type="text"
											value={formData.name}
											onChange={handleFieldChange}
											placeholder="Name"
											required
										/>
										<Form.Control.Feedback type="invalid">
											Please enter a client name.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={6}
										controlId="nationalite"
										label="Client nationality"
									>
										<Form.Control
											as="textarea"
											value={formData.nationalite}
											onChange={handleFieldChange}
											placeholder="Nationality"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="address"
										label="Client address"
									>
										<Form.Control
											as="textarea"
											rows={2}
											value={formData.address}
											onChange={handleFieldChange}
											placeholder="Address"
										/>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={8}
										controlId="IBAN"
										label="IBAN"
									>
										<Form.Control
											type="text"
											value={formData.IBAN}
											onChange={handleFieldChange}
											placeholder="IBAN"
										/>
										<Form.Control.Feedback type="invalid">
											Please enter a client IBAN.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={4}
										controlId="SWIFT"
										label="SWIFT"
									>
										<Form.Control
											type="text"
											value={formData.SWIFT}
											onChange={handleFieldChange}
											placeholder="SWIFT"
										/>
										<Form.Control.Feedback type="invalid">
											Please enter a client SWIFT code.
										</Form.Control.Feedback>
									</FloatingLabel>
									<FloatingLabel as={Col} lg={12}
										controlId="bankAddress"
										label="Bank address"
									>
										<Form.Control
											as="textarea"
											value={formData.bankAddress}
											onChange={handleFieldChange}
											placeholder="Bank address"
										/>
									</FloatingLabel>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<UploadFormFiles
				attachmentsData={attachmentsData}
				setAttachmentsData={setAttachmentsData}
			/>
			<Col lg={12} className='mt-3 mb-3 g-3'>
				<Card>
					<Card.Header className="bg-white">
						<h5>Comments</h5>
					</Card.Header>
					<Card.Body className="bg-light">
						<Form.Group as={Col}>
							<Form.Control
								style={{ minHeight: "150px" }}
								type="text"
								as="textarea"
								rows={5}
								value={formData.notes}
								onChange={handleFieldChange}
								id="notes"
							/>
						</Form.Group>
					</Card.Body>
				</Card>
			</Col>
		</>
	);
};

export default forwardRef(CreateClientForm);
