import React, { useState } from 'react';
import { Card, Row, Col, Accordion, Badge } from 'react-bootstrap';
import Notification from 'components/notification/Notification';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getUserRole } from 'helpers/utils';

const radios = [
	{ name: 'kyc', value: '1' },
	/*{ name: 'Out late', value: '2' },*/
	{ name: 'Other', value: '2' }
];

const NotificationsWidget = ({ notifications, show, dash, officeID, topright, paymentsIn, paymentsOut, paymentsReady }) => {

	const [retail, setRetail] = useState(false);
	const [key, setKey] = useState(dash ? 'kyc' : 'Payment In late');
	const role = getUserRole();
	let messages;
	return (

		<Card className='px-0' style={{ boxShadow: 'none' }}>
			{dash ? <>
				<Card.Header><h5>KYC and other alerts</h5></Card.Header>
				<Card.Body className={"p-0" + (dash ? " overflow-auto" : "")} style={dash ? { maxHeight: 616 } : {}}>
					<Tabs
						id="controlled-tab-example"
						activeKey={key}
						onSelect={(k) => setKey(k)}
						className="mb-1 list-inline mx-auto justify-content-center"
					>
						<Tab eventKey="kyc" title={<React.Fragment>
							KYC
							{
								(messages = notifications.data.filter(o => (officeID === -1 || o.officeId === officeID) && o.dashFilter === 12)).length > 0 &&
									<Badge bg='danger' className="ms-1">{messages.length}</Badge>
							}
						</React.Fragment>}>
							<Card className="p-0" style={{ boxShadow: 'none' }}>
								<Card.Body className="p-0">
									<Row className="g-0">
										{messages.length > 0 ?
											messages.sort((a, b) => a.problemRef.toLowerCase().localeCompare(b.problemRef.toLowerCase())).slice(0, notifications.data.length).map((notification, index) => (
												<Notification
													{...notification}
													key={index}
													toShow={show}
												/>
											))
											: <Notification children="No notifications were found for this office." />
										}
									</Row>
								</Card.Body>
							</Card>
						</Tab>
						<Tab eventKey="Other" title={<React.Fragment>
							Other
							{
								(messages = notifications.data.filter(o => (officeID === -1 || o.officeId === officeID) && o.dashFilter === 2 && !(o.children.includes('kyc')))).filter(o => o.problemRef === 'important').length > 0 &&
									<Badge bg='danger' className="ms-1">{messages.filter(o => o.problemRef === 'important').length}</Badge>
							}
						</React.Fragment>}>
							{messages.length > 0 ?
								messages.sort((a, b) => a.problemRef.toLowerCase().localeCompare(b.problemRef.toLowerCase())).slice(0, notifications.data.length).map((notification, index) => (
									<Card className="p-0" key={index} style={{ boxShadow: 'none' }}>
										<Card.Body className="p-0">
											<Row className="g-0">
												<Notification
													{...notification}
													key={index}
													toShow={show}
												/>
											</Row>
										</Card.Body>
									</Card>
								))
								: <Notification children="No notifications were found for this office." />
							}
						</Tab>
					</Tabs>
				</Card.Body>
			</> :
				topright ?
					<Card.Body className="p-0 overflow-auto" style={dash ? { maxHeight: 360 } : {}}>
						<Tabs
							fill
							id="controlled-tab-example"
							activeKey={key}
							onSelect={(k) => setKey(k)}
							className="mb-1 list-inline mx-auto justify-content-center"
						>
							<Tab eventKey="Payment In late" title={<React.Fragment>
								In Late
								{
									(messages = notifications.data.filter(o => o.dashFilter === 0)).filter(o => o.problemRef === 'important').length > 0 &&
										<Badge bg='danger' className="ms-1">{messages.filter(o => o.problemRef === 'important').length}</Badge>
								}
							</React.Fragment>}>
								<Card className="p-0" style={{ boxShadow: 'none' }}>
									<Card.Body className="p-0">
										<Row className="g-0">
											{messages.length > 0 ?
												messages.sort((a, b) => a.problemRef.toLowerCase().localeCompare(b.problemRef.toLowerCase())).slice(0, notifications.data.length).map((notification, index) => (
													<Notification
														{...notification}
														key={index}
														toShow={show}
													/>
												))
												: <Notification children="No notifications were found." />
											}
										</Row>
									</Card.Body>
								</Card>
							</Tab>
							<Tab eventKey="Payment Out late" title={<React.Fragment>
								Out Late
								{
									(messages = notifications.data.filter(o => o.dashFilter === 1)).filter(o => o.problemRef === 'important').length > 0 &&
										<Badge bg='danger' className="ms-1">{messages.filter(o => o.problemRef === 'important').length}</Badge>
								}
							</React.Fragment>}>
								<Card className="p-0" style={{ boxShadow: 'none' }}>
									<Card.Body className="p-0">
										<Row className="g-0">
											{messages.length > 0 ?
												messages.sort((a, b) => a.problemRef.toLowerCase().localeCompare(b.problemRef.toLowerCase())).slice(0, notifications.data.length).map((notification, index) => (
													<Notification
														{...notification}
														key={index}
														toShow={show}
													/>
												))
												: <Notification children="No notifications were found." />
											}
										</Row>
									</Card.Body>
								</Card>
							</Tab>
							<Tab eventKey="Other" title={<React.Fragment>
								Other
								{
									(messages = notifications.data.filter(o => o.dashFilter === 2)).filter(o => o.problemRef === 'important').length > 0 &&
										<Badge bg='danger' className="ms-1">{messages.filter(o => o.problemRef === 'important').length}</Badge>
								}
							</React.Fragment>}>
								<Card className="p-0" style={{ boxShadow: 'none' }}>
									<Card.Body className="p-0">
										<Row className="g-0">
											{messages.length > 0 ?
												messages.sort((a, b) => a.problemRef.toLowerCase().localeCompare(b.problemRef.toLowerCase())).slice(0, notifications.data.length).map((notification, index) => (
													<Notification
														{...notification}
														key={index}
														toShow={show}
													/>
												))
												: <Notification children="No notifications were found." />
											}
										</Row>
									</Card.Body>
								</Card>
							</Tab>
						</Tabs>
					</Card.Body>
					:
					(paymentsIn || paymentsOut || paymentsReady) ? <>
						<Card.Header className='mb-0'>
							<h5 style={{ display: 'inline-block' }}>Alerts for {paymentsIn ? "Incoming" : paymentsOut ? "Outgoing" : "Ready-to-pay Outgoing"} Payments</h5>
							{
								(messages = notifications.data.filter(o => (officeID === -1 || o.officeId === officeID) && o.paymentTo === (paymentsIn ? 'client' : 'owner') && (!paymentsReady && o.dashFilter !== 69 || paymentsReady && o.dashFilter === 69))).filter(o => o.problemRef === 'important').length > 0 &&
									<Badge bg='danger' className="ms-1">{messages.filter(o => o.problemRef === 'important').length}</Badge>
							}
						</Card.Header>
						<Card.Body className="p-0 overflow-auto" style={{ maxHeight: 260 }}>
							<Card className="p-0" style={{ boxShadow: 'none' }}>
								<Card.Body className="p-0">
									<Row className="g-0">
										{messages.length > 0 ?
											messages.sort((a, b) => a.problemRef.toLowerCase().localeCompare(b.problemRef.toLowerCase())).slice(0, notifications.data.length).map((notification, index) => (
												<Notification
													{...notification}
													key={index}
													toShow={show}
													payment={!paymentsIn}
													charterDate={notification.charterDate}
												/>
											))
											: <Notification children="No notifications were found for this office." />
										}
									</Row>
								</Card.Body>
							</Card>
						</Card.Body>
					</>
					: <>
						<Card.Header><h5>Alerts</h5></Card.Header>
						<Card.Body className={"p-0" + (dash ? " overflow-auto" : "")} style={dash ? { maxHeight: 360 } : {}}>
							<Tabs
								id="controlled-tab-example"
								activeKey={key}
								onSelect={(k) => setKey(k)}
								className="mb-1 list-inline mx-auto justify-content-center"
							>
								<Tab eventKey="Payment In late" title={<React.Fragment>
									In Late
									{
										(messages = notifications.filter(o => o.dashFilter === 0)).filter(o => o.problemRef === 'important').length > 0 &&
											<Badge bg='danger' className="ms-1">{messages.filter(o => o.problemRef === 'important').length}</Badge>
									}
								</React.Fragment>}>
									<Card className="p-0" style={{ boxShadow: 'none' }}>
										<Card.Body className="p-0">
											<Row className="g-0">
												{messages.length > 0 ?
													messages.sort((a, b) => a.problemRef.toLowerCase().localeCompare(b.problemRef.toLowerCase())).slice(0, notifications.length).map((notification, index) => (
														<Notification
															{...notification}
															key={index}
															toShow={show}
															titles={false}
														/>
													))
													: <Notification children="No notifications were found for this contract." />
												}
											</Row>
										</Card.Body>
									</Card>
								</Tab>
								<Tab eventKey="Payment Out late" title={<React.Fragment>
									Out Late
									{
										(messages = notifications.filter(o => o.dashFilter === 1)).filter(o => o.problemRef === 'important').length > 0 &&
											<Badge bg='danger' className="ms-1">{messages.filter(o => o.problemRef === 'important').length}</Badge>
									}
								</React.Fragment>}>
									<Card className="p-0" style={{ boxShadow: 'none' }}>
										<Card.Body className="p-0">
											<Row className="g-0">
												{messages.length > 0 ?
													messages.sort((a, b) => a.problemRef.toLowerCase().localeCompare(b.problemRef.toLowerCase())).slice(0, notifications.length).map((notification, index) => (
														<Notification
															{...notification}
															key={index}
															toShow={show}
															titles={false}
														/>
													))
													: <Notification children="No notifications were found for this contract." />
												}
											</Row>
										</Card.Body>
									</Card>
								</Tab>
								<Tab eventKey="Other" title={<React.Fragment>
									Other
									{
										(messages = notifications.filter(o => o.dashFilter === 2)).filter(o => o.problemRef === 'important').length > 0 &&
											<Badge bg='danger' className="ms-1">{messages.filter(o => o.problemRef === 'important').length}</Badge>
									}
								</React.Fragment>}>
									<Card className="p-0" style={{ boxShadow: 'none' }}>
										<Card.Body className="p-0">
											<Row className="g-0">
												{messages.length > 0 ?
													messages.sort((a, b) => a.problemRef.toLowerCase().localeCompare(b.problemRef.toLowerCase())).slice(0, notifications.length).map((notification, index) => (
														<Notification
															{...notification}
															key={index}
															toShow={show}
															titles={false}
														/>
													))
													: <Notification children="No notifications were found for this contract." />
												}
											</Row>
										</Card.Body>
									</Card>
								</Tab>
							</Tabs>
						</Card.Body>
					</>
			}
		</Card>
	);
};

export default NotificationsWidget;
