import React from 'react';
import { Col, Row, Card, DropdownButton, Dropdown } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

const YachtEmailRecipientList = ({ Add, setAdd, emailList, handleEmailDelete, setEmailList, setFormData, formData }) => {
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			if (/\S+@\S+\.\S+/.test(event.target.value)) {
				let data = emailList.data;
				if (data) {
					if (!data.includes(event.target.value)) {
						var obj = { email: event.target.value, type: Add }
						data.push(obj);
						setEmailList(
							{
								data: data
							});
						setFormData({ ...formData, emails: data });
						setAdd(-1);
					}
					else {
						toast.error(`That email is already in the list.`, {
							theme: 'colored'
						});
					}
				} else {
					setEmailList(
						{
							data: [event.target.value]
						});
					setFormData({ ...formData, emails: [event.target.value] });
					setAdd(-1);
				}
			}
			else {
				toast.error(`Please enter a valid email.`, {
					theme: 'colored'
				});
			}
		}
	}

	const loadEmailList = (InOrOut) => {
		if (emailList.data.filter(o => { return o.type == InOrOut }).length > 0) {
			return (
				<>
					{
						emailList.data.filter(o => { return o.type == InOrOut }).reverse().map(item => (<SoftBadge pill bg={item.Type == 1 ? 'danger' : item.Type == 0 ? "success" : "primary"} className='me-2'>
							{`${item.email}`}
							<FontAwesomeIcon color='red' onClick={() => { handleEmailDelete(item.email, InOrOut); }} style={{ paddingLeft: '5px', cursor: 'pointer' }} icon="fa-solid fa-xmark" />
						</SoftBadge>
						))
					}
				</>
			);

		} else if (Add != InOrOut) return (<SoftBadge pill bg='secondary' style={{ lineHeight: 1 }} className='mt-2'>No email addresses found. </SoftBadge>);
	};

	return (
		<Row className="mb-3 g-3">
			<Col lg={12}>
				<Card>
					<Card.Header className="d-inline">
						<h5 className="float-start">Email Recipient List</h5>
						<div className="float-end">
							<DropdownButton variant="success" style={{ display: 'inline-block' }} size="sm" id="dropdown-basic-button" title="+ Add">
								<Dropdown.Item onClick={() => { setAdd(0) }}>Add to Payment In list</Dropdown.Item>
								<Dropdown.Item onClick={() => { setAdd(1) }}>Add to Payment Out list</Dropdown.Item>
							</DropdownButton>
							{/* <a onClick={() => { setAdd(!Add); }} style={{ cursor: 'pointer' }}><SoftBadge pill bg='success' style={{ lineHeight: 1 }} className='ml-2'><FontAwesomeIcon icon="fa-solid fa-plus" size="xl" color="#5cb85c" className="fs-1" /> Add </SoftBadge></a>*/}
							{/* <a onClick={() => { handleResendEmail(); }} style={{ cursor: 'pointer', marginLeft: "10px" }}><SoftBadge pill bg='info' style={{ lineHeight: 1 }} className='ml-2'>  <FontAwesomeIcon icon="fa-solid fa-paper-plane" />Send email</SoftBadge></a>*/}
						</div>
					</Card.Header>
					<Card.Body className="bg-light">
						<Row className="g-3">
							<div id="emaillist">

								<div>
									<h6>Incoming Payments : </h6>
									<div>
										{Add == 0 ? <SoftBadge style={{ lineHeight: 3, backgroundColor: "#c8cfd89c" }} backgroundcolor="#c8cfd89c" pill className='me-2'>
											<input autoFocus id="emailInput" style={{ background: 'transparent', border: 'none', outline: 'none' }} type="email" name="name" onKeyDown={handleKeyDown} />

											<BsArrowReturnLeft color='grey' size='12' />
										</SoftBadge> : null}
										{loadEmailList(0)}
									</div>
								</div>
								<div className="mt-3">
									<h6>Outgoing Payments : </h6>
									<div>
										{Add == 1 ? <SoftBadge style={{ lineHeight: 3, backgroundColor: "#c8cfd89c" }} backgroundcolor="#c8cfd89c" pill className='me-2'>
											<input autoFocus id="emailInput" style={{ background: 'transparent', border: 'none', outline: 'none' }} type="email" name="name" onKeyDown={handleKeyDown} />

											<BsArrowReturnLeft color='grey' size='12' />
										</SoftBadge> : null}
										{loadEmailList(1)}
									</div>
								</div>

							</div>
						</Row>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

export default YachtEmailRecipientList;
