import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';
import FalconComponentCard from 'components/common/FalconComponentCard';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import TextFilter from 'components/filters/textFilter';
import axiosInstance from 'helpers/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';

const columns = [
	{
		accessor: 'name',
		sort: true,
		Header: 'Name'
	},
	{
		accessor: 'company',
		sort: true,
		Header: 'Company'
	},
	{
		accessor: 'ca',
		sort: true,
		Header: 'Type',
		Cell: rowData => {
			const { ca } = rowData.row.original;
			console.log(ca);
			return (
				<SoftBadge pill bg={ca? "primary" : "warning"}>{ca ? "Central Agent" : "Retail"}</SoftBadge>
			)
		}
	},
];

const YachtList = () => {
	const [state, setState] = useState({
		sortBy: [
			{ id: 'name', desc: false }
		],
		total: 0,
		totalPages: 0,
		remoteData: [],
		pageSize: 30,
		currentPage: 0
	});
	const [isLoading, showLoading] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [showYachtType, setShowYachtType] = useState("CA_RETAIL");
	const fetchIdRef = React.useRef(0);
	const getYachts = React.useCallback(({ page, size, sortBy }) => {
		// Give this fetch an ID
		const fetchId = ++fetchIdRef.current;
		// Only update the data if this is the latest fetch
		if (fetchId === fetchIdRef.current) {
			showLoading(true);
			let formQuery = $("#tableFilters").serialize();
			const type = $("#typeFilter").val();
			if (type != "CA_RETAIL" && typeof type !== 'undefined') {
				formQuery += `&CA=${type == "CA"}`;
			}
			formQuery += `${(formQuery.length > 0 ? "&" : "")}page=${page}`;
			formQuery += `&take=${size}`;
			if (sortBy && sortBy.length > 0) {
				formQuery += `&sortColumn=${sortBy[0].id}&desc=${sortBy[0].desc}`;
			}
			axiosInstance.get(`${window.location.origin}/yachts?${formQuery}`)
				.then((res) => {
					setState({
						sortBy: sortBy,
						total: res.data.count,
						totalPages: Math.ceil(res.data.count / (size * 1.0)),
						remoteData: res.data.data,
						pageSize: size,
						currentPage: page
					});
					showLoading(false);
				});
		}
	}, []);

	useEffect(() => {
		document.title = "Yachts";
	}, [])

	const search = () => {
		getYachts({ page: 0, size: state.pageSize, sortBy: state.sortBy });
	};
	const handleYachtTypeChange = (event) => {
		setShowYachtType(event.target.value);
	}
	/*Table layout*/
	const yachtTable = () => {

		/*Filters*/
		const renderFilters = (visible) => {
			if (visible) {
				return (<></>);
			} else {
				return (
					<>
						<form id="tableFilters">
							<Row className="mb-3 g-3">
								<Col lg={3}>
									<TextFilter label="Name" id="Name" placeholder="search..." />
								</Col>
								<Col lg={3}>
									<TextFilter label="Company" id="Company" placeholder="search..." />
								</Col>
								<Col lg={3}>
									<TextFilter label="Country" id="Country" placeholder="search..." />
								</Col>
								<Col lg={3}>
									<Form.Group>
										<Form.Label>Type</Form.Label>
										<Form.Select id="typeFilter" className="me-2" onChange={handleYachtTypeChange} placeholder={"CA or Retail"}>
											<option selected="selected" value={"CA_RETAIL"}>
												CA & Retail
											</option>
											<option value={"CA"}>
												CA
											</option>
											<option value={"Retail"}>
												Retail
											</option>
										</Form.Select>
									</Form.Group>
								</Col>
							</Row>
						</form>
					</>);
			}
		}

		return (
			<>
				<AdvanceTableWrapper
					columns={columns}
					pageCount={state.totalPages}
					sortable
					initialSortBy={state.sortBy}
					pagination
					perPage={state.pageSize}
					data={state.remoteData}
					fetchData={getYachts}
					initialPage={state.currentPage}
					isLoading={isLoading}
				>
					{/*Filters row*/}
					{renderFilters(showFilters)}

					<AdvanceTable
						trLinkById={`${window.location.origin}/components/yachts/edit`}
						table
						headerClassName="bg-200 text-900 text-nowrap align-middle"
						rowClassName="align-middle white-space-nowrap"
						tableProps={{
							bordered: true,
							striped: true,
							className: 'fs--1 mb-0 overflow-hidden'
						}}
					/>
					<div className="mt-3">
						<AdvanceTableFooter
							rowCount={state.total}
							pageCount={state.totalPages}
							table
							rowInfo
							navButtons
							rowsPerPageSelection
						/>
					</div>
				</AdvanceTableWrapper>
			</>
		);
	}

	return (
		<>
			<FalconComponentCard>
				<FalconComponentCard.Header
					light={false}
					className="border-bottom border-200"
				>
					<div className="d-flex flex-between-center">
						<Col md="auto" lg={6}>
							<h4>Yachts</h4>
						</Col>
						{/*Action Buttons*/}
						<Col md="auto" lg={6} className="text-end">
							{!showFilters && <Button variant='secondary' className='me-2 px-4' size='sm' onClick={search}>
								<FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className='pe-1' />Search</Button>}
							<IconButton
								variant="falcon-default"
								size="sm"
								icon="fa-filter"
								transform="shrink-3"
								className='me-2'
								onClick={() => setShowFilters(!showFilters)}
							>
								<span className="d-none d-sm-inline-block ms-1">Filters</span>
							</IconButton>
							<Link to="/components/yachts/create">
								<IconButton
									variant="primary"
									size="sm"
									icon="plus"
									transform="shrink-3"
								>
									<span className="d-none d-sm-inline-block ms-1">New</span>
								</IconButton>
							</Link>
							{/*<IconButton*/}
							{/*    variant="falcon-default"*/}
							{/*    size="sm"*/}
							{/*    icon="external-link-alt"*/}
							{/*    transform="shrink-3"*/}
							{/*    onClick={() => { window.location.replace(`${window.location.origin}/yachts/export-data?fileName=Yachts`); }}*/}
							{/*>*/}
							{/*    <span className="d-none d-sm-inline-block ms-1">Export</span>*/}
							{/*</IconButton>*/}
						</Col>
					</div>
				</FalconComponentCard.Header>
				<FalconComponentCard.Body
					children={yachtTable()}
					scope={{
						AdvanceTableWrapper,
						AdvanceTable,
						AdvanceTableFooter,
						IconButton,
						Link
					}}
					language="jsx"
					noInline
					noLight
				/>
			</FalconComponentCard>
		</>
	);
};

export default YachtList;
