export class SupplierViewModel {
	name: string;
	iban: string;
	swift: string;
	address: string;
	bankAddress: string;

	constructor(name, swift, iban) {
		this.name = name;
		this.iban = iban;
		this.swift = swift;
		this.address = this.bankAddress = '';
	}
}
