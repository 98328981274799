import React from 'react';
import { Card, Col, Row, Form, FloatingLabel } from 'react-bootstrap';

const SupplierDetail = ({formData, handleSubmit, handleFieldChange, validated, setRef}) => {
	//Handlers
	return (
		<Row className="mb-3 g-3">
			<Col lg={12}>
				<Card>
					<Card.Header >
						<h5>External Broker Details</h5>
					</Card.Header>
					<Card.Body className="bg-light">
						<Form validated={validated} onSubmit={handleSubmit} ref={setRef}>
							<Row className="g-3">
								<FloatingLabel as={Col} lg={12}
									controlId="name"
									label="External broker name"
								>
									<Form.Control
										type="text"
										value={formData.name}
										onChange={handleFieldChange}
										placeholder="Name"
										required
									/>
									<Form.Control.Feedback type="invalid">
										Please enter a external broker name.
									</Form.Control.Feedback>
								</FloatingLabel>
								<FloatingLabel as={Col} lg={12}
									controlId="address"
									label="External broker address"
								>
									<Form.Control
										as="textarea"
										rows={2}
										value={formData.address}
										onChange={handleFieldChange}
										placeholder="Address"
									/>
								</FloatingLabel>
								<FloatingLabel as={Col} lg={8}
									controlId="IBAN"
									label="IBAN"
								>
									<Form.Control
										type="text"
										value={formData.IBAN}
										onChange={handleFieldChange}
										placeholder="IBAN"
									/>
									<Form.Control.Feedback type="invalid">
										Please enter a external broker IBAN.
									</Form.Control.Feedback>
								</FloatingLabel>
								<FloatingLabel as={Col} lg={4}
									controlId="SWIFT"
									label="SWIFT"
								>
									<Form.Control
										type="text"
										value={formData.SWIFT}
										onChange={handleFieldChange}
										placeholder="SWIFT"
									/>
									<Form.Control.Feedback type="invalid">
										Please enter broker SWIFT code.
									</Form.Control.Feedback>
								</FloatingLabel>
								<FloatingLabel as={Col} lg={12}
									controlId="bankAddress"
									label="Bank address"
								>
									<Form.Control
										as="textarea"
										value={formData.bankAddress}
										onChange={handleFieldChange}
										placeholder="Bank address"
									/>
								</FloatingLabel>
								<FloatingLabel as={Col} lg={4}
									controlId="vatRegistration"
									label="VAT registration"
								>
									<Form.Control
										as="textarea"
										value={formData.vatRegistration}
										onChange={handleFieldChange}
										placeholder="VAT registration"
									/>
								</FloatingLabel>
								<FloatingLabel as={Col} lg={4}
									controlId="companyRegistration"
									label="Company registration"
								>
									<Form.Control
										as="textarea"
										value={formData.companyRegistration}
										onChange={handleFieldChange}
										placeholder="Company registration"
									/>
								</FloatingLabel>
								<FloatingLabel as={Col} lg={4}
									controlId="retailCommission"
									label="Retail commission"
								>
									<Form.Control
										as="input"
										type="number"
										value={formData.retailCommission}
										onChange={handleFieldChange}
										placeholder="Retail commission"
									/>
								</FloatingLabel>
							</Row>
						</Form>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

export default SupplierDetail;
