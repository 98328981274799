import React from 'react';
import { Col, Row, Form, FloatingLabel, Card } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

const YachtRetailReferralCie = ({ formData, setFormData }) => {

	return (
		<Row className="mb-3 g-3">
			<Col lg={12}>
				<Card>
					<Card.Header className="d-flex justify-content-between align-items-center">
						<h5 className="float-start">Referral Cie</h5>
						<span className="float-end d-flex">
							<BootstrapSwitchButton
								type='switch'
								id='VAT'
								name='VAT'
								onlabel='VAT'
								offlabel='VAT'
								onstyle="primary"
								offstyle="light"
								width="100"
								checked={formData.IntermediaryVAT}
								onChange={(e) => {
									setFormData({ ...formData, "IntermediaryVAT": e });
								}} />
						</span>
					</Card.Header>
					<Card.Body className="bg-light">
						<Row className="g-3">
							<Form.Group as={Col} lg={9}>
								<FloatingLabel
									controlId="name"
									label="Name"
								>
									<Form.Control
										type="text"
										value={formData.intermediary}
										onChange={e => { setFormData({ ...formData, intermediary: e.target.value }) }}
										placeholder="Name"
									/>
								</FloatingLabel>
							</Form.Group>
							<Form.Group as={Col} lg={3}>
								<FloatingLabel
									controlId="Comm"
									label="Comm. %"
								>
									<Form.Control
										type="number"
										value={formData.intermediaryComm}
										onWheel={(e) => e.target.blur()}
										onChange={e => { setFormData({ ...formData, intermediaryComm: e.target.value }) }}
										placeholder="Comm. %"
									/>
								</FloatingLabel>
							</Form.Group>
						</Row>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

export default YachtRetailReferralCie;
