import React, { useState, useEffect } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Col, Row, Button } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';
import TextFilter from 'components/filters/textFilter';
import axiosInstance from 'helpers/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const columns = [
	{
		accessor: 'name',
		Header: 'Name',
		sort: true
	},
	{
		accessor: 'country',
		Header: 'Country',
		sort: true
	},
	{
		accessor: 'city',
		Header: 'City',
		sort: true
	}
];

const OfficeList = () => {
	useEffect(() => {
		document.title = "Offices";
	}, [])
	const [state, setState] = useState({
		sortBy: [
			{ id: 'name', desc: false }
		],
		total: 0,
		totalPages: 0,
		remoteData: [],
		pageSize: 10,
		currentPage: 0
	});
	const [isLoading, showLoading] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const fetchIdRef = React.useRef(0);
	const getOffices = React.useCallback(({ page, size, sortBy }) => {
		// Give this fetch an ID
		const fetchId = ++fetchIdRef.current;

		// Only update the data if this is the latest fetch
		if (fetchId === fetchIdRef.current) {
			showLoading(true);
			let formQuery = $("#tableFilters").serialize();
			formQuery += `${(formQuery.length > 0 ? "&" : "")}page=${page}`;
			formQuery += `&take=${size}`;
			if (sortBy && sortBy.length > 0) {
				formQuery += `&sortColumn=${sortBy[0].id}&desc=${sortBy[0].desc}`;
			}
			axiosInstance.get(`${window.location.origin}/offices?${formQuery}`)
				.then((res) => {
					setState({
						sortBy: sortBy,
						total: res.data.count,
						totalPages: Math.ceil(res.data.count / (size * 1.0)),
						remoteData: res.data.data,
						pageSize: size,
						currentPage: page
					});
					showLoading(false);
				});
		}
	}, []);

	const search = () => {
		getOffices({ page: 0, size: state.pageSize, sortBy: state.sortBy });
	};

	const officeTable = () => {

		/*Filters*/
		const renderFilters = (visible) => {
			if (visible) {
				return (<></>);
			} else {
				return (
					<>
						<form id="tableFilters">
							<Row className="mb-3 g-3">
								<Col lg={3}>
									<TextFilter label="Name" id="Name" placeholder="search..." />
								</Col>
								<Col lg={3}>
									<TextFilter label="Country" id="Country" placeholder="search..." />
								</Col>
								<Col lg={3}>
									<TextFilter label="City" id="City" placeholder="search..." />
								</Col>
							</Row>
						</form>
					</>);
			}
		}

		/*Table layout*/
		return (
			<>
				<AdvanceTableWrapper
					columns={columns}
					sortable
					initialSortBy={state.sortBy}
					pagination
					perPage={state.pageSize}
					data={state.remoteData}
					pageCount={state.totalPages}
					fetchData={getOffices}
					initialPage={state.currentPage}
					isLoading={isLoading}
				>

					{/*Filters row*/}
					{renderFilters(showFilters)}

					<AdvanceTable
						trLinkById={`${window.location.origin}/components/offices/edit`}
						table
						headerClassName="bg-200 text-900 text-nowrap align-middle"
						rowClassName="align-middle white-space-nowrap"
						tableProps={{
							bordered: true,
							striped: true,
							className: 'fs--1 mb-0 overflow-hidden'
						}}
					/>
					<div className="mt-3">
						<AdvanceTableFooter
							rowCount={state.total}
							pageCount={state.totalPages}
							table
							rowInfo
							navButtons
							rowsPerPageSelection
						/>
					</div>
				</AdvanceTableWrapper>
			</>
		);
	}

	return (
		<>
			<FalconComponentCard>
				<FalconComponentCard.Header
					light={false}
					className="border-bottom border-200"
				>
					{/*Action Buttons*/}
					<div className="d-flex flex-between-center">
						<Col md="auto" lg={6}>
							<h4>Offices</h4>
						</Col>
						<Col md="auto" lg={6} className="text-end">
							{!showFilters && <Button variant='secondary' size='sm' className='me-2 px-4' onClick={search}>
								<FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className='pe-1' />Search</Button>}
							<IconButton
								variant="falcon-default"
								size="sm"
								icon="fa-filter"
								transform="shrink-3"
								className='me-2'
								onClick={() => setShowFilters(!showFilters)}
							>
								<span className="d-none d-sm-inline-block ms-1">Filters</span>
							</IconButton>
							<Link to="/components/offices/create">
								<IconButton
									variant="primary"
									size="sm"
									icon="plus"
									transform="shrink-3"
								>
									<span className="d-none d-sm-inline-block ms-1">New</span>
								</IconButton>
							</Link>
							{/*<IconButton*/}
							{/*    variant="falcon-default"*/}
							{/*    size="sm"*/}
							{/*    icon="external-link-alt"*/}
							{/*    transform="shrink-3"*/}
							{/*    onClick={() => { window.open(`${window.location.origin}/offices/export-data?fileName=Offices`); }}*/}
							{/*>*/}
							{/*    <span className="d-none d-sm-inline-block ms-1">Export</span>*/}
							{/*</IconButton>*/}
						</Col>
					</div>
				</FalconComponentCard.Header>
				<FalconComponentCard.Body
					children={officeTable()}
					scope={{
						AdvanceTableWrapper,
						AdvanceTable,
						AdvanceTableFooter,
						IconButton,
						Link
					}}
					language="jsx"
					noInline
					noLight
				/>
			</FalconComponentCard>
		</>
	);
};

export default OfficeList;
