import React from 'react';
import { Col, Row, Form, FloatingLabel, Card } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

const YachtMyseBrokerDetail = ({ handleBrokerDetails, formData, loadBrokersOptions }) => {
	const form = '';

	return (
		<Card className="">
			<Card.Header className="d-inline">
				<h5 className="float-start">MySea Broker</h5>

			</Card.Header>
			<Card.Body className="bg-light">
				<Row>
					<Col lg={12}>
						<Row>
							<Form.Group as={Col} lg={12}>
								<FloatingLabel
									label="Name"
								>
									<Form.Select onChange={e => {
										handleBrokerDetails(e.target.value);
									}} value={formData.mySeaBrokerId}>
										{loadBrokersOptions()}
									</Form.Select>
								</FloatingLabel>
							</Form.Group>

						</Row>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default YachtMyseBrokerDetail;
