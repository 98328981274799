import React, { useEffect, useState } from 'react';
import { Card, Col, Modal, Row, Button, Form, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import AddNewInstallmentModal from './addNewInstallmentModal';
import { InstallmentForm, PaymentForm } from 'viewModel/DtoClasses';
import DatePicker from 'react-datepicker';
import SoftBadge from 'components/common/SoftBadge';
import axiosInstance from 'helpers/axiosInstance';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { cloneObject } from 'helpers/utils';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';

const ContractPayments = ({
    formatter,
    controlZone,
    role,
    setFormData,
    formData,
    objectType,
    paymentKinds,
    paymentRecipients,
    emails,
    setEmails,
    defaultEmails,
    setPaymentUpdated
}) => {
    let form = '';
    const [IsModalOpened, setIsModalOpened] = useState(false);
    const [newPayment, setNewPayment] = useState(new PaymentForm());
    const [content, setContent] = useState('');
    const [Add, setAdd] = useState(false);
    const [index, setIndex] = useState(-1);
    const [emailsToDelete, setEmailsToDelete] = useState([]);
    const [displayableEmails, setDisplayableEmails] = useState([]);

    useEffect(() => {
        setDisplayableEmails([...emails]);
        setPaymentUpdated(true);
    }, [])

    const handleAddObj = async (sendemail) => {
        let err = true;
        let value = newPayment;
        let initialId = newPayment.id.toString();
        let data = [...formData.payments];
        let type = objectType.subTypeVal == "In" ? 0 : 1;
        value.emails = [...displayableEmails].filter(x => x.type == type);
        value.contractId = formData.id;
        // try {
        let res = await axiosInstance.put(`${window.location.origin}/payments`, value);
        if (res.status === 200) {
            toast.success(`Payment successfully ${newPayment.id != 0 ? 'saved' : 'created'}.`, {
                theme: 'colored'
            });
            value.id = res.data.Id;
            if (sendemail) {
                await handleResendEmail(value);
            }
            if (initialId != "0") {
                let index = data.findIndex(x => x.id == value.id);
                data[index] = value;
            } else {
                data.push(value);
            }
            setFormData({ ...formData, payments: data });
            setEmails([...displayableEmails]);
            setIsModalOpened(false);
            setPaymentUpdated(true);
        }
        else {
            toast.error(`An error occurred.`, {
                theme: 'colored'
            });
        }
        // }
        // catch (err) {
        // 	console.error(err);
        // 	toast.error(`An error occurred.`, {
        // 		theme: 'colored'
        // 	});
        // }
    }

    const handleResendEmail = async (payment) => {
        let emails = getAllEmails();
        let recipients = emails.map(o => o.Email);

        const form = {
            "Id": payment["Id"],
            "PaymentDate": payment["paymentDate"],
            "Amount": payment["amountReceived"],
            "TransactionType": payment.type,
            "PaymentKind": objectType.subTypeVal,
            "ContractId": formData.id,
            "PaymentTo": payment["paymentRecipient"] ? payment["paymentRecipient"] : "",
            "Recipients": recipients,
            "currency": formData.charter.currency,
            "PaymentId": payment.id,
            "Note": payment["Note"]
        }
        try {
            let res = await axiosInstance.post(`${window.location.origin}/emails/PaymentNotification`, form);
            if (res.status === 200) {
                toast.success(`Email sent successfully.`, {
                    theme: 'colored'
                });
                payment["emailSentDate"] = new Date();
            }
            else {
                toast.error(`There was an error sending the email.`, {
                    theme: 'colored'
                });
            }
        } catch (e) {
            toast.error(`There was an error sending the email.`, {
                theme: 'colored'
            });
        }
    }

    const handleDelete = async e => {
        try {
            let res = await axiosInstance.delete(`${window.location.origin}/payments/${newPayment.id}`);
            if (res.status === 204) {
                toast.success(`${objectType.name} deleted successfully.`, {
                    theme: 'colored'
                });
                let p = [...formData.payments]
                let i = p.findIndex(x => x.id == newPayment.id);
                p.splice(i, 1);
                setFormData({ ...formData, payments: p });
                setNewPayment(new PaymentForm());
                setPaymentUpdated(true);
            }
            else {
                toast.error(`There was an error modifying the ${objectType.name.toLowerCase()}.`, {
                    theme: 'colored'
                });
            }

        }
        catch (err) {
            console.error(err);
            toast.error(`Error deleting the ${objectType.name.toLowerCase()}.`, {
                theme: 'colored'
            });
        }
        setIsModalOpened(false);
    }

    const handleEditPayment = (obj, index) => {
        setNewPayment(obj);
        setIndex(index);
        setDisplayableEmails([...emails])
        setIsModalOpened(true);
    }

    const setReadyToPay = async (e, obj) => {
        e.stopPropagation();
        let data = formData.payments;
        let index = data.findIndex(x => x.id == obj.id);
        try {
            let req = await axiosInstance.put(`${window.location.origin}/payments/${obj.id}/payment-state`);
            if (req.status == 204) {
                data[index].readyToPay = !e.target.checked;
                setFormData({ ...formData, payments: data });
                toast.success("Payment state has been changed", { theme: 'colored' });
            } else {
                toast.error("There was an error changing the payment's state", { theme: 'colored' });
            }

        } catch {
            toast.error("There was an error changing the payment's state", { theme: 'colored' });
        }

    }

    const loadPaymentKindOptions = () => {
        if (paymentKinds.data) {

            return (
                <>
                    <option value={-1} key={-1}>Select payment type</option>
                    {
                        paymentKinds.data.map(payment => (<option value={payment.name} key={payment.id} >
                            {`${payment.name}`}
                        </option>))
                    }
                </>
            );

        }
    }

    const loadPaymentRecepientOptions = () => {
        if (paymentRecipients.data) {

            return (
                <>
                    <option value={-1} key={-1}>{objectType.subTypeVal == "In" ? "Select Payment From" : "Select Payment To"}</option>
                    {
                        paymentRecipients.data.map(payment => (<option value={payment.name} key={payment.id} >
                            {`${payment.name}`}
                        </option>))
                    }
                </>
            );

        }
    }

    const getAllEmails = () => {
        let type = objectType.subTypeVal == "In" ? 0 : 1;
        let contractType = formData.yacht?.ca ? 0 : 1;
        let allMails = displayableEmails.concat(defaultEmails).filter(x => x.type === type || x.type == 2);
        if (formData.contact?.email != null && contractType == type) {
            let contactEmail = { email: formData.contact?.email, type: contractType };
            allMails.push(contactEmail);
        }
        return allMails;
    }

    const loadEmailList = () => {
        let type = objectType.subTypeVal == "In" ? 0 : 1;
        let allMails = getAllEmails();
        let contactEmail = { email: formData.contact?.email, type: formData.yacht?.ca ? 0 : 1 };
        let result = allMails.filter((v, i, a) => a.findIndex(v2 => (v2.email === v.email)) === i);
        if (result.length == 0)
            return (<SoftBadge pill bg='secondary' style={{ lineHeight: 1 }}>No email addresses found. </SoftBadge>);
        return (
            <>
                {
                    result.reverse().map(item => (
                        <SoftBadge pill bg='primary' className='me-2'>
                            {`${item.email ? item.email : item}`}
                            {(role == "Administrator" && !defaultEmails.some(x => x.email === item.email && x.type === type) && contactEmail.email != item.email && item.type != 2) &&
                                <FontAwesomeIcon color='red' onClick={() => { handleEmailDelete(item); }} style={{ paddingLeft: '5px', cursor: 'pointer' }} icon="fa-solid fa-xmark" />
                            }
                        </SoftBadge>
                    ))
                }
            </>
        );
    }

    const handleEmailDelete = (emailToDelete) => {
        let cl = [...displayableEmails];
        let i = cl.findIndex(x => x == emailToDelete);
        cl.splice(i, 1);
        setDisplayableEmails(cl);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (/\S+@\S+\.\S+/.test(event.target.value)) {
                if (!getAllEmails().some(x => x.email == event.target.value)) {
                    let type = objectType.subTypeVal == "In" ? 0 : 1;
                    displayableEmails.push({ email: event.target.value, type: type });
                    setDisplayableEmails(displayableEmails);
                    setAdd(false);
                }
                else {
                    toast.error(`That email is already in the list.`, {
                        theme: 'colored'
                    });
                }
            }
            else {
                toast.error(`Please enter a valid email.`, {
                    theme: 'colored'
                });
            }
        }
    }

    const handleNew = () => {
        let payment = new PaymentForm();
        payment.type = objectType.subTypeVal;
        payment.contractId = formData.id;
        payment.id = 0;
        setNewPayment(payment);
        setIndex(index);
        setDisplayableEmails([...emails])
        setIsModalOpened(true);
    }

    const SingleItem = ({ title, value, formatter }) => {
        return (
            <div className="bg-transparent text-grey px-3 py-1">
                <div className='text-center'>
                    <h5>{formatter.format(value)}</h5>
                    <p className="mb-0">{title}</p>
                </div>
            </div>
        );
    };

    return (
        <>
            <Card>
                <Card.Header style={objectType.subTypeVal == "In" ? { backgroundColor: "rgb(0 210 122 / 64%)" } : { backgroundColor: "rgb(230 55 87 / 78%)" }}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h5 className="mb-0 text-white">{objectType.subTypeVal == "In" ? "Incoming" : "Outgoing"} Payments</h5>
                        <div className={`float-end ${role != "Administrator" && "d-none"}`}>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="plus"
                                transform="shrink-3"
                                onClick={() => { handleNew() }}
                            >
                                <span className="d-none d-sm-inline-block ms-1" >New</span>
                            </IconButton>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="timeline-vertical pt-0 pb-0">
                        <Table borderless hover className="mb-0" responsive>
                            <thead className="position-static">
                                <tr style={{ textAlign: "center" }}>
                                    <th scope="col" >Amount to {objectType.subTypeVal == "In" ? "Receive" : "Pay"}</th>
                                    <th scope="col" >Date to {objectType.subTypeVal == "In" ? "Receive" : "Pay"}</th>
                                    <th scope="col" >Type</th>
                                    {
                                        objectType.subTypeVal == "Out" && !role.includes("External") ? <th scope="col" >Ready to pay</th> : null
                                    }
                                    <th scope="col" >Amount {objectType.subTypeVal == "In" ? "Received" : "Paid"} </th>
                                    <th scope="col" >Date {objectType.subTypeVal == "In" ? "Received" : "Paid"} </th>
                                    <th scope="col" >Email Notification</th>
                                    <th scope="col" >C/R</th>
                                    <th scope="col" >Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    formData.payments.length == 0 ? <>
                                        <tr className="bg-200">
                                            <td colSpan={8} className="text-center">
                                                No payments were found.
                                            </td>
                                        </tr>
                                    </> : <>
                                        {
                                            formData.payments.filter(x => x.type == objectType.subTypeVal).sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime()).map((obj, index) => {
                                                return (
                                                    <tr key={index} style={{ height: "46px", textAlign: "center", cursor: "pointer", borderLeft: obj.amountReceived == 0 && obj.dueDate < new Date() ? "5px solid #e63757" : obj.amountReceived == 0 && (obj.dueDate) < (new Date(new Date().getTime() + (5 * 24 * 60 * 60 * 1000))) ? "5px solid #f5803e" : null }}
                                                        onClick={() => {
                                                            handleEditPayment(obj, index);
                                                        }}>
                                                        <td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{formatter.format(obj.amountToPay)}</td>
                                                        <td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{obj.dueDate ? obj.dueDate.getFullYear() === 1970 ? '' : obj.dueDate.toLocaleDateString('es') : null}</td>
                                                        <td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{obj.kind ? obj.kind : null}</td>
                                                        {
                                                            objectType.subTypeVal == "Out" && !role.includes("External") ?
                                                                <th scope="col" >
                                                                    <Form.Check
                                                                        type='checkbox'
                                                                        checked={obj.readyToPay}
                                                                        value={obj.readyToPay}
                                                                        onClick={(e) => setReadyToPay(e, obj)}
                                                                        onChange={(e) => { }}
                                                                    />
                                                                </th> : null
                                                        }
                                                        <td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{formatter.format(obj.amountReceived)}</td>
                                                        <td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{obj.paymentDate ? obj.paymentDate.getFullYear() === 1970 ? '' : obj.paymentDate.toLocaleDateString('es') : null}</td>
                                                        <td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{obj.emailSentDate ? <SoftBadge pill bg="success" className="fs--2"> {new Date(obj.emailSentDate).toLocaleString([], { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit' })} </SoftBadge> : <SoftBadge pill bg="danger" className="fs--2"> Email not sent. </SoftBadge>}</td>
                                                        <td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{obj.cleared == true ? obj.reconciled == true ? <FontAwesomeIcon icon="check" transform="shrink-1" className="text-success" /> : <span className="text-primary">C</span> : obj.reconciled == true ? <span className="text-primary">R</span> : <FontAwesomeIcon icon="xmark" transform="shrink-1" className="text-danger" />}</td>
                                                        <td style={{ paddingBottom: "8px", paddingTop: "8px", verticalAlign: "middle" }}>{obj.note?.trim() != "" ? <FontAwesomeIcon icon="check" transform="shrink-1" className="text-success" /> : <FontAwesomeIcon icon="xmark" transform="shrink-1" className="text-danger" />}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </>
                                }
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
                <Card.Footer className='border-top'>
                    {objectType.subTypeVal == "In" ?
                        <>
                            <Row>
                                {controlZone.map((item, index) => (
                                    <Col lg={6} key={index}>
                                        <SingleItem title={item.name} value={item.value} formatter={formatter} />
                                    </Col>

                                ))}
                            </Row>
                        </> :
                        <>
                            <Row>
                                {controlZone.map((item, index) => (
                                    <Col lg={4} key={index}>
                                        <SingleItem title={item.name} value={item.value} formatter={formatter} />
                                    </Col>

                                ))}
                            </Row>
                        </>
                    }
                </Card.Footer>
            </Card>
            <Modal
                show={IsModalOpened}
                onHide={() => { setIsModalOpened(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <h4>{objectType.subTypeVal == "In" ? "Incoming payment" : "Outgoing payment"}</h4>
                </Modal.Header>
                <Modal.Body >
                    <div className='row mx-0'>
                        <Form.Group className="mt-3 col-12 d-none">
                            <Form.Label>Transaction type</Form.Label>
                            <Form.Select disabled>
                                <option value={0} key={0}>Select transaction type</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="col-6">
                            <Form.Label className="mb-2">Amount to {objectType.subTypeVal == "In" ? "Receive" : "Pay"}</Form.Label>
                            <Form.Control
                                onWheel={(e) => e.target.blur()}
                                disabled={role != "Administrator"}
                                value={newPayment.amountToPay}
                                onChange={(e) => {
                                    const rawValue = e.target.value.replace(/[^0-9.,]/g, ''); // Remove non-numeric, non-comma, non-dot characters
                                    const numericValue = rawValue.replace(/,/g, '.'); // Replace commas with dots
                                    // Only allow one dot or comma
                                    if ((numericValue.match(/\./g) || []).length > 1 || (numericValue.match(/,/g) || []).length > 1) {
                                        return;
                                    }
                                    setNewPayment({ ...newPayment, amountToPay: numericValue });
                                }}
                                name="amount"
                            />
                        </Form.Group>
                        <Form.Group className="col-6">
                            <Form.Label className="mb-2">
                                Amount {objectType.subTypeVal === "In" ? "Received" : "Paid"}
                            </Form.Label>
                            <Form.Control
                                disabled={role !== "Administrator"}
                                onWheel={(e) => e.target.blur()}
                                value={newPayment.amountReceived}
                                onChange={(e) => {
                                    const rawValue = e.target.value.replace(/[^0-9.,]/g, ''); // Remove non-numeric, non-comma, non-dot characters
                                    const numericValue = rawValue.replace(/,/g, '.'); // Replace commas with dots

                                    // Only allow one dot or comma
                                    if ((numericValue.match(/\./g) || []).length > 1 || (numericValue.match(/,/g) || []).length > 1) {
                                        return;
                                    }
                                    setNewPayment({ ...newPayment, amountReceived: numericValue });

                                }}
                                name="amount"
                            />
                        </Form.Group>
                        <Form.Group className="mt-3 col-6">
                            <Form.Label className="mb-2 d-block">Due date</Form.Label>
                            <DatePicker
                                autoComplete="off"
                                selected={newPayment.dueDate}
                                onChange={(date) => {
                                    setNewPayment({ ...newPayment, dueDate: new Date(date.getTime() - date.getTimezoneOffset() * 60000) });
                                }}
                                formatWeekDay={day => day.slice(0, 3)}
                                className='form-control'
                                value={newPayment.dueDate}
                                disabled={role != "Administrator"}
                                name="dueDate"
                                format="dd/MM/yyyy"

                                dateFormat="dd/MM/yyyy"
                            />
                        </Form.Group>
                        <Form.Group className="mt-3 col-6">
                            <Form.Label className="mb-2 d-block">Payment date</Form.Label>
                            <DatePicker
                                disabled={role != "Administrator"}
                                autoComplete="off"
                                onChange={(date) => {
                                    setNewPayment({ ...newPayment, paymentDate: new Date(date.getTime() - date.getTimezoneOffset() * 60000) });
                                }}
                                formatWeekDay={day => day.slice(0, 3)}
                                className='form-control'
                                selected={newPayment.paymentDate}
                                name="PaymentDate"
                                format="dd/MM/yyyy"
                                dateFormat="dd/MM/yyyy"
                            />
                        </Form.Group>
                        <Form.Group className="mt-3 col-6">
                            <Form.Label className="mb-2">Payment type</Form.Label>
                            <Form.Select disabled={role != "Administrator"}
                                onChange={(e) => {
                                    setNewPayment({ ...newPayment, kind: e.target.value });
                                }}
                                value={newPayment.kind}
                            >

                                {loadPaymentKindOptions()}

                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mt-3 col-6">
                            <Form.Label className="mb-2">{objectType.subTypeVal == "In" ? "Payment From" : "Payment To"}</Form.Label>
                            <Form.Select disabled={role != "Administrator"} value={newPayment.paymentRecipient} onChange={e => { setNewPayment({ ...newPayment, paymentRecipient: e.target.value }); }} >

                                {loadPaymentRecepientOptions()}

                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='d-block w-100 my-2'>
                            <Form.Label className="mb-2">Note</Form.Label>
                            <Form.Control
                                type="text"
                                disabled={role != "Administrator"}
                                as="textarea"
                                rows={2}
                                value={newPayment.note}
                                onChange={(e) => {
                                    setNewPayment({ ...newPayment, note: e.target.value });
                                }}
                                id="note"
                            />
                        </Form.Group>
                        {
                            <Form.Group className='d-block w-100 my-2'>
                                <Form.Label className="mb-2">Payment details</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={role != "Administrator"}
                                    as="textarea"
                                    rows={2}
                                    value={newPayment.reconciliationDetails}
                                    onChange={(e) => {
                                        setNewPayment({ ...newPayment, reconciliationDetails: e.target.value });
                                    }}
                                    id="note"
                                />
                            </Form.Group>
                        }
                        <Form.Group className='d-block w-100 my-2'>
                            <Form.Label style={{ marginRight: "10px" }} className="mb-2">Email Recipient List</Form.Label>
                            {role == "Administrator" || role == "Broker" ?
                                <a onClick={() => { setAdd(!Add); }} style={{ cursor: 'pointer' }}>
                                    <SoftBadge pill bg='success' style={{ lineHeight: 1 }} className='ml-2'>
                                        <FontAwesomeIcon icon="fa-solid fa-plus" size="xl" color="#5cb85c" className="fs-1" /> Add
                                    </SoftBadge>
                                </a> : null}

                            <div id="emaillist">
                                {Add ? <SoftBadge style={{ lineHeight: 3, backgroundColor: "#c8cfd89c" }} backgroundcolor="#c8cfd89c" pill className='me-2'>
                                    <input autoFocus id="emailInput" style={{ background: 'transparent', border: 'none', outline: 'none' }} type="email" name="name" onKeyDown={handleKeyDown} />

                                    <BsArrowReturnLeft color='grey' size='12' />
                                </SoftBadge> : null}
                                {loadEmailList()}
                            </div>
                            <div className='mt-2' style={{ fontSize: "15px" }}>
                                {newPayment.emailSentDate != null ?
                                    "Email notification for this payment was sent on " + new Date(newPayment.emailSentDate).toLocaleString()
                                    : "Email notification has not been sent for this payment."
                                }

                            </div>
                        </Form.Group>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="w-100">

                        {
                            (role == "Administrator" && (
                                <>
                                    <Col className="justify-content-start" lg="auto">
                                        {newPayment.id != 0 ?
                                            <Button
                                                variant="danger"
                                                onClick={() => { handleDelete(); }}
                                            >
                                                Delete
                                            </Button> : null
                                        }
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <Link target="_blank" className='btn fw-bold btn-primary me-2' to={`/components/payments/${newPayment.id}/remittance`}>Remittance</Link>
                                        <Button
                                            variant="primary"
                                            className="me-2"
                                            onClick={() => {
                                                handleAddObj(true);
                                            }
                                            }
                                            disabled={(!newPayment.amountToPay || !newPayment.dueDate || !newPayment.kind)}
                                        >
                                            {newPayment.id != 0 ? "Save" : "Add"} & send email

                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={() => {
                                                handleAddObj(false);
                                            }
                                            }
                                            disabled={(!newPayment.amountToPay || !newPayment.dueDate || !newPayment.kind)}
                                        >
                                            {newPayment.id != 0 ? "Save" : "Add"}

                                        </Button>

                                        {/*<Button onClick={() => { setModalShow(false); setNewObject({ kind: {}, Amount: 0 }); }}>Cancel</Button>*/}
                                    </Col>
                                </>
                            ))
                        }
                        {
                            role == "ExternalUser" && <Col className="d-flex justify-content-end">
                                <Link target="_blank" className='btn fw-bold btn-primary me-2' to={`/components/payments/${newPayment.id}/remittance`}>Remittance</Link>
                            </Col>
                        }
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ContractPayments;
