import React, { useEffect, useState } from 'react';
import { Card, Col, Modal, Row, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import AddNewInstallmentModal from './addNewInstallmentModal';
import { InstallmentForm } from 'viewModel/DtoClasses';
import DatePicker from 'react-datepicker';
import { getUserRole } from 'helpers/utils';

const ContractControlZone = ({
	formatter,
	paymentSummary,
	brokerAdvancedCommission
}) => {
	const role = getUserRole();

	return (
		<Card className='h-100'>
			<Card.Body className='px-0 py-1'>
				<table className="fs--1 mb-0 overflow-hidden table">
					<thead className="bg-100 text-800">
						<tr>
							<th className="text-nowrap"></th>
							<th className="text-nowrap text-end">Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="text-truncate fw-bold">Installment Total</td>
							<td className="text-truncate text-end">{formatter.format(paymentSummary.toReceive)}</td>
						</tr>
						<tr className="bg-100 text-800 bg-success">
							<th className="text-nowrap text-white">To receive</th>
							<th className="text-nowrap text-end"></th>
						</tr>
						<tr>
							<td className="text-truncate fw-bold">Installment 1</td>
							<td className="text-truncate text-end">{formatter.format(paymentSummary.toRInstall1)}</td>
						</tr>
						<tr>
							<td className="text-truncate fw-bold">Installment 2</td>
							<td className="text-truncate text-end">{formatter.format(paymentSummary.toRInstall2)}</td>
						</tr>
						<tr>
							<td className="text-truncate fw-bold">Installment 3</td>
							<td className="text-truncate text-end">{formatter.format(paymentSummary.toRInstall3)}</td>
						</tr>
						{
							role != "ExternalUser" && <>
								<tr className="bg-100 text-800 bg-danger">
									<th className="text-nowrap text-white">To pay</th>
									<th className="text-nowrap text-end">
									</th>
								</tr>
								<tr>
									<td className="text-truncate fw-bold">VAT</td>
									<td className="text-truncate text-end">{formatter.format(paymentSummary.ToPVat)}</td>
								</tr>
								<tr>
									<td className="text-truncate fw-bold">Installment 1</td>
									<td className="text-truncate text-end">{formatter.format(paymentSummary.toPInstall1)}</td>
								</tr>
								<tr>
									<td className="text-truncate fw-bold">Installment 2</td>
									<td className="text-truncate text-end">{formatter.format(paymentSummary.toPInstall2)}</td>
								</tr>
								<tr>
									<td className="text-truncate fw-bold">Installment 3</td>
									<td className="text-truncate text-end">{formatter.format(paymentSummary.toPInstall3)}</td>
								</tr>
								<tr>
									<td className="text-truncate fw-bold">Comm. Broker to Pay</td>
									<td className="text-truncate text-end">{formatter.format(brokerAdvancedCommission.commBrokerToPay)}</td>
								</tr>
								<tr>
									<td className="text-truncate fw-bold">Referral to pay</td>
									<td className="text-truncate text-end">{formatter.format(paymentSummary.referralToPay)}</td>
								</tr>
								<tr>
									<td className="text-truncate fw-bold">Comm. Net MySea</td>
									<td className="text-truncate text-end">{formatter.format(brokerAdvancedCommission.commNetMySea)}</td>
								</tr>
							</>
						}
					</tbody>
				</table>
			</Card.Body>
		</Card>
	);
};

export default ContractControlZone;
