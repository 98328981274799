import React, { useEffect, useState } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Col, Row, Button, Spinner, Form } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';
import TextFilter from 'components/filters/textFilter';
import NumberFilter from 'components/filters/numberFilter';
import axiosInstance from 'helpers/axiosInstance';
import { BeatLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { toast } from 'react-toastify';



const CommisssionInvoiceList = () => {
	const [state, setState] = useState({
		sortBy: [
			{ id: 'fileName', desc: true }
		],
		total: 0,
		totalPages: 0,
		remoteData: [],
		pageSize: 10,
		currentPage: 0
	});
	const [isLoading, showLoading] = useState(false);
	const [exportLoading, setExportLoading] = useState(false);
	const [exportInvoiceLoading, setExportInvoiceLoading] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [exportSelects, setExportSelects] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const fetchIdRef = React.useRef(0);
	const columns = [
		{
			accessor: 'Id',
			sort: false,
			Header: () => (
				<Form.Check
					type='checkbox'
					checked={selectAll}
					onClick={(e) => {
						setSelectAll(!selectAll);
					}}
				/>
			),
			Cell: rowData => {
				const { id } = rowData.row.original;
				return (
					<Form.Check
						type='checkbox'
						checked={exportSelects.some(x => x == id) || selectAll}
						onClick={(e) => {
							console.log(e);
							let d = [...exportSelects];
							let index = d.findIndex(x => x == id);
							if (index == -1) {
								d.push(id);
							} else {
								d.splice(index, 1);
							}
							setExportSelects(d);
						}}
					/>
				)
			}
		},
		{
			accessor: 'invoiceNumber',
			sort: true,
			Header: 'Invoice number',
			// Cell: rowData => {
			// 	const { invoiceNumber, contractId } = rowData.row.original;
			// 	return (
			// 		<span onClick={() => downloadFile(invoiceNumber, contractId)} className='text-primary text-decoration-underscore cursor-pointer'>{invoiceNumber}</span>
			// 	)
			// }
		},
		{
			accessor: 'fileName',
			sort: true,
			Header: 'File number',
			Cell: rowData => {
				const { contractId, fileName } = rowData.row.original;
				return (
					<Link target="_blank" to={`/components/contracts/editSecondPage?id=${contractId}`}>{fileName}</Link>
				)
			}
		},
		{
			accessor: 'YachtName',
			sort: true,
			Header: 'Yacht name',
			Cell: rowData => {
				const { yachtId, yachtName } = rowData.row.original;
				return (
					<Link target="_blank" to={`/components/yachts/edit?id=${yachtId}`}>{yachtName}</Link>
				)
			}
		},
		{
			accessor: 'commissionType',
			sort: true,
			Header: 'Commission type',
			Cell: rowData => {
				const { commissionType } = rowData.row.original;
				let text = commissionType;
				return (
					<h6>{text}</h6>
				)
			}
		},
		{
			accessor: 'broker',
			sort: true,
			Header: 'Name'
		},
		{
			accessor: 'commission',
			sort: true,
			Header: 'Commission %',
			Cell: rowData => {
				const { commission } = rowData.row.original;
				return (
					<h6>{commission}</h6>
				)
			}
		},
		{
			accessor: 'amount',
			sort: true,
			Header: 'Amount',
			Cell: rowData => {
				const { amount, currency } = rowData.row.original;
				let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: currency });
				return (
					<span>{formatter.format(amount)}</span>
				)
			}
		},
		{
			accessor: 'vat',
			sort: true,
			Header: 'VAT',
			Cell: rowData => {
				const { vat, currency } = rowData.row.original;
				let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: currency });
				return (
					<span>{formatter.format(vat)}</span>
				)
			}
		}
	];

	useEffect(() => {
		document.title = "Commissions invoices";
	}, [])

	const getInvoices = React.useCallback(({ page, size, sortBy }) => {
		// Give this fetch an ID
		const fetchId = ++fetchIdRef.current;

		// Only update the data if this is the latest fetch
		if (fetchId === fetchIdRef.current) {
			showLoading(true);
			let formQuery = $("#tableFilters").serialize();
			formQuery += `${(formQuery.length > 0 ? "&" : "")}page=${page}`;
			formQuery += `&take=${size}`;
			if (sortBy && sortBy.length > 0) {
				let sortQuery = encodeURIComponent(sortBy.map(x => `${x.id} ${x.desc ? "desc" : "asc"}`).join(','));
				formQuery += `&sort=${sortQuery}`;
			}
			axiosInstance.get(`${window.location.origin}/contracts/commission-invoices?${formQuery}`)
				.then((res) => {
					setState({
						sortBy: sortBy,
						total: res.data.count,
						totalPages: Math.ceil(res.data.count / (size * 1.0)),
						remoteData: res.data.data,
						pageSize: size,
						currentPage: page
					});
					showLoading(false);
				});
		}
	}, []);
	const search = () => {
		getInvoices({ page: 0, size: state.pageSize, sortBy: state.sortBy });
	};

	const exportTableToExcel = () => {
		setExportLoading(true);
		let formQuery = $("#tableFilters").serialize();
		let form = new FormData();
		if (selectAll) {
			form.append('ids', [-1])
		} else {
			exportSelects.forEach(function (value, index) {
				form.append('ids[' + index + ']', value);
			});
		}
		axiosInstance.post(`${window.location.origin}/contracts/commission-invoices/export?${formQuery}`, form,
			{ responseType: 'blob' })
			.then(response => {
				let blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = `commissions${moment(new Date()).format("DDMMYYYY")}.xlsx`
				link.click()
				setExportLoading(false);
			}).catch(e => {
				console.log(e);
				toast.error("There was an error exporting the data", { theme: 'colored' })
				setExportLoading(false);
			})
	}

	const exportTableInvoices = () => {
		setExportInvoiceLoading(true);
		let formQuery = $("#tableFilters").serialize();
		let form = new FormData();
		if (selectAll) {
			form.append('ids', [-1])
		} else {
			exportSelects.forEach(function (value, index) {
				form.append('ids[' + index + ']', value);
			});
		}
		axiosInstance.post(`${window.location.origin}/contracts/commission-invoices/export-invoices?${formQuery}`, form,
			{ responseType: 'blob' })
			.then(response => {
				let blob = new Blob([response.data], { type: "application/zip" })
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = `commissions${moment(new Date()).format("DDMMYYYY")}.zip`
				link.click()
			}).catch(e => {
				console.log(e);
				toast.error("There was an error exporting the data", { theme: 'colored' })
			}).finally(() => {
				setExportInvoiceLoading(false);
			})
	}

	const downloadFile = (invoiceNumber, contractId) => {
		axiosInstance.get(`${window.location.origin}/contracts/${contractId}/file?Num=${invoiceNumber}`)
			.then(response => {
				let blob = new Blob([response.data], { type: "application/pdf" })
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = `${invoiceNumber}.pdf`
				link.click()
			}).catch(e => {
				console.log(e);
				toast.error("There was an error downloading the file data", { theme: 'colored' })
			})
	}

	const invoiceTable = () => {

		/*Filters*/
		const renderFilters = (visible) => {
			if (visible) {
				return (<></>);
			} else {
				return (
					<>
						<form id="tableFilters">
							<Row className="mb-3 g-3 justify-content-end">
								<Col>
									<TextFilter label="Invoice" id="InvoiceNumber" placeholder="search..." />
								</Col>
								<Col>
									<TextFilter label="Contract" id="FileName" placeholder="search..." />
								</Col>
								<Col>
									<TextFilter label="Yacht" id="YachtName" placeholder="search..." />
								</Col>
								<Col>
									<TextFilter label="Commission type" id="CommissionType" placeholder="search..." />
								</Col>
								<Col>
									<TextFilter label="Name" id="Broker" placeholder="search..." />
								</Col>
							</Row>
							<Row className="mb-3 g-3 justify-content-end">
								<Col lg={2}>
									<NumberFilter label="Commission %" id="Commission" placeholder="search..." />
								</Col>
								<Col lg={2}>
									<NumberFilter label="Amount" id="Amount" placeholder="search..." />
								</Col>
								<Col lg={2}>
									<NumberFilter label="VAT" id="Vat" placeholder="search..." />
								</Col>
							</Row>
						</form>
					</>);
			}
		}

		/*Table layout*/
		return (
			<>
				<AdvanceTableWrapper
					columns={columns}
					sortable
					initialSortBy={state.sortBy}
					pagination
					perPage={state.pageSize}
					data={state.remoteData}
					pageCount={state.totalPages}
					fetchData={getInvoices}
					initialPage={state.currentPage}
					isLoading={isLoading}
				>
					{renderFilters(showFilters)}
					<AdvanceTable
						table
						headerClassName="bg-200 text-900 text-nowrap align-middle"
						rowClassName="align-middle white-space-nowrap"
						tableProps={{
							bordered: true,
							striped: true,
							className: 'fs--1 mb-0 overflow-hidden'
						}}
					/>
					<div className="mt-3">
						<AdvanceTableFooter
							rowCount={state.total}
							pageCount={state.totalPages}
							table
							rowInfo
							navButtons
							rowsPerPageSelection
						/>
					</div>
				</AdvanceTableWrapper>
			</>
		);
	}

	return (
		<>
			<FalconComponentCard>
				<FalconComponentCard.Header
					light={false}
					className="border-bottom border-200"
				>
					{/*Action Buttons*/}
					<div className="d-inline">
						<h4 className="float-start">Commissions invoices</h4>
						<div className="float-end">
							{!showFilters && <Button variant='secondary' size='sm' className='me-2 px-4' onClick={search}>
								<FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className='pe-1' />Search</Button>}
							<IconButton
								variant="falcon-default"
								size="sm"
								icon="fa-filter"
								transform="shrink-3"
								className='me-2'
								onClick={() => setShowFilters(!showFilters)}
							>
								<span className="d-none d-sm-inline-block ms-1">Filters</span>
							</IconButton>
							<IconButton
								variant="falcon-default"
								size="sm"
								icon="external-link-alt"
								transform="shrink-3"
								onClick={exportTableToExcel}
							>
								{exportLoading ? (<Spinner animation="border" role="status" size="sm" className='ms-2'>
									<span className="visually-hidden">Loading...</span>
								</Spinner>) : "Export"}
							</IconButton>
							<span className='ms-2'>
								<IconButton
									variant="falcon-default"
									size="sm"
									icon="external-link-alt"
									transform="shrink-3"
									onClick={exportTableInvoices}
								>
									{exportInvoiceLoading ? (<Spinner animation="border" role="status" size="sm" className='ms-2'>
										<span className="visually-hidden">Loading...</span>
									</Spinner>) : "Export invoices"}
								</IconButton>
							</span>
						</div>
					</div>
				</FalconComponentCard.Header>
				<FalconComponentCard.Body
					children={invoiceTable()}
					scope={{
						AdvanceTableWrapper,
						AdvanceTable,
						AdvanceTableFooter,
						AdvanceTableSearchBox,
						IconButton,
						Link
					}}
					language="jsx"
					noInline
					noLight
				/>
			</FalconComponentCard>
		</>
	);


};

export default CommisssionInvoiceList;
