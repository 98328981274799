import UserForm from 'components/users/UserForm';


const CreateBrokerForm = (props) => {
    return (
        <>
            <UserForm isBroker={true} />
        </>
    );
};

export default CreateBrokerForm;