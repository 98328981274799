import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const DateRangeFilter = ({ label, id, startDate, endDate }) => {
    const [dpStartDate, setStartDate] = useState(startDate ? startDate : null);
    const [dpEndDate, setEndDate] = useState(endDate ? endDate : null);
    const onChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    return (
        <>
            <Form.Group>
                <Form.Label htmlFor={id}>{label}</Form.Label>
                <DatePicker
                    id={id}
                    name={id}
                    selected={dpStartDate}
                    onChange={onChange}
                    startDate={dpStartDate}
                    endDate={dpEndDate}
                    formatWeekDay={day => day.slice(0, 3)}
                    selectsRange
                    dateFormat="dd.MM.yyyy"
                    className='form-control'
                    language="jsx"
                />
            </Form.Group>
        </>
    );
}

DateRangeFilter.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    startDate: PropTypes.string,
    endDate: PropTypes.string
};

export default DateRangeFilter;