import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Form, Spinner, Button, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import mySeaLogo from 'assets/img/logos/my-sea-logo.png';
import axiosInstance from 'helpers/axiosInstance';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';

const OfficeInvoicePreview = (props) => {
    const location = useLocation();
    const [contract, setContract] = useState();
    const [formatter, setFormatter] = useState();
    const [comment, setComment] = useState(true);
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const param = useParams();
    const navigate = useNavigate();
    const [pdfData, setPdfData] = useState(null);
    const [vatNumber, setVatNumber] = useState("");
    const [vatDate, setVatDate] = useState(new Date());
    const [render, setRender] = useState(false);
    let timer = null;
    let controller = new AbortController();
    let signal = controller.signal;

    useEffect(() => {
        axiosInstance.get(`${window.location.origin}/contracts/${param.id}`).then(x => {
            let formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: x.data.contract.charter.currency });
            setFormatter(formatter);
            setContract(x.data.contract);
            setVatNumber(x.data.contract.yacht.defaultVATNumber);
            if (x.data.contract.officeCommission?.invoice == null) {
                generateNumber();
            } else {
                setInvoiceNumber(x.data.contract.officeCommission?.invoice);
                setRender(true);
            }
        });
    }, [])


    useEffect(() => {
        console.log(vatNumber, comment, render, vatDate, invoiceNumber);
        if (contract != null && render) {
            let form = new FormData();
            if (vatNumber != null)
                form.append('VatNumber', vatNumber);
            form.append('addComment', comment);
            form.append('date', vatDate.toISOString());
            form.append('invoiceRef', invoiceNumber ? invoiceNumber : '');
            axiosInstance.post(`${window.location.origin}/contracts/${param.id}/visualise`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                responseType: 'arraybuffer', // To receive the response as a buffer,
                signal: signal
            }).then(resp => {
                const blob = new Blob([resp.data], { type: "application/pdf" });
                const url = URL.createObjectURL(blob);
                setPdfData(url);
                setRender(false);
            })
        }
    }, [render])

    const generateAndUpload = async () => {
        if (!invoiceNumber || invoiceNumber == "") {
            toast.error("Invoice reference cannot be empty", { theme: 'colored' });
        } else {
            setLoading(true);
            let form = new FormData();
            if (vatNumber != null)
                form.append('VatNumber', vatNumber);
            form.append('addComment', comment);
            form.append('date', vatDate.toISOString());
            form.append('invoiceRef', invoiceNumber ? invoiceNumber : '');
            axiosInstance.post(`${window.location.origin}/contracts/${param.id}/office-invoice?comment=${comment}`, form).then(c => {
                toast.success("Invoice generated and uploaded", { theme: 'colored' });
                navigate(`/components/contracts/editSecondPage?id=${param.id}`);
                setLoading(false);
            }).catch(e => {
                toast.error("An error has occurred while generating the invoice", { theme: 'colored' });
                setLoading(false);
            })
        }
    }
    const copyLinkToInvoice = async () => {
        if (!invoiceNumber || invoiceNumber == "" || contract.officeCommission.invoice == null) {
            toast.error("Invoice reference cannot be empty, the invoice must be generated before copying the link", { theme: 'colored' });
        } else {
            setLoading(true);
            axiosInstance.post(`${window.location.origin}/contracts/${param.id}/link-office-invoice`).then(c => {
                toast.success("Link to invoice copied to clipboard", { theme: 'colored' });
                navigator.clipboard.writeText(c.data);
                setLoading(false);
            }).catch(e => {
                toast.error("An error has occurred while generating the link to the invoice", { theme: 'colored' });
                setLoading(false);
            })
        }

    }
    const loadVatNumber = () => {
        return (
            <>
                {
                    <option value={contract.yacht.defaultVATNumber}>{contract.yacht.defaultVATNumber}</option>
                }
                {
                    <option value={contract.yacht.secondVATNumber}>{contract.yacht.secondVATNumber}</option>
                }
                {
                    <option value={contract.yacht.thirdVATNumber}>{contract.yacht.thirdVATNumber}</option>
                }
            </>
        );
    }
    const generateNumber = () => {
        let form = new FormData();
        form.append('date', vatDate.toISOString());
        axiosInstance.post(`${window.location.origin}/contracts/${param.id}/invoice-number`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(res => {
            if (res.status == 200) {
                setInvoiceNumber(res.data);
            }
            setRender(true);
        });
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between align-items-center">
                        <Col md xs="auto">
                            <Link className="mb-2 mb-md-0 h5 text-primary" to={`/components/contracts/editSecondPage?id=${param.id}`}>Contract #{contract?.fileName}</Link>
                        </Col>
                        <Col className='d-flex' xs="auto">
                            <Button
                                variant="falcon-primary"
                                size="sm"
                                className="mb-2 mb-sm-0 ms-2"
                                style={{ whiteSpace: "nowrap" }}
                                onClick={generateAndUpload}
                            >
                                {!loading ? (<span><FontAwesomeIcon icon='upload'></FontAwesomeIcon> Generate and upload</span>) : (
                                    <Spinner variant="primary" animation="border" size="sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>)}
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className='w-100' style={{ height: "800px" }}>
                <Card.Header className='d-flex justify-content-end border-bottom'>
                    <Form.Group className="px-2">
                        <Form.Label className="mb-2">Yacht note options</Form.Label>
                        <Form.Check
                            checked={comment}
                            onChange={(e => { setComment(!comment); setRender(true) })}
                            label="Add note from yacht"
                            style={{ whiteSpace: "nowrap" }}
                            className="me-2 customCheckboxNoMargin"
                        >
                        </Form.Check>
                    </Form.Group>
                    {
                        contract?.yacht != null &&
                        <Form.Group className="px-2">
                            <Form.Label className="mb-2">Vat number option</Form.Label>
                            <Form.Select size="sm"
                                onChange={e => { setVatNumber(e.target.value); setRender(true) }}
                                value={vatNumber}
                                style={{ width: "200px" }}>
                                {
                                    loadVatNumber()
                                }
                            </Form.Select>
                        </Form.Group>
                    }
                    <Form.Group className="px-2" style={{ width: "200px" }}>
                        <Form.Label className="mb-2">Invoice date</Form.Label>
                        <DatePicker
                            selected={vatDate}
                            onChange={(date) => { setVatDate(date); setRender(true); }}
                            formatWeekDay={day => day.slice(0, 3)}
                            className='form-control form-control-sm'
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Invoice date"
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group className="px-2">
                        <Form.Label className="mb-2 d-block">Invoice number</Form.Label>
                        <InputGroup size="sm">
                            <Form.Control size="sm"
                                type="text"
                                id="invoiceNumber"
                                value={invoiceNumber}
                                onChange={(e) => setInvoiceNumber(e.target.value)}
                                onBlur={(e) => setRender(true)}
                                style={{ width: "200px", }}
                            />
                            <OverlayTrigger
                                overlay={
                                    <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
                                        Automatically generate new number
                                    </Tooltip>
                                }
                            >
                                <InputGroup.Text
                                    as={Button}
                                    variant="secondary"
                                    onClick={() => generateNumber()}
                                >
                                    #
                                </InputGroup.Text>
                            </OverlayTrigger>
                        </InputGroup>
                    </Form.Group>
                    {contract?.officeCommission.invoice != null &&
                        <Form.Group className="px-2">
                            <Form.Label className="mb-2">Copy link</Form.Label>
                            <Button
                                variant="falcon-primary"
                                size="sm"
                                className="mb-2 mb-sm-0 ms-2"
                                onClick={copyLinkToInvoice}
                            >
                                {!loading ? (<span><FontAwesomeIcon icon='copy'></FontAwesomeIcon></span>) : (
                                    <Spinner variant="primary" animation="border" size="sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>)}
                            </Button>
                        </Form.Group>
                    }
                </Card.Header>
                <Card.Body className='d-flex justify-content-center align-items-center'>
                    {pdfData != null ?
                        <iframe id="previewPdf" name="previewPdf" src={pdfData} style={{ height: "100%", width: "100%" }}></iframe>
                        :
                        <BeatLoader color={"#9B9B9B"} size={25} />
                    }
                </Card.Body>
            </Card>
            {/* <Card className="mb-3">
                        <Card.Body className='pt-4'>
                            <Row className="pb-3 border-bottom">
                                <Col sm={12} className="d-flex justify-content-center" >
                                    <img src={mySeaLogo} alt="invoice" width={200} />
                                </Col>
                                <Col sm={6} className="text-sm-start" >
                                    <div>Facture <b>{invoiceNumber}</b></div>
                                    <div>Dossier <b>{contract.fileName}</b></div>
                                </Col>
                                <Col col={6} className="text-sm-end mt-3 mt-sm-0">
                                    <div>{contract.yacht.company}<br />
                                        {contract.yacht.address1}<br />
                                        {contract.yacht.address2}<br />
                                        {contract.yacht.city}, {contract.yacht.zipCode}, {contract.yacht.country} <br /> {contract.yacht.vatIntraCom}
                                    </div>
                                </Col>
                                <Col xs={12} className="text-end mt-4" >
                                    <b className="text-sm-end mt-3 mt-sm-0 pe-6">Monaco, le {moment(new Date()).format("DD/MM/YYYY")}</b>
                                </Col>
                            </Row>
                            <Row className="border-bottom">
                                <Col xs={9} xl={10} className="pt-4">
                                    <h6 className="text-500">Commission regarding</h6>
                                    <h5><span className='fw-light'>YACHT</span> {contract.yacht.name}</h5>
                                    <h5><span className='fw-light'>Period</span> {moment(new Date(contract.charter.startDate)).format("DD/MM/YYYY")} to {moment(new Date(contract.charter.endDate)).format("DD/MM/YYYY")}</h5>
                                    <h5><span className='fw-light'>From </span> {contract.charter.placeFrom} to {contract.charter.placeFrom}</h5>
                                    <h5 className='mt-4'><span className='fw-light me-3'>charter Fee</span> {formatter.format(contract.charter.charterFeeAmount)}</h5>
                                    <h5><span className='fw-light me-3'>Commission</span> {officeComm ? officeComm : contract.officeCommission.comm}% of the charter fee</h5>
                                    <h5><span className='fw-light me-3'>VAT</span></h5>
                                    <h5 className='pt-2'><span className='fw-light me-3'>TOTAL</span></h5>
                                </Col>
                                <Col xs={3} xl={2} className="border-start ps-3 pt-4 pb-4">
                                    <h6 className="text-500">&#8205;</h6>
                                    <h5>&#8205; </h5>
                                    <h5>&#8205; </h5>
                                    <h5 className='mt-4'>&#8205; </h5>
                                    <h5 className='text-center'>{officeAmount ? formatter.format(officeAmount) : formatter.format((contract.officeCommission.comm / 100) * contract.charter.charterFeeAmount)}</h5>
                                    <h5 className='text-center'>{formatter.format((contract.officeCommission.comm / 100) * (contract.officeCommission.isTaxIncluded ? contract.charter.vat : 0))}</h5>
                                    <h5 className='text-center pt-2 border-top mx-n3'>{officeAmount ? formatter.format(officeAmount + (officeComm / 100) * (contract.officeCommission.isTaxIncluded ? contract.charter.vat : 0)) : formatter.format(((contract.officeCommission.comm / 100) * contract.charter.charterFeeAmount) + (contract.officeCommission.comm / 100) * (contract.officeCommission.isTaxIncluded ? contract.charter.vat : 0))}</h5>
                                </Col>
                            </Row>
                            {
                                (comment && (<>
                                    <Row className='mt-3'>
                                        <Col lg={12}>{contract.yacht.Comment}</Col>
                                    </Row>
                                </>))
                            }
                            <Row className='mt-4'>
                                <Col lg={6}>
                                    <span><b>Bank address :</b> {contract.office?.bankAddress}</span> <br />
                                    <span><b>Bank code :</b> {contract.office?.bankCode} <span className="mx-3">-</span> <b>Agency code :</b> {contract.office?.codeGuichet}</span> <br />
                                    <span><b>Account number :</b> {contract.office?.accountNumber} <span className="mx-3">-</span> <b>RIB :</b> {contract.office?.ribKey}</span> <br />
                                    <span><b>IBAN :</b> {contract.office?.iban} <span className="mx-3">-</span> <b>BIC :</b> {contract.office?.bic}</span>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="bg-light">
                            <p className="fs--1 mb-0">
                                {contract.office?.address1}<br />
                                {contract.office?.address2}<br />
                                {contract.office?.city} {contract.office?.country}<br />
                                Tel: {contract.office.phone} | Fax: {contract.office.fax} | Email: {contract.office.email}<br />
                                company registration number: {contract.office.registrationNumber} | VAT registration number: {contract.office.vat}
                            </p>
                        </Card.Footer>
                    </Card> */}
        </>
    );


};

export default OfficeInvoicePreview;
