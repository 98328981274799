export class YachtForm {
    emails = [];
    users = [];
    userIds = [];
    id = 0;
    name = '';
    company = '';
    address1 = '';
    address2 = '';
    zipCode = '';
    city = '';
    country = '';
    vatIntraCom = '';
    intermediary = null;
    intermediaryComm = null;
    rE_CAAgent = '';
    rE_CAAgentComm = 0;
    comment = null;
    intermediaryVAT = null;
    officeId = null;
    mySeaBrokerId = null;
    vatRep = false;
    flag = '';
    site = '';
    length = 40;
    ca = false;
    kyc = true;
    mySeaVatApplicable = false;
    ibanOwner = '';
    swiftOwner = '';
    bankAddressOwner = '';
    ibanVat = '';
    swiftVat = '';
    bankAddressVat = '';
    ibanApa = '';
    swiftApa = '';
    bankAddressApa = '';
    ibanOther = '';
    swiftOther = '';
    bankAddressOther = '';
    beneficiary = '';
    beneficiaryAPA = null;
    beneficiaryOther = null;
    beneficiaryVAT = null;
    defaultVATNumber = null;
    secondVATNumber = null;
    thirdVATNumber = null;
    contactId = null;
    contact = null;
    centralAgentId = null;
    centralAgent = null;

    static getYachtPostForm(obj) {
      return (({ centralAgent, contact, ...o }) => o)(obj);
    }
}
