export class OfficeViewModel {
    id: number;
    address1: string;
    address2: string;
    city: string;
    country: string;
    vat: string;
    rci: string;
    dsee: string;
    fax: string;
    phone: string;
    registrationNumber: string;
    email: string;
    cacommission: number;
    retailcommission: number;
    name: string;
    bankAddress: string;
    bankCode: string;
    codeGuichet: string;
    accountNumber: string;
    ribKey: string;
    iban: string;
    bic: string;

    constructor(country: country, city: city) {
        this.id = 0;
        this.address2 = this.address1 = '';
        this.country = country;
        this.city = city;
        this.dsee = this.rci = this.vat = '';
        this.cacommission = this.retailcommission = 0;
    }
};