import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import axiosInstance from 'helpers/axiosInstance';
import { Button, Col } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import ContactDetail from './contactForm';
import { SimpleUserForm } from 'viewModel/userForm';
import { toast } from 'react-toastify';

const columns = [
  {
    accessor: 'firstName',
    Header: 'First name',
    sort: true
  },
  {
    accessor: 'lastName',
    Header: 'Last name',
    sort: true
  },
  {
    accessor: 'email',
    Header: 'Email',
    sort: true
  },
  {
    accessor: 'phone',
    Header: 'Phone',
    sort: true
  }
];

const ContactList = forwardRef(({ supplierId, customSubmit }, ref) => {
  const [state, setState] = useState({
    sortBy: [
      { id: 'firstName', desc: false },
      { id: 'lastName', desc: false }
    ],
    total: 0,
    totalPages: 0,
    remoteData: [],
    pageSize: 10,
    currentPage: 0
  });
  const [userFormData, setUserFormData] = useState(new SimpleUserForm());
  const [userValidated, setUserValidated] = useState(false);
  const [isLoading, showLoading] = useState(false);
  const fetchIdRef = React.useRef(0);
  const newContactRef = React.createRef();
  const userForm = React.createRef();
  const [submitUrl, setSubmitUrl] = useState(`${window.location.origin}/suppliers/${supplierId}/contacts`);
  useImperativeHandle(ref, () => ({
    reinit,
    addUser,
    setUserFormData,
    setShowModal
  }), [])

  useEffect(() => {
    console.log(state);
  }, [state])
  const setShowModal = (b) => newContactRef.current.setShowModal(b);

  const getUsers = React.useCallback(({ page, size, sortBy }) => {
    if (supplierId != null) {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        showLoading(true);
        // let formQuery = $("#tableFilters").serialize();
        // formQuery += `${(formQuery.length > 0 ? "&" : "")}page=${page}`;
        // formQuery += `&take=${size}`;
        // if (sortBy && sortBy.length > 0) {
        // 	let sortQuery = encodeURIComponent(sortBy.map(x => `${x.id} ${x.desc ? "desc" : "asc"}`).join(','));
        // 	formQuery += `&sort=${sortQuery}`;
        // }
        axiosInstance.get(`${window.location.origin}/suppliers/${supplierId}/contacts`)
          .then((res) => {
            setState({
              sortBy: sortBy,
              total: res.data.count,
              totalPages: Math.ceil(res.data.count / (size * 1.0)),
              remoteData: res.data,
              pageSize: size,
              currentPage: page
            });
            showLoading(false);
          });
      }
    }
  }, []);
  const handleContactSubmit = (event) => {
    if (customSubmit != null) {
      customSubmit(userFormData);
      newContactRef.current.setShowModal(false);
    } else {
      axiosInstance.post(submitUrl, userFormData)
        .then((res) => {
          newContactRef.current.setShowModal(false);
          setUserFormData(new SimpleUserForm());
          reinit();
        });
    }
    // formData["attachments"] = attachmentsData.data;
  };

  const handleContactDelete = (event) => {
    axiosInstance.delete(`${window.location.origin}/contacts/${userFormData.id}`).then(x => {
      newContactRef.current.setShowModal(false);
      setUserFormData(new SimpleUserForm());
      reinit();
      toast.success(`Attachment type has been modified.`, {
        theme: 'colored'
      });
    }).catch(e => {
      toast.error(`An error occurred.`, {
        theme: 'colored'
      });
    })
    // formData["attachments"] = attachmentsData.data;
  };

  const reinit = () => {
    getUsers({ page: 0, size: state.pageSize, sortBy: state.sortBy });
  };

  const addUser = (user) => {
    let arr = state.remoteData;
    arr.push(user);
    setState({
      sortBy: state.sortBy,
      total: arr.length,
      totalPages: Math.ceil(arr.length / (state.pageSize * 1.0)),
      remoteData: arr,
      pageSize: state.pageSize,
      currentPage: 0
    });
  }

  const handleRowClick = (row) => {
    setUserFormData(row.original);
    setSubmitUrl(`${window.location.origin}/contacts/${row.original.id}`);
    newContactRef.current.setShowModal(true)
  }

  const handleNewClick = () => {
    setUserFormData(new SimpleUserForm());
    setSubmitUrl(`${window.location.origin}/suppliers/${supplierId}/contacts`);
    newContactRef.current.setShowModal(true);
  }
  /*Table layout*/
  const userTable = () => {

    // /*Filters*/
    // const renderFilters = (visible) => {
    // 	if (visible) {
    // 		return (<></>);
    // 	} else {
    // 		return (
    // 			<>
    // 				<form id="tableFilters">
    // 					<Row className="mb-3 g-3">
    // 						<Col lg={2}>
    // 							<TextFilter label="First Name" id="FirstName" placeholder="search..." />
    // 						</Col>
    // 						<Col lg={2}>
    // 							<TextFilter label="Last Name" id="LastName" placeholder="search..." />
    // 						</Col>
    // 						<Col lg={2}>
    // 							<TextFilter label="Role" id="Role" placeholder="search..." />
    // 						</Col>
    // 						<Col lg={2}>
    // 							<TextFilter label="Email" id="Email" placeholder="search..." />
    // 						</Col>
    // 					</Row>
    // 				</form>
    // 			</>);
    // 	}
    // }

    return (
      <>
        <AdvanceTableWrapper
          columns={columns}
          sortable
          initialSortBy={state.sortBy}
          pagination
          perPage={state.pageSize}
          data={state.remoteData}
          pageCount={state.totalPages}
          fetchData={getUsers}
          initialPage={state.currentPage}
          isLoading={isLoading}
        >

          {/* Filter row
                    {renderFilters(showFilters)} */}

          <AdvanceTable
            rowClick={handleRowClick}
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={state.total}
              pageCount={state.totalPages}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </>
    );
  }

  return (
    <>
      <FalconComponentCard>
        <FalconComponentCard.Header
          light={false}
          className="border-bottom border-200"
        >
          {/*Action Buttons*/}
          <div className="d-flex flex-between-center">
            <Col md="auto" lg={6}>
              <h4>Contacts</h4>
            </Col>
            <Col md="auto" lg={6} className="text-end">
              {/* {!showFilters && <Button variant='secondary' size='sm' className='me-2 px-4' onClick={search}>
                            <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className='pe-1' />Search</Button>}
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="fa-filter"
                            transform="shrink-3"
                            className='me-2'
                            onClick={() => setShowFilters(!showFilters)}
                        >
                            <span className="d-none d-sm-inline-block ms-1">Filters</span>
                        </IconButton> */}
              <IconButton
                variant="primary"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={handleNewClick}
              >
                <span className="d-none d-sm-inline-block ms-1">New</span>
              </IconButton>
              {/*<IconButton*/}
              {/*    variant="falcon-default"*/}
              {/*    size="sm"*/}
              {/*    icon="external-link-alt"*/}
              {/*    transform="shrink-3"*/}
              {/*    onClick={() => { window.open(`${window.location.origin}/persons/export-data?fileName=Users`); }}*/}
              {/*>*/}
              {/*    <span className="d-none d-sm-inline-block ms-1">Export</span>*/}
              {/*</IconButton>*/}
            </Col>
          </div>
        </FalconComponentCard.Header>
        <FalconComponentCard.Body
          children={userTable()}
          scope={{
            AdvanceTableWrapper,
            AdvanceTable,
            AdvanceTableFooter,
          }}
          language="jsx"
          noInline
          noLight
        />
      </FalconComponentCard>
      <ContactDetail
        ref={newContactRef}
        handleContactSubmit={handleContactSubmit}
        setUserFormData={setUserFormData}
        userFormData={userFormData}
        userValidated={userValidated}
        handleContactDelete={handleContactDelete}
      ></ContactDetail>
    </>
  );
});

export default ContactList;
