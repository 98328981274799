export class LoginViewModel {
    password: string;
    confirmPassword: string;
    userName: string;
    email: string;
    role: object;
    isEnabled: boolean;
    address1: string;
    address2: string;

    constructor(email, password) {
        this.email = email;
        this.password = password;
        this.username = email
        this.confirmPassword = password;
        this.role = null;
        this.isEnabled = true;
        this.address1 = this.address2 = '';
    }
};