import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import NotificationsWidget from '../../dashboard-widgets/notifications';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';
import bg4 from 'assets/img/icons/spot-illustrations/corner-4.png';
import StatisticsCard from 'components/dashboards/saas/stats-cards/StatisticsCard';
import axiosInstance from 'helpers/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDashboardOffice, getUserRole, setDashboardOffice } from 'helpers/utils';
import TransactionList from 'components/resume';


const Dashboard = () => {
	const [notifications, setNotifications] = useState({ data: [] });
	const [offices, setOffices] = useState({ data: [] });
	const [activeOffice, setActiveOffice] = useState("all offices");
	const [activeOfficeID, setActiveOfficeID] = useState(-1);
	const [totalContracts, setTotalContracts] = useState(0);
	const [totalCharterEUR, setTotalCharterEUR] = useState(0);
	const [totalCharterUSD, setTotalCharterUSD] = useState(0);
	const role = getUserRole();
	const ref = useRef();


	useEffect(() => {
		axiosInstance.get(`${window.location.origin}/widgets/Notifications`)
			.then((res) => {
				if (res.data) {
					setNotifications({ data: res.data.data });
				}
			});
		axiosInstance.get(`${window.location.origin}/offices/dashboard`)
			.then((res) => {
				if (res.data) {
					setOffices({ data: res.data.offices });
					setTotalCharterEUR(res.data.offices.reduce((prev, next) => prev + next.salesEUR, 0));
					setTotalCharterUSD(res.data.offices.reduce((prev, next) => prev + next.salesUSD, 0));
					setTotalContracts(res.data.countAllContract);
				}

			});
		//axiosInstance.get(`${window.location.origin}/widgets/CalcSummary`)
		//    .then((res) => {
		//        if (res.data) {
		//            setSummary({ data: res.data });
		//        }

		//    });
		document.title = "Dashboard";
	}, []);

	/*console.log(activeOffice);*/

	return (
		<>
			<div className="content pb-0">
				<Row className="mb-3 g-3">
					<div className="container-fluid overflow-auto" id="customScroll">
						<Row className="mb-1 g-3 row flex-nowrap">
							<Col key={0}>
								{/*<Link to={`/components/offices/edit?id=${office.office.id}`} state={{ from: "dashboard" }} style={{ textDecoration: 'none' }}>*/}
								<StatisticsCard className="h-100" stat={{
									title: "Office Summary",
									value: totalCharterEUR,
									value2: totalCharterUSD,
									decimal: false,
									suffix: ' \u20AC',
									suffix2: ' $',
									prefix: '',
									valueClassName: 'text-secondary',
									badgeBg: 'secondary',
									badgeText: totalContracts + ' active contract(s)',
									link: ""

								}} style={activeOfficeID != -1 ? { opacity: 0.5, minWidth: '17rem', cursor: "pointer" } : { minWidth: '17rem', cursor: "pointer" }}
									onClick={() => {
										setActiveOffice("Office Summary");
										setActiveOfficeID(-1);
										setDashboardOffice(null);
										ref.current?.test(null);
									}}
								/>
								{/*</Link>*/}
							</Col>
							{
								offices.data.map((office, index) => {
									return (
										<Col key={index}>
											{/*<Link to={`/components/offices/edit?id=${office.office.id}`} state={{ from: "dashboard" }} style={{ textDecoration: 'none' }}>*/}
											<StatisticsCard className="h-100" stat={{
												title: office.office.name ? office.office.name : (office.office.city + ", " + office.office.country),
												value: office.salesEUR,
												value2: office.salesUSD,
												decimal: false,
												suffix: ' \u20AC',
												suffix2: ' $',
												prefix: '',
												valueClassName: index == 0 ? 'text-danger' : index == 1 ? 'text-info' : index == 2 ? 'text-success' : index == 3 ? 'text-warning' : '',
												badgeBg: index == 0 ? 'danger' : index == 1 ? 'info' : index == 2 ? 'success' : index == 3 ? 'warning' : 'dark',
												badgeText: office.nbContracts + ' active contract(s)',
												link: "" /*`/components/offices/edit?id=${office.office.ids}`*/,
												/* linkText: 'See all',*/
												image: index == 0 ? bg1 : index == 1 ? bg2 : index == 2 ? bg3 : index == 3 ? bg4 : ''
											}} style={office.office.id !== activeOfficeID ? { opacity: 0.5, minWidth: '17.35rem', cursor: "pointer" } : { minWidth: '17.35rem', cursor: "pointer" }}
												onClick={() => {
													setActiveOffice(office.office.city + ", " + office.office.country);
													setActiveOfficeID(office.office.id);
													ref.current?.test(office.office.id);
												}}
											/>
											{/*</Link>*/}
										</Col>
									);
								})
							}
						</Row>
					</div>
				</Row>
				<Row className="g-3">
					<Col md={12} lg={8}>
						<Row className="mb-3 g-3">
							<Col lg={12}>
								<NotificationsWidget show={true} paymentsIn={true} officeID={activeOfficeID} notifications={notifications} />
							</Col>
							<Col lg={12}>
								<NotificationsWidget show={true} paymentsOut={true} officeID={activeOfficeID} notifications={notifications} />
							</Col>
							<Col lg={12}>
								<NotificationsWidget show={true} paymentsReady={true} officeID={activeOfficeID} notifications={notifications} />
							</Col>
						</Row>
					</Col>
					<Col md={12} lg={4}>
						<Row className="mb-3 g-3">
							<Col lg={12}>
								<NotificationsWidget show={true} dash={true} officeID={activeOfficeID} notifications={notifications} />
							</Col>
						</Row>
						{/*{*/}
						{/*    role == "Administrator" && (<>*/}
						{/*        <Row>*/}
						{/*            <EcomStat data={Summary.data.find(o => { return o.officeID == activeOfficeID })} />*/}
						{/*        </Row>*/}
						{/*    </>)*/}
						{/*}*/}
					</Col>
				</Row>
				{(role === "Administrator" || role === "Broker") &&
					<Row>
						<Col>
							<TransactionList ref={ref}></TransactionList>
						</Col>
					</Row>
				}
			</div>
		</>
	);
}

export default Dashboard;
