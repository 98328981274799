import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Card, Col, Row, Form, Button, Spinner, FloatingLabel, Table, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import axiosInstance from 'helpers/axiosInstance';
import YachtFormBase from './YachtFormBase';
import { YachtForm } from 'viewModel/YachtForm';

const CreateYachtForm = (props, ref) => {
	// State
	let form = '';
	const [validated, setValidated] = useState(false);
	const [attachmentsData, setAttachmentsData] = useState({ data: [] });
	const [users, setUsers] = useState({ data: [] });
	const [creationText, setCreationText] = useState("Save");
	const [formData, setFormData] = useState(new YachtForm());
	const [emailList, setEmailList] = useState({ data: [] });

	useImperativeHandle(ref, () => ({
		test: async () => {
			handleSubmit();
		}
	}))

	//Handlers
	let navigate = useNavigate();
	const handleSubmit = async (event) => {
		event?.preventDefault();
		const form = $(".first-form-yacht")[0].checkValidity();
		const formSecond = $(".second-form-yacht")[0].checkValidity();
		const formThird = $(".third-form-yacht")[0].checkValidity();

		if (form === false || formSecond === false || formThird === false) {
			event?.preventDefault();
			event?.stopPropagation();
		}
		else {
			setCreationText("Creating yacht...");
			try {
				let f = YachtForm.getYachtPostForm(formData);
				f.userIds = users.data.map(o => o.id);
				let res = await axiosInstance.post(`${window.location.origin}/yachts`, f);
				if (res.data.id !== '0') {
					toast.success(`Yacht has successfully been created.`, {
						theme: 'colored'
					});
					if (attachmentsData.data.length != 0) {
						setCreationText("Uploading files...");
						if (props.InModal) {
							props.SetModalSaveBtn("Uploading files...");
						}
						const formData = new FormData();
						for (let x = 0; x < attachmentsData.data.length; x++) {
							let obj = attachmentsData.data[x];
							formData.append("files[" + x + "].file", obj.file);
							formData.append("files[" + x + "].type", obj.type);
						}
						formData.append("uploadTo", "yacht-" + res.data.id);
						let test = await axiosInstance.post(`${window.location.origin}/uploadFiles/multiple`, formData);
						toast.success("Files uploaded", { theme: 'colored' });
					}
					if (props.InModal) {
						props.onYachtSave(res.data);
					} else {
						navigate("/components/yachts/edit?id=" + res.data.id);
					}
				} else {
					toast.error(`${res.data.message}`, {
						theme: 'colored'
					});
				}
				$('.save-spinner').attr('hidden', 'hidden');
			} catch (e) {
				console.log(e);
				toast.error("A problem occurred while creating the yacht.");
			}
		}
		setCreationText("Save");
		setValidated(true);
	};

	const handleFieldChange = e => {
		setFormData({
			...formData,
			[e.target.id]: e.target.value
		});
	};
	const handleYachtDetails = (user) => {
		let allUsers = users.data;
		if (allUsers.find(x => x.id == user.id) == null) {
			allUsers.push(user);
			setUsers({ data: allUsers });
		} else {
			toast.warn("User already exists in the list", { theme: 'colored' });
		}
	}

	const loadYachtUserList = () => {
		if (users.data.length > 0) {
			return (
				<>
					{
						users.data.reverse().map((item, index) => (<SoftBadge pill bg="primary" className='me-2'>
							{`${item.fullName}`}
							<FontAwesomeIcon color='red' onClick={() => { handleYachtUserDelete(index); }} style={{ paddingLeft: '5px', cursor: 'pointer' }} icon="fa-solid fa-xmark" />
						</SoftBadge>
						))
					}
				</>
			);

		} else return (<SoftBadge pill bg='secondary' style={{ lineHeight: 1 }} className='mt-2'>No users have been added. </SoftBadge>);
	}

	const handleYachtUserDelete = (index) => {
		let allUsers = users.data;
		allUsers.splice(index, 1);
		setUsers({ data: allUsers });
	}

	return (
		<>
			<PageHeader className={`mb-3 g-3 ${props.InModal ? "d-none" : ""}`} title="">
				<div className="d-inline">
					<h3 className="float-start">Add yacht</h3>
					<div className="float-end">
						<Button
							variant='falcon-primary'
							className='me-2'
							type="submit"
							onClick={e => handleSubmit(e)}
						>
							<Spinner animation="border" variant="primary" size="sm" hidden={true} className="save-spinner" />
							{creationText}
						</Button>
						<Button variant='falcon-warning' onClick={() => { window.history.back(); }}>
							<Spinner animation="border" variant="warning" role="status" size="sm" hidden={true} className="cancel-spinner" />
							Cancel
						</Button>
					</div>
				</div>
			</PageHeader>

			<YachtFormBase
				attachmentsData={attachmentsData}
				setAttachmentsData={setAttachmentsData}
				handleSubmit={handleSubmit}
				handleFieldChange={handleFieldChange}
				formData={formData}
				loadYachtUserList={loadYachtUserList}
				handleYachtDetails={handleYachtDetails}
				setFormData={setFormData}
				validated={validated}
				currentId={0}
				emailList={emailList}
				setEmailList={setEmailList}
			>
			</YachtFormBase>

			{/*Left column*/}


		</>
	);
};

export default forwardRef(CreateYachtForm);
